
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../helpers/Axios";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { TimePicker } from 'react-ios-time-picker';
// import 'react-ios-time-picker/dist/index.css';
// import { DesktopDatePicker } from '@mui/x-date-pickers-pro/DesktopDatePicker';
import s from './Bookings.module.css'
import {
  Card,
  CardBody,
  CardHeader,
  Input,
  Label,
  Col,
  Collapse,
  Container,
  Row,
  DropdownItem,
  ModalBody,
  ModalFooter,
  ModalHeader,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  CardFooter,
} from "reactstrap";
function ViewAgentModal({
  setShow,
  show,
  carname,
  bookingid,
  phonee,
  cartype,
  updateId,
  update_booking_id,
  Update_userDetails,
  Updated_userDetailsphone,
  Updated_bookingSummaryname,
  Updated_userDetails_location,
  Updated_bookingSummarytime,
  Updated_bookingSummarydate,
  Updated_bookingSummaryprice,
  updated_vendorname,
  id,
  agentname,
  location,
  email,
  status,
  alter_email,
  // alter_email2,
  phone,
  alt_phone,
}) {
  //   const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const useCurrentCallback = (callback) => {
    const reference = React.useRef();
    reference.current = callback;
    return (...args) => {
      return reference.current?.(...args);
    };
  };
  const now = new Date();
  // console.log('Current date: ' + now.toDateString());
  // console.log('Current time: ' + now.toTimeString()); 
  const time = now.toLocaleTimeString();
  const date = now.toLocaleDateString();

  // const now = new Date();

  const [reciveData, setReceiveData] = useState([]);
  // const [driverdetails, setDriverdetails]= useState([]);
  const [chauffeurname, setChauffeurname] = useState("")
  const [vehiclenumber, setVehiclenumber] = useState("")
  const [contact, setContact] = useState("")
  const [alternativeno, setAlternativeno] = useState("")
  const [reportingdate, setReportingdate] = useState(new Date());
  const [reportingtime, setReportingtime] = useState(now);
  const [cartypee, setCartype] = useState("")
  const [bookinid, setBookingid] = useState("")
  const [car, setCar] = useState("")
  // setReportingdate(date)
  // setReportingtime(time)

  const showToastMessageUploadedSuccess = () => {
    toast.success("Updated Successfully !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const showToastMessageErroruploading = () => {
    toast.error("Error Updating !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  function driversend() {


    axios.get(`https://api.mylogin.co.in/api/v2/SendSMS?ApiKey=8NzlHH40L/AH3MR83XpYmT5d1EQVofsbB+Vx1jxqUcY=&ClientId=e649ffb9-407e-49ec-8155-8eba78e30620&SenderId=BLKLUX&Message=Greetings from BLCK! Please find your chauffeur and vehicle details below, Chauffeur name:${chauffeurname} Contact number:${contact} Alternate number:${contact} Vehicle number: ${vehiclenumber} Reporting Time:${reportingtime} cheers Team BLCK&MobileNumbers=${phonee}`)
    toast.success('Sms send...')

  }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Greetings from BLCK!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group
              classNameNameName="mb-3"
              controlId="exampleForm.ControlInput1"
            >
              <div className="auth-page-content">
                <Container fluid>
                  <Row>
                    <Col xxl={12}>
                      <Card>
                        <CardBody className="p-7">
                          <div>
                            <CardHeader>
                              <div className="text-center mt-5">
                                <h5>
                                  Please find the your chauffeur and vehicle details below
                                </h5>
                              </div>
                            </CardHeader>
                           <p><b>ID:</b>&nbsp;&nbsp;{updateId}</p>
                           <p><b>Booking ID:</b>&nbsp;&nbsp;{update_booking_id}</p>
                           <p><b>Customer Name:</b> &nbsp;&nbsp;{Update_userDetails}</p>
                           <p><b>Car Name:</b>&nbsp;&nbsp;{Updated_bookingSummaryname}</p>
                           <p><b>Amount:</b>&nbsp;&nbsp;{Updated_bookingSummaryprice}</p>
                           <p><b>User Type:</b>&nbsp;&nbsp;{}</p>
                           <p><b>Agent name:</b>&nbsp;&nbsp;{updated_vendorname}</p>
                           <p><b>location:</b>&nbsp;&nbsp;{Updated_userDetails_location}</p>
                           <p><b>Reporting time and date:</b>&nbsp;&nbsp;{Updated_bookingSummarydate}&nbsp;<span  style={{ fontWeight: 'bold',color:"skyblue"}}>TO</span>&nbsp;{Updated_bookingSummarytime}</p>
                           <p><b>Phone number:</b>&nbsp;&nbsp;{Updated_userDetailsphone}</p>
                           <p><b>Package:</b>&nbsp;&nbsp;{}</p>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Container>
                <ToastContainer />
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          

          {/*  <Button variant="secondary" onClick={handleClose}>
            Close
                              </Button>*/}
        </Modal.Footer>
      </Modal>
    </>
  );
}

// render(<ModalView />);
export default ViewAgentModal;
