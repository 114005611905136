// import React,{useState} from 'react'
// import styles from "./AgentViewmodel.module.css";
// import { Row, Col, CardBody, Card, Alert, Container,     CardHeader,Input, Label, Form, FormFeedback, Button, Table } from "reactstrap";
// import axios from '../../helpers/Axios';
// import { Link } from 'react-router-dom';

// const ViewAgents = ({ setIsOpenView, id, agentname,location, email,status,alter_email,alter_email2,phone,alt_phone}) => {
//     const[updateId,setUpdateId]=useState("")
//     const[updatedagentname,setUpdatedagentName]=useState("")
//     const[updatedlocation,setUpdatedlocation]=useState("")
//     const[updatedemail,setUpdatedemail]=useState("")
//     const[updatedaltemail,setUpdatedaltemail]=useState("")
//     const[updatedaltemai2,setUpdatedaltemail2]=useState("")
//     const[updatedphn,setUpdatedphn]=useState("")
//     const[updatedaltphn,setUpdatedaltPhn]=useState("")

//     const[updatedstatus,setUpdatedstatus]=useState("")
//     const[reciveData,setReceiveData]=useState([])
//     console.log(id);

//   return (
//     <>
//      <div className={styles.darkBG} onClick={() => setIsOpenView(false)} />
//             <div className={styles.centered}>
//                 <div className="auth-page-content" >
//                     <Container fluid  >
//                         <Row>
//                             <Col>
//                                 <Card style={{backgroundColor:"#2a2f34"}} className="mt-2">
//                                     <CardBody className="p-2" id={styles.openedmodal}>
//                                         <div >
//                                             <div className="text-center mt-2">
//                                                 {/* <h6 style={{color:"eee4e4"}} >Agent Information</h6> */}
//                                             </div>
//                                             <div className="p-2 mt-0 justify-content-center">
                                               
//                                                  <Row>
//                         <Col className="col-12">
                            

//                             <Row>
//                                 <Col xxl={16}>
//                                     {/* <Card className="border card-border-primary" style={{backgroundColor:"#2a2f34"}} >
//                                         <CardHeader>
                                            
                                           
//                                         </CardHeader>
                                        
                                      
                                        
                                     
//                                     </Card> */}
//                                     <div className="card text-center">
//                             {/* <h6 className="card-header">
//                             Agent Information 
//                             </h6> */}
//                             <div className="card-body">
//                                              <h5  > Agent Name : {agentname}</h5>
//                                             <h5   className="card-text"> Email : {email}</h5>
//                                             <h5   className="card-text"> Location : {location}</h5>
//                                             <h5  className="card-text"> Phone Number : {phone}</h5>
//                                             <h5   className="card-text"> Alternate Phone  : {alt_phone}</h5>
//                                             <div className="text-end">
//                                             </div>
//                                 {" "}
                                               
//                             </div>
//                             <div className="card-footer text-muted">
//                             <Label
//                                                     className="btn btn-outline-danger  className='text-center centered"
//                                                     for="btn-check-outlined"
                                                   
//                                                     onClick={() => setIsOpenView(false)}
//                                                 >
//                                                    Close
//                                                 </Label>
//                             </div>
//                             </div>
//                                 </Col>
                                
//                             </Row>
//                         </Col>
//                     </Row>

//                                             </div>
//                                         </div>
//                                     </CardBody>
//                                 </Card>
//                             </Col>
//                         </Row>
//                     </Container>
//                 </div>
//             </div>
// </>
//   )
// }

// export default ViewAgents



// import React, { useState } from 'react';
// import Button from 'react-bootstrap/Button';
// import Form from 'react-bootstrap/Form';
// import Modal from 'react-bootstrap/Modal';
// import { Card, CardBody, CardHeader, Input, Label, Col, Collapse, Container, Row, DropdownItem, ModalBody, ModalFooter, ModalHeader, DropdownToggle, DropdownMenu, UncontrolledDropdown, CardFooter } from 'reactstrap';
// // import ImageForView from './ImageForView';

// function ViewAgents({ setShow, show, agentname, location, description, email,alter_email,alter_email2,phone, status,alt_phone }) {
//   //   const [show, setShow] = useState(false);

//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);

//   return (
//     <>
//       <Modal show={show} onHide={handleClose}>
//         <Modal.Header closeButton>
//           <Modal.Title>{agentname}</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <h5>Agent Name : {agentname}</h5>
//           <h5>Location : {location}</h5>
//           <h5>Email : {email}</h5>
//           <h5>Alternative Email : {alter_email}</h5>
//           <h5>Phone Number : {phone}</h5>
//           <h5>Alternative Phone Number: {alt_phone}</h5>
//           <Form>
//             <Form.Group classNameNameName="mb-3" controlId="exampleForm.ControlInput1">
//               <CardBody>
//                 <div className="d-flex align-items-center">
//                   <div >
                    
//                   </div>
//                   <div className="flex-grow-1 ms-3">

//                   </div>
//                 </div>
//               </CardBody>

//             </Form.Group>
//             <Form.Group
//               classNameNameName="mb-3"
//               controlId="exampleForm.ControlTextarea1"
//             >
//             </Form.Group>
//           </Form>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleClose}>
//             Close
//           </Button>

//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// }

// // render(<ModalView />);
// export default ViewAgents


import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Card, CardBody, CardHeader, Input, Label, Col, Collapse, Container, Row, DropdownItem, ModalBody, ModalFooter, ModalHeader, DropdownToggle, DropdownMenu, UncontrolledDropdown, CardFooter } from 'reactstrap';
// import ImageForView from './ImageForView';
function ViewAgents ({ setShow, show, agentname, location, description, email,alter_email,phone, status,alt_phone }) {
  //   const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Modal show={show} onHide={handleClose}  >
        <Modal.Header closeButton>
          <Modal.Title>{agentname}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                <Form style={{display:'flex', justifyContent:"center", alignItems:"center"}} >
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <CardBody>
                      <div className="d-flex align-items-center">
                      <htmlForm>
        <div className="mb-3">
          <label htmlFor="exampleInputEmail1" className="form-label">Agent Name &nbsp; :&nbsp; {agentname}</label>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">Location &nbsp; : &nbsp; {location}</label>
        </div>
     
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">Email &nbsp;: &nbsp; {email}</label>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">Alternate Email 1  &nbsp;:&nbsp; {alter_email}</label>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">Phone Number &nbsp; :&nbsp; {phone}</label>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">Alternate Phone Number &nbsp;:&nbsp; {alt_phone}</label>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">Status &nbsp;:&nbsp; {status}</label>
        </div>
       
        
        
      </htmlForm>
                  <div className="flex-grow-1 ms-3">

                  </div>
                </div>
              </CardBody>

            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>

        </Modal.Footer>
      </Modal>
    </>
  );
}

// render(<ModalView />);
export default ViewAgents