import React,{useState} from 'react'
import styles from "./ViewCouponmodal.module.css";
import { Row, Col, CardBody, Card, Alert, Container,     CardHeader,Input, Label, Form, FormFeedback, Button, Table } from "reactstrap";
import axios from '../../helpers/Axios';
import { Link } from 'react-router-dom';
const ViewCoupon = ({ setIsOpenViewcou,id, car_type, start_date, end_date, description, code, deduct_deposit, discounts_in_amount, discounts_type, max_discount_amount, status }) => {

  return (
    <>
      <div className={styles.darkBG} onClick={() => setIsOpenViewcou(false)} />
      <div className={styles.centered}>
        <div className="auth-page-content">
          <Container fluid>
            <Row>
              <Col>
                <Card className="mt-2">
                  <CardBody className="p-2" id={styles.openedmodal}>
                    <div>
                      <div className="text-center mt-2">
                        <h6 className="text-primary">Coupon Information</h6>
                      </div>
                      <div className="p-2 mt-0 justify-content-center">
                        <Row>
                          <Col className="col-12">
                            <Row>
                              <Col xxl={16}>
                                <Card className="border card-border-primary">
                                  <CardHeader>
                                    <h5 className="card-title mb-0">
                                      Car Brand
                                    </h5>
                                  </CardHeader>
                                  <CardBody>
                                  <p className="card-text">
                                      {" "}
                                      Car Type : {car_type}
                                    </p>
                                    <p className="card-text">
                                      {" "}
                                      Code : {code}
                                    </p>
                                    <p className="card-text">
                                      {" "}
                                      Start Date : {start_date}
                                    </p>
                                    <p className="card-text">
                                      {" "}
                                      End Date : {end_date}
                                    </p>
                                    <p className="card-text">
                                      {" "}
                                      Description : {description}
                                    </p>
                                    <p className="card-text">
                                      {" "}
                                      Deduct Diposite : {deduct_deposit}
                                    </p>
                                    <p className="card-text">
                                      {" "}
                                      Discount Amount : {discounts_in_amount}
                                    </p>
                                    <p className="card-text">
                                      {" "}
                                      Discounts Type : {discounts_type}
                                    </p>
                                    <p className="card-text">
                                      {" "}
                                      Maximum Discount Amount : {max_discount_amount}
                                    </p>
                                    <p className="card-text">
                                      {" "}
                                      Status : {status}
                                    </p>
                                    <div className="text-end">
                                      {" "}
                                      <Label
                                        className="btn btn-outline-danger  className='text-center centered"
                                        for="btn-check-outlined"
                                        style={{ marginRight: "200px" }}
                                        onClick={() => setIsOpenViewcou(false)}
                                      >
                                        Close
                                      </Label>
                                    </div>
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default ViewCoupon;
