import React, { useEffect, useState } from "react";
import styles from "./Carbrandmodal.module.css";
import axios from "../../helpers/Axios";
import { Link } from "react-router-dom";
import {
  FormGrid,
  Gutters,
  VerticalForm,
  HorizontalForm,
  HorizontalFormLabelSizing,
  ColumnSizing,
  AutoSizing,
  InlineForms,
  FloatingLabels,
} from "../AddCarFleet/FormsLayoutDummy";
import PreviewCardHeader from "../AddCarFleet/PreviewCardHeaderDummy";

// import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import {
  Card,
  CardBody,
  CardHeader,
  Input,
  Label,
  Col,
  Collapse,
  Container,
  Row,
  DropdownItem,
  ModalBody,
  ModalFooter,
  ModalHeader,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  CardFooter,
} from "reactstrap";
// import ImageForView from "./ImageForView";
function UpdateCarbrand({
  setShow,
  show,
  id,
  car_brand,
  car_logo,
  status,
}) {
  //   const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [updateId, setUpdateId] = useState(id);
  const [updateicon, setUpdateicon] = useState(car_logo);
  const [updateBrandname, setUpdateBrandname] = useState(car_brand);
  const [updatedstatus, setUpdatedstatus] = useState(status);

  const uploadIcon = (e) => {
    e.preventDefault();
    const Image = e.target.files[0];
    // console.log(e.target.files[0].name);
    setUpdateicon("car_brand/"+e.target.files[0].name);
    // setFiles(e.target.files[0].name)
    // console.log(Image,"################");
    if (Image != null) {
      const data = new FormData();
      data.append("Image", Image);
      // console.log(data);
      axios
        .post("/uploadlogo", data)
        .then(() => {
          // setTimeout(()=>{
          //   setUploadinglogo("")
          // },1500)
          // console.log("Uploaded icon");
        })
        .catch(() => {
          // console.log("Error in Uploading logo");
        });
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>CarBrand Information</Modal.Title>
        </Modal.Header>
        <div className="auth-page-content">
          <Container fluid>
            <Row>
              <Col xxl={12}>
                <Card>
                  {/* <PreviewCardHeader className="text-center" title="Coupons" /> */}
                  <CardBody>
                    <div className="live-preview">
                      <Form action="#">
                        <Row>
                          <Col lg={12}>
                            <div className="mb-3">
                              <Label
                                htmlFor="employeeNamecode"
                                className="form-label"
                              >
                                Brand Name
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="employeeNamecode"
                                defaultValue={car_brand}
                                placeholder="Create Code"
                                onChange={(e) =>
                                  setUpdateBrandname(e.target.value)
                                }
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={12}>
                            <Card>
                              <CardHeader className="card-header">
                                <h5 className="text-center">Brand Logo</h5>
                              </CardHeader>
                              <CardBody>
                                <p className="text-muted"></p>
                                <Input
                                  type="file"
                                  className="form-control"
                                  id="carfile"
                                  placeholder="upload icon"
                                  onChange={uploadIcon}
                                />
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="employeeNamestatus"
                                className="form-label"
                              >
                                Status
                              </Label>
                              <select
                                id="employeeNamestatus"
                                className="form-select"
                                defaultValue={status}
                                data-choices
                                data-choices-sorting="true"
                                onChange={(e) =>
                                  setUpdatedstatus(e.target.value)
                                }
                              >
                                <option value={"Active"}>Active</option>
                                <option value={"Inactive"}>Inactive</option>
                              </select>
                            </div>
                          </Col>
                        </Row>
                        {/* </Col> */}
                      </Form>
                    </div>
                    <div className="d-none code-view">
                      <pre
                        className="language-markup"
                        style={{ height: "375px" }}
                      >
                        <code>{/* <VerticalForm /> */}</code>
                      </pre>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={() => {
              let payload = {
                id: id,
                car_brand: updateBrandname,
                car_logo: updateicon,
                status: updatedstatus,
              };
              axios
                .put("/carsbrand", payload)
                .then((res) => {
                  // console.log("carbrand updated")
                })
                .catch((err) => {
                  // console.log("Error")
                });
            }}
          >
            Update
          </Button>

          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

// render(<ModalView />);
export default UpdateCarbrand;
