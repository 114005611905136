import React, { useEffect, useState } from "react";
import styles from "./Surgemodal.module.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Input,
  Label,
  Form,
  CardHeader,
  FormFeedback,
  Button,
  Table,
} from "reactstrap";
import axios from "../../helpers/Axios";
import { Link } from "react-router-dom";
import {
  FormGrid,
  Gutters,
  VerticalForm,
  HorizontalForm,
  HorizontalFormLabelSizing,
  ColumnSizing,
  AutoSizing,
  InlineForms,
  FloatingLabels,
} from "../AddCarFleet/FormsLayoutDummy";
import PreviewCardHeader from "../AddCarFleet/PreviewCardHeaderDummy";
import BreadCrumb from "../../Components/Common/BreadCrumb";
function SurgeUpdate({
    luxury_cd, setIsOpensurge, luxury_sd, luxury_at, standard_cd, standard_sd, standard_at, yatch, id
}) {
  // for updating the data in database
  const [updateId, setUpdateId] = useState(id);
  const [luxury_cdupdated,setLuxury_cdupdated] = useState(luxury_cd);
  const [luxury_sdupdated,setLuxury_sdupdated] = useState(luxury_sd);
  const [luxury_atupdated,setLuxury_atupdated] = useState(luxury_at);
  const [standard_cdupdated,setStandard_cdupdated] = useState(standard_cd);
  const [standard_sdupdated,setStandard_sdupdated] = useState(standard_sd);
  const [standard_atupdated,setStandard_atupdated] = useState(standard_at);
  const [yatchupdated,setYatchupdated] = useState(yatch);

  const showToastMessageforupdateSurge = () => {
    toast.success("Surge Updated Sucessfully !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  return (
    <>
      <div className={styles.darkBG} onClick={() => setIsOpensurge(false)} />
      <div className={styles.centered}>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Surge" pageTitle="Edit" />
          <Row>
            <Col xxl={12}>
              <Card>
                <PreviewCardHeader className="text-center" title="Surge" />
                <CardBody>
                  <div className="live-preview">
                    <Form action="#">
                      <Row>
                        <Label htmlFor="fullnameInput" className="form-label">
                          Luxury
                        </Label>
                        <Col md={4}>
                          <Input
                            type="text"
                            className="form-control"
                            id="fullnameInput"
                            defaultValue={luxury_cd}
                            placeholder="CD Surge"
                            onChange={(e) => setLuxury_cdupdated(e.target.value)}
                          />
                        </Col>
                        <Col md={4}>
                          <Input
                            type="text"
                            className="form-control"
                            id="fullnameInput"
                            defaultValue={luxury_sd}
                            placeholder="SD Surge"
                            onChange={(e) => setLuxury_sdupdated(e.target.value)}
                          />
                        </Col>
                        <Col md={4}>
                          <Input
                            type="text"
                            className="form-control"
                            id="fullnameInput"
                            defaultValue={luxury_at}
                            placeholder="AT Surge"
                            onChange={(e) => setLuxury_atupdated(e.target.value)}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Label htmlFor="fullnameInput" className="form-label">
                          Standard
                        </Label>
                        <Col md={4}>
                          <Input
                            type="text"
                            className="form-control"
                            id="fullnameInput"
                            defaultValue={standard_cd}
                            placeholder="CD Surge"
                            onChange={(e) => setStandard_cdupdated(e.target.value)}
                          />
                        </Col>
                        <Col md={4}>
                          <Input
                            type="text"
                            className="form-control"
                            id="fullnameInput"
                            defaultValue={standard_sd}
                            placeholder="SD Surge"
                            onChange={(e) => setStandard_sdupdated(e.target.value)}
                          />
                        </Col>
                        <Col md={4}>
                          <Input
                            type="text"
                            className="form-control"
                            id="fullnameInput"
                            defaultValue={standard_at}
                            placeholder="AT Surge"
                            onChange={(e) => setStandard_atupdated(e.target.value)}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Label htmlFor="fullnameInput" className="form-label">
                          Yatch
                        </Label>
                        <Col md={4}>
                          <Input
                            type="text"
                            className="form-control"
                            id="fullnameInput"
                            defaultValue={yatch}
                            placeholder="Yatch"
                            onChange={(e) => setYatchupdated(e.target.value)}
                          />
                        </Col>
                      </Row>
                      <div className="text-center">
                        {/* <Input
                      type="checkbox"
                      className="btn-check"
                      id="btn-check-outlined"
                    />
                    <Label
                      className="btn btn-outline-primary text-centre"
                      for="btn-check-outlined"
                    >
                      Add
                    </Label>
                    &nbsp; &nbsp; &nbsp;
                    <Input
                      type="checkbox"
                      className="btn-check"
                      id="btn-check-outline"
                    />
                    <Label
                      className="btn btn-outline-primary text-centre"
                      for="btn-check-outline"
                    >
                      Cancel
                    </Label> */}
                        <Label
                          className="btn btn-outline-primary text-centre"
                          for="btn-check-outlined"
                          onClick={() => {
                           
                            let payload = {
                              "id": id,
                              "luxury_cd": luxury_cdupdated,
                              "luxury_sd": luxury_sdupdated,
                              "luxury_at": luxury_atupdated,
                              "standard_cd": standard_cdupdated,
                              "standard_sd": standard_sdupdated,
                              "standard_at": standard_atupdated,
                              "yatch": yatchupdated,
                            };
                            // console.log(payload)
                            axios
                              .put(
                                "/surge",
                                payload
                              )
                              .then((res) => {
                                showToastMessageforupdateSurge()
                                setTimeout(()=>{
                                    setIsOpensurge(false);
                                },2000)
                              })
                              .catch((err) => {
                                alert(err);
                              });
                          }}
                        >
                          Update
                        </Label>
                        &nbsp; &nbsp; &nbsp;
                        <Label
                          className="btn btn-outline-danger"
                          for="danger-outlinedremove"
                          onClick={() => {
                            setIsOpensurge(false);
                          }}
                        >
                          Close
                        </Label>
                      </div>
                      {/* </Col> */}
                    </Form>
                  </div>
                  <div className="d-none code-view">
                    <pre
                      className="language-markup"
                      style={{ height: "375px" }}
                    >
                      <code>{/* <VerticalForm /> */}</code>
                    </pre>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ToastContainer/>
        </Container>
      </div>
      </div>
    </>
  );
}
export default SurgeUpdate;
