// import React, { useEffect, useState } from "react";
// import styles from "./Couponmodal.module.css";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import {
//   Row,
//   Col,
//   CardBody,
//   Card,
//   Alert,
//   Container,
//   Input,
//   Label,
//   Form,
//   CardHeader,
//   FormFeedback,
//   Button,
//   Table,
// } from "reactstrap";
// import axios from "axios";
// import { Link } from "react-router-dom";
// import {
//   FormGrid,
//   Gutters,
//   VerticalForm,
//   HorizontalForm,
//   HorizontalFormLabelSizing,
//   ColumnSizing,
//   AutoSizing,
//   InlineForms,
//   FloatingLabels,
// } from "../AddCarFleet/FormsLayoutDummy";
// import PreviewCardHeader from "../AddCarFleet/PreviewCardHeaderDummy";
// function CouponUpdate({
//   setIsOpen,
//   id,
//   car_type,
//   code,
//   start_date,
//   end_date,
//   description,
//   deduct_deposit,
//   discounts_in_amount,
//   discounts_type,
//   maxdiscount_amount,
//   status,
// }) {
//   // for updating the data in database
//   const [updateId, setUpdateId] = useState(id);
//   const [updateCartype, setUpdateCartype] = useState(car_type);
//   const [updateCode, setUpdateCode] = useState(code);
//   const [updateStartdate, setUpdateStartdate] = useState(start_date);
//   const [updateEnddate, setUpdateEnddate] = useState(end_date);
//   const [updateDescription, setUpdateDescription] = useState(description);
//   const [updateDeductdiposite, setUpdateDeductdiposite] =
//     useState(deduct_deposit);
//   const [updateDiscountamount, setUpdateDiscountamount] =
//     useState(discounts_in_amount);
//   const [updatedDiscounttype, setUpdatedDiscounttype] =
//     useState(discounts_type);
//   const [updatedmaxdiscount, setUpdatedmaxdiscount] =
//     useState(maxdiscount_amount);
//   const [updateStatus, setUpdateStatus] = useState(status);

//   const showToastMessageforupdateCoupon = () => {
//     toast.success("Coupon Updated Sucessfully !", {
//       position: toast.POSITION.TOP_CENTER,
//     });
//   };

//   return (
//     <>
//       <div className={styles.darkBG} onClick={() => setIsOpen(false)} />
//       <div className={styles.centered}>
//         <div className="auth-page-content">
//           <Container fluid>
//             {/* <BreadCrumb title="Coupons" pageTitle="Add" /> */}
//             <Row>
//               <Col xxl={12}>
//                 <Card>
//                   <PreviewCardHeader className="text-center" title="Coupons" />
//                   <CardBody>
//                     <div className="live-preview">
//                       <Form action="#">
//                         <Row>
//                           <Col lg={6}>
//                             <div className="mb-3">
//                               <Label
//                                 htmlFor="employeeNamecar"
//                                 className="form-label"
//                               >
//                                 Car Type
//                               </Label>
//                               <select
//                                 id="employeeNamecar"
//                                 className="form-select"
//                                 defaultValue={car_type}
//                                 data-choices
//                                 data-choices-sorting="true"
//                                 onChange={(e) => {
//                                   setUpdateCartype(e.target.value);
//                                 }}
//                               >
//                                 <option>Choose Car Type</option>
//                                 <option value={"Super Car"}>Super Car</option>
//                                 <option value={"Standard"}>Standard</option>
//                                 <option value={"Luxury"}>Luxury</option>
//                                 <option value={"Executive"}>Executive</option>
//                                 <option value={"Premium"}>Premium</option>
//                               </select>
//                             </div>
//                           </Col>
//                           <Col lg={6}>
//                             <div className="mb-3">
//                               <Label
//                                 htmlFor="employeeNamecode"
//                                 className="form-label"
//                               >
//                                 Code
//                               </Label>
//                               <Input
//                                 type="text"
//                                 className="form-control"
//                                 id="employeeNamecode"
//                                 defaultValue={code}
//                                 placeholder="Create Code"
//                                 onChange={(e) => setUpdateCode(e.target.value)}
//                               />
//                             </div>
//                           </Col>
//                         </Row>
//                         <Row>
//                           <Col lg={6}>
//                             <div className="mb-3">
//                               <Label
//                                 htmlFor="employeeNamestart"
//                                 className="form-label"
//                               >
//                                 Start Date
//                               </Label>
//                               <Input
//                                 type="date"
//                                 className="form-control"
//                                 id="employeeNamestart"
//                                 defaultValue={start_date}
//                                 placeholder="Start date"
//                                 onChange={(e) =>
//                                   setUpdateStartdate(e.target.value)
//                                 }
//                               />
//                             </div>
//                           </Col>
//                           <Col lg={6}>
//                             <div className="mb-3">
//                               <Label
//                                 htmlFor="employeeNameend"
//                                 className="form-label"
//                               >
//                                 End Date
//                               </Label>
//                               <Input
//                                 type="date"
//                                 className="form-control"
//                                 id="employeeNameend"
//                                 defaultValue={end_date}
//                                 placeholder="End Date"
//                                 onChange={(e) =>
//                                   setUpdateEnddate(e.target.value)
//                                 }
//                               />
//                             </div>
//                           </Col>
//                         </Row>
//                         <Row>
//                           <Col lg={6}>
//                             <div className="mb-3">
//                               <Label
//                                 htmlFor="employeeNamedesc"
//                                 className="form-label"
//                               >
//                                 Description
//                               </Label>
//                               <Input
//                                 type="text"
//                                 className="form-control"
//                                 id="employeeNamedesc"
//                                 defaultValue={description}
//                                 placeholder="Write description"
//                                 onChange={(e) =>
//                                   setUpdateDescription(e.target.value)
//                                 }
//                               />
//                             </div>
//                           </Col>
//                           <Col lg={6}>
//                             <div>
//                               <label
//                                 name="deposite"
//                                 htmlFor="amountdata"
//                                 className="form-check-label form-label"
//                               >
//                                 Discount Amount
//                               </label>
//                               <input
//                                 id="amountdata"
//                                 name="amount"
//                                 defaultValue={discounts_in_amount}
//                                 className="form-control"
//                                 onChange={(e) =>
//                                   setUpdateDiscountamount(e.target.value)
//                                 }
//                                 placeholder="Enter discount Amount"
//                               />
//                             </div>
//                           </Col>
//                         </Row>
//                         <Row>
//                           <Col lg={6}>
//                             <div className="mb-3">
//                               <Label
//                                 htmlFor="employeeNameamounttype"
//                                 className="form-label"
//                               >
//                                 Amount Type
//                               </Label>
//                               <select
//                                 id="employeeNameamounttype"
//                                 className="form-select"
//                                 defaultValue={discounts_type}
//                                 data-choices
//                                 data-choices-sorting="true"
//                                 onChange={(e) => {
//                                   setUpdatedDiscounttype(e.target.value);
//                                 }}
//                               >
//                                 <option>Choose Discount Type</option>
//                                 <option value={"Amount"}>Amount</option>
//                                 <option value={"Percentage"}>Percentage</option>
//                               </select>
//                             </div>
//                           </Col>
//                           <Col lg={6}>
//                             <div className="mb-3">
//                               <Label
//                                 htmlFor="employeeNamedescamount"
//                                 className="form-label"
//                               >
//                                 Maximum Discount
//                               </Label>
//                               <Input
//                                 type="text"
//                                 className="form-control"
//                                 id="employeeNamedescamount"
//                                 defaultValue={maxdiscount_amount}
//                                 placeholder="Enter maximum discount amount"
//                                 onChange={(e) =>
//                                   setUpdatedmaxdiscount(e.target.value)
//                                 }
//                               />
//                             </div>
//                           </Col>
//                         </Row>
//                         <Row>
//                         <Col lg={6}>
//                             <div className="mb-3">
//                               <Label
//                                 htmlFor="employeeNamededuct"
//                                 className="form-label"
//                               >
//                                 Deduct Diposite for Self Drive
//                               </Label>
//                               <select
//                                 id="employeeNamededuct"
//                                 className="form-select"
//                                 defaultValue={deduct_deposit}
//                                 data-choices
//                                 data-choices-sorting="true"
//                                 onChange={(e) =>
//                                   setUpdateDeductdiposite(e.target.value)
//                                 }
//                               >
//                                 <option value={"Allow"}>Allow</option>
//                                 <option value={"Not Allow"}>Not Allow</option>
//                               </select>
//                             </div>
//                           </Col>
//                         <Col lg={6}>
//                             <div className="mb-3">
//                               <Label
//                                 htmlFor="employeeNamestatus"
//                                 className="form-label"
//                               >
//                                 Status
//                               </Label>
//                               <select
//                                 id="employeeNamestatus"
//                                 className="form-select"
//                                 defaultValue={status}
//                                 data-choices
//                                 data-choices-sorting="true"
//                                 onChange={(e) =>
//                                   setUpdateStatus(e.target.value)
//                                 }
//                               >
//                                 <option value={"Active"}>Active</option>
//                                 <option value={"Inactive"}>Inactive</option>
//                               </select>
//                             </div>
//                           </Col>
//                         </Row>
//                         <Col lg={6}>
//                           <div className="mt-4 text-center">
//                             <button
//                               className="btn btn-outline-primary "
//                               // for="btn-check-outlined"
//                               onClick={() => {
//                                 let payload = {
//                                   "car_type": updateCartype,
//                                   "id": id,
//                                   "code": updateCode,
//                                   "start_date": updateStartdate,
//                                   "end_date": updateEnddate,
//                                   "description": updateDescription,
//                                   "deduct_deposit": updateDeductdiposite,
//                                   "discounts_in_amount": updateDiscountamount,
//                                   "discounts_type": updatedDiscounttype,
//                                   "maxdiscount_amount": updatedmaxdiscount,
//                                   "status": updateStatus,
//                                 };
//                                 axios
//                                   .put(
//                                     "/AddCoupondata",
//                                     payload
//                                   )
//                                   .then((res) => {
//                                     showToastMessageforupdateCoupon()
//                                     setTimeout(()=>{
//                                         setIsOpen(false);
//                                     },2000)
//                                   })
//                                   .catch((err) => {
//                                     alert(err);
//                                   });
//                               }}
//                             >
//                               Update
//                             </button>
//                             &nbsp;&nbsp;&nbsp;
//                             <button
//                               style={{ textAlign: "center" }}
//                               className="btn btn-outline-danger "
//                               // for="btn-check-outlined"
//                               onClick={() => {
//                                 setIsOpen(false);
//                               }}
//                             >
//                               Close
//                             </button>
//                           </div>
//                         </Col>
//                         {/* </Col> */}
//                       </Form>
//                     </div>
//                     <div className="d-none code-view">
//                       <pre
//                         className="language-markup"
//                         style={{ height: "375px" }}
//                       >
//                         <code>{/* <VerticalForm /> */}</code>
//                       </pre>
//                     </div>
//                   </CardBody>
//                 </Card>
//               </Col>
//             </Row>
//           </Container>
//           <ToastContainer/>
//         </div>
//       </div>
//     </>
//   );
// }
// export default CouponUpdate;

import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../helpers/Axios";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import {
  Card,
  CardBody,
  CardHeader,
  Input,
  Label,
  Col,
  Collapse,
  Container,
  Row,
  DropdownItem,
  ModalBody,
  ModalFooter,
  ModalHeader,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  CardFooter,
} from "reactstrap";
function CouponUpdate({
  setShow,
  show,
  id,
  car_type,
  code,
  start_date,
  end_date,
  description,
  deduct_deposit,
  discounts_in_amount,
  discounts_type,
  max_discount_amount,
  status,
}) {
  //   const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [updateId, setUpdateId] = useState(id);
  const [updateCartype, setUpdateCartype] = useState(car_type);
  const [updateCode, setUpdateCode] = useState(code);
  const [updateStartdate, setUpdateStartdate] = useState(start_date);
  const [updateEnddate, setUpdateEnddate] = useState(end_date);
  const [updateDescription, setUpdateDescription] = useState(description);
  const [updateDeductdiposite, setUpdateDeductdiposite] =
    useState(deduct_deposit);
  const [updateDiscountamount, setUpdateDiscountamount] =
    useState(discounts_in_amount);
  const [updatedDiscounttype, setUpdatedDiscounttype] =
    useState(discounts_type);
  const [updatedmaxdiscount, setUpdatedmaxdiscount] =
    useState(max_discount_amount);
  const [updateStatus, setUpdateStatus] = useState(status);

  const showToastMessageforupdateCoupon = () => {
    toast.success("Coupon Updated Sucessfully !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const [newstatus, setNewstatus] = useState("");

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Coupon Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group
              classNameNameName="mb-3"
              controlId="exampleForm.ControlInput1"
            >
              <div className="auth-page-content">
                <Container fluid>
                  {/* <BreadCrumb title="Coupons" pageTitle="Add" /> */}
                  <Row>
                    <Col xxl={12}>
                      <Card>
                        {/* <PreviewCardHeader className="text-center" title="Coupons" /> */}
                        <CardBody>
                          <CardHeader>
                            <div className="text-center mt-10">
                              <h5 className="text-primary">Edit Coupon</h5>
                            </div>
                          </CardHeader>
                          <div className="live-preview">
                            <Form action="#">
                              <Row>
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label
                                      htmlFor="employeeNamecar"
                                      className="form-label"
                                    >
                                      Car Type
                                    </Label>
                                    <select
                                      id="employeeNamecar"
                                      className="form-select"
                                      defaultValue={car_type}
                                      data-choices
                                      data-choices-sorting="true"
                                      onChange={(e) => {
                                        setUpdateCartype(e.target.value);
                                      }}
                                    >
                                      <option>Choose Car Type</option>
                                      <option value={"Super Car"}>
                                        Super Car
                                      </option>
                                      <option value={"Standard"}>
                                        Standard
                                      </option>
                                      <option value={"Luxury"}>Luxury</option>
                                      <option value={"Executive"}>
                                        Executive
                                      </option>
                                      <option value={"Premium"}>Premium</option>
                                    </select>
                                  </div>
                                </Col>
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label
                                      htmlFor="employeeNamecode"
                                      className="form-label"
                                    >
                                      Code
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="employeeNamecode"
                                      defaultValue={code}
                                      placeholder="Create Code"
                                      onChange={(e) =>
                                        setUpdateCode(e.target.value)
                                      }
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label
                                      htmlFor="employeeNamestart"
                                      className="form-label"
                                    >
                                      Start Date
                                    </Label>
                                    <Input
                                      type="date"
                                      className="form-control"
                                      id="employeeNamestart"
                                      defaultValue={start_date}
                                      placeholder="Start date"
                                      onChange={(e) =>
                                        setUpdateStartdate(e.target.value)
                                      }
                                    />
                                  </div>
                                </Col>
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label
                                      htmlFor="employeeNameend"
                                      className="form-label"
                                    >
                                      End Date
                                    </Label>
                                    <Input
                                      type="date"
                                      className="form-control"
                                      id="employeeNameend"
                                      defaultValue={end_date}
                                      placeholder="End Date"
                                      onChange={(e) =>
                                        setUpdateEnddate(e.target.value)
                                      }
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label
                                      htmlFor="employeeNamedesc"
                                      className="form-label"
                                    >
                                      Description
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="employeeNamedesc"
                                      defaultValue={description}
                                      placeholder="Write description"
                                      onChange={(e) =>
                                        setUpdateDescription(e.target.value)
                                      }
                                    />
                                  </div>
                                </Col>
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label
                                      name="deposite"
                                      htmlFor="amountdata"
                                      className="form-label"
                                    >
                                      Discount Amount
                                    </Label>
                                    <input
                                      className="form-control"
                                      id="amountdata"
                                      name="amount"
                                      defaultValue={discounts_in_amount}
                                      onChange={(e) =>
                                        setUpdateDiscountamount(e.target.value)
                                      }
                                      placeholder="Enter discount Amount"
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label
                                      htmlFor="employeeNameamounttype"
                                      className="form-label"
                                    >
                                      Amount Type
                                    </Label>
                                    <select
                                      id="employeeNameamounttype"
                                      className="form-select"
                                      defaultValue={discounts_type}
                                      data-choices
                                      data-choices-sorting="true"
                                      onChange={(e) => {
                                        setUpdatedDiscounttype(e.target.value);
                                      }}
                                    >
                                      <option>Choose Discount Type</option>
                                      <option value={"Amount"}>Amount</option>
                                      <option value={"Percentage"}>
                                        Percentage
                                      </option>
                                    </select>
                                  </div>
                                </Col>
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label
                                      htmlFor="employeeNamedescamount"
                                      className="form-label"
                                    >
                                      Maximum Discount
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="employeeNamedescamount"
                                      defaultValue={max_discount_amount}
                                      placeholder="Enter maximum discount amount"
                                      onChange={(e) =>
                                        setUpdatedmaxdiscount(e.target.value)
                                      }
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label
                                      htmlFor="employeeNamededuct"
                                      className="form-label"
                                    >
                                      Deduct Diposite for Self Drive
                                    </Label>
                                    <select
                                      id="employeeNamededuct"
                                      className="form-select"
                                      defaultValue={deduct_deposit}
                                      data-choices
                                      data-choices-sorting="true"
                                      onChange={(e) =>
                                        setUpdateDeductdiposite(e.target.value)
                                      }
                                    >
                                      <option value={"Allow"}>Allow</option>
                                      <option value={"Not Allow"}>
                                        Not Allow
                                      </option>
                                    </select>
                                  </div>
                                </Col>
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label
                                      htmlFor="employeeNamestatus"
                                      className="form-label"
                                    >
                                      Status
                                    </Label>
                                    <select
                                      id="employeeNamestatus"
                                      className="form-select"
                                      defaultValue={status}
                                      data-choices
                                      data-choices-sorting="true"
                                      onChange={(e) =>
                                        setUpdateStatus(e.target.value)
                                      }
                                    >
                                      <option value={"Active"}>Active</option>
                                      <option value={"Inactive"}>
                                        Inactive
                                      </option>
                                    </select>
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          </div>
                          <div className="d-none code-view">
                            <pre
                              className="language-markup"
                              style={{ height: "375px" }}
                            >
                              <code>{/* <VerticalForm /> */}</code>
                            </pre>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Container>
                <ToastContainer />
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={() => {

              let payload = {
                car_type: updateCartype,
                id: id,
                code: updateCode,
                start_date: updateStartdate,
                end_date: updateEnddate,
                description: updateDescription,
                deduct_deposit: updateDeductdiposite,
                discounts_in_amount: updateDiscountamount,
                discounts_type: updatedDiscounttype,
                max_discount_amount: updatedmaxdiscount,
                status: updateStatus,
              };
              axios
                .put("/AddCoupondata", payload)
                .then((res) => {
                  showToastMessageforupdateCoupon();
                  setTimeout(() => {
                    // setIsOpen(false);
                  }, 2000);
                })
                .catch((err) => {
                  alert(err);
                });
            }}
          >
            Update
          </Button>

          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

// render(<ModalView />);
export default CouponUpdate;
