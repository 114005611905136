// import React, { useEffect, useState } from "react";
// import Swal from "sweetalert2";
// import BreadCrumb from "../../Components/Common/BreadCrumb"
// import {
//   Card,
//   CardBody,
//   CardHeader,
//   Input,
//   Label,
//   Col,
//   Collapse,
//   Container,
//   Row,
//   Button,
//   DropdownItem,
//   DropdownToggle,
//   DropdownMenu,
//   UncontrolledDropdown,
//   CardFooter,
// } from "reactstrap";
// import { Link, useNavigate } from "react-router-dom";
// import DataTable, { createTheme } from "react-data-table-component";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// import axios from "../../helpers/Axios"
// import axiosfeedback from 'axios'

// const Feedback = () => {

//   const [recive, setRecive] = useState([]);
//   const [selectedRows, setSelectedRows] = useState([]);
//   const [isOpen, setIsOpen] = useState(false);
//   const [count,setCount]=useState()
//   const [updateId, setUpdateId] = useState("");
//   const [updateLocation, setUpdatefirstname] = useState("");
//   const [updateLocationStatus, setUpdatelastname] = useState("");
//   const [updateStatus, setUpdateStatus] = useState("");
//   const [isOpenViewloc,setIsOpenViewloc] = useState(false)
//   function gettingFeedback() {
//     axios
//       .get("/feedback")
//       .then((res) => {
//         setRecive(res.data.users);
//         console.log(res.data.users);
//         console.log("received feedback details ");
//       })
//       .catch(() => {
//         console.log("error receiveing feedback details ");
//       });
//   }
//   useEffect(() => {
//     gettingFeedback();
//   }, []);
//   const showToastMessageDeleteSuccess = () => {
//     toast.success("Deleted Successfully !", {
//       position: toast.POSITION.TOP_CENTER,
//     });
//   };
//   const showToastMessageRetervied = () => {
//     toast.success("Delete Retervied !", {
//       position: toast.POSITION.TOP_CENTER,
//     });
//   };
//   // const handleRowSelect = ({ selectedRows }) => {
//   //   // Update the selectedRows state with the selected row objects
//   //   setSelectedRows(selectedRows);
//   //   handlePostFeedback(selectedRows);
//   // };
//   // const handlePostFeedback = (row) => {
//   //   if (!row || row.length === 0) {
//   //     // No rows selected, return early
//   //     return;
//   //   }
  
//   //   // Access the data of the selected rows using the selectedRows state
//   //   const selectedFeedbackData = selectedRows
//   //   console.log(selectedFeedbackData, "@@@@@@@@@@@@@@@@@@@@@@");
  
//   //   // Build the query parameters
//   //   const queryParams = selectedFeedbackData.map((data) => `data=${encodeURIComponent(JSON.stringify(data))}`).join("&");
  
//   //   // Make the GET request to send the selected feedback data to the server
//   //   axios.get(`/feedback?${queryParams}`)
//   //     .then((res) => {
//   //       console.log("Feedback data successfully posted!");
//   //       // Show a success toast message
//   //       toast.success("Feedback data successfully posted!", {
//   //         position: toast.POSITION.TOP_CENTER,
//   //       });
//   //     })
//   //     .catch((error) => {
//   //       console.log("Error posting feedback data: ", error);
//   //       // Show an error toast message
//   //       toast.error("Error posting feedback data!", {
//   //         position: toast.POSITION.TOP_CENTER,
//   //       });
//   //     });
//   // };
  
  
//   createTheme("fulldark", {
//     text: {
//       primary: "white",
//       secondary: "white",
//     },
//     background: {
//       default: "#212529",
//     },
//     context: {
//       text: "white",
//     },
//     button: {
//       default: "white",
//       focus: "white",
//       hover: "white",
//       disabled: "white",
//     },
//   });

  
//   const columns = [
    // {
    //   name: <p>Id </p>,
    //   selector: (row) => row.id,
    // },
    // {
    //   name: <p>First Name </p>,
    //   selector: (row) => row.first_name,
    // },
    // {
    //   name: <p>Last Name </p>,
    //   selector: (row) => row.last_name,
    // },
    // {
    //   name: <p>Email</p>,
    //   selector: (row) => row.email,
    // },
    // {
    //   name: <p>Ratings</p>,
    //   selector: (row) => row.ratings,
    // },
    // {
    //   name: <p>Message</p>,
    //   selector: (row) => row.message,
    // },
    // {
    //   name: <p>Date </p>,
    //   selector: (row) => row.feedback_date,
    // },
    // {
    //   name: <p>Time </p>,
    //   selector: (row) => row.feedback_time,
    // },
    // {
    //   name: <p>Status</p>,
    //   selector: (row) => row.status,
    // },
    
//     {
//       name: <p className="font-weight-bold fs-13">Action</p>,
//       // sortable: true,
//       cell: (row) => {
//         return (
//           <UncontrolledDropdown className="dropdown d-inline-block">
//             <DropdownToggle
//               className="btn btn-soft-secondary btn-sm"
//               tag="button"
//             >
//               <i className="ri-more-fill align-middle"></i>
//             </DropdownToggle>
//             <DropdownMenu className="dropdown-menu-end">
//               <DropdownItem href="#!"
//               onClick={(e) => {
//                 setUpdateId(row.id);
//                 setUpdatefirstname(row.first_name);
//                 setUpdatelastname(row.last_name);
//                 setUpdateStatus(row.status);
//                 setIsOpenViewloc(true);
//               }}>
//                 <i className="ri-eye-fill align-bottom me-2 text-muted"></i>View
//               </DropdownItem>
//               <DropdownItem
//                 onClick={(e) => {
//                   setUpdateStatus(row.status);
//                 }}
//                 className="edit-item-btn"
//               >
//                 <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
//                 Edit
//               </DropdownItem>
//               <DropdownItem
//                 onClick={() => {
//                   Swal.fire({
//                     title: "Are you sure?",
//                     text: `Your sure .. u want to delete ${row.message}!`,
//                     icon: "warning",
//                     showCancelButton: true,
//                     confirmButtonColor: "#3085d6",
//                     cancelButtonColor: "#d33",
//                     confirmButtonText: "Yes, delete it!",
//                   }).then((result) => {
//                     if (result.isConfirmed) {
//                       Swal.fire(
//                         "Deleted!",
//                         " data has been deleted.",
//                         "success"
//                       );
//                       axios
//                         .delete(
//                           `/feedback?id=${row.id}`
//                         )
//                         .then(() => {
//                           console.log("deleted");
//                           // locationDatafun()
                          
//                         })
//                         .catch(() => {
//                           console.log("error deleting ");
//                           // showToastMessageerrorDeleteforloc();
//                         });
//                     }
//                   });
//                 }}
//                 className="remove-item-btn"
//               >
//                 {" "}
//                 <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
//                 Delete{" "}
//               </DropdownItem>
//             </DropdownMenu>
//           </UncontrolledDropdown>
//         );
//       },
//     },
//   ];
//   // console.log(selectedRows,'#######################')

//   return (
//     <React.Fragment>
//       <div className="page-content">
//         <Container fluid>
//           <BreadCrumb title="Feedback" pageTitle="Feedback" />
//           <Row>
//             <Col lg={12}>
//               <Card>
//                 <CardHeader>
//                   <h4 className="card-title mb-0  text-centre">Feedbacks</h4>
//                 </CardHeader>
//                 <CardBody>
//                   <DataTable
//                     columns={columns}
//                     data={recive}
//                     pagination
//                     theme="fulldark"
//                     // selectableRows // Enable row selection
//                     // onSelectedRowsChange={handleRowSelect}
//                   />
//                 </CardBody>
//                 <CardFooter>
//                 {/* <Button onClick={() => handlePostFeedback(selectedRows)}>Submit Feedback</Button> */}
//                 </CardFooter>
//               </Card>
//             </Col>
//           </Row>
//           <ToastContainer />
//         </Container>
//       </div>
//     </React.Fragment>
//   );
// };

// export default Feedback;


//new code

import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import {
  Card,
  CardBody,
  CardHeader,
  Input,
  Label,
  Col,
  Collapse,
  Container,
  Row,
  Button,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  CardFooter,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import DataTable, { createTheme } from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import s from './feedbackmodal.module.css'
import axios from "../../helpers/Axios";
import axiosfeedback from "axios";

const Feedback = () => {
  const [recive, setRecive] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [count, setCount] = useState();
  const [updateId, setUpdateId] = useState("");
  const [updatefirsname, setUpdatefirstname] = useState("");
  const [updatelastname, setUpdatelastname] = useState("");
  const [updateStatus, setUpdateStatus] = useState("");
  const [updatemessage, setUpdatemessage] = useState("");
  const [updatedate, setUpdatedate] = useState("");
  const [updatetime, setUpdatetime] = useState("");
  const [updateemail, setUpdateemail] = useState("");
  const [updaterating, setUpdaterating] = useState("");
  const [isOpenView, setIsOpenView] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [selectedFeedbackData, setSelectedFeedbackData] = useState({})
  function gettingFeedback() {
    axios
      .get("/feedback")
      .then((res) => {
        setRecive(res.data.users);
        // console.log(res.data.users);
        // console.log("received feedback details ");
      })
      .catch(() => {
        // console.log("error receiveing feedback details ");
      });
  }

  useEffect(() => {
    gettingFeedback();
  }, []);

  const showToastMessageDeleteSuccess = () => {
    toast.success("Deleted Successfully !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const showToastMessageRetervied = () => {
    toast.success("Delete Retervied !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  createTheme("fulldark", {
    text: {
      primary: "white",
      secondary: "white",
    },
    background: {
      default: "#212529",
    },
    context: {
      text: "white",
    },
    button: {
      default: "white",
      focus: "white",
      hover: "white",
      disabled: "white",
    },
  });

  const columns = [
    // ... other columns ...
    {
      name: <p>Id </p>,
      selector: (row) => row.id,
    },
    {
      name: <p>First Name </p>,
      selector: (row) => row.first_name,
    },
    {
      name: <p>Last Name </p>,
      selector: (row) => row.last_name,
    },
    {
      name: <p>Email</p>,
      selector: (row) => row.email,
    },
    {
      name: <p>Ratings</p>,
      selector: (row) => row.ratings,
    },
    {
      name: <p>Message</p>,
      selector: (row) => row.message,
    },
    {
      name: <p>Date </p>,
      selector: (row) => row.feedback_date,
    },
    {
      name: <p>Time </p>,
      selector: (row) => row.feedback_time,
    },
    {
      name: <p>Status</p>,
      selector: (row) => row.status,
    },
    {
      name: <p className="font-weight-bold fs-13">Action</p>,
      cell: (row) => {
        return (
          <UncontrolledDropdown className="dropdown d-inline-block" direction="right">
            <DropdownToggle
              className="btn btn-soft-secondary btn-sm"
              tag="button"
            >
              <i className="ri-more-fill align-middle"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem
                onClick={(e) => {
                  setUpdateId(row.id);
                  setUpdatefirstname(row.first_name);
                  setUpdatelastname(row.last_name);
                  setUpdatemessage(row.message);
                  setUpdatedate(row.feedback_date);
                  setUpdatetime(row.feedback_time);
                  setUpdateemail(row.email);
                  setUpdaterating(row.ratings)
                  setUpdateStatus(row.status);
                  setIsOpenView(true);
                }}
              >
                <i className="ri-eye-fill align-bottom me-2 text-muted"></i>
                View
              </DropdownItem>
              <DropdownItem
                onClick={(e) => {
                  setIsEditing(true);
                  setEditedData(row);
                }}
                className="edit-item-btn"
              >
                <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
                Edit
              </DropdownItem>
              {/* <DropdownItem
                onClick={() => {
                  Swal.fire({
                    title: "Are you sure?",
                    text: `Your sure .. u want to delete ${row.message}!`,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      Swal.fire(
                        "Deleted!",
                        " data has been deleted.",
                        "success"
                      );
                      axios
                        .delete(`/feedback?id=${row.id}`)
                        .then(() => {
                          console.log("deleted");
                          // locationDatafun()
                        })
                        .catch(() => {
                          console.log("error deleting ");
                          // showToastMessageerrorDeleteforloc();
                        });
                    }
                  });
                }}
                className="remove-item-btn"
              >
                {" "}
                <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                Delete{" "}
              </DropdownItem> */}
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      },
    },
  ];

  const handleSave = () => {
    axios
      .put(`/feedback?id=${editedData.id}`, editedData)
      .then(() => {
        setIsEditing(false);
        // Update the feedback table with the new data
        setRecive((prevState) =>
          prevState.map((item) =>
            item.id === editedData.id ? { ...item, ...editedData } : item
          )
        );
        toast.success("Feedback updated successfully!", {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((error) => {
        // console.log("Error updating feedback data: ", error);
        toast.error("Error updating feedback data!", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  const handleViewFeedback = (row) => {
    setSelectedFeedbackData(row);
    setIsOpenView(true);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Feedback" pageTitle="Feedback" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0  text-centre">Feedbacks</h4>
                </CardHeader>
                <CardBody>
                  <DataTable
                    columns={columns}
                    data={recive}
                    pagination
                    theme="fulldark"
                    customColumns={[
                      {
                        cell: (row) => (
                          <Button
                            color="link"
                            onClick={() => handleViewFeedback(row)}
                          >
                            View
                          </Button>
                        ),
                        ignoreRowClick: true,
                        allowOverflow: true,
                        button: true,
                      },
                    ]}
                  />
                </CardBody>
                <CardFooter></CardFooter>
              </Card>
            </Col>
          </Row>
          <ToastContainer />
        </Container>
      </div>
      <Modal isOpen={isOpenView} toggle={() => setIsOpenView(false)}>
        <ModalHeader>View Feedback</ModalHeader>
        <ModalBody>
          {/* ... Render the view modal content ... */}
          {/* {selectedFeedbackData && ( */}
          <CardHeader>
          <div style={{ padding: "10px"}}></div>
          </CardHeader>
            
               <p><b>ID:</b>&nbsp;&nbsp;{updateId}</p>
        <p><b>First Name:</b>&nbsp;&nbsp;{updatefirsname}</p>
        <p><b>Last Name:</b>&nbsp;&nbsp;{updatelastname}</p>
        <p><b>Email:</b>&nbsp;&nbsp;{updateemail}</p>
        <p><b>Ratings:</b>&nbsp;&nbsp;{updaterating}</p>
        <p><b>Message:</b>&nbsp;&nbsp;{updatemessage}</p>
        <p><b>Date:</b>&nbsp;&nbsp;{updatedate}</p>
        <p><b>Time:</b>&nbsp;&nbsp;{updatetime}</p>
        <p><b>Status:</b>&nbsp;&nbsp;{updateStatus}</p>
              {/* Display other feedback details here */}
            
          {/* )} */}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setIsOpenView(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={isEditing} toggle={() => setIsEditing(false)}>
        <ModalHeader>Edit Feedback</ModalHeader>
        <ModalBody>
          {/* ... Render the editable fields ... */}
          <Label for="status">Status</Label>
          <select
            type="text"
            id="status"
            className={s.formlable}
            value={editedData.status|| ""}
            onChange={(e) =>
              setEditedData((prevState) => ({
                ...prevState,
                status: e.target.value,
              }))
            }
            
          >
    <option value={"Active"}>Active</option>
    <option value={"Inactive"}>Inactive</option>
    </select>
          {/* Add other editable fields as needed */}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSave}>
            Save
          </Button>{" "}
          <Button color="secondary" onClick={() => setIsEditing(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default Feedback;
