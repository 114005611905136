
module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  api: {
    // API_URL: "http://13.200.44.134",
    // API_URL: "http://54.147.142.185:5001",
    // API_URL: "http://13.200.44.134",
    // API_URL: "http://localhost:5001",
    // API_URL: "http://0.0.0.0:5001",
    API_URL: "http://localhost:5001",
    
  }
};