
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../helpers/Axios";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import {
  Card,
  CardBody,
  CardHeader,
  Input,
  Label,
  Col,
  Collapse,
  Container,
  Row,
  DropdownItem,
  ModalBody,
  ModalFooter,
  ModalHeader,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  CardFooter,
} from "reactstrap";
function UpdateAgents({
  setShow,
  show,
  id,
  agentname,
  location,
  email,
  status,
  alter_email,
  // alter_email2,
  phone,
  alt_phone,
}) {
  //   const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [updateId, setUpdateId] = useState(id);
  const [updatedagentname, setUpdatedagentName] = useState(agentname);
  const [updatedlocation, setUpdatedlocation] = useState(location);
  const [updatedemail, setUpdatedemail] = useState(email);
  const [updatedaltemail, setUpdatedaltemail] = useState(alter_email);
  // const [updatedaltemai2, setUpdatedaltemail2] = useState(alter_email2);
  const [updatedphn, setUpdatedphn] = useState(phone);
  const [updatedaltphn, setUpdatedaltPhn] = useState(alt_phone);
  const [updatedstatus, setUpdatedstatus] = useState(status);
  // for updating the data in database

  const [agentnamelist, setAgentnamelist] = useState("");
  const [idlist, setIdlist] = useState("");
  const [locationlist, setocationlist] = useState("");
  const [emaillist, setEmaillist] = useState("");
  const [emaillist1, setEmaillist1] = useState("");
  const [emaillist2, setEmaillist2] = useState("");
  const [phonelist, setPhonelist] = useState("");
  const [alt_phonelist, setalt_phonelist] = useState("");
  const [statuslist, setStatuslist] = useState("");

  const [reciveData, setReceiveData] = useState([]);

  const showToastMessageUploadedSuccess = () => {
    toast.success("Updated Successfully !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const showToastMessageErroruploading = () => {
    toast.error("Error Updating !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const [newstatus, setNewstatus] = useState("");

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Agent Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group
              classNameNameName="mb-3"
              controlId="exampleForm.ControlInput1"
            >
              <div className="auth-page-content">
                <Container fluid>
                  <Row>
                    <Col xxl={12}>
                      <Card>
                        <CardBody className="p-7">
                          <div>
                            <CardHeader>
                              <div className="text-center mt-10">
                                <h5 className="text-primary">
                                  Edit Information
                                </h5>
                              </div>
                            </CardHeader>
                            <div className="p-2 mt-0 justify-content-center">
                              <Form
                                onSubmit={(e) => {
                                  e.preventDefault();
                                }}
                                action="#"
                              >
                                <Row>
                                  <Col lg={6}>
                                    <div className="mb-2">
                                      <Label
                                        htmlFor="catname"
                                        className="form-label"
                                      >
                                        Agent Name{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Input
                                        id="catname"
                                        name="catname"
                                        className="form-control"
                                        placeholder="Edit name"
                                        type="text"
                                        defaultValue={agentname}
                                        onChange={(e) => {
                                          setUpdatedagentName(e.target.value);
                                        }}
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="mb-2">
                                      <Label
                                        htmlFor="catprice"
                                        className="form-label"
                                      >
                                        Location{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Input
                                        name="price"
                                        type="text"
                                        placeholder="Enter new price"
                                        defaultValue={location}
                                        onChange={(e) => {
                                          setUpdatedlocation(e.target.value);
                                        }}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={6}>
                                    <div className="mb-2">
                                      <Label
                                        htmlFor="firstname"
                                        className="form-label"
                                      >
                                        Email{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Input
                                        name="firstname"
                                        type="text"
                                        placeholder="Enter username"
                                        defaultValue={email}
                                        onChange={(e) => {
                                          setUpdatedemail(e.target.value);
                                        }}
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="mb-2">
                                      <Label
                                        htmlFor="firstname"
                                        className="form-label"
                                      >
                                        Alt Email1{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Input
                                        name="firstname"
                                        type="text"
                                        placeholder="Enter username"
                                        defaultValue={alter_email}
                                        onChange={(e) => {
                                          setUpdatedaltemail(e.target.value);
                                        }}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  {/* <Col lg={6}>
                                    <div className="mb-2">
                                      <Label
                                        htmlFor="firstname"
                                        className="form-label"
                                      >
                                        Email Alt 2{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Input
                                        name="firstname"
                                        type="text"
                                        placeholder="Enter username"
                                        defaultValue={alter_email2}
                                        onChange={(e) => {
                                          setUpdatedaltemail2(e.target.value);
                                        }}
                                      />
                                    </div>
                                  </Col> */}
                                  <Col lg={6}>
                                    <div className="mb-2">
                                      <Label
                                        htmlFor="firstname"
                                        className="form-label"
                                      >
                                        Phone Number{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Input
                                        name="firstname"
                                        type="text"
                                        placeholder="Enter username"
                                        defaultValue={phone}
                                        onChange={(e) => {
                                          setUpdatedphn(e.target.value);
                                        }}
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="mb-2">
                                      <Label
                                        htmlFor="firstname"
                                        className="form-label"
                                      >
                                        Alt Phone{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Input
                                        name="firstname"
                                        type="text"
                                        placeholder="Enter username"
                                        defaultValue={alt_phone}
                                        onChange={(e) => {
                                          setUpdatedaltPhn(e.target.value);
                                        }}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  
                                  <Col lg={6}>
                                  <div className="mb-2">
                                  <Label
                                    htmlFor="employeeNamestatus"
                                    className="form-label"
                                  >
                                    Status{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <select
                                    id="employeeNamestatus"
                                    className="form-select"
                                    defaultValue={status}
                                    data-choices
                                    data-choices-sorting="true"
                                    onChange={(e) =>
                                      setUpdatedstatus(e.target.value)
                                    }
                                  >
                                    <option value={"Active"}>Active</option>
                                    <option value={"Inactive"}>Inactive</option>
                                  </select>
                                </div>
                                  </Col>
                                </Row>
                              </Form>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Container>
                <ToastContainer></ToastContainer>
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={() => {
              let payload = {
                agentname: updatedagentname,
                id: id,
                location: updatedlocation,
                email: updatedemail,
                alter_email: updatedaltemail,
                // alter_email2: updatedaltemai2,
                phone: updatedphn,
                status: updatedstatus,
                alt_phone: updatedaltphn,
              };
              axios
                .put("/updateAgentslist", payload)
                .then((res) => {
                  // setIsOpen(false)
                  showToastMessageUploadedSuccess();
                })
                .catch((err) => {
                  // setIsOpen(false)
                  showToastMessageErroruploading();
                });
            }}
          >
            Update
          </Button>

          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

// render(<ModalView />);
export default UpdateAgents;
