import React, { useState, useEffect } from "react";
import axios from "../../helpers/Axios";
// import Swal from "sweetalert2";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import DataTable, { createTheme } from "react-data-table-component";
// import { DropdownItem, DropdownMenu, DropdownToggle, Input,UncontrolledDropdown } from 'reactstrap';
import { Link, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
// import ViewAgents from "./ViewAgents";
import "react-toastify/dist/ReactToastify.css";
// import s from './sweetalert.css'
// import ModalViewAgent from './ModalViewAgent';
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Container,
  Label,
  Row,
  Table,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  UncontrolledDropdown,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import CarsEditModal from "./CarsEditModal";
// import Flatpickr from "react-flatpickr";
// import UpdateAgents from "./UpdateAgents";

const CarsDisplay = () => {

  

  createTheme('fulldark', {
    text: {
      primary: 'white',
      secondary: 'white'
    },
    background: {
      default: '#212529'
    },
    context: {
      text: 'white'
    },
    button: {
      default: 'white',
      focus: 'white',
      hover: 'white',
      disabled: 'white'
    }
  })

  const [reciveData, setReceiveData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [caro, setCaro] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [chauffer, setChauffer] = useState([]);
  const [selfDrive, setSelfDrive] = useState([]);
  const [showData, setShowData] = useState(0);
  const [modalData, setModalData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [viewedLocation, setViewedLocation] = useState("");
  const [getlocation, setGetlocation] = useState("");

  

  useEffect(() => {
    axios.get(`/get-chauffeur-cars`)
      .then((res) => { setChauffer(res.data); 
        console.log(res.data,'9994934394');
      }

      )
      .catch((err) => { 
        // console.log(err); 
      });
    axios.get(`/get-selfdrive-cars`)
      .then((res) => { setSelfDrive(res.data); })
      .catch((err) => {
        //  console.log(err);
         });
    axios
      .get(`/location`)
      .then((res) => {
        setLocationData(res.data.location);
        // console.log(res,'@@@@@@@@@@@@@@@@@@')
        // console.log(locationData,'##############');

      })

      .catch(() => {
        // console.log("Error");
      });
    axios
      .get(`/CarFleetDetials?`)
      .then((res) => {
        setCaro(res.data.users);
        // console.log(res,'@@@@@@@@@@@@@@@@@@')


      })
      .catch(() => {
        // console.log("Error");
      });
  }, []);
  // sucessfull update
  useEffect(() => {
   
  
    if (showData === "0" && chauffer && chauffer.standard) {
      console.log(chauffer.standard,"chauffer.standard")
      setFilteredData(chauffer.standard.filter(row => row.location === getlocation));
    } else if (showData === "1" && chauffer && chauffer.outstation) {
      setFilteredData(chauffer.outstation.filter(row => row.location === getlocation));
    } else if (showData === "2" && chauffer && chauffer.airport) {
      setFilteredData(chauffer.airport.filter(row => row.location === getlocation));
    } else if (showData === "3" && selfDrive) {      console.log(selfDrive,"selfdrive")


      setFilteredData(selfDrive.filter(row => row[15] === getlocation));
    }
  else{console.log("22223244")}
  }, [showData, getlocation, chauffer, selfDrive]);
  
  
    
  const showToastMessageSuccessUpdate = () => {
    toast.success("Updated Successfully !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  // error update
  const showToastMessageErrorUpdate = () => {
    toast.error("Error Updating !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  // sucessfull deleting
  const showToastMessageSuccessfulDelete = () => {
    toast.success("Deleted Sucessfully !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  // Reterive deleting
  const showToastMessageerrorDelete = () => {
    toast.success("Reterived Delete", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
// console.log(getlocation,"karthikkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk")
  // useEffect(() => {
  //   // axios.get(`/CarFleetDetials?id=${27}`)
  //   // .then((res)=>{console.log(res.data.car[0].car_brand +' ' + res.data.car[0].car_model)})
  // }, [])
  // console.log(caro,'##############');
  // console.log(viewedLocation,'################################')
  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Car Type</span>,

      cell: (row) => {

        if (showData == 3) {
          const type = row[13]
          return (
            <p>{type} </p>
          );
        }
        else {
          const type = row.car_type
          return (
            <p>{type} </p>
          );

        }
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Car Name</span>,


      cell: (row) => {
        const ok = []
        // async function load(){

        //   const  type = row.car_id

        //   if(type){

        //    await axios.get(`/CarFleetDetials?id=${type}`)
        //     .then((res) => {
        //    ok.push(res.data.car[0].car_brand)


        //     ;}

        //     )


        //   }


        // }

        if (showData == 3) {
          const type = row[1] + " " + row[2]
          return (
            <p>{type} </p>
          );
        }
        else {
          //  const  type = row.location
          const arr = []
          caro.forEach((item) => {
            if (row.car_id == item.id) {
              arr.push(item.car_brand + "  " + item.car_model)

            }
          })
          // console.log(row,"9090909090999")
          return (
            <p>{arr} </p>
          );

        }
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Location</span>,
      // selector: (row) => row.location,
      // sortable: true,
      cell: (row) => {

        if (showData == 3) {
          // const  type = row[15] 
          const arr = []
          locationData.forEach((item) => {
            // console.log(item.id == getlocation,"jiiiiiiiiiiiiiiiiiiiiiiiiiiiii")
            if(item.id == getlocation){
              console.log("ture",row[15],item.id)
            // if (row[15] == item.id) {
              console.log("ture",item.location)
              //  return item.location
             
              arr.push(item.location)
            // }
          }
          })
          return (
            <p>{arr} </p>
          );
        }
        else {
          //  const  type = row.location
          const arr = []
          locationData.forEach((item) => {
            if(item.id == getlocation){
            // if (row.location == item.id) {
              // console.log("ture",item.location)
              //  return item.location
              arr.push(item.location)

            }
          })

          return (
            <p>{arr} </p>
          );

        }
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Price</span>,
      // selector: (row) => row.price,
      // sortable: true,
      cell: (row) => {

        if (showData == 3) {
          const type = row[21]
          return (
            <p>{type} </p>
          );
        }
        else {
          const type = row.price
          return (
            <p>{type} </p>
          );

        }
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Deposit</span>,
      // selector: (row) => row.deposit,
      // sortable: true,
      cell: (row) => {

        if (showData == 3) {
          const type = row[20]
          return (
            <p>{type} </p>
          );
        }
        else {
          const type = row.deposit
          return (
            <p>{type} </p>
          );

        }
      },
    },
    {
      name: <span className="font-weight-bold fs-13">OnRequest</span>,
      // selector: (row) => row.forPrime,
      // sortable: true,
      cell: (row) => {
// console.log(row)
        if (showData == 3) {
          const type = row[16]
          return (
            <p>{type} </p>
          );
        }
        else {
          const type = row.forPrime
          return (
            <p>{type} </p>
          );

        }
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Status</span>,
      // selector: (row) => row.status,
      // sortable: true,
      cell: (row) => {

        if (showData == 3) {
          const type = row[22]
          return (
            <p>{type} </p>
          );
        }
        else {
          const type = row.status
          return (
            <p>{type} </p>
          );

        }
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      sortable: true,

      cell: (row) => {
        return (
          <UncontrolledDropdown className="dropdown d-inline-block" direction="right">
            <DropdownToggle className="btn btn-soft-danger btn-sm" tag="button">
              <i className="ri-more-fill align-middle"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem
                href="#!"
                // onClick={(e) => handleViewClick(row)}
                onClick={(e) => {
                  //   // setUpdateId(row.id);
                  //   // setUpdatedagentName(row.agentname);
                  //   // setUpdatedlocation(row.location);
                  //   // setUpdatedemail(row.email);

                  //   // setUpdatedaltemail(row.alter_email1);
                  //   // // setUpdatedaltemail2(row.alter_email2);
                  //   // setUpdatedphn(row.phone);
                  //   // setUpdatedaltPhn(row.altPhone);
                  //   // setUpdatedstatus(row.status);
                  //   // setModalData(row)
                  //   //  setShow(true)
                  setSelectedRowData(row);
                  // const arr = []
                  locationData.forEach((item) => {
                    if (row.location == item.id) {
                      // console.log("ture", item.location)
                      //  return item.location
                      // arr.push(item.location)
                      // alert('hiiiiiiiiii', item.location)
                      setViewedLocation(item.location);
                    }
                  })
                  
                }}
              >
                <i className="ri-eye-fill align-bottom me-2 text-muted"></i>View
              </DropdownItem>

              <DropdownItem
                className="edit-item-btn"
                onClick={(e) => {
                  setModalData(row)
                  // console.log(row, "dataaaaaaaaaaa")
                  setShowModal(true)
                }}
              >
                <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
                Edit
              </DropdownItem>

              <DropdownItem

                onClick={() => {

                  // console.log("final data", row)

                  Swal.fire({
                    title: "Are you sure?",
                    text: ` Uh want to Delete ${row.agentname}!`,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                    background: '#212529',
                    // backgroundColor:"black"

                  }).then((result) => {
                    if (result.isConfirmed) {
                      // Swal.fire(
                      //    `Deleted ${row.agentname} ! `,
                      //   ` ${row.agentname} has been deleted!`,
                      //   "success",
                      //   "red"
                      // );
                      Swal.fire({
                        title: `Deleted ${row.agentname} ! `,
                        // text: `${row.agentname} Deleted Successfully!`,
                        icon: "success",
                        // showCancelButton: true,
                        // confirmButtonColor: "#3085d6",
                        // cancelButtonColor: "#d33",
                        // confirmButtonText: "Yes, delete it!",
                        background: '#212529',
                        // backgroundColor:"black"

                      })
                      if (showData == 0) {
                        axios
                          .delete(
                            `/standard-data?id=${row.id}`
                          )
                          .then(() => {
                            // console.log("deleted");
                          })
                          .catch(() => {
                            // console.log("error deleting ");
                            showToastMessageerrorDelete();
                          });
                      }
                      else if (showData == 1) {
                        axios
                          .delete(
                            `/outstation-data?id=${row.id}`
                          )
                          .then(() => {
                            // console.log("deleted");
                          })
                          .catch(() => {
                            // console.log("error deleting ");
                            showToastMessageerrorDelete();
                          });

                      }
                      else if (showData == 2) {
                        axios
                          .delete(
                            `/airport-data?id=${row.id}`
                          )
                          .then(() => {
                            // console.log("deleted");
                          })
                          .catch(() => {
                            // console.log("error deleting ");
                            showToastMessageerrorDelete();
                          });

                      }
                      else if (showData == 3) {
                        axios
                          .delete(
                            `/self-drive-data?id=${row[11]}`
                          )
                          .then(() => {
                            // console.log("deleted");
                          })
                          .catch(() => {
                            // console.log("error deleting ");
                            showToastMessageerrorDelete();
                          });

                      }
                      // axios
                      //   .delete(
                      //     `/deleteAgent?id=${row.id}`
                      //   )
                      //   .then(() => {
                      //     console.log("deleted");
                      //   })
                      //   .catch(() => {
                      //     console.log("error deleting ");
                      //     showToastMessageerrorDelete();
                      //   });
                    }

                  });
                }}
                className="remove-item-btn"
              >
                {" "}
                <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                Delete{" "}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      },
    },
  ];

  // const [data, setData] = useState(chauffer.standard)
  // useEffect(() => {
  //   if (showData == 0) setData(chauffer.standard)
  //   else if (showData == 1) setData(chauffer.outstation)
  //   else if (showData == 2) setData(chauffer.airport)
  //   else if (showData == 3) setData(selfDrive)
  //   //  console.log(data,"nameeeeeeeeeeee");
  // },)

  //  console.log(chauffer,selfDrive,"nameeeeeeeeeeee");
  // const getCarName = (carId) => {
  //   const car = caro.find((item) => item.id === carId);
  //   if (car) {
  //     return `${car.car_brand} ${car.car_model}`;
  //   }
  //   return "";
  // };
  // function getLocationName() {
  //   const arr = []
  //   locationData.forEach((item) => {
  //     if (selectedRowData.location == item.id) {
  //       // console.log("ture", item.location)
  //       //  return item.location
  //       arr.push(item.location)
  //       alert('hiiiiiiiiii', item.location)

  //     }
  //   })
  //   return arr;
  // }
  const getCarName = (carId) => {
    const car = caro.find((item) => item.id === carId);
    if (car) {
      return `${car.car_brand} ${car.car_model}`;
    }
    return "";
  };

  function getLocationName() {
    const arr = [];
    locationData.forEach((item) => {
      if (selectedRowData.location === item.id) {
        arr.push(item.location);
      }
    });
    return arr;
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* <BreadCrumb  /> */}
          <BreadCrumb title="Car" pageTitle="Car Lists" />

          <Card   >
            <CardHeader>
              <h4 className="card-title mb-0">Cars List</h4>
            </CardHeader>
            <CardBody>
              <Row className="g-4 mb-3">
                <Col className="col-sm-auto">
                  <div className="d-flex gap-1">
                    <Link to="/Cars">
                      {" "}
                      <Label
                        className="btn btn-outline-primary text-centre"
                        for="btn-check-outlined"
                      >
                        Add Cars
                      </Label>
                    </Link>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Label
                    style={{ color: "#eee4e4" }}
                    htmlFor="inputState"
                    className="form-label"
                  >
                    Select Category
                  </Label>
                  <select
                    style={{ backgroundColor: "#2a2f34", color: "#eee4e4" }}
                    onChange={(e) => {
                      setShowData(e.target.value)
                    }}
                    id="inputState"
                    className="form-select"
                    data-choices
                    data-choices-sorting="true"
                  >
                    <option hidden>Select Category</option>
                    <option value={0}>Standard</option>
                    <option value={1}>Out-Station</option>
                    <option value={2}>Airport</option>
                    <option value={3}>Selfdrive</option>
                  </select>
                </Col>
                <Col md={6}>
                  <Label
                    style={{ color: "#eee4e4" }}
                    htmlFor="inputState"
                    className="form-label"
                  >
                   location
                  </Label>
                  {/* <select
                    style={{ backgroundColor: "#2a2f34", color: "#eee4e4" }}
                    onChange={(e) => {
                      setShowData(e.target.value)
                    }}
                    id="inputState"
                    className="form-select"
                    data-choices
                    data-choices-sorting="true"
                  >
                    <option hidden>Select location</option>
                    <option value={0}>Standard</option>
                    <option value={1}>Out-Station</option>
                    <option value={2}>Airport</option>
                    <option value={3}>Selfdrive</option>
                  </select> */}
                        <select
                        onChange={(e) => {
                          // const [getlocation, setGetlocation] = useState();
                          setGetlocation(e.target.value);
                          // setAlldata({...alldata,location:e.target.value})
                       
                        }}
                        id="inputState"
                        className="form-select"
                        data-choices
                        data-choices-sorting="true"
                      >
                         {/* {location01 ? ( <option value="">{location01}</option>) : <option value="">Loading</option>} */}
                         {viewedLocation ?( <option value="">{viewedLocation}</option>) : <option value="">Select location</option>}
                        {/* <option value="">{location01}</option> */}
                        {locationData ? (
                          locationData.map((data) => {
                            return (
                              <option key={data.id} value={data.id}>{data.location}</option>
                            );
                          })
                        ) : (
                          <h1>loading</h1>
                        )}
                      </select>
                </Col>
              </Row>
              <Row>
                <DataTable columns={columns} data={filteredData} pagination theme='fulldark' />
              </Row>
            </CardBody>
          </Card>
        </Container>
        <ToastContainer />
      </div>
      {showModal && (
        <CarsEditModal details={modalData} setShow={setShowModal} show={showModal} />
      )}
      {selectedRowData && (
        <Modal isOpen={true} toggle={() => setSelectedRowData(null)}>
          <ModalHeader toggle={() => setSelectedRowData(null)}>View Row Details</ModalHeader>
          <ModalBody>
            {/* Display the data here */}
            <CardHeader>
              <div style={{ padding: "10px" }}></div>
            </CardHeader>

            <p><b>Car Type:&nbsp;&nbsp;</b>{selectedRowData.car_type}  {selectedRowData[13]}</p>
            {/* <p><b>Car Name:&nbsp;&nbsp;</b>{} {getCarName(selectedRowData.car_id)} {selectedRowData[1] + ' ' + selectedRowData[2]}</p> */}

            <p><b>Car Name:&nbsp;&nbsp;</b> {showData == 3 ? (
                          <span>{selectedRowData[1] + ' ' + selectedRowData[2]}</span>
                        ) : (
                          <span>{getCarName(selectedRowData.car_id)}</span>
                        )}</p>
            <p><b>Location:&nbsp;&nbsp;</b>{viewedLocation}</p>
            <p><b>Price:&nbsp;&nbsp;</b>{selectedRowData.price} {selectedRowData[21]}</p>
            <p><b>Deposit:&nbsp;&nbsp;</b>{selectedRowData.deposit} {selectedRowData[20]}</p>
            <p><b>For Prime:&nbsp;&nbsp;</b>{selectedRowData.forPrime} {selectedRowData[16]}</p>
            <p><b>Status:&nbsp;&nbsp;</b>{selectedRowData.status} {selectedRowData[22]}</p>
            {/* Add other data fields as needed */}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setSelectedRowData(null)}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default CarsDisplay;