import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Container,CardHeader, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner } from 'reactstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import axios from '../../helpers/Axios';
import PreviewCardHeader from "../CarFeatures/PreviewCardReaderDemo";
import { Link } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import Dropzone from "react-dropzone";
import BreadCrumb from '../AddCarFleet/BreadcrumDummy';
// import { VerticalForm, HorizontalForm } from "./FormlayoutsCode";
// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
const Car = () => {
    const[carname,setCarname]=useState("")
    const[cartype,setCartype]=useState("")
    const[logo,setLogo]=useState("")
    const[status,setStatus]=useState("")
    const [selectedFiles, setselectedFiles] = useState([]);
    const [location, setlocation] = useState([]);
    const [options, setOptions] = useState([]);
    const [AgentDetails, setAgentDetails] = useState([]);
    // function handelSubmit(e){
    //     e.Preventdefault()
    //     const payload ={carname, cartype, logo, status}
    //     axios.post(``)
    // }
    useEffect(() => {
      axios
        .get(`/location`)
        .then((res) => {
          if (Array.isArray(res.data.location)){
          setOptions(res.data.location);
          // console.log(res,'@@@@@@@@@@@@@@@@@@')
          // console.log(options,'##############');
          } else {
          // console.error('Invalid data format. Expected an array.');
        }
        })
        .catch(() => {
          // console.log("Error");
        });


        axios
        .get(`/getAgentInfo`)
        .then((res) => {
          setAgentDetails(res.data.users);
          // console.log(res.data.users);
        })
        .catch(() => {
          // console.log("Error");
        });
      }, []);
    function handleAcceptedFiles(files) {
        files.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
          })
        );
        setselectedFiles(files);
      }
      function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
      }
  return (
    <React.Fragment>
        <div className='page-content'>
          <Container fluid>
          <BreadCrumb title="Bookings" pageTitle="Car Bookings" />
        <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader
              className="text-center"
              title="Car Bookings"
            />
            <CardBody>
              <div className="live-preview">
                <Form action="#">
                <Row>
                  <Col lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="BookingStatus1" className="form-label">
                      Booking Status
                      </Label>
                      <select
                      id="BookingStatus1"
                      className="form-select"
                      data-choices
                      data-choices-sorting="true"
                    >
                      <option>Choose Booking Status</option>
                      <option>Standard</option>
                      <option>Airport-pickup</option>
                      <option>outStation</option>
                      <option>Selfdrive</option>
                    </select>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="BookingStatus1" className="form-label">
                      customer Name
                      </Label>
                      <div
                      id="BookingStatus10"
                      className="form-select"
                      // data-choices
                      // data-choices-sorting="true"
                    >
                    <input  type="text" name="name" value={'+91'} />
                    </div>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="BookingStatus1" className="form-label">
                      customer number
                      </Label>
                      <select
                      id="BookingStatus11"
                      className="form-select"
                      data-choices
                      data-choices-sorting="true"
                    >
                      <option>Choose Booking Status</option>
                      
                      <option>done</option>
                    </select>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="BookingStatus2" className="form-label">
                      Type
                      </Label>
                      <select
                      id="BookingStatus2"
                      className="form-select"
                      data-choices
                      data-choices-sorting="true"
                     
                    >
           
                      <option>Admin</option>
                     
                    </select>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="BookingStatus3" className="form-label">
                      Location
                      </Label>
                      <select
                      id="BookingStatus3"
                      className="form-select"
                      data-choices
                      data-choices-sorting="true"
                      placeholder='Select location'
                    >
                    <option>Choose Location</option>
                    {options.map(option => (
                      
                      <option key={option.id} value={option.id}>
                        {option.location}
                      </option>
                    ))}
                    </select>
                    
                    
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="BookingStatus4" className="form-label">
                      Vendor
                      </Label>
                      <select
                      id="BookingStatus4"
                      className="form-select"
                      data-choices
                      data-choices-sorting="true"
                    >
                    <option>Choose vendor</option>
                    {AgentDetails.map(option => (
                      
                      <option key={option.id} value={option.id}>
                        {option.agent_name}
                      </option>
                    ))}
                    </select>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="BookingStatus5" className="form-label">
                      Booking 
                      </Label>
                      <select
                      id="BookingStatus5"
                      className="form-select"
                      data-choices
                      data-choices-sorting="true"
                    >
                      <option>Select Booking</option>
                      <option>Standard</option>
                      <option>Airport-pickup</option>
                      <option>outStation</option>
                      <option>Selfdrive</option>
                    </select>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="BookingStatus6" className="form-label">
                      Platform
                      </Label>
                      <select
                      id="BookingStatus6"
                      className="form-select"
                      data-choices
                      data-choices-sorting="true"
                    >
                      <option>Select Platform</option>
                      <option>...</option>
                      <option>...</option>
                      <option>...</option>
                    </select>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <div className="mb-3">
                      <Label htmlFor="BookingStatus7" className="form-label">
                      Dates
                      </Label>
                      <select
                      id="BookingStatus7"
                      className="form-select"
                      data-choices
                      data-choices-sorting="true"
                    >
                      <option>Select Dates</option>
                      <option>...</option>
                      <option>...</option>
                      <option>...</option>
                    </select>
                    </div>
                  </Col>
                </Row>
                
                  <div className="text-center">
                    {/* <Input
                      type="checkbox"
                      className="btn-check"
                      id="btn-check-outlined"
                    />
                    <Label
                      className="btn btn-outline-primary text-centre"
                      for="btn-check-outlined"
                    >
                      Add
                    </Label>
                    &nbsp; &nbsp; &nbsp;
                    <Input
                      type="checkbox"
                      className="btn-check"
                      id="btn-check-outline"
                    />
                    <Label
                      className="btn btn-outline-primary text-centre"
                      for="btn-check-outline"
                    >
                      Cancel
                    </Label> */}
                    <Label
                      className="btn btn-outline-primary text-centre"
                      for="btn-check-outlined"
                      >
                      Add
                  </Label>
                  &nbsp; &nbsp; &nbsp;
                  <Label
                      className="btn btn-outline-danger"
                      for="danger-outlinedremove"
                      >
                      Remove
                  </Label>
                  </div>
                  {/* </Col> */}
                </Form>
              </div>
              <div className="d-none code-view">
                <pre className="language-markup" style={{ height: "375px" }}>
                  <code>
                    {/* <VerticalForm /> */}
                  </code>
                </pre>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
          </Container>
        </div>
    </React.Fragment>
  )
}
export default Car