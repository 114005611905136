import React from 'react'


const Subscription = () => {
  return (
    <div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <h1> Subscription Page</h1>
    </div>
  )
}

export default Subscription
