import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Alert,
  Spinner,
} from "reactstrap";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import axios from "../../helpers/Axios";
import PreviewCardHeader from "../CarFeatures/PreviewCardReaderDemo";
import { Link, useNavigate } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import Dropzone from "react-dropzone";
import BreadCrumb from "../AddCarFleet/BreadcrumDummy";
// import { VerticalForm, HorizontalForm } from "./FormlayoutsCode";
// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Location = () => {
  const [location, setLocation] = useState("");
  const [locationstatus, setLocationstatus] = useState("");
  const [status, setStatus] = useState("");
  const [selectedFiles, setselectedFiles] = useState([]);

  // function handelSubmit(e){
  //     e.Preventdefault()
  //     const payload ={carname, cartype, logo, status}
  //     axios.post(``)
  // }
  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  }
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const numberformat = /^[0-9.]+$/;
  const nameformat = /^[A-Za-z_.]+$/;

  function handleClick(e) {
    e.preventDefault();
    if (location === "" && locationstatus === "" && status === "") {
      showToastMessageAllEmptyDetails();
    } else if (location === "") {
      messageforEmptyLocation();
    } else if (!location.match(nameformat)) {
      massageforValidLocationName();
    } else if (locationstatus === "") {
      messageforEmptyLocationStatus();
    } else if (status === "") {
      messageforEmptyStatus();
    } else {
      const payload = { location, locationstatus, status };
      axios
        .post(`/location`, payload)
        .then(() => {
          // console.log("Location added successfully");
          showToastMessageDeleteSuccess();
          setTimeout(() => {
            setLocation("");
            setLocationstatus("");
            setStatus("");
          }, 1500);
        })
        .catch(() => {
          // console.log("error in location getting");
        });
    }
  }

  const messageforEmptyLocation = () => {
    toast.error("Please fill Location ...!", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "1",
    });
  };
  const messageforEmptyLocationStatus = () => {
    toast.error("Please Select Location Status ...!", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "2",
    });
  };
  const messageforEmptyStatus = () => {
    toast.error("Please Select Status ...!", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "3",
    });
  };

  const massageforValidLocationName = () => {
    toast.error("Please Enter a Valid Location ...!", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "4",
    });
  };

  const showToastMessageDeleteSuccess = () => {
    toast.success("Location added Successfully !", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "5",
    });
  };

  const showToastMessageAllEmptyDetails = () => {
    toast.error("Please fill all details !", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "6",
    });
  };
  const jumploclist = useNavigate();

  function cencelloc() {
    jumploclist("/Location_list");
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Master" pageTitle="Location" />
          <Row>
            <Col xxl={12}>
              <Card>
                <PreviewCardHeader className="text-center" title="Locations" />
                <CardBody>
                  <div className="live-preview">
                    <Form action="#">
                      <div className="mb-3">
                        <Label htmlFor="employeeName" className="form-label">
                          Add Location
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="employeeName"
                          placeholder="Enter your Location"
                          value={location}
                          onChange={(e) => {
                            setLocation(e.target.value);
                          }}
                        />
                      </div>
                      {/* <Label htmlFor="employeeName" className="form-label">
                    Location Status
                  </Label>
                  <div className="live-preview">
                    <div className="hstack gap-2 flex-wrap">
                      <Input
                        type="radio"
                        className="btn-check"
                        name="options-outlined"
                        id="success-outlinedbtn"
                        defaultChecked
                      />
                      <Label
                        className="btn btn-outline-success"
                        for="success-outlinedbtn"
                      >
                        Popular
                      </Label>
                      <Input
                        type="radio"
                        className="btn-check"
                        name="options-outlined"
                        id="danger-outlinedbtn"
                      />
                      <Label
                        className="btn btn-outline-danger"
                        for="danger-outlinedbtn"
                      >
                        Unpopular
                      </Label>
                    </div>
                  </div> */}
                      {/* <Label htmlFor="employeeName" className="form-label">
                        Location Status
                      </Label>
                      <div className="live-preview">
                        <div className="hstack gap-2 flex-wrap">
                          <Input
                            type="radio"
                            className="btn-check"
                            onChange={(e) => {
                              setLocationstatus(e.target.value);
                            }}
                            value="Popular"
                            name="options-outlined1"
                            id="success-outlined1"
                          />
                          <Label
                            className="btn btn-outline-success"
                            for="success-outlined1"
                          >
                            Popular
                          </Label>
                          <Input
                            type="radio"
                            className="btn-check"
                            onChange={(e) => {
                              setLocationstatus(e.target.value);
                            }}
                            value="Unpopular"
                            name="options-outlined1"
                            id="danger-outlined1"
                          />
                          <Label
                            className="btn btn-outline-danger"
                            for="danger-outlined1"
                          >
                            Unpopular
                          </Label>
                        </div>
                      </div> */}
                      <Col xxl={4}>
                      <Label htmlFor="employeeName" className="form-label">
                       Location Status
                      </Label>
                       <select
                          id="inputState"
                          className="form-select"
                         value={locationstatus}
                          data-choices
                          data-choices-sorting="true"
                          onChange={(e) => {
                            setLocationstatus(e.target.value);
                          }}
                        >
                          <option hidden>Select Status</option>
                          <option 
                            
                            value="Popular"
                            onChange={(e) => {
                              setLocationstatus(e.target.value);
                            }}
                            >Popular</option>
                          <option 
                            value="Unpopular"
                            onChange={(e) => {
                              setLocationstatus(e.target.value);
                            }}
                            >Unpopular</option>
                          
                        </select>
                        </Col>  

                        <Col xxl={4} className="mt-3">
                      <Label htmlFor="employeeName" className="form-label">
                       Select Status
                      </Label>
                     
                       
                      
                       <select
                          id="inputState"
                          className="form-select"
                         value={status}
                          data-choices
                          data-choices-sorting="true"
                          onChange={(e) => {
                            setStatus(e.target.value);
                          }}
                        >
                          <option hidden>Select Status</option>
                          <option 
                            
                            value="Active"
                            onChange={(e) => {
                              setStatus(e.target.value);
                            }}
                            >Active</option>
                          <option 
                            value="Inactive"
                            onChange={(e) => {
                              setStatus(e.target.value);
                            }}
                            >Inactive</option>
                          
                        </select>
                        </Col>
                        
                        
                   
                      <div className="text-center mt-3">
                        {/* <Input
                      type="checkbox"
                      className="btn-check"
                      id="btn-check-outlined"
                      name='sub'
                    />
                    <Label
                      className="btn btn-outline-primary text-centre"
                      for="btn-check-outlined"
                    >
                      Add
                    </Label>
                    &nbsp; &nbsp; &nbsp;
                    <Input
                      type="checkbox"
                      className="btn-check"
                      id="btn-check-outline"
                      name='sub'
                    />
                    <Label
                      className="btn btn-outline-primary text-centre"
                      for="btn-check-outline"
                    >
                      Cancel
                    </Label> */}
                        <Label
                          className="btn btn-outline-primary text-centre"
                          for="btn-check-outlined"
                          // onClick={handelClick}
                          onClick={handleClick}
                        >
                          Add
                        </Label>
                        &nbsp; &nbsp; &nbsp;
                        <Label
                          className="btn btn-outline-danger"
                          for="danger-outlinedremove"
                          onClick={cencelloc}
                        >
                          Cancel
                        </Label>
                      </div>
                      {/* </Col> */}
                    </Form>
                  </div>
                  <div className="d-none code-view">
                    <pre
                      className="language-markup"
                      style={{ height: "375px" }}
                    >
                      <code>{/* <VerticalForm /> */}</code>
                    </pre>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  );
};
export default Location;
