import React, { useEffect, useRef, useState } from 'react';
import UiContent from "../AddCarFleet/UIContentDummy";
//import Components
import BreadCrumb from '../AddCarFleet/BreadcrumDummy';
import { Card, CardBody, CardHeader, Col, Container, Form, Input, InputGroup, Label, Row } from 'reactstrap';
import PreviewCardHeader from '../AddCarFleet/PreviewCardHeaderDummy';
import { Link } from 'react-router-dom';
import { FormGrid, Gutters, VerticalForm, HorizontalForm, HorizontalFormLabelSizing, ColumnSizing, AutoSizing, InlineForms, FloatingLabels } from '../AddCarFleet/FormsLayoutDummy';
import { FilePond, registerPlugin } from 'react-filepond';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { useNavigate } from 'react-router-dom';
import BreadCrumbDummy from '../AddCarFleet/BreadcrumDummy';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BasicTable, ScrollVertical, ScrollHorizontal, AlternativePagination, FixedHeaderDatatables, ModalDataDatatables, AjaxDatatables } from './AgentsLists';
import axios from '../../helpers/Axios';
// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);
//Import Flatepicker
// import "flatpickr/dist/themes/material_blue.css"
const Agents = () => {
    const [selectedFiles, setselectedFiles] = useState([]);
    const [files, setFiles] = useState([]);
    document.title="Form Layouts | Agent";
    const[agentname,setAgentName]=useState('')
    const[location,setLocation]=useState("")
    const[email,setEmail]=useState("")
    const[alter_email1,setAlter_email1]=useState("")
    // const[alter_email2,setAlter_email2]=useState("")
    const[phone,setPhone]=useState("")
    const[altPhone,SetAltPhone]=useState("")
    const[status,setStatus]=useState("")

    const[selectLocation,setSelectLocation]=useState("")
    const[editdata,setEditdata]=useState("")

    const emailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    const phoneNumPattern = /^\d{10}$/
    const nameformat = /^[A-Za-z_.]+$/;

    let aName = useRef()
    function onsubmit(e) {
        e.preventDefault()

        if (agentname === "" && location === "" && email === "" && phone === "" && status === "") {
            // console.log("fill all details ")
            showToastEnterAllDetails()
        }
        else if(agentname === "")
        {
            showToastEmptyagentname()
        }
        else if (!nameformat.test(agentname)) {
            //  console.log("agename not valid ")
             showToastNameNotValid()
         }
        else if(location === "")
        {
            showToastlocation()
        }
        else if(email === "")
        {
            showToastemail()
        }
        else if (!email.match(emailformat)) {
            // console.log("email is not valid  ")
            showToastEmailNotValid()
        }
        else if (email === alter_email1) {
            showToastforEqualEmailANDemailone()
        }
        // else if(alter_email2 === "")
        // {
        //     showToastalteremailtwo()
        // }
        // else if (!alter_email2.match(emailformat)){
        //     showToastAltEmail2NotValid()

        // }
        // else if (email === alter_email1 === alter_email2)
        // {
        //     showToastforEqualemailandemailoneANDemailTwo()
        // }
        // else if (alter_email1 === alter_email2){
        //     showToastforEqualemailoneANDemailTwo()
        // }
        // else if(email === alter_email2){
        //     showToastforEqualemailoneANDemailTwo()
        // }
        else if(phone === "")
        {
            showToastemptyphone()
        }
        else if (!phoneNumPattern.test(phone) ) {
            // console.log("error in number")
            showToastMessageNumNotValid()
        }
        else if(altPhone === "")
        {
            showToastemptyaltphone()
        }
        else if (!phoneNumPattern.test(altPhone)){
            showToastMessageNumAltNotValid()
        }
        else if (phone === altPhone) {
            showToastMessagePhonnAltphn()
        }
        else if(status === "")
        {
            showToastEmptystatus()
        }
        
        //     else if(alter_email1==email) {
        //         console.log("error in number")
        //         showToastemailnaltemail1()
        // }
        //     else if(alter_email1==alter_email2) {
        //         console.log("email alter1 n alr2  cant b same")
        //         showToastMessageNumNotValid()
        //      }
        //      else if(email==alter_email2) {
        //         console.log("email cant b same")
        //         showToastemailnaltemail1naltemail2()
        //      }
        //      else if(alter_email1==alter_email2) {
        //         console.log("email cant b same")
        //         showToastAleREmail1n2()
        //      }
        else {
            // let payload = { agentname, location, email, alter_email1, alter_email2, phone, altPhone, status }
            let payload = { agentname, location, email, alter_email1, phone, altPhone, status }
            axios.post("/POSTAgent", payload)
                .then(() => {
                    showToastMessage();
                    setTimeout(()=>{
                        setAgentName('')
                    setLocation('')
                    setEmail('')
                    setAlter_email1('')
                    // setAlter_email2('')
                    setPhone('')
                    setStatus('')
                    SetAltPhone('')
                    },1500)
                    // console.log("data reached")
                })
                .catch(() => {
                    // console.log("error saving data")
                })
        }

    }
    let jump = useNavigate()
    function handelclick() {
        jump('/Add_Agents')
    }
    //fill all details 
    const showToastEnterAllDetails = () => {
        toast.error('Please fill all details !', {
            position: toast.POSITION.TOP_CENTER,
            toastId: '1',
        });
    };
    //agent name not valid 
    const showToastNameNotValid = () => {
        toast.error('Agent Name not valid  !', {
            position: toast.POSITION.TOP_CENTER,
            toastId: '2',
        });
    };
    //email name not valid 
    const showToastAltEmail1NotValid = () => {
        toast.error('Alternative Email 1 not valid  !', {
            position: toast.POSITION.TOP_CENTER,
            toastId: '3',
        });
    };
    const showToastAltEmail2NotValid = () => {
        toast.error('Alternative Email 2 not valid  !', {
            position: toast.POSITION.TOP_CENTER,
            toastId: '4',
        });
    };
    const showToastEmailNotValid = () => {
        toast.error('Email not valid  !', {
            position: toast.POSITION.TOP_CENTER,
            toastId: '5',
        });
    };

    const showToastMessage = () => {
        toast.success('Agent Added Sucessfully !', {
            position: toast.POSITION.TOP_CENTER,
            toastId: '6',
        });
    };
    //number not valid 
    const showToastMessageNumNotValid = () => {
        toast.error('Invalid Phone Number!', {
            position: toast.POSITION.TOP_CENTER,
            toastId: '7',
        });
    };
    const showToastMessageNumAltNotValid = () => {
        toast.error('Invalid Alternate Phone Number!', {
            position: toast.POSITION.TOP_CENTER,
            toastId: '8',
        });
    };
 

    //phone and altphone 
    const showToastMessagePhonnAltphn = () => {
        toast.success('Phone Number and Alternate Phone Number cant be Same  !', {
            position: toast.POSITION.TOP_CENTER,
            toastId: '9',
        });
    };

    const showToastEmptyagentname = () => {
        toast.error('Please Fill Agent Name !', {
            position: toast.POSITION.TOP_CENTER,
            toastId: '10',
        });
    };
    const showToastlocation = () => {
        toast.error('Please Select Location !', {
            position: toast.POSITION.TOP_CENTER,
            toastId: '11',
        });
    };
    const showToastemail = () => {
        toast.error('Please Enter Email !', {
            position: toast.POSITION.TOP_CENTER,
            toastId: '12',
        });
    };
    const showToastalteremailone = () => {
        toast.error('Please Enter Alternative Email 1 !', {
            position: toast.POSITION.TOP_CENTER,
            toastId: '13',
        });
    };
    const showToastalteremailtwo = () => {
        toast.error('Please Enter Alternative Email 2 ! !', {
            position: toast.POSITION.TOP_CENTER,
            toastId: '14',
        });
    };
    const showToastemptyphone = () => {
        toast.error('Please Enter Phone Number!', {
            position: toast.POSITION.TOP_CENTER,
            toastId: '15',
        });
    };
    const showToastemptyaltphone = () => {
        toast.error('Please Enter Alternative Phone Number !', {
            position: toast.POSITION.TOP_CENTER,
            toastId: '16',
        });
    };
    const showToastEmptystatus = () => {
        toast.error('Please Select Status !', {
            position: toast.POSITION.TOP_CENTER,
            toastId: '17',
        });
    };
    const showToastforEqualEmailANDemailone = () => {
        toast.error("Email and Alternative Email 1 can't be same !", {
            position: toast.POSITION.TOP_CENTER,
            toastId: '18',
        });
    };
    const showToastforEqualEmailANDemailTwo = () => {
        toast.error("Email and Alternative Email 2 can't be same !", {
            position: toast.POSITION.TOP_CENTER,
            toastId: '19',
        });
    };
    const showToastforEqualemailoneANDemailTwo = () => {
        toast.error("Alternative Email 1 and Alternative Email 2 can't be same !", {
            position: toast.POSITION.TOP_CENTER,
            toastId: '20',
        });
    };
    const showToastforEqualemailandemailoneANDemailTwo = () => {
        toast.error("Email, Alternative Email 1 and Alternative Email 2 can't be same !", {
            position: toast.POSITION.TOP_CENTER,
            toastId: '21',
        });
    };

    

    

    // getting car location 

    function carlocation() {
        axios.get("/LocationInformation")
            .then((res) => {
                // console.log(res.data.users)
                setSelectLocation(res.data.users)

            })
            .catch(() => {
                // console.log("error getting locations")
            })
    }
    useEffect(() => {
        carlocation()
    }, [])
    // console.log(agentname);
    // console.log(status)

    return (
        <React.Fragment>
            <div className="page-content" style={{backgroundColor:"#212529"}} >
                <Container fluid>
                    <BreadCrumb title="Master" pageTitle="Agents" />    
                    <Col  style={{color:"#eee4e4"}}  xxl={12}>
                        <Card  style={{backgroundColor:"#212529"}}  >
                            <PreviewCardHeader title="Add Agents" />
                            <div className="card-body">
                                {/* <p className="text-muted">By adding <Link to="/docs/5.1/layout/gutters/" className="text-decoration-underline">gutter modifier classes</Link>, you can have control over the gutter width in as well the inline as block direction. <span className="fw-medium">Also requires the <code>$enable-grid-classes</code> Sass variable to be enabled</span> (on by default).</p> */}
                                <div className="live-preview">
                                    <form action="#" className="row g-3">
                                        <Col md={6}>
                                            <Label htmlFor="fullnameInput" style={{color:"#eee4e4"}} className="form-label">Agent Name</Label>
                                            <Input  style={{backgroundColor:"#2a2f34",color:"#eee4e4"}} type="text" value={agentname}  className="form-control" id="fullnameInput" onInput={(e) => { setAgentName(e.target.value) }} placeholder="Enter Agent Name" />
                                        </Col>
                                        <Col md={6}>
                                            <Label htmlFor="inputState" className="form-label">Location</Label>
                                            <select  style={{backgroundColor:"#2a2f34",color:"#eee4e4"}}  value={location} onInput={(e) => { setLocation(e.target.value) }} id="inputState" className="form-select" data-choices data-choices-sorting="true">
                                                <option >Select Any</option>
                                                {
                                                    selectLocation ? selectLocation.map((item) => {
                                                        return (
                                                            <option key={item.id}>{item.location}</option>
                                                        )
                                                    }) : <h1>no options available  </h1>
                                                }
                                            </select>
                                        </Col>
                                        <Col md={6}>
                                            <Label htmlFor="fullnameInput"  style={{color:"#eee4e4"}} className="form-label">Email</Label>
                                            <Input value={email}  style={{backgroundColor:"#2a2f34",color:"#eee4e4"}}  type="text" className="form-control" id="fullnameInput" onInput ={(e) => { setEmail(e.target.value) }} placeholder="Enter Email" />
                                        </Col>
                                        <Col md={6}>
                                            <Label htmlFor="fullnameInput" className="form-label">Alternative Email 1</Label>
                                            <Input   style={{backgroundColor:"#2a2f34",color:"#eee4e4"}} value={alter_email1} type="text" className="form-control" id="fullnameInput" onInput={(e) => { setAlter_email1(e.target.value) }} placeholder="Enter Alternative Email" />
                                        </Col>
                                        {/* <Col md={6}>
                                            <Label htmlFor="fullnameInput" className="form-label">Alternative Email 2</Label>
                                            <Input  style={{backgroundColor:"#2a2f34",color:"#eee4e4"}}  value={alter_email2} type="text" className="form-control" id="fullnameInput" onInput={(e) => { setAlter_email2(e.target.value) }} placeholder="Enter Alternative Email" />
                                        </Col> */}
                                        <Col md={6}>
                                            <Label htmlFor="fullnameInput" className="form-label">Phone Number</Label>
                                            <Input  style={{backgroundColor:"#2a2f34",color:"#eee4e4"}}  value={phone} type="text" className="form-control" id="fullnameInput" onInput={(e) => { setPhone(e.target.value) }} placeholder="xxxx-xxx-xxx" />
                                        </Col>
                                        <Col md={6}>
                                            <Label htmlFor="inputEmail4" className="form-label">Alternative Phone Number</Label>
                                            <Input  style={{backgroundColor:"#2a2f34",color:"#eee4e4"}}  type="text" value={altPhone} className="form-control" id="inputEmail4" onInput={(e) => { SetAltPhone(e.target.value) }} placeholder="xxxx-xxx-xxx" />
                                        </Col>
                                        <Col xl={4}>
                      <Label htmlFor="employeeName" className="form-label">
                       Select Status
                      </Label>
                     
                       
                      
                       <select
                          id="inputState"
                          className="form-select"
                         value={status}
                          data-choices
                          data-choices-sorting="true"
                          onChange={(e) => {
                            setStatus(e.target.value);
                          }}
                        >
                          <option hidden>Select Status</option>
                          <option 
                            
                            value="Active"
                            onChange={(e) => {
                              setStatus(e.target.value);
                            }}
                            >Active</option>
                          <option 
                            value="Inactive"
                            onChange={(e) => {
                              setStatus(e.target.value);
                            }}
                            >Inactive</option>
                          
                        </select>
                        </Col>
                                        

                        
                                        <div className="text-center">
                                            {/* <Input type="checkbox" className="btn-check" id="btn-check-outlined" /> */}
                                            {/* <Label className="btn btn-outline-primary text-centre"  onClick={onsubmit} for="btn-check-outlined">Add</Label> */}
                                            <Label
                                                className="btn btn-outline-primary text-centre"
                                                for="btn-check-outlined"

                                                onClick={onsubmit}
                                            >
                                                Add
                                            </Label>
                                            &nbsp; &nbsp; &nbsp;
                                            <Link to="/Agents_Lists">
                                                <Label
                                                    className="btn btn-outline-danger"
                                                    for="danger-outlinedremove"
                                                >
                                                    Cancel
                                                </Label>
                                            </Link>
                                            <br></br>
                                        </div>
                                    </form>
                                </div>
                                <div className="d-none code-view">
                                    <pre className="language-markup" style={{ "height": "375px" }}>
                                        <code>
                                            <Gutters />
                                        </code>
                                    </pre>
                                </div>
                            </div>
                        </Card>
                    </Col>
                </Container>
                <ToastContainer />
            </div>
        </React.Fragment>
    )
};
export default Agents;