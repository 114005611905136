
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../helpers/Axios";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import {
  Card,
  CardBody,
  CardHeader,
  Input,
  Label,
  Col,
  Collapse,
  Container,
  Row,
  DropdownItem,
  ModalBody,
  ModalFooter,
  ModalHeader,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  CardFooter,
} from "reactstrap";
function LocationListModal({
  setShow,
  show,
  id,
  location,
  location_status,
  status,
}) {
  //   const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [updateId, setUpdateId] = useState(id);
  const [updateLocation, setUpdateLocation] = useState(location);
  const [updateLocationStatus, setUpdateLocationStatus] = useState(location_status);
  const [updateStatus, setUpdateStatus] = useState(status);

  const showToastMessageforupdateLocation = () => {
    toast.success("Coupon Updated Sucessfully !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  function UpdateLocationfun() {
    let payload = {
      id: id,
      location: updateLocation,
      location_status: updateLocationStatus,
      status: updateStatus,
    };
    // let payload = {updateId, location, locationstatus, status}
    axios
      .put("/location", payload)
      .then((res) => {
        showToastMessageforupdateLocation();
        setTimeout(() => {
          // setIsOpen(false);
        }, 2000);
      })
      .catch((err) => {
        alert(err);
      });
  }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Location Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group
              classNameNameName="mb-3"
              controlId="exampleForm.ControlInput1"
            >
              <div className="auth-page-content">
                <Container fluid>
                  <Row>
                    <Col>
                      <Card className="mt-2">
                        <CardBody className="p-2">
                          <div>
                            <div className="text-center mt-2">
                              <h6 className="text-primary">Edit Location</h6>
                            </div>
                            <div className="p-2 mt-0 justify-content-center">
                              <Form
                                onSubmit={(e) => {
                                  e.preventDefault();
                                }}
                                action="#"
                              >
                                <div className="mb-2">
                                  <Label
                                    htmlFor="catname"
                                    className="form-label"
                                  >
                                    Location
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    id="catname"
                                    name="catname"
                                    className="form-control"
                                    placeholder="Edit name"
                                    type="text"
                                    defaultValue={location}
                                    onChange={(e) => {
                                      setUpdateLocation(e.target.value);
                                    }}
                                  />
                                </div>
                                <div className="mb-2">
                                  <Label
                                    htmlFor="employeeNamestatus"
                                    className="form-label"
                                  >
                                    Location Status{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <select
                                    id="employeeNamestatus"
                                    className="form-select"
                                    defaultValue={location_status}
                                    data-choices
                                    data-choices-sorting="true"
                                    onChange={(e) =>
                                      setUpdateLocationStatus(e.target.value)
                                    }
                                  >
                                    <option value={"Popular"}>Popular</option>
                                    <option value={"Unpopular"}>Unpopular</option>
                                  </select>
                                </div>
                                <div className="mb-2">
                                  <Label
                                    htmlFor="employeeNamestatus"
                                    className="form-label"
                                  >
                                    Location Status{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <select
                                    id="employeeNamestatus"
                                    className="form-select"
                                    defaultValue={status}
                                    data-choices
                                    data-choices-sorting="true"
                                    onChange={(e) =>
                                      setUpdateStatus(e.target.value)
                                    }
                                  >
                                    <option value={"Active"}>Active</option>
                                    <option value={"Inactive"}>Inactive</option>
                                  </select>
                                </div>
                              </Form>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Container>
                <ToastContainer />
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={UpdateLocationfun}>
            Update
          </Button>

          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

// render(<ModalView />);
export default LocationListModal;
