
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { api_url,aws_bucket_url } from "../../helpers/base_url";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Card, CardBody, CardHeader, Input, Label, Col, Collapse, Container, Row, DropdownItem, ModalBody, ModalFooter, ModalHeader, DropdownToggle, DropdownMenu, UncontrolledDropdown, CardFooter } from 'reactstrap';
// import ImageForView from './ImageForView';
function Modalviewyacht ({ setShow, show, yachtname, receivedata,capacity, description, price,sailing,anchorage, time,yachtimage }) {
  //   const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // console.log(receivedata)
  // console.log(receivedata.yacht_name)
  // console.log(yachtname)

  return (
    <>
      <Modal show={show} onHide={handleClose}  >
        <Modal.Header closeButton>
          <Modal.Title>{yachtname}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                <Form style={{display:'flex', justifyContent:"center", alignItems:"center"}} >
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <CardBody>
                      <div className="d-flex align-items-center">
                      <htmlForm>
        <div className="mb-3">
          <label htmlFor="exampleInputEmail1" className="form-label">Yacht Name &nbsp; :&nbsp; {yachtname}</label>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">Description &nbsp; : &nbsp; {description}</label>
        </div>
        <div className="mb-3">
        {/* <img
            height="300px" width="460px"
              // src={require(`../../CarImages/CarBrandIcon/${carlogo}`)}
              src={aws_bucket_url + '/' + `${yachtimage}`}
            ></img> */}
            {<Carousel autoPlay infiniteLoop showThumbs={false} showArrows={false} swipeable={true} stopOnHover={true} showStatus={false}>
                      {
                        yachtimage.split(',').map((image) => {
                          return (
                            <div key={image}>
                              <img height="300px" width="460px" src={`${aws_bucket_url}/${image}`} alt="Car" />
                            </div>
                          )
                        })
                      }
            </Carousel>}
        </div>
     
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">Price &nbsp;: &nbsp; {price}</label>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">Capacity  &nbsp;:&nbsp; {capacity}</label>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">Time  &nbsp;:&nbsp;  Sailing {sailing} hours &nbsp; + &nbsp; Anchoring {anchorage} hours</label>
        </div>
        
       
        
        
      </htmlForm>
                  <div className="flex-grow-1 ms-3">

                  </div>
                </div>
              </CardBody>

            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>

        </Modal.Footer>
      </Modal>
    </>
  );
}

// render(<ModalView />);
export default Modalviewyacht