// import axios from "axios";
import axios from "../../helpers/Axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import DataTable,{createTheme} from "react-data-table-component";
import { Link } from "react-router-dom";
import LocationListModal from "./LocationListModal";
import PreviewCardHeader from "../../Components/Common/PreviewCardHeader";
import ModalViewLocation from "./ModalViewLocation";

// import { Row } from 'react-table';

const LocationList = () => {
  const [locationData, setLocationData] = useState([]);
  const [id, setId] = useState("");
  const [search, setSearch] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  const [updateId, setUpdateId] = useState("");
  const [updateLocation, setUpdateLocation] = useState("");
  const [updateLocationStatus, setUpdateLocationStatus] = useState("");
  const [updateStatus, setUpdateStatus] = useState("");

  const [isOpenViewloc,setIsOpenViewloc] = useState(false)

  const[show,setShow]=useState(false)
  const[show1,setShow1]=useState(false)

  const [showModal, setShowModal] = useState(false)


  createTheme('fulldark',{
    text:{
        primary: 'white',
        secondary:'white'
    },
    background:{
        default:'#212529'
    },
    context:{
        text:'white'
    },
    button:{
        default:'white',
        focus:'white',
        hover:'white',
        disabled:'white'
    }
})

  function locationDatafun(){
    axios
      .get(`/location`)
      .then((res) => {
        setLocationData(res.data.location);
        // console.log(res,'@@@@@@@@@@@@@@@@@@')
        // console.log(locationData,'##############');
      })
      .catch(() => {
        // console.log("Error");
      });
    }

  useEffect(() => {
    locationDatafun()
  }, [showModal]);

  const showToastMessageerrorDeleteforloc = () => {
    toast.success("Reterived Delete", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const jump = useNavigate();

  function handelClick() {
    jump("/location");
  }

  // const showToastMessageDeleteSuccessinlist = () => {
  //   toast.success("Deleted Successfully !", {
  //     position: toast.POSITION.TOP_CENTER,
  //   });
  // };
  const columns = [
    {
      name: <span className="font-weight-bold fs-13">ID</span>,
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Location</span>,
      selector: (row) => row.location,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">location Status</span>,
      selector: (row) => row.location_status,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Status</span>,
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      sortable: true,
      cell: (row) => {
        return (
          <UncontrolledDropdown className="dropdown d-inline-block" direction="right">
            <DropdownToggle
              className="btn btn-soft-secondary btn-sm"
              tag="button"
            >
              <i className="ri-more-fill align-middle"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem href="#!"
              onClick={(e) => {
                setUpdateId(row.id);
                setUpdateLocation(row.location);
                setUpdateLocationStatus(row.location_status);
                setUpdateStatus(row.status);
                setShow(true)
              }}>
                <i className="ri-eye-fill align-bottom me-2 text-muted"></i>View
              </DropdownItem>
              <DropdownItem
                onClick={(e) => {
                  setUpdateId(row.id);
                  setUpdateLocation(row.location);
                  setUpdateLocationStatus(row.location_status);
                  setUpdateStatus(row.status);
                  setShowModal(true)
                  
                }}
                className="edit-item-btn"
              >
                <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
                Edit
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  Swal.fire({
                    title: "Are you sure?",
                    text: `Your sure .. u want to delete ${row.location}!`,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                    background:"rgb(33, 37, 41)"
                  }).then((result) => {
                    if (result.isConfirmed) {
                      Swal.fire({
                        title: "Deleted ",
                       
                        icon: "success",
                        background:"rgb(33, 37, 41)"

                      }
                        // "Deleted!",
                        // " data has been deleted.",
                        // "success"
                        
                      );
                      axios
                        .delete(
                          `/location?id=${row.id}`
                        )
                        .then(() => {
                          // console.log("deleted");
                          locationDatafun()
                          
                        })
                        .catch(() => {
                          // console.log("error deleting ");
                          showToastMessageerrorDeleteforloc();
                        });
                    }
                  });
                }}
                className="remove-item-btn"
              >
                {" "}
                <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                Delete{" "}
                
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      },
    },
  ];
  const data = locationData;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Master" pageTitle="Location List" />
          <Col xl={12}>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardHeader>
                    <h5 className="card-title mb-0">Location List</h5>
                  </CardHeader>
                  <CardBody>
                    <Row className="g-4 mb-3">
                      <Col className="col-sm-auto">
                        {/* <div className="d-flex gap-1">
                                                    <Button color="success" className="add-btn" onClick={() => tog_list()} id="create-btn"><i className="ri-add-line align-bottom me-1"></i> Add</Button>
                                                    <Button className="btn btn-soft-danger"
                                                    // onClick="deleteMultiple()"
                                                    ><i className="ri-delete-bin-2-line"></i></Button>
                                                </div>  */}
                        <Label
                          className="btn btn-outline-primary"
                          onClick={handelClick}
                          for="btn-check-outlined"
                        >
                          Add Location
                        </Label>
                      </Col>
                    </Row>
                    <DataTable columns={columns} data={data} pagination   theme='fulldark' />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Container>
        {showModal && (
          <LocationListModal
            setShow = {setShowModal}
            show = {showModal}
            id={updateId}
            location={updateLocation}
            location_status={updateLocationStatus}
            status={updateStatus}
          />
        )}
        {
          show && <ModalViewLocation 
          setShow={setShow}
          show={show}
          id={updateId}
          location={updateLocation}
          location_status={updateLocationStatus}
          status={updateStatus}
          />

        }
      </div>
    </React.Fragment>
  );
};
export default LocationList;
