import React from 'react'

const Airport = () => {
  return (
    <div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <h1>This is Airport Section</h1>
    </div>
  )
}

export default Airport
