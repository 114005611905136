import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import BreadCrumb from "../../Components/Common/BreadCrumb"
import {
  Card,
  CardBody,
  CardHeader,
  Input,
  Label,
  Col,
  Collapse,
  Container,
  Row,
  Button,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  CardFooter,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import DataTable, { createTheme } from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "../../helpers/Axios"

const Viewers = () => {

  const [recive, setRecive] = useState([]);

  function gettingviewersdata() {
    axios
      .get("/viewers")
      .then((res) => {
        setRecive(res.data.users);
        console.log(res.data.users);
        console.log("received feedback details ");
      })
      .catch(() => {
        console.log("error receiveing feedback details ");
      });
  }
  useEffect(() => {
    gettingviewersdata();
  }, []);
  const showToastMessageDeleteSuccess = () => {
    toast.success("Deleted Successfully !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const showToastMessageRetervied = () => {
    toast.success("Delete Retervied !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  createTheme("fulldark", {
    text: {
      primary: "white",
      secondary: "white",
    },
    background: {
      default: "#212529",
    },
    context: {
      text: "white",
    },
    button: {
      default: "white",
      focus: "white",
      hover: "white",
      disabled: "white",
    },
  });

  
  const columns = [
    {
      name: <p>Id </p>,
      selector: (row) => row.id,
    },
   
    {
      name: <p>Email</p>,
      selector: (row) => row.email,
    },
   
   
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Subscribers" pageTitle="Viewers" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0  text-centre">Subscription</h4>
                </CardHeader>
                <CardBody>
                  <DataTable
                    columns={columns}
                    data={recive}
                    pagination
                    theme="fulldark"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Viewers;
