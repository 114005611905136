import React, { useEffect, useState, useNavigate, useRef } from "react";

import {
  Card,
  Col,
  Row,
  Label,
  Form,
  CardHeader,
  Container,
  Input,
  CardBody,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PreviewCardHeader from "./PreviewCardReaderDemo";
import { Link } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import Dropzone from "react-dropzone";
import BreadCrumb from "./BreadCrumbDummyCarDetails";
import { VerticalForm, HorizontalForm } from "./FormlayoutsCode";
// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import axios from '../../helpers/Axios';
const AddCarDetails = () => {
  const [selectedFiles, setselectedFiles] = useState([]);
  let imgData = useRef();
  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),

        // path:file.path(files),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
    // console.log(files);
  }

  // let jump=useNavigate()
  // function Cancel(){
  //   jump('/dashboard-crm')
  // }
  const showToastMessageSuccessAdding = () => {
    toast.success("Added Successfully!", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "1",
    });
  };

  const showToastMessageFeaturename = () => {
    toast.error("Add Featurename", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "2",
    });
  };

  const showToastMessageDescription = () => {
    toast.error("Add Description!", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "3",
    });
  };

  const showToastMessageUploadFile = () => {
    toast.error("Please Upload a Image!", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "4",
    });
  };

  const showToastMessageSuccessStatus = () => {
    toast.error("Select Status", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "5",
    });
  };
  const showToastMessageforEmpty = () => {
    toast.error("Please fill all details..!", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "5",
    });
  };
  const showToastMessageforInvalidCarfeatures = () => {
    toast.error("Please enter valid features name", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "6",
    });
  };

  // additonal function for file path name

  // Dropzone.options.yourDropzoneID = {
  //   init: function() {
  //     this.on("sending", function(file, xhr, data) {
  //         if(file.fullPath){
  //             data.append("fullPath", file.fullPath);
  //         }
  //     });
  //   }
  // };

  //for useState addding car feature name and image

  const [featurename, setFeaturename] = useState("");
  const [files, setFiles] = useState();
  // for getting preview and status
  const [preview, setPreview] = useState("");
  const [status, setStatus] = useState("");
  const [description, setDescription] = useState("");

  //to upload Images
  const [uploading, setUploadimg] = useState("");

  function uploadsFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );

    // console.log(status);

    //  for image name
    setFiles(files[0].name);
    // console.log(files[0].name);

    setPreview(files[0].path);
    // console.log(files[0].preview);

    //for path name
    // setFiles(files[0])
    // console.log(files[0])
    // console.log(files[0].preview)
    // console.log(files[0].name)
  }

  //upload images
  const imagesUpload = (e) => {
    e.preventDefault();
    const Image = e.target.files[0];
    // console.log(e.target.files[0].name);
    setUploadimg(e.target.files[0].name);
    setFiles(e.target.files[0].name);
    // console.log(Image);
    if (Image != null) {
      const data = new FormData();
      data.append("Image", Image);
      // console.log(data);
      axios
        .post("/uploadfeatureimg", data)
        .then(() => {
          // imgData.current.value = null;
          // setTimeout(() => {
          //   // e.target.files[0] = null;
          //   setFiles("");
          //   setUploadimg("");
          //   e.target.value = null;
          // }, 1500);
          // console.log("Uploaded");
        })

        .catch(() => {
          // console.log("Error in Uploading");
        });
    }
  };

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const nameformat = /^[A-Za-z_.]+$/;

  //posting

  function handelClick(e) {
    e.preventDefault();
    if (
      featurename === "" &&
      description == "" &&
      files == null &&
      status === ""
    ) {
      showToastMessageforEmpty();
    } else if (featurename === "") {
      // console.log("add feature name ");
      showToastMessageFeaturename();
    } else if (!featurename.match(nameformat)) {
      showToastMessageforInvalidCarfeatures();
    } else if (description == "") {
      // console.log("add descirption");
      showToastMessageDescription();
    } else if (files == null) {
      // console.log("pls upload a image ");
      showToastMessageUploadFile();
    } else if (status === "") {
      // console.log("add status");
      showToastMessageSuccessStatus();
    } else {
      let payload = { featurename, preview, status, description, uploading };
      axios
        .post("/carfeatures", payload)
        .then(() => {
          showToastMessageSuccessAdding();
          setTimeout(() => {
            setFeaturename("");
            setPreview("");
            setStatus("");
            setDescription("");
            setUploadimg("");
            // imgData.current.value = "";
          }, 1500);
          // console.log("data reached");
        })
        .catch(() => {
          // console.log("error");
        });
    }
  }
  return (
    <React.Fragment>
      <div className="page-content" style={{ backgroundColor: "#212529" }}>
        <Container fluid>
          <BreadCrumb title="Master" pageTitle="Car Features" />
          <Row>
            <Col xxl={12}>
              <Card style={{ backgroundColor: "#2a2f34" }}>
                <PreviewCardHeader
                  className="text-center"
                  title="Add Car Features"
                />
                <CardBody>
                  <div className="live-preview">
                    <Form action="/upload" encType="multipart/form-data">
                      <div className="mb-3">
                        <Label
                          htmlFor="employeeName"
                          style={{
                            backgroundColor: "#2a2f34",
                            color: "#eee4e4",
                          }}
                          className="form-label"
                        >
                          Car Feature
                        </Label>
                        <Input
                          type="text"
                          style={{
                            backgroundColor: "#2a2f34",
                            color: "#eee4e4",
                          }}
                          className="form-control"
                          id="employeeName"
                          placeholder="Enter Car Features"
                          value={featurename}
                          onChange={(e) => {
                            setFeaturename(e.target.value);
                          }}
                        />
                      </div>
                      {/* feature descriptions */}
                      <div className="mb-3">
                        <Label
                          htmlFor="employeeName"
                          style={{ color: "#eee4e4" }}
                          className="form-label"
                        >
                          Description
                        </Label>
                        <Input
                          style={{
                            backgroundColor: "#2a2f34",
                            color: "#eee4e4",
                          }}
                          type="text"
                          className="form-control"
                          id="employeeName"
                          placeholder="Describe Car Features"
                          value={description}
                          onChange={(e) => {
                            setDescription(e.target.value);
                          }}
                        />
                      </div>

                      {/* <from encType="multipart/form-data" > */}

                      {/* </from> */}

                      {/* file upload */}
                      <Col lg={12}>
                        {/* <Label htmlFor="employeeName" className="form-label">Add Icon</Label> */}
                        <Card
                          style={{
                            backgroundColor: "#2a2f34",
                            color: "#eee4e4",
                          }}
                        >
    
                            <h5
                              style={{ color: "#eee4e4" }}
                              className="text-center"
                            >
                              
                            </h5>

                          <CardBody
                            style={{
                              backgroundColor: "#2a2f34",
                              color: "#eee4e4",
                            }}
                          >
                            <p>Add Feature Icons</p>
                            <div>
                              <Input
                                ref={imgData}
                                style={{
                                  backgroundColor: "#2a2f34",
                                  color: "#eee4e4",
                                }}
                                className="form-control"
                                id="formFile"
                                placeholder="additonal input field "
                                type="file"
                                name="file"
                                onChange={imagesUpload}
                              />
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                     
                      <Col xxl={4}>
                      <Label htmlFor="employeeName" className="form-label">
                        Status
                      </Label>
                      {/* <div className="live-preview">
                        <div className="hstack gap-2 flex-wrap">
                          <Input
                            type="radio"
                            className="btn-check"
                            value="Active"
                            onClick={(e) => {
                              setStatus(e.target.value);
                            }}
                            name="options-outlined"
                            id="success-outlined"
                          />
                          <Label
                            className="btn btn-outline-success"
                            for="success-outlined"
                          >
                            Active
                          </Label>
                          <Input
                            type="radio"
                            className="btn-check"
                            value="Inactive"
                            onClick={(e) => {
                              setStatus(e.target.value);
                            }}
                            name="options-outlined"
                            id="danger-outlined"
                          />
                          <Label
                            className="btn btn-outline-danger"
                            for="danger-outlined"
                          >
                            Inactive
                          </Label>
                        </div>
                      </div> */}
                       <select
                          // id="inputState"
                          className="form-select"
                         value={status}
                          data-choices
                          data-choices-sorting="true"
                          onChange={(e) => {
                            setStatus(e.target.value);
                          }}
                        >
                          <option>Select Status</option>
                          <option 
                            
                            value="Active"
                            onChange={(e) => {
                              setStatus(e.target.value);
                            }}
                            >Active</option>
                          <option 
                            value="Inactive"
                            onChange={(e) => {
                              setStatus(e.target.value);
                            }}
                            >Inactive</option>
                          
                        </select>
                        </Col>
                        
                      {/* <div className="live-preview">
                        <div className="hstack gap-2 flex-wrap">
                          <Input
                            style={{
                              backgroundColor: "#2a2f34",
                              color: "#eee4e4",
                            }}
                            type="radio"
                            className="btn-check"
                            name="options-outlined"
                            id="success-outlined"
                            value="Active"
                            onChange={(e) => {
                              setStatus(e.target.value);
                            }}
                          />
                          <Label
                            className="btn btn-outline-success"
                            for="success-outlined"
                          >
                            Active
                          </Label>
                          <Input
                            type="radio"
                            className="btn-check"
                            name="options-outlined"
                            id="danger-outlined"
                            value="Inactive"
                          />
                          <Label
                            className="btn btn-outline-danger"
                            for="danger-outlined"
                          >
                            Inactive
                          </Label>
                        </div>
                      </div> */}
                      {/* <div className="d-none code-view">
                                        <pre className="language-markup" style={{ "height": "275px" }}>
                                            <code>
                                                <OutlinedStyles />
                                            </code>
                                        </pre>
                                    </div> */}
                      {/* <Col xxl={6}> */}
                      <div className="text-center">
                        {/* <Input
                      type="checkbox"
                      className="btn-check"
                      id="btn-check-outlined"

                      // onClick={handelClick}
                    /> */}
                        {/* <Label
                      className="btn btn-outline-primary text-centre"
                      for="btn-check-outlined"
                    >
                      Add
                    </Label> */}
                        <Label
                          className="btn btn-outline-primary text-centre"
                          for="btn-check-outlined"
                          onClick={handelClick}
                        >
                          Add
                        </Label>
                        &nbsp; &nbsp; &nbsp;
                        <Link to="/Car_Features">
                          <Label
                            className="btn btn-outline-danger"
                            for="danger-outlinedremove"
                          >
                            cancel
                          </Label>
                        </Link>
                      </div>
                      {/* </Col> */}
                    </Form>
                  </div>
                  <div className="d-none code-view">
                    <pre
                      className="language-markup"
                      style={{ height: "375px" }}
                    >
                      <code>
                        <VerticalForm />
                      </code>
                    </pre>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer></ToastContainer>
    </React.Fragment>
  );
};
export default AddCarDetails;