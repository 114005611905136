import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import {
  Card,
  CardBody,
  CardHeader,
  Input,
  Label,
  Col,
  Collapse,
  Container,
  Row,
  DropdownItem,
  ModalBody,
  ModalFooter,
  ModalHeader,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  CardFooter,
} from "reactstrap";
// import ImageForView from "./ImageForView";
function ViewDriver({
  setShow,
  show,
  id,
  driver_name, 
  driver_img,
  driver_loc,
  email,
  contact,
  alter_contact,
  driving_licence_no,
  aadhar_no,
  pancard_no,
  date_of_birth,
  driver_pin,
  status,
}) {
  //   const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}

      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Drivers Information</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <Form >
            <Form.Group
              classNameNameName="mb-3 text-center"
              controlId="exampleForm.ControlInput1"
            >
              <CardBody>
                <div>
                  <div>
                  <img
              width="100px"
              height="100px"
              src={require(`../../CarImages/DriverImages/${driver_img}`)}
            ></img>
                  </div>
                  <div className="flex-grow-1 ms-3"></div>
                </div>
              </CardBody>
            </Form.Group>
            <br/>
            <Form style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <CardBody>
                      <div className="d-flex align-items-center">
            <div className="d-flex align-items-center">
                      <htmlForm>
        <div className="mb-3">
          <label htmlFor="exampleInputEmail1" className="form-label">Name:&nbsp;{driver_name}</label>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">Email:&nbsp;{email}</label>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">Location:&nbsp;{driver_loc}</label>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">Contact:&nbsp;{contact}</label>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">Alternative_Contact:&nbsp;{alter_contact}</label>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">Driving_Licence:&nbsp;{driving_licence_no}</label>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">Aadhar_Number : {aadhar_no}</label>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">PanCard_Number: {pancard_no}</label>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">D.O.B: {date_of_birth}</label>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">Pin_Number: {driver_pin}</label>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">Status: {status}</label>
        </div>
      </htmlForm>
      <div className="flex-grow-1 ms-3"></div>
      </div>
      </div>
      </CardBody>
      </Form.Group>
      </Form> */}
            {/* <htmlForm>
            <Form.Group>
                    <Label htmlFor="employeeName" className="form-label">
                     Name:&nbsp;{driver_name}
                    </Label>        
            </Form.Group>
            <Form.Group><Label className="mb-3">Email:&nbsp;{email}</Label></Form.Group>
            <Form.Group><Label className="mb-3">Location:&nbsp;{driver_loc}</Label></Form.Group>
            <Form.Group><Label>Contact:&nbsp;{contact}</Label></Form.Group>
            <Form.Group><Label>Alternative_Contact:&nbsp;{alter_contact}</Label></Form.Group>
            <Form.Group><Label>Driving_Liceb{driving_licence_no}</Label></Form.Group>
            <Form.Group><Label>{aadhar_no}</Label></Form.Group>
            <Form.Group><Label>{pancard_no}</Label></Form.Group>
            <Form.Group><Label>{date_of_birth}</Label></Form.Group>
            <Form.Group><Label>{driver_pin}</Label></Form.Group>
            <Form.Group><Label>{status}</Label></Form.Group>
            </htmlForm> */}
            
          {/* </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}
      <Modal show={show} onHide={handleClose}  >
        <Modal.Header closeButton>
          <Modal.Title>Drivers Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="text-center">
          <Form.Group
              classNameNameName="mb-3 text-center"
              controlId="exampleForm.ControlInput1"
            >
              <CardBody>
                <div>
                  <div>
                  <img
              width="100px"
              height="100px"
              src={require(`../../CarImages/DriverImages/${driver_img}`)}
            ></img>
                  </div>
                  <div className="flex-grow-1 ms-3"></div>
                </div>
              </CardBody>
            </Form.Group>
            <br/>
            </Form>
                <Form style={{display:'flex', justifyContent:"center", alignItems:"center"}} >
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <CardBody>
                      <div className="d-flex align-items-center">
                      <htmlForm>
        <div className="mb-3">
          <label htmlFor="exampleInputEmail1" className="form-label">Name:&nbsp;{driver_name}</label>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">Email:&nbsp;{email}</label>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">Location:&nbsp;{driver_loc}</label>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">Contact:&nbsp;{contact}</label>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">Alternative_Contact:&nbsp;{alter_contact}</label>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">Driving_Licence:&nbsp;{driving_licence_no}</label>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">Aadhar_Number : {aadhar_no}</label>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">PanCard_Number: {pancard_no}</label>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">D.O.B: {date_of_birth}</label>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">Pin_Number: {driver_pin}</label>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">Status: {status}</label>
        </div>
       
      </htmlForm>
                  <div className="flex-grow-1 ms-3">
                  </div>
                </div>
              </CardBody>
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

// render(<ModalView />);
export default ViewDriver;