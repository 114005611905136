import React, { useEffect, useState } from "react";
// import styles from "./Carfeaturesmodal.module.css";
import axios from "../../helpers/Axios";
// import { ListGroup, ListGroupItem, ModalBody, ModalFooter,  ModalHeader } from 'reactstrap';
import { Link } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import {
  FormGrid,
  Gutters,
  VerticalForm,
  HorizontalForm,
  HorizontalFormLabelSizing,
  ColumnSizing,
  AutoSizing,
  InlineForms,
  FloatingLabels,
} from "../AddCarFleet/FormsLayoutDummy";
import PreviewCardHeader from "../AddCarFleet/PreviewCardHeaderDummy";
import Flatpickr from "react-flatpickr";
import { ToastContainer, toast } from "react-toastify";
import { color } from "echarts";

// import React, { useState } from 'react';
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import {
  Card,
  CardBody,
  CardHeader,
  Input,
  Label,
  Col,
  Collapse,
  Container,
  Row,
  DropdownItem,
  ModalBody,
  ModalFooter,
  ModalHeader,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  CardFooter,
} from "reactstrap";
// import ImageForView from "./ImageForView";
function CarfleetUpdate({
  setShow,
  show,
  car_brand,
  car_features,
  car_image,
  car_model,
  fuel,
  gear,
  id,
  luggage,
  seats,
  slider_images,
  status
}) {
  // console.log(id);
  const [data, setData] = useState({
    id: id,
    carbrand: car_brand,
    carmodel: car_model,
    feature: car_features,
    fuel: fuel,
    gear: gear,
    seats: seats,
    applanding: car_image,
    luggage: luggage,
    slider: slider_images,
    status: status,
  })
  // console.log('data :', data);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [selected, setSelected] = useState([]);
  const [updatedCarbrand, setUpdatedCarbrand] = useState(car_brand)
  const [updatedCarfeatures, setUpdatedCarfeatures] = useState(car_features)
  const [updatedCarimage, setUpdatedCarimage] = useState(car_image)
  const [updatedCarmodel, setUpdatedCarmodel] = useState(car_model)
  const [updatedFuel, setUpdatedFuel] = useState(fuel)
  const [updatedGear, setUpdatedGear] = useState(gear)
  const [updatedId, setUpdatedId] = useState(id)
  const [updatedLuggage, setUpdatedLuggage] = useState(luggage)
  const [updatedSeats, setUpdatedSeats] = useState(seats)
  const [updatedSliderIMG, setUpdatedSliderIMG] = useState(slider_images)
  const [updatedStatus, setUpdatedStatus] = useState(status)
  const [feature, setFeature] = useState([]);


  const a = () => {
    toast.success("Updated Successfully !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const errormessage = () => {
    toast.error("No Changes Detected !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  // for refereshing

  function togetallfeatureIcons() { }
  useEffect(() => {
    togetallfeatureIcons();
  }, []);

  // for uploaing landing image
  // App Landing images
  const landingimagesUpload = (e) => {
    e.preventDefault();
    const LandingImage = e.target.files[0];
    // console.log(e.target.files[0].name);
    setData({...data, applanding:e.target.files[0].name})
    // setFiles(e.target.files[0].name);
    // console.log(LandingImage);
    if (LandingImage != null) {
      const data = new FormData();
      data.append("LandingImage", LandingImage);
      // console.log(data);
      axios
        .post("/uploadLandingimg", data)
        .then(() => {
          // console.log("Uploaded");
        })
        .catch(() => {
          // console.log("Error in Uploading");
        });
    }
  };

  //   console.log(updatedfeatureicon);
  // For  Slider Images

  const updateSliderImages = (e) => {
    e.preventDefault();
    const Image = e.target.files;
    // setSlider(e.target.files[0].name);
    // setFiles(e.target.files[0].name);
    // console.log(Image[0]);
    let imageNameArray = []
    let AllImageNames = ''
    for (let i = 0; i < Image.length; i++) {
      imageNameArray.push("car_fleet_slider/" + Image[i].name)
    }
    AllImageNames = imageNameArray.join(',')
    setData({...data, slider:AllImageNames});
    if (Image != null) {
      for (let i = 0; i < Image.length; i++) {
        const data = new FormData();
        data.append("Images", Image[i]);
        axios
          .post("/uploadSliderimg", data)
          .then(() => {
            // console.log("Uploaded");
          })
          .catch(() => {
            // console.log("Error in Uploading");
          });
      }
    }
  };
  // console.log(slider);
  function features() {
    axios
      .get("/getCarFeature")
      .then((res) => {
        setFeature(res.data.users);
        // console.log(res.data.users);
        // console.log("got car features");

      })
      .catch(() => {
        // console.log("error");
      });
  }

  useEffect(() => {
    features();
    // carband();
  }, []);
  function updateCar() {
    let featureArray = []
    let feature = ''
    for (let i = 0; i < selected.length; i++) {
      featureArray.push(selected[i].label)
    }
    feature = featureArray.join(',')
    // console.log(feature);
  }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{car_brand}&nbsp;{car_model}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div>
              <CardHeader>
                <div className="text-center mt-10">
                  <h5 style={{ color: "#eee4e4" }}>Edit Information</h5>
                </div>
              </CardHeader>
              <div className="p-2 mt-0 justify-content-center">
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                  action="#"
                >
                  <Row>
                    <Col lg={6}>
                      <div className="mb-2">
                        <Label
                          style={{ color: "#eee4e4" }}
                          htmlFor="catname"
                          className="form-label"
                        >
                          Brand Name <span className="text-danger">*</span>
                        </Label>
                        <Input
                          style={{
                            backgroundColor: "#2a2f34",
                            color: "#eee4e4",
                          }}
                          id="catname"
                          name="catname"
                          className="form-control"
                          placeholder="Edit name"
                          type="text"
                          defaultValue={car_brand}
                          onChange={(e) => {
                            setData({ ...data, carbrand: e.target.value })
                          }}
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <Label
                        style={{ color: "#eee4e4" }}
                        htmlFor="inputState"
                        className="form-label"
                      >
                        Edit Features
                      </Label>
                      <MultiSelect
                        // options={options}

                        style={{ borderRadius: "10px" }}
                        className="dark"
                        options={feature.map(({ id, feature_name }) => ({
                          value: id,
                          label: feature_name,
                        }))}
                        value={selected}

                        onChange={setSelected}
                        labelledBy="Select Car Features"
                        theme={{
                          Colors: {
                            selectToggleTextColor: "#053075",
                            text: "#053075",
                          },
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div className="mb-2">
                        <Label
                          style={{ color: "#eee4e4" }}
                          htmlFor="catprice"
                          className="form-label"
                        >
                          Landing Image <span className="text-danger">*</span>
                        </Label>
                        <Input
                          style={{
                            backgroundColor: "#2a2f34",
                            color: "#eee4e4",
                          }}
                          className="form-control"
                          id="formFile"
                          placeholder="additonal input field "
                          type="file"
                          name="file"
                          //   defaultValue={files[0].name}
                          onChange={landingimagesUpload}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-2">
                        <Label
                          style={{ color: "#eee4e4" }}
                          htmlFor="catname"
                          className="form-label"
                        >
                          Model Name <span className="text-danger">*</span>
                        </Label>
                        <Input
                          style={{
                            backgroundColor: "#2a2f34",
                            color: "#eee4e4",
                          }}
                          id="catname"
                          name="catname"
                          className="form-control"
                          placeholder="Edit model"
                          type="text"
                          defaultValue={car_model}
                          onChange={(e) => {
                            setData({...data, carmodel:e.target.value});
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div className="mb-2">
                        <Label
                          style={{ color: "#eee4e4" }}
                          htmlFor="firstname"
                          className="form-label"
                        >
                          Fuel <span className="text-danger">*</span>
                        </Label>
                        <Input
                          style={{
                            backgroundColor: "#2a2f34",
                            color: "#eee4e4",
                          }}
                          name="firstname"
                          type="text"
                          placeholder="Enter username"
                          defaultValue={fuel}
                          onChange={(e) => {
                            setData({...data, fuel:e.target.value});
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-2">
                        <Label
                          style={{ color: "#eee4e4" }}
                          htmlFor="firstname"
                          className="form-label"
                        >
                          Gear <span className="text-danger">*</span>
                        </Label>
                        <Input
                          style={{
                            backgroundColor: "#2a2f34",
                            color: "#eee4e4",
                          }}
                          name="firstname"
                          type="text"
                          placeholder="Enter username"
                          defaultValue={gear}
                          onChange={(e) => {
                            setData({...data, gear:e.target.value});
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div className="mb-2">
                        <Label
                          style={{ color: "#eee4e4" }}
                          htmlFor="firstname"
                          className="form-label"
                        >
                          Luggage <span className="text-danger">*</span>
                        </Label>
                        <Input
                          style={{
                            backgroundColor: "#2a2f34",
                            color: "#eee4e4",
                          }}
                          name="firstname"
                          type="text"
                          placeholder="Enter username"
                          defaultValue={luggage}
                          onChange={(e) => {
                            setData({...data, luggage:e.target.value});
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-2">
                        <Label
                          style={{ color: "#eee4e4" }}
                          htmlFor="firstname"
                          className="form-label"
                        >
                          Seats <span className="text-danger">*</span>
                        </Label>
                        <Input
                          style={{
                            backgroundColor: "#2a2f34",
                            color: "#eee4e4",
                          }}
                          name="firstname"
                          type="text"
                          placeholder="Edit seats number"
                          defaultValue={seats}
                          onChange={(e) => {
                            setData({...data, seats:e.target.value});
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <Label
                        style={{ color: "#eee4e4" }}
                        htmlFor="inputPassword4"
                        className="form-label"
                      >
                        Slider Images
                      </Label>
                      <Card
                        style={{
                          backgroundColor: "#2a2f34",
                          color: "#eee4e4",
                        }}
                      >
                        <CardBody>
                          <Form enctype="multipart/form-data">
                            <input
                              style={{
                                backgroundColor: "#2a2f34",
                                color: "#eee4e4",
                              }}
                              className="form-control"
                              type="file"
                              onChange={updateSliderImages}
                              multiple
                            />
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg={6}>
                      <Label htmlFor="employeeName" className="form-label">
                        Status
                      </Label>
                      <select
                        id="inputState"
                        className="form-select"
                        defaultValue={status}
                        data-choices
                        data-choices-sorting="true"
                        onChange={(e) => {
                          setData({...data, status:e.target.value});
                        }}
                      >
                        <option hidden>Select Status</option>
                        <option
                          value="Active"
                        >Active</option>
                        <option
                          value="Inactive"
                        >Inactive</option>

                      </select>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"

            onClick={() => {
              let featureArray = []
              let feature = ''
              for (let i = 0; i < selected.length; i++) {
                featureArray.push(selected[i].label)
              }
              feature = featureArray.join(',')
              setData({...data,feature:feature})
              axios
                .put(
                  `/CarFleetDetials?id=${id}`,
                  data
                )
                .then(() => {
                  // console.log("reached");
                  setTimeout(() => {
                    
                  }, 200);
                  // setIsOpen(false)
                })
                .catch(() => {
                  setTimeout(() => {
                    errormessage();
                    // console.log("Error")
                  }, 10);
                  // setIsOpen(false)
                });
            }}
          >
            Update
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

// render(<ModalView />);
export default CarfleetUpdate;
