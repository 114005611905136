import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import {
  Card,
  CardBody,
  CardHeader,
  Input,
  Label,
  Col,
  Collapse,
  Container,
  Row,
  Button,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  CardFooter,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import DataTable, { createTheme } from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { api_url, aws_bucket_url } from "../../helpers/base_url";
import CarfleetView from "./CarfleetView";

import axios from "../../helpers/Axios";
import CarfleetUpdate from "./CarfleetUpdate";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const DisplayCarfleet = (gettingBrandicon) => {
  // for map methods

  const [carfleetlist, setCarfleetlist] = useState([]);
  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [updatedCarbrand, setUpdatedCarbrand] = useState("");
  const [updatedCarfeatures, setUpdatedCarfeatures] = useState("");
  const [updatedCarimage, setUpdatedCarimage] = useState("");
  const [updatedCarmodel, setUpdatedCarmodel] = useState("");
  const [updatedFuel, setUpdatedFuel] = useState("");
  const [updatedGear, setUpdatedGear] = useState("");
  const [updatedId, setUpdatedId] = useState("");
  const [updatedLuggage, setUpdatedLuggage] = useState("");
  const [updatedSeats, setUpdatedSeats] = useState("");
  const [updatedSliderIMG, setUpdatedSliderIMG] = useState("");
  const [updatedStatus, setUpdatedStatus] = useState("");

  let jump = useNavigate("");

  function handelClick() {
    jump("/car_fleet");
  }

  function gettingBrandicon() {
    axios
      .get("/CarFleetDetials")
      .then((res) => {
        setCarfleetlist(res.data.users);
        // console.log(res.data.users[0].slider_images);
        // console.log(res.data.users);
        // for(let i=0; i<=res.length; i++){
        //   console.log(res.data.users[i].slider_images)
        // }
        // console.log("received brand Icons ");
        // console.log(carfleetlist);
        // setArr(carfleetlist)
      })
      .catch(() => {
        // console.log("error receiveing brand icons deatils ");
      });
  }
  useEffect(() => {
    gettingBrandicon();
  }, [modalShow]);

  const showToastMessageDeleteSuccess = () => {
    toast.success("Deleted Successfully !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  //Cancelation message
  const showToastMessageRetervied = () => {
    toast.success("Delete Retervied !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  createTheme("fulldark", {
    text: {
      primary: "white",
      secondary: "white",
    },
    background: {
      default: "#212529",
    },
    context: {
      text: "white",
    },
    button: {
      default: "white",
      focus: "white",
      hover: "white",
      disabled: "white",
    },
  });
  const columns = [
    {
      name: <p>Id </p>,
      selector: (row) => row.id,
    },
    {
      name: <p>Landing Image </p>,
      cell: (row) => {
        return (
          <div>
            <img
              width="120px"
              height="70px"
              // src={require(`../../CarImages/CarBrandIcon/${row.carlogo}`)}
              src={aws_bucket_url + "/" + `${row.car_image}`}
              alt="car"
            ></img>
          </div>
        );
      },
    },
    {
      name: <p>Brand Name </p>,
      selector: (row) => row.car_brand,
    },
    
    {
      name: <p>Model Name </p>,
      selector: (row) => row.car_model,
    },
    {
      name: <p>Slider Image </p>,
      cell: (row) => {
        return (
          <div>
            {/* <img
              width="100px"
              height="100px"
              // src={require(`../../CarImages/CarBrandIcon/${row.carlogo}`)}
              // src={aws_bucket_url+'/'+`${row.car_image}`}
              src={aws_bucket_url + '/' + `${row.slider_images}`}
              // alt = 'car'
            ></img> */}
            {
              <Carousel
                autoPlay
                infiniteLoop
                showThumbs={false}
                showArrows={false}
                swipeable={true}
                stopOnHover={true}
                showStatus={false}
              >
                {row.slider_images.split(",").map((image) => {
                  return (
                    <div key={image}>
                      <img
                        src={`${aws_bucket_url}/${image}`}
                        alt="Car"
                        width="150px"
                        height="50px"
                      />
                    </div>
                  );
                })}
              </Carousel>
            }
          </div>
        );
      },
    },
    {
      name: <p>Status </p>,
      selector: (row) => row.status,
    },
    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      sortable: true,

      cell: (row) => {
        return (
          <UncontrolledDropdown className="dropdown d-inline-block" direction="right">
            <DropdownToggle className="btn btn-soft-danger btn-sm" tag="button">
              <i className="ri-more-fill align-middle"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem
                href="#!"
                onClick={() => {
                  setUpdatedCarbrand(row.car_brand);
                  setUpdatedCarfeatures(row.car_features);
                  setUpdatedCarimage(row.car_image);
                  setUpdatedCarmodel(row.car_model);
                  setUpdatedFuel(row.fuel);
                  setUpdatedGear(row.gear);
                  setUpdatedId(row.id);
                  setUpdatedLuggage(row.luggage);
                  setUpdatedSeats(row.seats);
                  setUpdatedSliderIMG(row.slider_images);
                  setUpdatedStatus(row.status);
                  setShow(true);
                }}
              >
                <i className="ri-eye-fill align-bottom me-2 text-muted"></i>View
              </DropdownItem>

              <DropdownItem
                className="edit-item-btn"
                onClick={() => {
                  setUpdatedCarbrand(row.car_brand);
                  setUpdatedCarfeatures(row.car_features);
                  setUpdatedCarimage(row.car_image);
                  setUpdatedCarmodel(row.car_model);
                  setUpdatedFuel(row.fuel);
                  setUpdatedGear(row.gear);
                  setUpdatedId(row.id);
                  setUpdatedLuggage(row.luggage);
                  setUpdatedSeats(row.seats);
                  setUpdatedSliderIMG(row.slider_images);
                  setUpdatedStatus(row.status);
                  setModalShow(true);
                }}
              >
                <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
                Edit
              </DropdownItem>

              <DropdownItem
                onClick={() => {
                  Swal.fire({
                    title: "Are you sure?",
                    text: `Uh want to delete ${row.car_brand}!`,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    background: "#212529",
                    confirmButtonText: "Yes, delete it!",
                    background: "rgb(33, 37, 41)",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      Swal.fire(
                        {
                          title: "Deleted ",

                          icon: "success",
                          background: "rgb(33, 37, 41)",
                        }
                        // "Deleted!",
                        // " data has been deleted.",
                        // "success"
                      );
                      axios
                        .delete(`/deleteCarfleet?id=${row.id}`)
                        .then(() => {
                          // console.log("deleted");
                          gettingBrandicon();
                        })
                        .catch(() => {
                          // console.log("error deleting ");
                        });
                    }
                  });
                }}
                className="remove-item-btn"
              >
                {" "}
                <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                Delete{" "}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Master" pageTitle="Brand List" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0  text-centre">
                    Car Fleet Lists
                  </h4>
                </CardHeader>
                <CardBody>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <Label
                        className="btn btn-outline-primary"
                        onClick={handelClick}
                        for="btn-check-outlined"
                      >
                        Add Car Fleet
                      </Label>
                    </Col>
                    <Col className="col-sm"></Col>
                  </Row>
                  <Row>
                    <Col className="col-12">
                      <Row></Row>
                    </Col>
                  </Row>
                  <DataTable
                    columns={columns}
                    data={carfleetlist}
                    pagination
                    theme="fulldark"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ToastContainer />
        </Container>
        {modalShow && (
          <CarfleetUpdate
            setShow={setModalShow}
            show={modalShow}
            car_brand={updatedCarbrand}
            car_features={updatedCarfeatures}
            car_image={updatedCarimage}
            car_model={updatedCarmodel}
            fuel={updatedFuel}
            gear={updatedGear}
            id={updatedId}
            luggage={updatedLuggage}
            seats={updatedSeats}
            slider_images={updatedSliderIMG}
            status={updatedStatus}
          />
        )}
        {show && (
          <CarfleetView
            setShow={setShow}
            show={show}
            car_brand={updatedCarbrand}
            car_features={updatedCarfeatures}
            car_image={updatedCarimage}
            car_model={updatedCarmodel}
            fuel={updatedFuel}
            gear={updatedGear}
            id={updatedId}
            luggage={updatedLuggage}
            seats={updatedSeats}
            slider_images={updatedSliderIMG}
            status={updatedStatus}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default DisplayCarfleet;
