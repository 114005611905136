import React, { useState } from 'react'
import { Card, CardBody, Col, Container,CardHeader, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner } from 'reactstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import axios from '../../helpers/Axios';
import PreviewCardHeader from "../CarFeatures/PreviewCardReaderDemo";
import { Link } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import Dropzone from "react-dropzone";
import { ToastContainer, toast } from "react-toastify";
import BreadCrumb from '../AddCarFleet/BreadcrumDummy';
// import { VerticalForm, HorizontalForm } from "./FormlayoutsCode";
// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
const Addyacht = () => {
    const[yachtname,setYachtname ]=useState("")
    const[capacity,setCapacity]=useState("")
    const[price,setPrice]=useState("")
    const[time,setTime]=useState("")
    const[description,setDescription]=useState("")
    const[sailing,setSailing]=useState("")
    const[anchorage,setAnchorage]=useState("")
  
    const[yachtimage,setYachtimage]=useState()

    // const uploadIcon = (e) => {
    //   e.preventDefault();
    //   const Image = e.target.files[0];
    //   console.log(e.target.files[0].name);
    //   setYachtimage(e.target.files[0].name);
    //   // setFiles(e.target.files[0].name)
    //   console.log(Image);
    //   if (Image != null) {
    //     const data = new FormData();
    //     data.append("Image", Image);
    //     console.log(data);
    //     axios
    //       .post("/uploadyatchimage", data)
    //       .then(() => {
    //         // setTimeout(()=>{
    //         //   setUploadinglogo("")
    //         // },1500)
    //         console.log("Uploaded Image");
    //       })
    //       .catch(() => {
    //         console.log("Error in Uploading Image");
    //       });
    //   }
    // };
    
    //yacht slider images 

    const uploadIcon = (e) => {
      e.preventDefault();
      const Image = e.target.files;
      // setSlider(e.target.files[0].name);
      // setFiles(e.target.files[0].name);
      // console.log(Image[0]);
      let imageNameArray = []
      let AllImageNames = ''
      for (let i = 0; i < Image.length; i++) {
        imageNameArray.push("yacht/"+Image[i].name)
      }
      AllImageNames = imageNameArray.join(',')
      setYachtimage(AllImageNames);
      if (Image != null) {
        for (let i = 0; i < Image.length; i++) {
          const data = new FormData();
          data.append("Image", Image[i]);
          axios
          .post("/uploadyatchimage", data)
          .then(() => {
            // console.log("Uploaded");
            })
            .catch(() => {
              // console.log("Error in Uploading");
            });
          }
        }
      };
      // console.log(yachtimage);

      const showToastMessageEnterDeatils = () => {
        toast.error("Enter All Details", {
          position: toast.POSITION.TOP_CENTER,
          toastId: "21",
        });
      };
      const showToastMessageEnterName = () => {
        toast.error("Invalid Yacht Name", {
          position: toast.POSITION.TOP_CENTER,
          toastId: "22",
        });
      };
      const showToastMessageEnterNumber = () => {
        toast.error("Invalid Capacity", {
          position: toast.POSITION.TOP_CENTER,
          toastId: "22",
        });
      };
      const showToastMessageEnterPrice = () => {
        toast.error("Invalid Price", {
          position: toast.POSITION.TOP_CENTER,
          toastId: "22",
        });
      };
      const showToastMessageEnterSailing = () => {
        toast.error("Invalid Sailing", {
          position: toast.POSITION.TOP_CENTER,
          toastId: "22",
        });
      };
      const showToastMessageEnterAncorage = () => {
        toast.error("Invalid Anchorage", {
          position: toast.POSITION.TOP_CENTER,
          toastId: "22",
        });
      };
      const showToastMessageImage = () => {
        toast.error("Image not Uploaded", {
          position: toast.POSITION.TOP_CENTER,
          toastId: "22",
        });
      };
      const showToastMessageTime = () => {
        toast.error("Invalid Time", {
          position: toast.POSITION.TOP_CENTER,
          toastId: "22",
        });
      };
      const showToastMessageDescription = () => {
        toast.error("Enter Description", {
          position: toast.POSITION.TOP_CENTER,
          toastId: "22",
        });
      };
      const showToastMessageAdded = () => {
        toast.success("Added Successfully", {
          position: toast.POSITION.TOP_CENTER,
          toastId: "22",
        });
      };
  //

  const numberformat = /^[0-9" "-.]+$/;
  const nameformat = /^[A-Za-z" " ]+$/;
  const namenum = /^[A-Za-z0-9" " .]+$/;
      //post yacht details

     function submit(e){
      e.preventDefault() 
      
    if (!yachtname.match(nameformat)){
        showToastMessageEnterName()
      }
      else if (!capacity.match(numberformat)){
        showToastMessageEnterNumber()
      }
      else if (!price.match(numberformat)){
        showToastMessageEnterPrice()
      }
      else if (!sailing.match(numberformat)){
        showToastMessageEnterSailing()
      }
      else if (!anchorage.match(numberformat)){
        showToastMessageEnterAncorage()
      }
      else if (yachtimage==""){
        showToastMessageImage()
      }
      else if (!time.match(numberformat)){
        showToastMessageTime()
      }
      else if (description === "")
      {
        showToastMessageDescription()
      }

      else{
        let payload = {yachtname,capacity,price,description,time,sailing,anchorage,yachtimage}
      axios.post('/addyatchdetails',payload)
      .then(()=>{
        // console.log("Yatch details Addeed")
        showToastMessageAdded()

      })
      .catch(()=>{
        // console.log("error")
      })
     }
      }
      
  return (
    <React.Fragment>
        <div className='page-content'>
          <Container fluid>
          <BreadCrumb title="Bookings" pageTitle="Yacht Bookings" />
        <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader
              className="text-center"
              title="Add Yacht "
            />
            <CardBody>
              <div className="live-preview">
                <Form action="#">
                <Row>
                  <Col lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="BookingStatus1" className="form-label">
                       Name
                      </Label>
                      <Input   type="text" className="form-control" id="fullnameInput" onChange={(e)=>{setYachtname(e.target.value)}}  placeholder="Enter Yacht Name " />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="BookingStatus2" className="form-label">
                      Capacity
                      </Label>
                      <Input   type="text" className="form-control" onChange={(e)=>{setCapacity(e.target.value)}} id="fullnameInput"  placeholder="Capacity " />
                     
                    </div>
                  </Col>
                </Row>
                <Row>
                <Col lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="BookingStatus2" className="form-label">
                      Price ( Start from )
                      </Label>
                      <Input   type="text" className="form-control" onChange={(e)=>{setPrice(e.target.value)}}  id="fullnameInput"  placeholder="Price " />
                     
                    </div>
                
                  </Col>
                  {/* <Col lg={6}>
                  <div className="mb-3">
                      <Label htmlFor="BookingStatus2" className="form-label">
                      Time 
                      </Label>
                      <Input   type="text" className="form-control" onChange={(e)=>{setTime(e.target.value)}}  id="fullnameInput"  placeholder="Time " />
                     
                    </div>
                    
                  </Col> */}
                  <Col lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="BookingStatus5" className="form-label">
                      No of Hours
                      </Label>
                   <Row>
                   <Col lg={6}>
                      <Input   type="text" className="form-control" id="fullnameInput"  onChange={(e)=>{setSailing(e.target.value)}} placeholder="Sailing Hours" />
                     </Col>
                    <Col lg={6}>
                    <Input   type="text" className="form-control" id="fullnameInput"  onChange={(e)=>{setAnchorage(e.target.value)}} placeholder="Anchorage Hours" />
                    </Col>
                   </Row>
                     
                    </div>
                  </Col>
                </Row>
                <Row>
                <Col lg={6}>
                    <Form action="/uploadicon" encType="multipart/form-data" >
                    <div className="mb-3">
                      <Label htmlFor="BookingStatus6" className="form-label">
                      Upload Images
                      </Label>
                      <Input  onChange={uploadIcon}  multiple max={5}  type="file" className="form-control" id="fullnameInput"    />
                    
                    </div>
                    </Form>
                  </Col>
                  <Col lg={6}>
                    
                    <div className="mb-3">
                      <Label htmlFor="BookingStatus6" className="form-label">
                      Time
                      </Label>
                      <Input  onChange={(e)=>{setTime(e.target.value)}}  type="text" className="form-control" id="fullnameInput"    />
                    
                    </div>
                    
                  </Col>
                  
                  <Col lg={12}>
                    <div className="mb-3">
                   
                      {/* <Input   type="text" className="form-control" id="fullnameInput"  onChange={(e)=>{setHours(e.target.value)}} placeholder="Sailing/Anchorage" /> */}
                      <div className="mb-3">
                      <Label htmlFor="BookingStatus4" className="form-label">
                      Description
                      </Label>
                      <textarea  rows={5} type="text" className="form-control" id="fullnameInput"  onChange={(e)=>{setDescription(e.target.value)}}  placeholder="Description " />
                    </div> 
                    </div>
                  </Col>
                  
                </Row>
               
                
                  <div className="text-center mt-3" >
                
                    <Label
                      className="btn btn-outline-primary text-centre"
                      for="btn-check-outlined"
                      onClick={submit}
                      >
                      Add
                  </Label>
                  &nbsp; &nbsp; &nbsp;
                <Link to="/Yachtlist" >
                <Label
                      className="btn btn-outline-danger"
                      for="danger-outlinedremove"
                      >
                      Remove
                  </Label>
                </Link>
                  </div>
                  {/* </Col> */}
                </Form>
              </div>
              <div className="d-none code-view">
                <pre className="language-markup" style={{ height: "375px" }}>
                  <code>
                    {/* <VerticalForm /> */}
                  </code>
                </pre>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
          </Container>
        </div>
        <ToastContainer></ToastContainer>
    </React.Fragment>
  )
}
export default Addyacht