import axios from "../../helpers/Axios";
import React, { useEffect, useState,createContext} from "react";
import { useNavigate } from "react-router-dom";
import DataTable, { createTheme } from "react-data-table-component";
import Swal from "sweetalert2";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { ToastContainer, toast } from "react-toastify";
import Dashboard from "../Dashboard_Blck/Dashboard";
import "react-toastify/dist/ReactToastify.css";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
// import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
// import LocationListModal from "./LocationListModal";
import PreviewCardHeader from "../../Components/Common/PreviewCardHeader";
// import UpdateLocation from "./UpdateLocation";
// import ViewLocation from "./ViewLocation";
// import { Row } from 'react-table';

const Customers= () => {
  const [customersDetails, setCustomersDetails] = useState([]);
  const [id, setId] = useState("");
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [count,setCount]=useState()
  const [updateId, setUpdateId] = useState("");
  const [updateLocation, setUpdateLocation] = useState("");
  const [updateLocationStatus, setUpdateLocationStatus] = useState("");
  const [updateStatus, setUpdateStatus] = useState("");
  const [time,setTime]=useState(false)

// useContetxt

const SubscriptionCount =createContext();

  var today = new Date(),
  date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

  // console.log(date)

  const [isOpenViewloc,setIsOpenViewloc] = useState(false)

  // function customersDatafun(){
  //   var length=0;
  //   axios
  //     .get(`/user_information`)
  //     .then((res) => {
  //       setCustomersDetails(res.data.users)
  //       console.log(res.data.users);
  //       setTime(res.users)
  //       length = Object.keys(res.data.users).length;
  //       setCount(length)
  //       // console.log(length)
        
  //     }) 
  //     .catch(() => {
  //       // console.log("Error");
  //     });
  //     axios
  //     .get(`/payment/success`)
  //     .then((res) => {
  //       // setCustomersDetails(res.data.users)
  //       console.log(res.data,'################');
  //       // setTime(res.users)
  //       // length = Object.keys(res.data.users).length;
  //       // setCount(length)
  //       // console.log(length)
        
  //     })
  //     .catch(() => {
  //       // console.log("Error");
  //     });
  //   }

    // console.log(count)

    useEffect(() => {
      Promise.all([
        axios.get(`/user_information`),
        axios.get(`/payment/success`)
      ]).then(responses => {
        const userInfoResponse = responses[0];
        // console.log(userInfoResponse,'############')
        const paymentResponse = responses[1];
        // console.log(paymentResponse,'$$$$$$$$$$$$')
  
        const userInfoData = userInfoResponse.data.users;
        const paymentData = paymentResponse.data.users; // Assuming paymentResponse directly provides an array
  
        // Compare and filter out the data
        const differentData = userInfoData.filter(
          (user) => !paymentData.some((paymentUser) => paymentUser.user_id === user.contact)
        );

        setCustomersDetails(differentData);
      }).catch(error => {
        console.error(error);
      });
    }, []);


  const showToastMessageerrorDeleteforloc = () => {
    toast.success("Reterived Delete", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  
  createTheme('fulldark', {
    text: {
      primary: 'white',
      secondary: 'white'
    },
    background: {
      default: '#212529'
    },
    context: {
      text: 'white'
    },
    button: {
      default: 'white',
      focus: 'white',
      hover: 'white',
      disabled: 'white'
    }
  })

  const jump = useNavigate();


  const columns = [
    {
      name: <span className="font-weight-bold fs-13">ID</span>,
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">First Name</span>,
      selector: (row) => row.firstname,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Last Name</span>,
      selector: (row) => row.lastname,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Email</span>,
      selector: (row) => row.email,
      sortable: true,
      
    },
    {
      name: <span className="font-weight-bold fs-13">Contact Number</span>,
      selector: (row) => row.contact,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Date</span>,
      
      selector: (row) => row.timestamp.substr(0,10),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Time</span>,
      
      selector: (row) => row.timestamp.substr(11),
      sortable: true,
    },
    // {
    //   name: <span className="font-weight-bold fs-13">Action</span>,
    //   sortable: true,
    //   cell: (row) => {
    //     return (
    //       <UncontrolledDropdown className="dropdown d-inline-block">
    //         <DropdownToggle
    //           className="btn btn-soft-secondary btn-sm"
    //           tag="button"
    //         >
    //           <i className="ri-more-fill align-middle"></i>
    //         </DropdownToggle>
    //         <DropdownMenu className="dropdown-menu-end">
    //           <DropdownItem href="#!"
    //           onClick={(e) => {
    //             setUpdateId(row.id);
    //             setUpdateLocation(row.location);
    //             setUpdateLocationStatus(row.locationstatus);
    //             setUpdateStatus(row.status);
    //             setIsOpenViewloc(true);
    //           }}>
    //             <i className="ri-eye-fill align-bottom me-2 text-muted"></i>View
    //           </DropdownItem>
    //           <DropdownItem
    //             onClick={(e) => {
    //               setUpdateId(row.id);
    //               setUpdateLocation(row.location);
    //               setUpdateLocationStatus(row.locationstatus);
    //               setUpdateStatus(row.status);
    //               setIsOpen(true);
    //             }}
    //             className="edit-item-btn"
    //           >
    //             <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
    //             Edit
    //           </DropdownItem>
    //           <DropdownItem
    //             onClick={() => {
    //               Swal.fire({
    //                 title: "Are you sure?",
    //                 text: `Your sure .. u want to delete ${row.location}!`,
    //                 icon: "warning",
    //                 showCancelButton: true,
    //                 confirmButtonColor: "#3085d6",
    //                 cancelButtonColor: "#d33",
    //                 confirmButtonText: "Yes, delete it!",
    //               }).then((result) => {
    //                 if (result.isConfirmed) {
    //                   Swal.fire(
    //                     "Deleted!",
    //                     " data has been deleted.",
    //                     "success"
    //                   );
    //                   axios
    //                     .delete(
    //                       `/location?id=${row.id}`
    //                     )
    //                     .then(() => {
    //                       // console.log("deleted");
    //                       // locationDatafun()
                          
    //                     })
    //                     .catch(() => {
    //                       // console.log("error deleting ");
    //                       showToastMessageerrorDeleteforloc();
    //                     });
    //                 }
    //               });
    //             }}
    //             className="remove-item-btn"
    //           >
    //             {" "}
    //             <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
    //             Delete{" "}
    //           </DropdownItem>
    //         </DropdownMenu>
    //       </UncontrolledDropdown>
    //     );
    //   },
    // },
  ];
  const data = customersDetails;
  const sortedData = data.slice().sort((a, b) => {
    const timestampA = new Date(a.timestamp);
    const timestampB = new Date(b.timestamp);
    return timestampB - timestampA;
  });
console.log(sortedData)
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Customers" pageTitle="Customers List" />
          <Col xl={12}>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardHeader>
                    <h5 className="card-title mb-0">Customers List</h5>
                  </CardHeader>
                  <CardBody>
                    <Row className="g-4 mb-3">
                      <Col className="col-sm-auto">
                    
                       </Col>

                      {/* // serach button  */}
                      {/* <Col className="col-sm">
                        <div className="d-flex justify-content-sm-end">
                          <div className="search-box ms-6">
                            <input
                              type="text"
                              onChange={(e) => {
                                setSearch(e.target.value);
                              }}
                              className="form-control search"
                              placeholder="Search..."
                            />
                            <i className="ri-search-line search-icon"></i>
                          </div>
                        </div>
                      </Col> */}
                    </Row>
                    <DataTable columns={columns} data={customersDetails} pagination  theme='fulldark' />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Container>
        {/* {isOpen && (
          <LocationListModal
            setIsOpen={setIsOpen}
            id={updateId}
            location={updateLocation}
            locationstatus={updateLocationStatus}
            status={updateStatus}
          />
        )}
        {
          isOpenViewloc && (
            <ViewLocation 
            setIsOpenViewloc={setIsOpenViewloc}
            id={updateId}
            location={updateLocation}
            locationstatus={updateLocationStatus}
            status={updateStatus}
            />
          )
        } */}
    
        
      </div>
    </React.Fragment>
  );
};
export default Customers;
