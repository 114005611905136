import React, { useState } from 'react'
import { Card, CardBody, Col, Container,CardHeader, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner } from 'reactstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import axios from '../../helpers/Axios';
import PreviewCardHeader from "../CarFeatures/PreviewCardReaderDemo";
import { Link, useNavigate } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import Dropzone from "react-dropzone";
import BreadCrumb from '../AddCarFleet/BreadcrumDummy';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { VerticalForm, HorizontalForm } from "./FormlayoutsCode";
// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
const Coupon = () => {


  const[cartype,setCartype]=useState("")
  const[code,setCode]=useState("")
  const[startdate,setStartdate]=useState("")
  const[enddate,setEnddate]=useState("")
  const[desc, setDesc] = useState("")
  const[discountamount, setDiscountamount] = useState("")
  const[discounttype, setDiscounttype] = useState("")
  const[deductdiposite,setDeductdiposite] = useState("")
  const[status, setStatus] = useState("")
  const[maxdiscount, setMaxdiscount]=useState("")

  const [change, setChange] = useState(false)
  const [selectedFiles, setselectedFiles] = useState([]);
    // function handelSubmit(e){
    //     e.Preventdefault()
    //     const payload ={carname, cartype, logo, status}
    //     axios.post(``)
    // }
    function handleAcceptedFiles(files) {
        files.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
          })
        );
        setselectedFiles(files);
      }
      function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
      }


      const amountformat = /^\d*\.?\d{0,2}$/;
      const numberformat = /^[0-9.]+$/;
      const nameformat = /^[A-Za-z_.]+$/;

      function handelClick(e){
        e.preventDefault()
        if(cartype === ""){messageforEmptycartype()}
        else if (code === ""){messageforEmptycode()}
        else if (startdate ===""){messageforEmptyStartdate()}
        else if (enddate === ""){messageforEmptyEnddate()}
        else if (enddate < startdate){messageforvalidDates()}
        else if (desc === ""){messageforEmptyDesc()}
        else if (!discountamount){messageforEmptydiscountAmount()}
        else if (discounttype === ""){messageforEmptydiscountType()}
        else if (discounttype === "Percentage" && (discountamount>100 || discountamount<0)){messageforinvalidDiscountAmountWithTypePercentage()}
        else if (discounttype === "Percentage" && maxdiscount === ""){messageforEmptyMaximumDiscount()}
        else if (deductdiposite === ""){messageforEmptydeductDiposite()}
        else if (status === ""){messageforEmptystatus()}
        else if (!amountformat.test(discountamount)){messageforinvalidAmount()}
        else if (parseFloat(discountamount) <= 0){messageforinvalidamountsign()}
        else if (!amountformat.test(maxdiscount)){messageforinvalidMaxAmount()}
        else if (parseFloat(maxdiscount) <= 0){messageforinvalidmaxamountsign()}
        else{
        const payload = {cartype, code, startdate, enddate, desc, discounttype, discountamount, deductdiposite, maxdiscount, status};
        axios.post(`/AddCoupondata`, payload)
        .then(()=>{
          showToastMessageforcoupon()
          // console.log("Coupon added Successfully");
        })
        .catch(()=>{
          // console.log("getting coupon data error");
        })
    }
  }
    const messageforEmptycartype = () =>{
      toast.error('Please Select Car Type ...!',{
        position: toast.POSITION.TOP_CENTER
      });
    };
    const messageforEmptycode = () =>{
      toast.error('Please Enter Code ...!',{
        position: toast.POSITION.TOP_CENTER
      });
    };
    const messageforEmptyStartdate = () =>{
      toast.error('Please fill Start date ...!',{
        position: toast.POSITION.TOP_CENTER
      });
    };
    const messageforEmptyEnddate = () =>{
      toast.error('Please fill End date ...!',{
        position: toast.POSITION.TOP_CENTER
      });
    };
    const messageforEmptyDesc = () =>{
      toast.error('Please fill Description ...!',{
        position: toast.POSITION.TOP_CENTER
      });
    };
    const messageforEmptydiscountType = () =>{
      toast.error('Please fill Discount Type ...!',{
        position: toast.POSITION.TOP_CENTER
      });
    };
    const messageforEmptyMaximumDiscount = () =>{
      toast.error('Please fill Maximum Discount ...!',{
        position: toast.POSITION.TOP_CENTER
      });
    };
    const messageforEmptydeductDiposite = () =>{
      toast.error('Please Select Deduct Diposite ...!',{
        position: toast.POSITION.TOP_CENTER
      });
    };
    const messageforEmptydiscountAmount = () =>{
      toast.error('Please fill Discount Amount ...!',{
        position: toast.POSITION.TOP_CENTER
      });
    };
    const messageforEmptystatus = () =>{
      toast.error('Please Select Status ...!',{
        position: toast.POSITION.TOP_CENTER
      });
    };
    const messageforvalidDates = () =>{
      toast.error('End date must be greater than or equal to Start date ...!',{
        position: toast.POSITION.TOP_CENTER
      });
    };
    const messageforinvalidAmount = () =>{
      toast.error('Please Enter Valid Discount Amount ...!',{
        position: toast.POSITION.TOP_CENTER
      });
    };
    const messageforinvalidMaxAmount = () =>{
      toast.error('Please Enter Valid Maximum Discount Amount ...!',{
        position: toast.POSITION.TOP_CENTER
      });
    };
    const messageforinvalidDiscountAmountWithTypePercentage = () =>{
      toast.error('For AmountType Percentage, Discount Amount should be 0 to 100 ...!',{
        position: toast.POSITION.TOP_CENTER
      });
    };
    const messageforinvalidamountsign = () =>{
      toast.error('Discount amount must be greater than zero ...!',{
        position: toast.POSITION.TOP_CENTER
      });
    };
    const messageforinvalidmaxamountsign = () =>{
      toast.error('Maximum Discount amount must be greater than zero ...!',{
        position: toast.POSITION.TOP_CENTER
      });
    };
    const showToastMessageforcoupon = () => {
      toast.success('Coupon Added Sucessfully !', {
          position: toast.POSITION.TOP_CENTER
      });
    };

    // const navcoupon = useNavigate()
    // function cencelCupon (){
    //   navcoupon('/coupon_list')
    // }

  return (
    <React.Fragment>
        <div className='page-content'>
          <Container fluid>
          <BreadCrumb title="Coupons" pageTitle="Add" />
        <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader
              className="text-center"
              title="Coupons"
            />
            <CardBody>
              <div className="live-preview">
                <Form action="#">
                <Row>
                  <Col lg={6}>
                  <div className="mb-3">
                    <Label htmlFor="employeeNamecar" className="form-label">
                      Car Type
                    </Label>
                    <select
                      id="employeeNamecar"
                      className="form-select"
                      value={cartype}
                      data-choices
                      data-choices-sorting="true"
                      onChange={(e) => {
                        setCartype(e.target.value);
                      }}
                    >
                      <option>Choose Car Type</option>
                      <option value={"Super Car"}>Super Car</option>
                      <option value={"Standard"}>Standard</option>
                      <option value={"Luxury"}>Luxury</option>
                      <option value={"Executive"}>Executive</option>
                      <option value={"Premium"}>Premium</option>
                    </select>
                  </div>
                  </Col>
                  <Col lg={6}>
                  <div className="mb-3">
                    <Label htmlFor="employeeNamecode" className="form-label">
                      Code
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="employeeNamecode"
                      placeholder="Create Code"
                      onChange={(e)=>setCode(e.target.value)}
                    />
                  </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                  <div className="mb-3">
                    <Label htmlFor="employeeNamestart" className="form-label">
                      Start Date
                    </Label>
                    <Input
                      type="date"
                      className="form-control"
                      id="employeeNamestart"
                      placeholder="Start date"
                      onChange={(e)=>setStartdate(e.target.value)}
                    />
                  </div>
                  </Col>
                  <Col lg={6}>
                  <div className="mb-3">
                    <Label htmlFor="employeeNameend" className="form-label">
                      End Date
                    </Label>
                    <Input
                      type="date"
                      className="form-control"
                      id="employeeNameend"
                      placeholder="End Date"
                      onChange={(e)=>setEnddate(e.target.value)}
                    />
                  </div>
                  </Col>
                </Row>
                <Row>
                <Col lg={6}>
                  <div className="mb-3">
                    <Label htmlFor="employeeNamedesc" className="form-label">
                      Description
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="employeeNamedesc"
                      placeholder="Write description"
                      onChange={(e)=>setDesc(e.target.value)}
                    />
                  </div>
                  </Col>
                  <Col lg={6}>
                      <div>
                        <label name="deposite" htmlFor='amountdata' className="form-check-label form-label">Discount Amount</label>
                        <input id='amountdata' name='amount'  className='form-control' onChange={(e)=>setDiscountamount(e.target.value)} placeholder='Enter discount Amount'/>
                      </div>
                  </Col>
                </Row>
                <Row>
                <Col lg={6}>
                  <div className="mb-3">
                    <Label htmlFor="employeeNameamounttype" className="form-label">
                      Amount Type
                    </Label>
                    <select
                      id="employeeNameamounttype"
                      className="form-select"
                      value={discounttype}
                      data-choices
                      data-choices-sorting="true"
                      onChange={(e) => {
                        setDiscounttype(e.target.value);
                        if(e.target.value === 'Amount') {setChange(!change)}
                        else setChange(false)
                      }}
                      
                    >
                      <option>Choose Discount Type</option>
                      <option value={"Amount"}>Amount</option>
                      <option value={"Percentage"}>Percentage</option>
                    </select>
                  </div>
                  </Col>
                  {
                    change ?
                    "":
                  <Col lg={6}>
                  <div className="mb-3">
                    <Label htmlFor="employeeNamedescamount" className="form-label">
                      Maximum Discount
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="employeeNamedescamount"
                      placeholder="Enter maximum discount amount"
                      onChange={(e)=>setMaxdiscount(e.target.value)}
                    />
                  </div>
                  </Col>
                  }
                </Row>
                <Row>
                {/* <Col>
                    <Label htmlFor="employeeName" className="form-label">Deduct Diposite for Self Drive</Label>
                    <div className="live-preview">
                      <div className="hstack gap-2 flex-wrap">
                        <Input type="radio" className="btn-check" onChange={(e)=>setDeductdiposite(e.target.value)} value="Allow" name="options-outlined1" id="success-outlinedself"  />
                        <Label className="btn btn-outline-success" for="success-outlinedself">Allow</Label>
                        <Input type="radio" className="btn-check" onChange={(e)=>setDeductdiposite(e.target.value)} value="Not Allow"  name="options-outlined1" id="danger-outlinedself" />
                        <Label className="btn btn-outline-danger" for="danger-outlinedself">Not Allow</Label>
                      </div>
                    </div>
                  </Col> */}
                  <Col xl={4}>
                        <Label htmlFor="employeeName" className="form-label">
                          Deduct Diposite for Self Drive
                        </Label>

                        <select
                          id="inputState"
                          className="form-select"
                          value={deductdiposite}
                          data-choices
                          data-choices-sorting="true"
                          onChange={(e)=>setDeductdiposite(e.target.value)}
                        >
                          <option hidden> Select Deduct Diposite for Self Drive</option>
                          <option
                            onChange={(e)=>setDeductdiposite(e.target.value)} 
                            value="Allow"
                          >Allow</option>
                          <option
                            onChange={(e)=>setDeductdiposite(e.target.value)} 
                            value="Not Allow"
                          >Not Allow</option>
                        </select>
                      </Col>
                </Row>
                <Row>
                  {/* <Col>

                    <Label htmlFor="employeeName" className="form-label">Status</Label>
                    <div className="live-preview">
                      <div className="hstack gap-2 flex-wrap">
                        <Input type="radio" className="btn-check" onChange={(e)=>setStatus(e.target.value)}  value="Active" name="options-outlined" id="success-outlined"  />
                        <Label className="btn btn-outline-success" for="success-outlined">Active</Label>
                        <Input type="radio" className="btn-check"  value="Inactive"  name="options-outlined" id="danger-outlined" />
                        <Label className="btn btn-outline-danger" for="danger-outlined">Inactive</Label>
                      </div>
                    </div>
                  </Col> */}
                  <Col xl={4}>
                        <Label htmlFor="employeeName" className="form-label">
                          Status
                        </Label>

                        <select
                          id="inputState"
                          className="form-select"
                          value={status}
                          data-choices
                          data-choices-sorting="true"
                          onChange={(e)=>setStatus(e.target.value)}
                        >
                          <option hidden>Select Status</option>
                          <option

                            value="Active"
                            onChange={(e)=>setStatus(e.target.value)}
                          >Active</option>
                          <option
                            value="Inactive"
                            onChange={(e)=>setStatus(e.target.value)}
                          >Inactive</option>

                        </select>
                      </Col>
                </Row>
                  <div className="text-center">
                    {/* <Input
                      type="checkbox"
                      className="btn-check"
                      id="btn-check-outlined"
                    />
                    <Label
                      className="btn btn-outline-primary text-centre"
                      for="btn-check-outlined"
                    >
                      Add
                    </Label>
                    &nbsp; &nbsp; &nbsp;
                    <Input
                      type="checkbox"
                      className="btn-check"
                      id="btn-check-outline"
                    />
                    <Label
                      className="btn btn-outline-primary text-centre"
                      for="btn-check-outline"
                    >
                      Cancel
                    </Label> */}
                    <Label
                      className="btn btn-outline-primary text-centre"
                      for="btn-check-outlined"
                      onClick={handelClick}
                      >
                      Add
                  </Label>
                  &nbsp; &nbsp; &nbsp;
                  <Link to={'/coupon_list'}>
                  <Label
                      className="btn btn-outline-danger"
                      for="danger-outlinedremove"
                      // onClick={cencelCupon}
                      >
                      Cancel
                  </Label>
                  </Link>
                  </div>
                  {/* </Col> */}
                </Form>
              </div>
              <div className="d-none code-view">
                <pre className="language-markup" style={{ height: "375px" }}>
                  <code>
                    {/* <VerticalForm /> */}
                  </code>
                </pre>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
          </Container>
          <ToastContainer/>
        </div>
    </React.Fragment>
  )
}
export default Coupon