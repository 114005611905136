import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import BreadCrumb from "../../../Components/Common/BreadCrumb"
import {
  Card,
  CardBody,
  CardHeader,
  Input,
  Label,
  Col,
  Collapse,
  Container,
  Row,
  Button,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  CardFooter,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import DataTable, { createTheme } from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "../../../helpers/Axios"
import { api_url,aws_bucket_url } from "../../../helpers/base_url";
// import UpdateCarbrand from "./UpdateCarbrand";
// import ViewCarBrand from "./ViewCarBrand";

const GeneralEnquires = (gettingBrandicon) => {
  // for map methods

  const [recive, setRecive] = useState([]);
  const [updateId, setUpdateId] = useState("");
  const [updatename, setUpdatename] = useState("");
  const [updatephonenumber, setUpdatephonenumber] = useState("");
  const [updatelookingfor, setUpdatelookingfor] = useState("");
  const [updatecity, setUpdatecity] = useState("");
  const [updateemail, setUpdateemail] = useState("");
  const [updatemessage, setUpdatemessage] = useState("");
  const [updateenquirytime, setUpdateenquirytime] = useState("");
  const [updateenquirydate, setUpdateenquirydate] = useState("");
  const [isOpenView, setIsOpenView] = useState(false);
//   const [show, setShow] = useState(false);
//   const [isOpen, setIsOpen] = useState(false);
//   const [updateId, setUpdateId] = useState("");
//   const [updateicon, setUpdateicon] = useState("");
//   const [updateBrandname, setUpdateBrandname] = useState("");
//   const [updateCartype, setUpdateCartype] = useState("");
//   const [updatedstatus, setUpdatedstatus] = useState("");
//   const [modalShow, setModalShow] = React.useState(false);

//   const [isOpenViewcar, setIsOpenViewcar] = useState(false);

//   const [search, setSearch] = useState("");

//   let jump = useNavigate("");

//   function handelClick() {
//     jump("/addCarbrand");
//   }

  function gettingGeneralEnquiresData() {
    axios
      .get("/general_enquires")
      .then((res) => {
        setRecive(res.data.users);
        // console.log(res.data.users);
        // console.log("received general_enquires data ");
      })
      .catch(() => {
        // console.log("error reciving general_enquires");
      });
  }

  useEffect(() => {
    gettingGeneralEnquiresData();
  }, []);

  const showToastMessageDeleteSuccess = () => {
    toast.success("Deleted Successfully !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  //Cancelation message
  const showToastMessageRetervied = () => {
    toast.success("Delete Retervied !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  createTheme("fulldark", {
    text: {
      primary: "white",
      secondary: "white",
    },
    background: {
      default: "#212529",
    },
    context: {
      text: "white",
    },
    button: {
      default: "white",
      focus: "white",
      hover: "white",
      disabled: "white",
    },
  });

  
  const columns = [
    {
      name: <p>Id </p>,
      selector: (row) => row.id,
    },
    {
      name: <p>Name </p>,
      selector: (row) => row.user_name,
    },
    {
      name: <p>Email</p>,
      selector: (row) => row.email,
    },
    {
      name: <p>Contact</p>,
      selector: (row) => row.phone_number,
    },
    {
      name: <p>Looking for</p>,
      selector: (row) => row.looking_for,
    },
    {
      name: <p>City</p>,
      selector: (row) => row.city,
    },
    {
      name: <p>Message</p>,
      selector: (row) => row.message,
    },
    {
      name: <p>Enquiry Date</p>,
      selector: (row) => row.enquiry_date,
    },
    {
      name: <p>Enquiry Time</p>,
      selector: (row) => row.enquiry_time,
    },
    {
      name: <p className="font-weight-bold fs-13">Action</p>,
      cell: (row) => {
        return (
          <UncontrolledDropdown className="dropdown d-inline-block" direction="right">
            <DropdownToggle
              className="btn btn-soft-secondary btn-sm"
              tag="button"
            >
              <i className="ri-more-fill align-middle"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem
                onClick={(e) => {
                  setUpdateId(row.id);
                  setUpdatename(row.user_name);
                  setUpdatephonenumber(row.phone_number);
                  setUpdatelookingfor(row.looking_for)
                  setUpdatecity(row.city)
                  setUpdatemessage(row.message);
                  setUpdateenquirytime(row.enquiry_time);
                  setUpdateenquirydate(row.enquiry_date)
                  setUpdateemail(row.email);     
                  setIsOpenView(true);
                }}
              >
                <i className="ri-eye-fill align-bottom me-2 text-muted"></i>
                View
              </DropdownItem>
              </DropdownMenu>
              </UncontrolledDropdown>
        )
      },
    }
  ];

  // Get current page
  // const indexOfLastpost = currentpage * postperpage;
  // const indexOfFirstpost = indexOfLastpost - postperpage;
  // const currentPosts = recive.slice(indexOfFirstpost, indexOfLastpost);

  // Change page
  // const paginate = (pageNumber) => setCurrentpage(pageNumber);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Enquires" pageTitle="General" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0  text-centre">General Enquires</h4>
                </CardHeader>
                <CardBody>
                  <DataTable
                    columns={columns}
                    data={recive}
                    pagination
                    theme="fulldark"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ToastContainer />
        </Container>
        {/* {modalShow && (
          <UpdateCarbrand
            setShow={setModalShow}
            show={modalShow}
            id={updateId}
            car_brand={updateBrandname}
            car_logo={updateicon}
            status={updatedstatus}
          />
        )}
        {show && (
          <ViewCarBrand
            setShow={setShow}
            show={show}
            id={updateId}
            carbrand={updateBrandname}
            carlogo={updateicon}
            status={updatedstatus}
          />
        )} */}
      </div>
      <Modal isOpen={isOpenView} toggle={() => setIsOpenView(false)}>
        <ModalHeader>View General Enquires</ModalHeader>
        <ModalBody>
          {/* ... Render the view modal content ... */}
          {/* {selectedFeedbackData && ( */}
          <CardHeader>
          <div style={{ padding: "10px"}}></div>
          </CardHeader>
            
               <p><b>ID:</b>&nbsp;&nbsp;{updateId}</p>
               <p><b>User Name:</b>&nbsp;&nbsp;{updatename}</p>
               <p><b>Email:</b>&nbsp;&nbsp;{updateemail}</p>
               <p><b>Contact:</b>&nbsp;&nbsp;{updatephonenumber}</p>
               <p><b>Looking For:</b>&nbsp;&nbsp;{updatelookingfor}</p>
        <p><b>City:</b>&nbsp;&nbsp;{updatecity}</p>
        <p><b>Message:</b>&nbsp;&nbsp;{updatemessage}</p>
        <p><b>Enquires Date:</b>&nbsp;&nbsp;{updateenquirydate}</p>
        <p><b>Enquires Time:</b>&nbsp;&nbsp;{updateenquirytime}</p>
              {/* Display other feedback details here */}
            
          {/* )} */}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setIsOpenView(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default GeneralEnquires;
