import React, { useEffect, useState } from "react";
// import styles from "./Carfeaturesmodal.module.css";
import axios from "../../helpers/Axios";
// import { ListGroup, ListGroupItem, ModalBody, ModalFooter,  ModalHeader } from 'reactstrap';
import { Link } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import {
  FormGrid,
  Gutters,
  VerticalForm,
  HorizontalForm,
  HorizontalFormLabelSizing,
  ColumnSizing,
  AutoSizing,
  InlineForms,
  FloatingLabels,
} from "../AddCarFleet/FormsLayoutDummy";
import PreviewCardHeader from "../AddCarFleet/PreviewCardHeaderDummy";
import Flatpickr from "react-flatpickr";
import { ToastContainer, toast } from "react-toastify";
import { color } from "echarts";

// import React, { useState } from 'react';
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import {
  Card,
  CardBody,
  CardHeader,
  Input,
  Label,
  details,
  Col,
  Collapse,
  Container,
  id,
  Row,
  DropdownItem,
  ModalBody,
  ModalFooter,
  ModalHeader,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  CardFooter,
} from "reactstrap";

function CarsEditModal({ setShow, show, details }) {
  const [orginal,setOrginal]=useState([])
  const handleClose = () => setShow(false);
  const [data, setData] = useState([])
  const [locationData, setLocationData] = useState([]);
  const [location01, setLocation01] = useState([])
  const [modellist, setModellist] = useState("");
  const [type, setType] = useState()
  const [car1, setCar1] = useState()
  const [car2, setCar2] = useState()
  const [carid, setCarid] = useState()
  const [pick01, setPick01] = useState(false);
  const [currentprice, setCurrentprice] = useState()
  const [alldata, setAlldata] = useState({ carname: "", brand: "", price: "", deposit: "", status: "", car_type: "", location: "", locationID: "", for_prime: "", car_style: ""})
  useEffect(() => {
    if(alldata.for_prime =="yes"){
      setCurrentprice(alldata.price)
      setAlldata({...alldata,price:0})
      // setAlldata({...alldata,for_prime:"yes"})
      
    }
    else if (alldata.for_prime =="no"){
      if(currentprice){setAlldata({...alldata,price:currentprice})}
      else{console.log("currentprice not found")}
      
      // setAlldata({...alldata,for_prime:"no"})
    }
  }, [alldata.for_prime]);
  
  useEffect(() => {
    setOrginal(details)
    const type = details.car_type
    if (type) {
      setType(true)
      //  console.log(details,"878787878")
      setAlldata({
        ...alldata,
        price: details.price,
        deposit: details.deposit,
        status: details.status,
        car_type: details.car_type,
        locationID: details.location,
        for_prime: details.forPrime,
        car_style: details.car_style,
        location: details.location,
            })



    }
    else {
      // console.log(details,"878787878")
      setOrginal(details)
      setType(false)
      setAlldata({
        ...alldata,
        carname: details[1],
        brand: details[2],
        price: details[21],
        deposit: details[20],
        status: details[22],
        car_type: details[13],
        locationID: details[15],
        for_prime: details[16],
        car_style: details[14],

       location:details[15]
      })
    }
    locat()

    load() 
    getcarclasslist()
  }, []);




  async function locat() {
    await axios
      .get(`/location`)
      .then((res) => {
        setLocationData(res.data.location);


      })
      .catch(() => {
        // console.log("Error");
      });
  }

  async function load() {
    // console.log("started")
    const type = details.car_type
    if (type) {
    await axios
      .get(`/CarFleetDetials?id=${details.car_id}`)
      .then((res) => {
        // setLocationData(res.data.location);
        // console.log(res.data.car[0].car_brand,"nameeeeeeeeeeeeee")

        // setAlldata({
        //   ...alldata,
        //   carname: res.data.car[0].car_brand,
        //   brand:  res.data.car[0].car_model,
        // })
        setCarid(details.car_id)
        setCar1(res.data.car[0].car_brand)
        setCar2(res.data.car[0].car_model)

      })
      .catch(() => {
        // console.log("Error");
      });
    }
    else{
      setCarid(details[12])
      setCar1(details[1])
      setCar2(details[2])


    }

  }
  function getcarclasslist() {
    axios
      .get("/getCarClaslist")
      .then((res) => {
        setModellist(res.data.users);
        // console.log(res.data.users);
        // console.log("received Class List");
      })
      .catch(() => {
        // console.log("error receiving class list");
      });
  }




  useEffect(() => {
    const arr01 = []

    // console.log(locationData, '##############');
    if (locationData) {
      locationData.forEach((item) => {


        // console.log(itam.id)
        if (item.id == alldata.locationID) {
          // console.log('location',item)
          arr01.push(item.location)

        }
      })
    }

    setLocation01(arr01)
  })
  useEffect(() => {
    async function cardetails(){
      if(pick01 == true )

      {
       const pick =carid.match(/(.*)foul(.*)foul(.*)/)
      //  console.log(pick[1],pick[2],pick[3],"flase")

   const intNumber = parseInt(pick[1])
       setCarid(intNumber)
       setCar1(pick[2])
       setCar2(pick[3])
      
      }



    }
    cardetails()
   
  
  })
  useEffect(()=>{

    const type = details.car_type
           
    if (type == "Chauffeur_Driven_Standard") {
setOrginal({...orginal,


// carStyle:alldata.car_style,
// price:parseInt(alldata.price),
// deposit:parseInt(alldata.deposit),
// status:alldata.status,
// forPrime:alldata.for_prime,
// location:alldata.location,
// car_id:parseInt(carid)
carModel: parseInt(carid),
carStyle: alldata.car_style,
deposit: parseInt(alldata.deposit),
forPrime: alldata.for_prime,
location: alldata.location,
status: alldata.status,
standardPrice: parseInt(alldata.price),
id:details.id,
agent: details.agent,
car_type: details.car_type,
color: details.color,
standardKmsLimit: details.extra_kms,
launch: details.launch,
standardPackageHrs: details.package_hours
})
    }
else if (type == "Chauffeur_Driven_OutStation") {
      setOrginal({...orginal,
      carModel: parseInt(carid),
      carStyle: alldata.car_style,
      deposit: parseInt(alldata.deposit),
      forPrime: alldata.for_prime,
      location: alldata.location,
      status: alldata.status,
      outStationPrice: parseInt(alldata.price),
      id:details.id,

      agent: details.agent,
      car_type: details.car_type,
      color: details.color,
      outStationKmsLimit: details.extra_kms,
      launch: details.launch,
      outStationPackageHrs: details.package_hours
      })
          }
   else if (type == "Chauffeur_Driven_Airport") {
            setOrginal({...orginal,
            carModel: parseInt(carid),
            carStyle: alldata.car_style,
            deposit: parseInt(alldata.deposit),
            forPrime: alldata.for_prime,
            location: alldata.location,
            status: alldata.status,
            airprice: parseInt(alldata.price),
            id:details.id,
          
            agent: details.agent,
            car_type: details.car_type,
            color: details.color,
            parkingFee: details.parking_fee,
            launch: details.launch,
            tollFee: details.toll_fee,
            })
                }

    else {
      setOrginal({...orginal,
        carModel: parseInt(carid),
        carStyle: alldata.car_style,
        deposit: parseInt(alldata.deposit),
        forPrime: alldata.for_prime,
        location: alldata.location,
        status: alldata.status,
        selfDrivePrice: parseInt(alldata.price),
        id:details[11],
      
        agent: details[17],
        carType: "Self_Drive",
        color: details[18],
        launch: details[19],
  

        // [14]:alldata.car_style,
        // [21]:parseInt(alldata.price),
        // [22]:alldata.status,
        // [20]:parseInt(alldata.deposit),
        // [15]:parseInt(alldata.location),
        // [16]:alldata.for_prime,
        // [12]:parseInt(carid)
      })
    }

  })
  const showToastMessageSuccessUpdate = () => {
    toast.success("Updated Successfully !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

    // console.log(orginal,"modifiedddddddddddddddd")   
      // console.log(details)
    //  console.log(carid,car1,car2,"l")
    console.log(alldata,"primeeeeeeeeeeeeee")
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          {/* <Modal.Title>{datcar_brand}&nbsp;{car_model}</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div>
              <CardHeader>
                <div className="text-center mt-10">
                  <h5 style={{ color: "#eee4e4" }}>Edit Information</h5>
                </div>
              </CardHeader>
              <div className="p-2 mt-0 justify-content-center">
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                  action="#"
                >
                  <Row>
                    <Col lg={6}>
                      <div className="mb-2">
                        <Label
                          style={{ color: "#eee4e4" }}
                          htmlFor="catname"
                          className="form-label"

                        >
                          CarType <span className="text-danger">*</span>
                        </Label>
                        {/* <Input
                          style={{
                            backgroundColor: "#2a2f34",
                            color: "#eee4e4",
                          }}
                          id="catname"
                          name="catname"
                          className="form-control"
                          placeholder="Edit name"
                          type="text"
                          // value={details.car_type}
                          // Value={alldata.car_type}
                          // onChange={(e) => {
                          //   // setData({ ...data, carbrand: e.target.value })
                          //   setAlldata({...alldata,car_type:e.target.value})
                          // }} */}
                        
                      <div  className="form-control">{alldata.car_type}</div>
                      </div>
                    </Col>
                    {/* Error part */}
                    {/* <Col lg={6}>
                      <div className="mb-2">
                        <Label
                          style={{ color: "#eee4e4" }}
                          htmlFor="catname"
                          className="form-label"
                        >
                          Car Style<span className="text-danger">*</span>
                        </Label>
                        <Input
                          style={{
                            backgroundColor: "#2a2f34",
                            color: "#eee4e4",
                          }}
                          id="catname"
                          name="catname"
                          className="form-control"
                          placeholder="Edit model"
                          type="text"
                          defaultValue={alldata.car_style}
                          onChange={(e) => {
                            setAlldata({...alldata,car_style:e.target.value})
                            // setData({ ...data, carmodel: e.target.value });
                          }}
                        />
                      </div>
                    </Col> */}

                    <Col md={6}>
                      <Label htmlFor="employeeName" className="form-label">
                        Car Style
                      </Label>
                      <select
                        id="inputState"
                        className="form-select"
                        data-choices
                        data-choices-sorting="true"
                        onChange={(e) => {
                          setAlldata({...alldata,car_style:e.target.value})
                        }}
                      >
                        <option>{alldata.car_style}</option>
                        <option value={"Super Car"}>Super Car</option>
                        <option value={"Standard"}>Standard</option>
                        <option value={"Luxury"}>Luxury</option>
                        <option value={"Executive"}>Executive</option>
                        <option value={"Premium"}>Premium</option>
                      </select>
                      {/* </div> */}
                    </Col>


                  </Row>
                  <Row>
                    {/* <Col lg={6}>
                      <div className="mb-2">
                        <Label
                          style={{ color: "#eee4e4" }}
                          htmlFor="catprice"
                          className="form-label"
                        >
                          Landing Image <span className="text-danger">*</span>
                        </Label>
                        <Input
                          style={{
                            backgroundColor: "#2a2f34",
                            color: "#eee4e4",
                          }}
                          className="form-control"
                          id="formFile"
                          placeholder="additonal input field "
                          type="file"
                          name="file"
                          //   defaultValue={files[0].name}
                        //   onChange={landingimagesUpload}
                        />
                      </div>
                    </Col> */}
                    <Col lg={6}>
                      <Label htmlFor="employeeName" className="form-label">
                        Status
                      </Label>
                      <select
                        id="inputState"
                        className="form-select"
                        defaultValue={alldata.status}
                        data-choices
                        data-choices-sorting="true"
                        onChange={(e) => {
                          // setData({ ...data, status: e.target.value });
                          setAlldata({...alldata,status:e.target.value})
                        }}
                      >
                        <option hidden>{alldata.status}</option>
                        <option
                          value="Active"
                        >Active</option>
                        <option
                          value="Inactive"
                        >Inactive</option>

                      </select>
                    </Col>
                    {/* <Col lg={6}>
                      <div className="mb-2">
                        <Label
                          style={{ color: "#eee4e4" }}
                          htmlFor="catname"
                          className="form-label"
                        >
                          Car Name <span className="text-danger">*</span>
                        </Label>
                        <Input
                          style={{
                            backgroundColor: "#2a2f34",
                            color: "#eee4e4",
                          }}
                          id="catname"
                          name="catname"
                          className="form-control"
                          placeholder="Edit model"
                          type="text"
                          defaultValue={car1}
                          onChange={(e) => {
                            // setAlldata({...alldata,carname:e.target.value})
                            setCar1(e.target.value)
                            // setData({ ...data, carmodel: e.target.value });
                          }}
                        />
                      </div>
                    </Col> */}
                    <Col md={6}>
                      <Label htmlFor="inputState" className="form-label">
                        Select Car
                      </Label>
                      <select
                        onChange={(e) => {
                          // setData({ ...data, carModel: e.target.value1 })
                         setCarid(e.target.value)
                         setPick01(true)
                        }}
                        id="inputState"
                        className="form-select"
                        data-choices
                        data-choices-sorting="true"
                      >
                        {car1 ?( <option value="">{car1 +"  "+car2}</option>): <option value="">Loading</option>}
                       
                        {modellist ? (
                          modellist.map((data) => {
                            return (
                              <option key={data.id} value={data.id+"foul"+data.car_brand+"foul"+data.car_model} >{data.car_brand}{' '}{data.car_model}</option>
                            );
                          })
                        ) : (
                          <h1>null</h1>
                        )}
                      </select>
                    </Col>
                  </Row>
                  <Row>
                  <Col md={6}>
                      <Label htmlFor="inputState" className="form-label">
                        Location
                      </Label>
                      <select
                        onChange={(e) => {
                          // setLocation(e.target.value);
                          setAlldata({...alldata,location:e.target.value})
                       
                        }}
                        id="inputState"
                        className="form-select"
                        data-choices
                        data-choices-sorting="true"
                      >
                         {/* {location01 ? ( <option value="">{location01}</option>) : <option value="">Loading</option>} */}
                         {location01 ?( <option value="">{location01}</option>) : <option value="">Loading</option>}
                        {/* <option value="">{location01}</option> */}
                        {locationData ? (
                          locationData.map((data) => {
                            return (
                              <option key={data.id} value={data.id}>{data.location}</option>
                            );
                          })
                        ) : (
                          <h1>loading</h1>
                        )}
                      </select>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-2">
                        <Label
                          style={{ color: "#eee4e4" }}
                          htmlFor="firstname"
                          className="form-label"
                        >
                          OnRequest <span className="text-danger">*</span>
                        </Label>
                       
                      <select
                        id="inputState"
                        className="form-select"
                       defaultValue={alldata.for_prime}
                        data-choices
                        data-choices-sorting="true"
                        onChange={(e) => {
                          // setData({...data, status:e.target.value});
                          setAlldata({...alldata,for_prime:e.target.value})
                        //  pricechanger()
                        }}
                      >
                        <option  hidden>{alldata.for_prime}</option>
                        <option
                          value="yes"
                        >yes</option>
                        <option
                          value="no"
                        >no</option>

                      </select>
                      </div>
                    </Col>
                    {/* <Col lg={6}>
                      <div className="mb-2">
                        <Label
                          style={{ color: "#eee4e4" }}
                          htmlFor="firstname"
                          className="form-label"
                        >
                          for prime <span className="text-danger">*</span>
                        </Label>
                        <Input
                          style={{
                            backgroundColor: "#2a2f34",
                            color: "#eee4e4",
                          }}
                          name="firstname"
                          type="text"
                          placeholder="Enter username"
                          defaultValue={c}
                          onChange={(e) => {
                            setAlldata({...alldata,for_prime:e.target.value})
                            
                            // setData({ ...data, gear: e.target.value });
                          }}
                        />
                      </div>
                      <select
                        id="inputState"
                        className="form-select"
                       defaultValue={alldata.status}
                        data-choices
                        data-choices-sorting="true"
                        onChange={(e) => {
                          setData({...data, status:e.target.value});
                        }}
                      >
                        <option  hidden>{alldata.status}</option>
                        <option
                          value="Active"
                        >Active</option>
                        <option
                          value="Inactive"
                        >Inactive</option>

                      </select>
                    </Col> */}
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div className="mb-2">
                        <Label
                          style={{ color: "#eee4e4" }}
                          htmlFor="firstname"
                          className="form-label"
                        >
                          Price <span className="text-danger">*</span>
                        </Label>
                        <Input
                          style={{
                            backgroundColor: "#2a2f34",
                            color: "#eee4e4",
                          }}
                          name="firstname"
                          type="text"
                          placeholder="Enter username"
                          defaultValue={alldata.price}
                          onChange={(e) => {
                            setAlldata({...alldata,price:e.target.value})
                            // setData({ ...data, luggage: e.target.value });
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-2">
                        <Label
                          style={{ color: "#eee4e4" }}
                          htmlFor="firstname"
                          className="form-label"
                        >
                          Deposit <span className="text-danger">*</span>
                        </Label>
                        <Input
                          style={{
                            backgroundColor: "#2a2f34",
                            color: "#eee4e4",
                          }}
                          name="firstname"
                          type="text"
                          placeholder="Edit seats number"
                          defaultValue={alldata.deposit}
                          onChange={(e) => {
                            setAlldata({...alldata,deposit:e.target.value})
                            // setData({ ...data, seats: e.target.value });
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    {/* <Col lg={6}>
                      <div className="mb-2">
                        <Label
                          style={{ color: "#eee4e4" }}
                          htmlFor="firstname"
                          className="form-label"
                        >
                          location <span className="text-danger">*</span>
                        </Label>
                        <Input
                          style={{
                            backgroundColor: "#2a2f34",
                            color: "#eee4e4",
                          }}
                          name="firstname"
                          type="text"
                          placeholder="Edit seats number"
                          defaultValue={location01}
                          onChange={(e) => {
                            setData({ ...data, seats: e.target.value });
                            setAlldata({...alldata,location:e.target.value})
                          }}
                        />
                      </div>
                    </Col> */}
                    
                    {/* <Col lg={6}>
                      <Label htmlFor="employeeName" className="form-label">
                        Status
                      </Label>
                      <select
                        id="inputState"
                        className="form-select"
                       defaultValue={alldata.status}
                        data-choices
                        data-choices-sorting="true"
                        onChange={(e) => {
                          setData({...data, status:e.target.value});
                        }}
                      >
                        <option  hidden>{alldata.status}</option>
                        <option
                          value="Active"
                        >Active</option>
                        <option
                          value="Inactive"
                        >Inactive</option>

                      </select>
                    </Col> */}
                  </Row>
                </Form>
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"

          // onClick={() => {
          //   let featureArray = []
          //   let feature = ''
          //   for (let i = 0; i < selected.length; i++) {
          //     featureArray.push(selected[i].label)
          //   }
          //   feature = featureArray.join(',')
          //   setData({...data,feature:feature})
          //   axios
          //     .put(
          //       `/CarFleetDetials?id=${id}`,
          //       data
          //     )
          //     .then(() => {
          //       console.log("reached");
          //       setTimeout(() => {

          //       }, 200);
          //       // setIsOpen(false)
          //     })
          //     .catch(() => {
          //       setTimeout(() => {
          //         errormessage();
          //         console.log("Error")
          //       }, 10);
          //       // setIsOpen(false)
          //     });
          // }}

          onClick={()=>{
            const type = details.car_type
           
            if (type == "Chauffeur_Driven_Standard") {
            axios
            .put(`/standard-data`,orginal)
            .then((res) => {
              setModellist(res.data.users);
              showToastMessageSuccessUpdate()
              // console.log(res,"updated Chauffeur_Driven_Standard");
            })
            .catch(() => {
              // console.log("error");
            });

          }
          else if (type == "Chauffeur_Driven_OutStation") {

            axios
            .put(`/outstation-data`,orginal)
            .then((res) => {
              setModellist(res.data.users);
              showToastMessageSuccessUpdate()
              // console.log(res,"updated Chauffeur_Driven_OutStation" );
            })
            .catch(() => {
              // console.log("error");
            });

          }
          else if (type == "Chauffeur_Driven_Airport") {
            axios
            .put(`/airport-data`,orginal)
            .then((res) => {
              setModellist(res.data.users);
              showToastMessageSuccessUpdate()
              // console.log(res,"updated Chauffeur_Driven_Airport" );
            })
            .catch(() => {
              // console.log("error");
            });


          }
          else {
            axios
            .put(`/self-drive-data`,orginal)
            .then((res) => {
              setModellist(res.data.users);
              showToastMessageSuccessUpdate()
              // console.log(res,"updated Chauffeur_Driven_Airport" );
            })
            .catch(() => {
              // console.log("error");
            });

            
          }


          }}
          >
            Update
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default CarsEditModal