// import React, { useEffect, useState } from "react";
// import styles from "./DriverUpdatemodal.module.css";
// import {
//   Row,
//   Col,
//   CardBody,
//   Card,
//   Alert,
//   Container,
//   Input,
//   Label,
//   Form,
//   CardHeader,
//   FormFeedback,
//   Button,
//   Table,
// } from "reactstrap";
// import axios from "axios";
// import { Link } from "react-router-dom";
// import {
//   FormGrid,
//   Gutters,
//   VerticalForm,
//   HorizontalForm,
//   HorizontalFormLabelSizing,
//   ColumnSizing,
//   AutoSizing,
//   InlineForms,
//   FloatingLabels,
// } from "../AddCarFleet/FormsLayoutDummy";
// import PreviewCardHeader from "../AddCarFleet/PreviewCardHeaderDummy";
// import { ToastContainer, toast } from "react-toastify";
// function UpdateDriver({
//   setIsOpen,
//   id,
//   driver_name,
//   driver_img,
//   driver_loc,
//   email,
//   contact,
//   alter_contact,
//   driving_licence_no,
//   aadhar_no,
//   pancard_no,
//   date_of_birth,
//   driver_pin,
//   status,
// }) {

//   const [updateId, setUpdateId] = useState(id);
//   const [updatedriverName, setUpdatedriverName] = useState(driver_name);
//   const [updatedriverImage, setUpdatedriverImage] = useState(driver_img);
//   const [updatelocation, setUpdatelocation] = useState(driver_loc);
//   const [updateEmail, setUpdateEmail] = useState(email);
//   const [updatecontactno, setUpdatecontactNo] = useState(contact);
//   const [updateAltercontactno, setUpdateAltercontactno] = useState(alter_contact);
//   const [updatedrivinglicno, setUpdatedrivinglicno] = useState(driving_licence_no);
//   const [updateAadharno, setUpdateAadharno] = useState(aadhar_no);
//   const [updatePancardno, setUpdatePancardno] = useState(pancard_no);
//   const [updateDob, setUpdateDob] =useState(date_of_birth);
//   const [updatePin, setUpdatePin] = useState(driver_pin);
//   const [updateStatus, setUpdateStatus] = useState(status);
//   // const [isOpen, setIsOpen] = useState(false);

//   const uploadDriverImg = (e) => {
//     e.preventDefault();
//     const Image = e.target.files[0];
//     console.log(e.target.files[0].name);
//     setUpdatedriverImage(e.target.files[0].name);
//     // setFiles(e.target.files[0].name)
//     console.log(Image);
//     if (Image != null) {
//       const data = new FormData();
//       data.append("Image", Image);
//       console.log(data);
//       axios
//         .post("/uploaddriverimg", data)
//         .then(() => {
//           // setTimeout(()=>{
//           //   setUploadinglogo("")
//           // },1500)
//           console.log("Uploaded icon");
//         })
//         .catch(() => {
//           console.log("Error in Uploading logo");
//         });
//     }
//   };

//   const showToastMessageUploadedSuccess = () => {
//     toast.success("Updated Driver Successfully !", {
//       position: toast.POSITION.TOP_CENTER,
//     });
//   };
//   const showToastMessageErroruploading = () => {
//     toast.error("Error Updating Driver !", {
//       position: toast.POSITION.TOP_CENTER,
//     });
//   };

//   const [newstatus, setNewstatus] = useState("");
//   return (
//     <>
//       <div className={styles.darkBG} onClick={() => setIsOpen(false)} />
//       <div className={styles.centered}>
//         <div className="auth-page-content">
//           <Container fluid>
//             <Row>
//               <Col xxl={12}>
//                 <Card className="border card-border-primary">
//                   <CardBody className="p-7" id={styles.openedmodal}>
//                     <div>
//                       <CardHeader>
//                         <div className="text-center mt-10">
//                           <h5 className="text-primary">Edit Driver</h5>
//                         </div>
//                       </CardHeader>
//                       <div className="p-2 mt-0 justify-content-center">
//                         <Form
//                           onSubmit={(e) => {
//                             e.preventDefault();
//                           }}
//                           action="#"
//                         >
//                           <Row>
//                             <Col lg={6}>
//                               <div className="mb-2">
//                                 <Label htmlFor="catname" className="form-label">
//                                   Driver Name{" "}
//                                   <span className="text-danger">*</span>
//                                 </Label>
//                                 <Input
//                                   id="catname"
//                                   name="catname"
//                                   className="form-control"
//                                   placeholder="Edit name"
//                                   type="text"
//                                   defaultValue={driver_name}
//                                   onChange={(e) => {
//                                     setUpdatedriverName(e.target.value);
//                                   }}
//                                 />
//                               </div>
//                             </Col>
//                             <Col lg={6}>
//                               <div className="mb-2">
//                                 <Label
//                                   htmlFor="catprice"
//                                   className="form-label"
//                                 >
//                                   Driver Location{" "}
//                                   <span className="text-danger">*</span>
//                                 </Label>
//                                 <Input
//                                   name="price"
//                                   type="text"
//                                   placeholder="Enter new price"
//                                   defaultValue={driver_loc}
//                                   onChange={(e) => {
//                                     setUpdatelocation(e.target.value);
//                                   }}
//                                 />
//                               </div>
//                             </Col>
//                           </Row>
//                           <Row>
//                             <Col lg={12}>
//                             <div className="mb-2">
//                                 <Label htmlFor="catname" className="form-label">
//                                   Driver Image{" "}
//                                   <span className="text-danger">*</span>
//                                 </Label>
//                                 <Input
//                                   id="catname"
//                                   name="catname"
//                                   className="form-control"
//                                   placeholder="Edit name"
//                                   type="file"
//                                   // defaultValue={driver_name}
//                                   onClick={uploadDriverImg}
//                                 />
//                               </div>
//                             </Col>
//                           </Row>
//                           <Row>
//                             <Col lg={6}>
//                               <div className="mb-2">
//                                 <Label
//                                   htmlFor="firstname"
//                                   className="form-label"
//                                 >
//                                   email <span className="text-danger">*</span>
//                                 </Label>
//                                 <Input
//                                   name="firstname"
//                                   type="text"
//                                   placeholder="Enter username"
//                                   defaultValue={email}
//                                   onChange={(e) => {
//                                     setUpdateEmail(e.target.value);
//                                   }}
//                                 />
//                               </div>
//                             </Col>
//                             <Col lg={6}>
//                               <div className="mb-2">
//                                 <Label
//                                   htmlFor="firstname"
//                                   className="form-label"
//                                 >
//                                   Driver Contact{" "}
//                                   <span className="text-danger">*</span>
//                                 </Label>
//                                 <Input
//                                   name="firstname"
//                                   type="text"
//                                   placeholder="Enter username"
//                                   defaultValue={contact}
//                                   onChange={(e) => {
//                                     setUpdatecontactNo(e.target.value);
//                                   }}
//                                 />
//                               </div>
//                             </Col>
//                           </Row>
//                           <Row>
//                             <Col lg={6}>
//                               <div className="mb-2">
//                                 <Label
//                                   htmlFor="firstname"
//                                   className="form-label"
//                                 >
//                                   Driver Alternative Contact{" "}
//                                   <span className="text-danger">*</span>
//                                 </Label>
//                                 <Input
//                                   name="firstname"
//                                   type="text"
//                                   placeholder="Enter username"
//                                   defaultValue={alter_contact}
//                                   onChange={(e) => {
//                                     setUpdateAltercontactno(e.target.value);
//                                   }}
//                                 />
//                               </div>
//                             </Col>
//                             <Col lg={6}>
//                               <div className="mb-2">
//                                 <Label
//                                   htmlFor="firstname"
//                                   className="form-label"
//                                 >
//                                   Driving Licence{" "}
//                                   <span className="text-danger">*</span>
//                                 </Label>
//                                 <Input
//                                   name="firstname"
//                                   type="text"
//                                   placeholder="Enter username"
//                                   defaultValue={driving_licence_no}
//                                   onChange={(e) => {
//                                     setUpdatedrivinglicno(e.target.value);
//                                   }}
//                                 />
//                               </div>
//                             </Col>
//                           </Row>
//                           <Row>
//                             <Col lg={6}>
//                               <div className="mb-2">
//                                 <Label
//                                   htmlFor="firstname"
//                                   className="form-label"
//                                 >
//                                   Driver's Aadhar{" "}
//                                   <span className="text-danger">*</span>
//                                 </Label>
//                                 <Input
//                                   name="firstname"
//                                   type="text"
//                                   placeholder="Enter username"
//                                   defaultValue={aadhar_no}
//                                   onChange={(e) => {
//                                     setUpdateAadharno(e.target.value);
//                                   }}
//                                 />
//                               </div>
//                             </Col>
//                             <Col lg={6}>
//                               <div className="mb-2">
//                                 <Label
//                                   htmlFor="firstname"
//                                   className="form-label"
//                                 >
//                                   DOB <span className="text-danger">*</span>
//                                 </Label>
//                                 <Input
//                                   name="firstname"
//                                   type="date"
//                                   placeholder="Enter username"
//                                   defaultValue={status}
//                                   onChange={(e) => {
//                                     setUpdateDob(e.target.value);
//                                   }}
//                                 />
//                               </div>
//                             </Col>
//                           </Row>
//                           <Row>
//                             <Col lg={6}>
//                               <div className="mb-2">
//                                 <Label
//                                   htmlFor="firstname"
//                                   className="form-label"
//                                 >
//                                   Driver's Pancard{" "}
//                                   <span className="text-danger">*</span>
//                                 </Label>
//                                 <Input
//                                   name="firstname"
//                                   type="text"
//                                   placeholder="Enter username"
//                                   defaultValue={aadhar_no}
//                                   onChange={(e) => {
//                                     setUpdatePancardno(e.target.value);
//                                   }}
//                                 />
//                               </div>
//                             </Col>
//                             <Col lg={6}>
//                               <div className="mb-2">
//                                 <Label
//                                   htmlFor="firstname"
//                                   className="form-label"
//                                 >
//                                   Pin Number <span className="text-danger">*</span>
//                                 </Label>
//                                 <Input
//                                   name="firstname"
//                                   type="text"
//                                   placeholder="Enter username"
//                                   defaultValue={driver_pin}
//                                   onChange={(e) => {
//                                     setUpdatePin(e.target.value);
//                                   }}
//                                 />
//                               </div>
//                             </Col>
//                           </Row>
//                           <Row>
//                           <Col lg={6}>
//                               <div className="mb-2">
//                                 <Label
//                                   htmlFor="firstname"
//                                   className="form-label"
//                                 >
//                                   status <span className="text-danger">*</span>
//                                 </Label>
//                                 <Input
//                                   name="firstname"
//                                   type="text"
//                                   placeholder="Enter username"
//                                   defaultValue={status}
//                                   onChange={(e) => {
//                                     setUpdateStatus(e.target.value);
//                                   }}
//                                 />
//                               </div>
//                             </Col>
//                           </Row>
//                           &nbsp;
//                           <Col lg={6}>
//                             <div
//                               className="text-center centered"
//                               style={{ display: "flex", marginLeft: "160px" }}
//                             >
//                               <Label
//                                 className="btn btn-outline-primary "
//                                 for="btn-check-outlined"
//                                 onClick={() => {
//                                   let payload = {
//                                     driver_name : updatedriverName,
//                                     id : id,
//                                     driver_loc : updatelocation,
//                                     email : updateEmail,
//                                     driver_img : updatedriverImage,
//                                     contact : updatecontactno,
//                                     alter_contact : updateAltercontactno,
//                                     status : updateStatus,
//                                     driving_licence_no : updatedrivinglicno,
//                                     aadhar_no : updateAadharno,
//                                     pancard_no : updatePancardno,
//                                     date_of_birth : updateDob,
//                                     driver_pin : updatePin,
//                                   };
//                                   axios
//                                     .put(
//                                       "http://13.126.174.149/driver_details",
//                                       payload
//                                     )
//                                     .then((res) => {
//                                       setIsOpen(false);
//                                       showToastMessageUploadedSuccess();
//                                     })
//                                     .catch((err) => {
//                                       setIsOpen(false);
//                                       showToastMessageErroruploading();
//                                     });
//                                 }}
//                               >
//                                 Update
//                               </Label>
//                               <Label
//                                 className="btn btn-outline-danger "
//                                 for="btn-check-outlined"
//                                 onClick={() => {
//                                   setIsOpen(false);
//                                 }}
//                               >
//                                 Close
//                               </Label>
//                             </div>
//                           </Col>
//                         </Form>
//                       </div>
//                     </div>
//                   </CardBody>
//                 </Card>
//               </Col>
//             </Row>
//           </Container>
//           <ToastContainer/>
//         </div>
//       </div>
//     </>
//   );
// }
// export default UpdateDriver;

import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../helpers/Axios";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import {
  Card,
  CardBody,
  CardHeader,
  Input,
  Label,
  Col,
  Collapse,
  Container,
  Row,
  DropdownItem,
  ModalBody,
  ModalFooter,
  ModalHeader,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  CardFooter,
} from "reactstrap";
function UpdateDriver({
  setShow,
  show,
  id,
  driver_name,
  driver_img,
  driver_loc,
  email,
  contact,
  alter_contact,
  driving_licence_no,
  aadhar_no,
  pancard_no,
  date_of_birth,
  driver_pin,
  status,
}) {
  //   const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [updateId, setUpdateId] = useState(id);
  const [updatedriverName, setUpdatedriverName] = useState(driver_name);
  const [updatedriverImage, setUpdatedriverImage] = useState(driver_img);
  const [updatelocation, setUpdatelocation] = useState(driver_loc);
  const [updateEmail, setUpdateEmail] = useState(email);
  const [updatecontactno, setUpdatecontactNo] = useState(contact);
  const [updateAltercontactno, setUpdateAltercontactno] =
    useState(alter_contact);
  const [updatedrivinglicno, setUpdatedrivinglicno] =
    useState(driving_licence_no);
  const [updateAadharno, setUpdateAadharno] = useState(aadhar_no);
  const [updatePancardno, setUpdatePancardno] = useState(pancard_no);
  const [updateDob, setUpdateDob] = useState(date_of_birth);
  const [updatePin, setUpdatePin] = useState(driver_pin);
  const [updateStatus, setUpdateStatus] = useState(status);
  // const [isOpen, setIsOpen] = useState(false);

  const uploadDriverImg = (e) => {
    e.preventDefault();
    const Image = e.target.files[0];
    // console.log(e.target.files[0].name);
    setUpdatedriverImage(e.target.files[0].name);
    // setFiles(e.target.files[0].name)
    // console.log(Image);
    if (Image != null) {
      const data = new FormData();
      data.append("Image", Image);
      // console.log(data);
      axios
        .post("/driverimg", data)
        .then(() => {
          // setTimeout(()=>{
          //   setUploadinglogo("")
          // },1500)
          // console.log("Uploaded icon");
        })
        .catch(() => {
          // console.log("Error in Uploading logo");
        });
    }
  };

  const showToastMessageUploadedSuccess = () => {
    toast.success("Updated Driver Successfully !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const showToastMessageErroruploading = () => {
    toast.error("Error Updating Driver !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const [newstatus, setNewstatus] = useState("");

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Driver Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group
              classNameNameName="mb-3"
              controlId="exampleForm.ControlInput1"
            >
              <div className="auth-page-content">
                <Container fluid>
                  <Row>
                    <Col xxl={12}>
                      <Card>
                        <CardBody className="p-7">
                          <div>
                            <CardHeader>
                              <div className="text-center mt-10">
                                <h5 className="text-primary">Edit Driver</h5>
                              </div>
                            </CardHeader>
                            <div className="p-2 mt-0 justify-content-center">
                              <Form
                                onSubmit={(e) => {
                                  e.preventDefault();
                                }}
                                action="#"
                              >
                                <Row>
                                  <Col lg={6}>
                                    <div className="mb-2">
                                      <Label
                                        htmlFor="catname"
                                        className="form-label"
                                      >
                                        Driver Name{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Input
                                        id="catname"
                                        name="catname"
                                        className="form-control"
                                        placeholder="Edit name"
                                        type="text"
                                        defaultValue={driver_name}
                                        onChange={(e) => {
                                          setUpdatedriverName(e.target.value);
                                        }}
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="mb-2">
                                      <Label
                                        htmlFor="catprice"
                                        className="form-label"
                                      >
                                        Driver Location{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Input
                                        name="price"
                                        type="text"
                                        placeholder="Enter new price"
                                        defaultValue={driver_loc}
                                        onChange={(e) => {
                                          setUpdatelocation(e.target.value);
                                        }}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={12}>
                                    <div className="mb-2">
                                      <Label
                                        htmlFor="catname"
                                        className="form-label"
                                      >
                                        Driver Image{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Input
                                        id="catname"
                                        name="catname"
                                        className="form-control"
                                        placeholder="Edit name"
                                        type="file"
                                        // defaultValue={driver_name}
                                        onClick={uploadDriverImg}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={6}>
                                    <div className="mb-2">
                                      <Label
                                        htmlFor="firstname"
                                        className="form-label"
                                      >
                                        email{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Input
                                        name="firstname"
                                        type="text"
                                        placeholder="Enter username"
                                        defaultValue={email}
                                        onChange={(e) => {
                                          setUpdateEmail(e.target.value);
                                        }}
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="mb-2">
                                      <Label
                                        htmlFor="firstname"
                                        className="form-label"
                                      >
                                        Driver Contact{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Input
                                        name="firstname"
                                        type="text"
                                        placeholder="Enter username"
                                        defaultValue={contact}
                                        onChange={(e) => {
                                          setUpdatecontactNo(e.target.value);
                                        }}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={6}>
                                    <div className="mb-2">
                                      <Label
                                        htmlFor="firstname"
                                        className="form-label"
                                      >
                                        Driver Alternative Contact{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Input
                                        name="firstname"
                                        type="text"
                                        placeholder="Enter username"
                                        defaultValue={alter_contact}
                                        onChange={(e) => {
                                          setUpdateAltercontactno(
                                            e.target.value
                                          );
                                        }}
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="mb-2">
                                      <Label
                                        htmlFor="firstname"
                                        className="form-label"
                                      >
                                        Driving Licence{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Input
                                        name="firstname"
                                        type="text"
                                        placeholder="Enter username"
                                        defaultValue={driving_licence_no}
                                        onChange={(e) => {
                                          setUpdatedrivinglicno(e.target.value);
                                        }}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={6}>
                                    <div className="mb-2">
                                      <Label
                                        htmlFor="firstname"
                                        className="form-label"
                                      >
                                        Driver's Aadhar{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Input
                                        name="firstname"
                                        type="text"
                                        placeholder="Enter username"
                                        defaultValue={aadhar_no}
                                        onChange={(e) => {
                                          setUpdateAadharno(e.target.value);
                                        }}
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="mb-2">
                                      <Label
                                        htmlFor="firstname"
                                        className="form-label"
                                      >
                                        DOB{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Input
                                        name="firstname"
                                        type="date"
                                        placeholder="Enter username"
                                        defaultValue={date_of_birth}
                                        onChange={(e) => {
                                          setUpdateDob(e.target.value);
                                        }}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={6}>
                                    <div className="mb-2">
                                      <Label
                                        htmlFor="firstname"
                                        className="form-label"
                                      >
                                        Driver's Pancard{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Input
                                        name="firstname"
                                        type="text"
                                        placeholder="Enter username"
                                        defaultValue={aadhar_no}
                                        onChange={(e) => {
                                          setUpdatePancardno(e.target.value);
                                        }}
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="mb-2">
                                      <Label
                                        htmlFor="firstname"
                                        className="form-label"
                                      >
                                        Pin Number{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Input
                                        name="firstname"
                                        type="text"
                                        placeholder="Enter username"
                                        defaultValue={driver_pin}
                                        onChange={(e) => {
                                          setUpdatePin(e.target.value);
                                        }}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={6}>
                                    <div className="mb-2">
                                      <Label
                                        htmlFor="employeeNamestatus"
                                        className="form-label"
                                      >
                                        Status{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <select
                                        id="employeeNamestatus"
                                        className="form-select"
                                        defaultValue={status}
                                        data-choices
                                        data-choices-sorting="true"
                                        onChange={(e) =>
                                          setUpdateStatus(e.target.value)
                                        }
                                      >
                                        <option value={"Active"}>Active</option>
                                        <option value={"Inactive"}>
                                          Inactive
                                        </option>
                                      </select>
                                    </div>
                                  </Col>
                                </Row>
                              </Form>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Container>
                <ToastContainer />
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={() => {
              let payload = {
                driver_name: updatedriverName,
                id: id,
                driver_loc: updatelocation,
                email: updateEmail,
                driver_img: updatedriverImage,
                contact: updatecontactno,
                alter_contact: updateAltercontactno,
                status: updateStatus,
                driving_licence_no: updatedrivinglicno,
                aadhar_no: updateAadharno,
                pancard_no: updatePancardno,
                date_of_birth: updateDob,
                driver_pin: updatePin,
              };
              axios
                // .put("http://13.126.174.149/driver_details", payload)
                .put("/driver_details", payload)
                .then((res) => {
                  // setIsOpen(false);
                  showToastMessageUploadedSuccess();
                })
                .catch((err) => {
                  // setIsOpen(false);
                  showToastMessageErroruploading();
                });
            }}
          >
            Update
          </Button>

          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

// render(<ModalView />);
export default UpdateDriver;
