import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { api_url,aws_bucket_url } from "../../helpers/base_url";
import {
  Card,
  CardBody,
  CardHeader,
  Input,
  Label,
  Col,
  Collapse,
  Container,
  Row,
  DropdownItem,
  ModalBody,
  ModalFooter,
  ModalHeader,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  CardFooter,
} from "reactstrap";
// import ImageForView from "./ImageForView";
function ModalView({
  setShow,
  show,
  id,
  cartype,
  carbrand,
  carlogo,
  status,
}) {
  //   const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}

      <Modal show={show} onHide={handleClose}  size="sm" aria-labelledby="example-modal-sizes-title-sm" > 
        <Modal.Header closeButton>
          <Modal.Title>{carbrand}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form style={{display:'flex', justifyContent:"center", alignItems:"center"}}>
            
            <Form.Group
              classNameNameName="mb-3"
              controlId="exampleForm.ControlInput1"
            >
              <CardBody>
                <div className="d-flex align-items-center">
                  <div>
                  <img
              width="100px"
              height="100px"
              // src={require(`../../CarImages/CarBrandIcon/${carlogo}`)}
              src={aws_bucket_url + '/' + `${carlogo}`}
            ></img>
                  </div>
                  <div className="flex-grow-1 ms-3"></div>
                </div>
              </CardBody>
            </Form.Group>
            <Form.Group>
              {cartype}
            </Form.Group>
          </Form>
        </Modal.Body>
         
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

// render(<ModalView />);
export default ModalView;

