import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import {
  Card,
  CardBody,
  CardHeader,
  Input,
  Label,
  Col,
  Collapse,
  Container,
  Row,
  Button,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  CardFooter,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import DataTable, { createTheme } from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "../../helpers/Axios";
import { api_url,aws_bucket_url } from "../../helpers/base_url";
import UpdateCarbrand from "./UpdateCarbrand";
import ViewCarBrand from "./ViewCarBrand";

const DisplayBrandIcon = (gettingBrandicon) => {
  // for map methods

  const [recive, setRecive] = useState([]);

  const [show, setShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [updateId, setUpdateId] = useState("");
  const [updateicon, setUpdateicon] = useState("");
  const [updateBrandname, setUpdateBrandname] = useState("");
  const [updateCartype, setUpdateCartype] = useState("");
  const [updatedstatus, setUpdatedstatus] = useState("");
  const [modalShow, setModalShow] = React.useState(false);

  const [isOpenViewcar, setIsOpenViewcar] = useState(false);

  const [search, setSearch] = useState("");

  let jump = useNavigate("");

  function handelClick() {
    jump("/addCarbrand");
  }

  function gettingBrandicon() {
    axios
      .get("/carsbrand")
      .then((res) => {
        setRecive(res.data.users);
        // console.log(res.data.users);
        // console.log("received brand Icons ");
      })
      .catch(() => {
        // console.log("error receiveing brand icons deatils ");
      });
  }
  useEffect(() => {
    gettingBrandicon();
  }, [modalShow]);

  // useEffect(()=>{
  //   const gettingBrandicon = async () =>{
  //     setLoading(true);
  //     const res = await axios.get("/carsbrand");
  //     setRecive(res.users);
  //     setLoading(false);
  //   }
  //   gettingBrandicon();
  // },[]);

  // console.log(recive)

  // sucessfull delete
  const showToastMessageDeleteSuccess = () => {
    toast.success("Deleted Successfully !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  //Cancelation message
  const showToastMessageRetervied = () => {
    toast.success("Delete Retervied !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  createTheme("fulldark", {
    text: {
      primary: "white",
      secondary: "white",
    },
    background: {
      default: "#212529",
    },
    context: {
      text: "white",
    },
    button: {
      default: "white",
      focus: "white",
      hover: "white",
      disabled: "white",
    },
  });

  
  const columns = [
    {
      name: <p>Id </p>,
      selector: (row) => row.id,
    },
    {
      name: <p>Car Brand Icon </p>,
      cell: (row) => {
        return (
          <div>
            <img
              width="55px"
              height="55px"
              src={aws_bucket_url + '/' + `${row.car_logo}`}
            ></img>
          </div >
        );
      },
    },

    {
      name: <p>Brand Name </p>,
      selector: (row) => row.car_brand,
    },
    {
      name: <p>Status </p>,
      selector: (row) => row.status,
    },
    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      sortable: true,

      cell: (row) => {
        return (
          <UncontrolledDropdown className="dropdown d-inline-block" direction="right">
            <DropdownToggle className="btn btn-soft-danger btn-sm" tag="button">
              <i className="ri-more-fill align-middle"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem
                href="#!"
                onClick={() => {
                  setUpdateId(row.id);
                  setUpdateicon(row.car_logo);
                  setUpdateBrandname(row.car_brand);
                  setUpdatedstatus(row.status);
                  setShow(true);
                }}
              >
                <i className="ri-eye-fill align-bottom me-2 text-muted"></i>View
              </DropdownItem>

              <DropdownItem
                className="edit-item-btn"
                onClick={() => {
                  setUpdateId(row.id);
                  setUpdateicon(row.car_logo);
                  setUpdateBrandname(row.car_brand);
                  setUpdatedstatus(row.status);
                  setModalShow(true);
                }}
              >
                <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
                Edit
              </DropdownItem>

              <DropdownItem
                onClick={() => {
                  Swal.fire({
                    title: "Are you sure?",
                    text: `Your sure .. u want to delete ${row.car_brand}!`,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                    background:"rgb(33, 37, 41)"
                  }).then((result) => {
                    if (result.isConfirmed) {
                      Swal.fire({
                        title: "Deleted ",
                       
                        icon: "success",
                        background:"rgb(33, 37, 41)"

                      }
                        // "Deleted!",
                        // " data has been deleted.",
                        // "success"
                        
                      );
                      axios
                        .delete(`/carsbrand?id=${row.id}`)
                        .then(() => {
                          // console.log("deleted");
                          gettingBrandicon();
                        })
                        .catch(() => {
                          // console.log("error deleting ");
                        });
                    }
                  });
                }}
                className="remove-item-btn"
              >
                {" "}
                <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                Delete{" "}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      },
    },
  ];

  // Get current page
  // const indexOfLastpost = currentpage * postperpage;
  // const indexOfFirstpost = indexOfLastpost - postperpage;
  // const currentPosts = recive.slice(indexOfFirstpost, indexOfLastpost);

  // Change page
  // const paginate = (pageNumber) => setCurrentpage(pageNumber);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Master" pageTitle="Brand List" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0  text-centre">Brand Lists</h4>
                </CardHeader>
                <CardBody>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <Label
                        className="btn btn-outline-primary"
                        onClick={handelClick}
                        for="btn-check-outlined"
                      >
                        Add Car Brand
                      </Label>
                    </Col>
                    <Col className="col-sm">
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-12">
                      <Row></Row>
                    </Col>
                  </Row>
                  <DataTable
                    columns={columns}
                    data={recive}
                    pagination
                    theme="fulldark"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ToastContainer />
        </Container>
        {modalShow && (
          <UpdateCarbrand
            setShow={setModalShow}
            show={modalShow}
            id={updateId}
            car_brand={updateBrandname}
            car_logo={updateicon}
            status={updatedstatus}
          />
        )}
        {show && (
          <ViewCarBrand
            setShow={setShow}
            show={show}
            id={updateId}
            carbrand={updateBrandname}
            carlogo={updateicon}
            status={updatedstatus}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default DisplayBrandIcon;
