import React, { useState, useEffect } from "react";
import axios from "../../helpers/Axios";
import BreadCrumb from "../Cars/SelfBreadCrumb";
// import Swal from "sweetalert2";
import Swal from "sweetalert2/dist/sweetalert2.js";
import DataTable, { createTheme } from "react-data-table-component";
// import { DropdownItem, DropdownMenu, DropdownToggle, Input,UncontrolledDropdown } from 'reactstrap';
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ViewAgents from "./ViewAgents";
import "react-toastify/dist/ReactToastify.css";
// import ModalViewAgent from './ModalViewAgent';
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Container,
  Label,
  Row,
  Table,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  UncontrolledDropdown,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import UpdateAgents from "./UpdateAgents";

const AgentsLists = () => {
  const [id, setId] = useState();

  const [show, setShow] = useState(false);
  // for edit
  const [reciveData, setReceiveData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  // for view
  const [isOpenView, setIsOpenView] = useState(false);
  const [updateId, setUpdateId] = useState("");
  const [updatedagentname, setUpdatedagentName] = useState("");
  const [updatedlocation, setUpdatedlocation] = useState("");
  const [updatedemail, setUpdatedemail] = useState("");
  const [updatedaltemail, setUpdatedaltemail] = useState("");
  // const [updatedaltemai2, setUpdatedaltemail2] = useState("");
  const [updatedphn, setUpdatedphn] = useState("");
  const [updatedaltphn, setUpdatedaltPhn] = useState("");
  const [updatedstatus, setUpdatedstatus] = useState("");

  const [showModal, setShowModal] = useState(false);

  createTheme("fulldark", {
    text: {
      primary: "white",
      secondary: "white",
    },
    background: {
      default: "#212529",
    },
    context: {
      text: "white",
    },
    button: {
      default: "white",
      focus: "white",
      hover: "white",
      disabled: "white",
    },
  });

  useEffect(() => {
    axios
      .get(`/getAgentInfo`)
      .then((res) => {
        // console.log(" agent info data received");
        // console.log(revdata)
        setReceiveData(res.data.users);
        // console.log(reciveData);

        // console.log(res.users[0].agent_name)
      })
      .catch(() => {
        // console.log("error receiving data");
      });
  }, [showModal]);

  // const[tableData,SetTableData]=useState([])

  const [location, setLocation] = useState([]);

  let statuslist = ["Active", "Inactive"];
  // for status button
  const [search, setSearch] = useState("");

  const [newstatus, setNewstatus] = useState("");

  function tog_list() {
    setmodal_list(!modal_list);
  }

  const [modal_list, setmodal_list] = useState(false);
  function tog_list() {
    setmodal_list(!modal_list);
  }

  // sucessfull update
  const showToastMessageSuccessUpdate = () => {
    toast.success("Updated Successfully !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  // error update
  const showToastMessageErrorUpdate = () => {
    toast.error("Error Updating !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  // sucessfull deleting
  const showToastMessageSuccessfulDelete = () => {
    toast.success("Deleted Sucessfully !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  // Reterive deleting
  const showToastMessageerrorDelete = () => {
    toast.success("Reterived Delete", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  function getAgentInfo() {
    axios
      .get(`/getAgentInfo`)
      .then((res) => {
        // console.log(" agent info data received");
        setReceiveData(res.data.users);
        // console.log(res.data.users);

        // console.log(res.users[0].agent_name)
      })
      .catch(() => {
        // console.log("error receiving data");
      });
  }
  function getAgentLocationInfo() {
    axios
      .get("/getlocationlist")
      .then((res) => {
        // console.log("data received");
        setLocation(res.data.users);
        // console.log(res.data.users);
        // console.log(res.users[0].agent_name)
      })
      .catch(() => {
        // console.log("error receiving data");
      });
  }

  useEffect(() => {
    getAgentInfo();
    // getAgentLocationInfo()
  }, []);

  // let jumpto=useNavigate()
  
  // AllAgents

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Id</span>,
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Agent Name</span>,
      selector: (row) => row.agent_name,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Location</span>,
      selector: (row) => row.location,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Email</span>,
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Alternate Email 1</span>,
      selector: (row) => row.alter_email,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Phone Number</span>,
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: (
        <span className="font-weight-bold fs-13">Alternative Phone Number</span>
      ),
      selector: (row) => row.alt_phone,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Status</span>,
      selector: (row) => row.status,
      sortable: true,
    },

    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      sortable: true,

      cell: (row) => {
        return (
          <UncontrolledDropdown className="dropdown d-inline-block" direction="right">
            <DropdownToggle className="btn btn-soft-danger btn-sm" tag="button">
              <i className="ri-more-fill align-middle"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem
                href="#!"
                onClick={(e) => {
                  setUpdateId(row.id);
                  setUpdatedagentName(row.agent_name);
                  setUpdatedlocation(row.location);
                  setUpdatedemail(row.email);

                  setUpdatedaltemail(row.alter_email);
                  // setUpdatedaltemail2(row.alter_email2);
                  setUpdatedphn(row.phone);
                  setUpdatedaltPhn(row.alt_phone);
                  setUpdatedstatus(row.status);
                  // setIsOpenView(true);
                  setShow(true);
                }}
              >
                <i className="ri-eye-fill align-bottom me-2 text-muted"></i>View
              </DropdownItem>

              <DropdownItem
                className="edit-item-btn"
                onClick={(e) => {
                  setUpdateId(row.id);
                  setUpdatedagentName(row.agent_name);
                  setUpdatedlocation(row.location);
                  setUpdatedemail(row.email);

                  setUpdatedaltemail(row.alter_email);
                  setUpdatedphn(row.phone);
                  setUpdatedaltPhn(row.alt_phone);
                  setUpdatedstatus(row.status);
                  // setIsOpen(true);
                  setShowModal(true);
                }}
              >
                <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
                Edit
              </DropdownItem>

              <DropdownItem
                onClick={() => {
                  Swal.fire({
                    title: "Are you sure?",
                    text: `Your sure .. u want to delete ${row.agent_name}!`,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                    background:"rgb(33, 37, 41)"
                  }).then((result) => {
                    if (result.isConfirmed) {
                      Swal.fire({
                        title: "Deleted ",
                       
                        icon: "success",
                        background:"rgb(33, 37, 41)"

                      }
                        // "Deleted!",
                        // " data has been deleted.",
                        // "success"
                        
                      );
                      axios
                        .delete(`/deleteAgent?id=${row.id}`)
                        .then(() => {
                          // console.log("deleted");
                          getAgentInfo();
                        })
                        .catch(() => {
                          // console.log("error deleting ");
                          showToastMessageerrorDelete();
                        });
                    }
                  });
                }}
                className="remove-item-btn"
              >
                {" "}
                <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                Delete{" "}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      },
    },
  ];

  const data = reciveData;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Master" pageTitle="Agents Lists" />

          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">Agents</h4>
            </CardHeader>
            <CardBody>
              <Row className="g-4 mb-3">
                <Col className="col-sm-auto">
                  <div className="d-flex gap-1">
                    <Link to="/AllAgents">
                      {" "}
                      <Label
                        className="btn btn-outline-primary text-centre"
                        for="btn-check-outlined"
                      >
                        Add Agents
                      </Label>
                    </Link>
                  </div>
                </Col>
                {/* <Col className="col-sm">
                  <div className="d-flex justify-content-sm-end">
                    <div className="search-box ms-2">
                      <input
                        type="text"
                        className="form-control search"
                        placeholder="Search..."
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                  </div>
                </Col> */}
              </Row>

              <DataTable
                columns={columns}
                data={data}
                pagination
                theme="fulldark"
              />
            </CardBody>
          </Card>
        </Container>

        <ToastContainer />
        {show && (
          <ViewAgents
            setShow={setShow}
            show={show}
            id={id}
            agentname={updatedagentname}
            location={updatedlocation}
            email={updatedemail}
            alter_email={updatedaltemail}
            phone={updatedphn}
            alt_phone={updatedaltphn}
            status={updatedstatus}
            // setIsOpen={setIsOpen}
          />
        )}

        {showModal && (
          <UpdateAgents
            id={updateId}
            agentname={updatedagentname}
            location={updatedlocation}
            email={updatedemail}
            alter_email={updatedaltemail}
            phone={updatedphn}
            alt_phone={updatedaltphn}
            status={updatedstatus}
            setShow={setShowModal}
            show={showModal}
          />
        )}
        {/* {isOpenView && 
          <ViewAgents
            id={updateId}
            agentname={updatedagentname}
            location={updatedlocation}
            email={updatedemail}
            alter_email={updatedaltemail}
            alter_email2={updatedaltemai2}
            phone={updatedphn}
            alt_phone={updatedaltphn}
            status={updatedstatus}
            setIsOpenView={setIsOpenView}
          />
        } */}
      </div>
    </React.Fragment>
  );
};

export default AgentsLists;
