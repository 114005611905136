import React, { useEffect, useState, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UiContent from "../AddCarFleet/UIContentDummy";
import { MultiSelect } from "react-multi-select-component";
import "../AddCarFleet/CarFleetSelectType.css";

//import Components
import BreadCrumb from "../AddCarFleet/BreadcrumDummy";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import Select from "react-select";
import PreviewCardHeader from "../AddCarFleet/PreviewCardHeaderDummy";
import { Link } from "react-router-dom";
import {
  FormGrid,
  Gutters,
  VerticalForm,
  HorizontalForm,
  HorizontalFormLabelSizing,
  ColumnSizing,
  AutoSizing,
  InlineForms,
  FloatingLabels,
} from "../AddCarFleet/FormsLayoutDummy";
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import axios from '../../helpers/Axios';
// import Multiselect from 'multiselect-react-dropdown';
// import { MultiselectComponent } from '@syncfusion/ej2-react-dropdowns';

import { use } from "echarts";
// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);
//Import Flatepicker
// import "flatpickr/dist/themes/material_blue.css"
const AddCarFleet = () => {
  const [selectedFiles, setselectedFiles] = useState([]);
  const [files, setFiles] = useState([]);

  //all useStates are here
  // const [cartype, setCartype] = useState("");
  const [carbrand, setCarbrand] = useState("");
  const [carmodel, setCarmodel] = useState("");
  const [fuel, setFuel] = useState("");
  const [gear, setGear] = useState("");
  const [luggage, setLuggage] = useState("");
  const [seats, setSeats] = useState("");
  const [applanding, setApplandung] = useState("");
  const [slider, setSlider] = useState("");
  const [status, setStatus] = useState("");

  const [carfeatures, setCarfeature] = useState([1,2]);
  const [selectedMulti, setSelectedMulti] = useState("");
  const [feature, setFeature] = useState([]);
  // const[options,setOptions]=useState([feature])

  const optionData = feature;
  // const fields = { text: 'featurename', id: 'id' }
  // console.log(optionData);

  const nums = /^[0-9]+$/;
  const name = /^[A-Za-z.]+$/;

  function handelClick(e) {
    e.preventDefault();
    if (
      // cartype === "" &&
      carbrand === "" &&
      carmodel === "" &&
      carfeatures === "" &&
      fuel === "" &&
      gear === "" &&
      luggage === "" &&
      seats === "" &&
      applanding === "" &&
      slider === "" &&
      status === ""
    ) {
      showToastMessageempty();
    }
    // else if (cartype === "") {
    //   showToastselectCartype();
    // } 
    else if (carbrand === "") {
      showToastselectCarbrand();
    } else if (carmodel === "") {
      showToastselectClass();
    } else if (selected === "") {
      showToastselectCarFeatures();
    } else if (fuel === "") {
      showToastselectFueltype();
    } else if (gear === "") {
      showToastselectGearsType();
    } else if (luggage === "") {
      showToastselectLuggage();
    } else if (seats === "") {
      showToastselectSeats();
    } else if (!seats.match(nums)) {
      showToastMessageOnlyNumber();
    } else if (applanding == "") {
      showToastselectLandingImage();
    } else if (slider == "") {
      showToastselectSliderImage();
    } else if (!luggage.match(nums)) {
      showToastMessageLuggage();
    } else {
      let featureArray = []
      let feature = ''
      for (let i = 0; i < selected.length; i++) {
        featureArray.push(selected[i].label)
      }
      feature = featureArray.join(',')
      // console.log(feature);
      let payload = {
        // cartype,
        carbrand,
        carmodel,
        feature,
        fuel,
        gear,
        luggage,
        seats,
        applanding,
        slider,
        status,
      };
      axios
        .post("/CarFleetDetials", payload)
        .then(() => {
          // console.log("saved");
          setTimeout(() => {
          });
          showToastMessageAddedSuccessfully();
        })
        .catch(() => {
          // console.log("error");
        });
    }
  }
  function dummy(){
    let featureArray = []
    let feature = ''
    for (let i = 0; i < selected.length; i++) {
      featureArray.push(selected[i].label)
    }
    feature = featureArray.join(',')
    // console.log(feature);
    
      // let payload = {feature};
      // axios
      // .post("/CarFleetDetials", payload)
      
      //   showToastMessageAddedSuccessfully();
      }
  // }
  //all messages
  // enter all details
  const showToastMessageempty = () => {
    toast.error("Please Fill All Details", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "1",
    });
  };
  // luggage
  const showToastMessageLuggage = () => {
    toast.error("Only numbers Allowed for Luaggage", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "2",
    });
  };
  const showToastMessageAddedSuccessfully = () => {
    toast.success("Added Successfully", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "3",
    });
  };
  //for number error
  const showToastMessageOnlyNumber = () => {
    toast.error("Only Numbers allowed for Seats", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "4",
    });
  };
  //select landing images
  const showToastselectLandingImage = () => {
    toast.error("Select Landing Image", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "5",
    });
  };
  //select slider images
  const showToastselectSliderImage = () => {
    toast.error("Select Slider Image", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "6",
    });
  };
  const showToastselectCartype = () => {
    toast.error("Select Cartype", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "7",
    });
  };
  const showToastselectCarbrand = () => {
    toast.error("Select Carbrand", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "8",
    });
  };
  const showToastselectClass = () => {
    toast.error("Fill Model", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "9",
    });
  };
  const showToastselectCarFeatures = () => {
    toast.error("Select Car Features", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "10",
    });
  };
  const showToastselectFueltype = () => {
    toast.error("Select Fuel Type", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "11",
    });
  };
  const showToastselectGearsType = () => {
    toast.error("Select Gearts Type", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "12",
    });
  };
  const showToastselectLuggage = () => {
    toast.error("Enter luggage", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "13",
    });
  };
  const showToastselectSeats = () => {
    toast.error("Enter seats", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "14",
    });
  };

  // function to get features

  function features() {
    axios
      .get("/getCarFeature")
      .then((res) => {
        setFeature(res.data.users);
        // console.log(res.data.users);
        // console.log("got car features");

      })
      .catch(() => {
        // console.log("error");
      });
  }
  //function to get brand
  const [carbrandDeatils, setCarbrandDetails] = useState("");
  function carband() {
    axios
      .get("/getCarBrand")
      .then((res) => {
        setCarbrandDetails(res.data.users);
        // console.log(res.users);
        // console.log("got carbrand deatisl");
      })
      .catch(() => {
        // console.log("error");
      });
  }

  // UseEffect
  useEffect(() => {
    features();
    carband();
  }, []);

  // App Landing images
  const uploadImages = (e) => {
    e.preventDefault();
    const LandingImage = e.target.files[0];
    // console.log(e.target.files[0].name);
    setApplandung(e.target.files[0].name);
    setFiles(e.target.files[0].name);
    // console.log(LandingImage);
    if (LandingImage != null) {
      const data = new FormData();
      data.append("LandingImage", LandingImage);
      // console.log(data);
      axios
        .post("/uploadLandingimg", data)
        .then(() => {
          // console.log("Uploaded");
        })
        .catch(() => {
          // console.log("Error in Uploading");
        });
    }
  };

  // For  Slider Images

  const uploadSliderImages = (e) => {
    e.preventDefault();
    const Image = e.target.files;
    // setSlider(e.target.files[0].name);
    // setFiles(e.target.files[0].name);
    // console.log(Image[0]);
    let imageNameArray = []
    let AllImageNames = ''
    for (let i = 0; i < Image.length; i++) {
      imageNameArray.push("car_fleet_slider/"+Image[i].name)
    }
    AllImageNames = imageNameArray.join(',')
    setSlider(AllImageNames);
    if (Image != null) {
      for (let i = 0; i < Image.length; i++) {
        const data = new FormData();
        data.append("Images", Image[i]);
        axios
        .post("/uploadSliderimg", data)
        .then(() => {
          // console.log("Uploaded");
          })
          .catch(() => {
            // console.log("Error in Uploading");
          });
        }
      }
    };
    // console.log(slider);
    
  //multiple options
  function handleMulti(selectedMulti) {
    setSelectedMulti(selectedMulti);
  }
  const SingleOptions = [
    // { value: 'Choices 1', label: 'Choices 1' },
    // { value: 'Choices 2', label: 'Choices 2' },
    // { value: 'Choices 3', label: 'Choices 3' },
    // { value: 'Choices 4', label: 'Choices 3' },
    // { value: 'Choices 5', label: 'Choices 3' },
    // { value: 'Choices 6', label: 'Choices 4' }
  ];
  // console.log(feature);
  // console.log(carfeatures);
  const [selected, setSelected] = useState([]);
  // console.log(selected);
  // const options=feature
  const [demoms, setDemoms] = useState('')
  function mulsel(){
    for (let i = 0; i < selected.length; i++) {
      const element = selected[i];
      setDemoms(...demoms + selected[i].label)
      
    }
  }
  // const options=[  { label: "sunroof 🍇", value: "1" },
  // { label: "APS 🥭", value: "2" },
  // { label: "back 🍓", value: "3"},
  // { label: "Front 🍓", value: "4"},]

  // document.title = "Form Layouts | Velzon - React Admin & Dashboard Template";
  return (
    <React.Fragment>
      <div className="page-content">
      <Container fluid>
      <BreadCrumb title="Master" pageTitle="Car Fleet" />
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader title="Add Car Fleet" />
            <div className="card-body">
              {/* <p className="text-muted">By adding <Link to="/docs/5.1/layout/gutters/" className="text-decoration-underline">gutter modifier classes</Link>, you can have control over the gutter width in as well the inline as block direction. <span className="fw-medium">Also requires the <code>$enable-grid-classes</code> Sass variable to be enabled</span> (on by default).</p> */}
              <div className="live-preview">
                <form action="#" className="row g-3">
                  <Col md={6}>
                    <Label
                      style={{ color: "#eee4e4" }}
                      htmlFor="inputState"
                      className="form-label"
                    >
                      Car Brand
                    </Label>
                    <select
                      onChange={(e) => {
                        setCarbrand(e.target.value);
                      }}
                      // style={{ backgroundColor: "#2a2f34", color: "#eee4e4" }}
                      id="inputState"
                      className="form-select"
                      data-choices
                      data-choices-sorting="true"
                    >
                      <option >Select Any</option>
                      {carbrandDeatils ? (
                        carbrandDeatils.map((data) => {
                          return (
                            <option key={data.id}>{data.car_brand}</option>
                          );
                        })
                      ) : (
                        <h1>null</h1>
                      )}
                    </select>
                  </Col>
                  <Col md={6}>
                    <Label
                      style={{ color: "#eee4e4" }}
                      htmlFor="fullnameInput"
                      className="form-label"
                    >
                      Model
                    </Label>
                    <Input
                      // value={carclass}
                      // style={{ backgroundColor: "#2a2f34", color: "#eee4e4" }}
                      onChange={(e) => {
                        setCarmodel(e.target.value);
                      }}
                      type="text"
                      className="form-control"
                      id="fullnameInput"
                      placeholder="Enter Model"
                    />
                  </Col>

                  <Col md={6}>
                    <Label
                      style={{ color: "#eee4e4" }}
                      htmlFor="inputState"
                      className="form-label"
                    >
                      Car Features
                    </Label>
                    <MultiSelect
                      // options={options}
                      style={{ borderRadius: "10px" }}
                      className="dark"
                      options={feature.map(({ id, feature_name }) => ({
                        value: id,
                        label: feature_name,
                      }))}
                      value={selected}
                     
                      onChange={setSelected}
                      labelledBy="Select Car Features"
                      theme={{
                        Colors: {
                          selectToggleTextColor: "#053075",
                          text: "#053075",
                        },
                      }}
                    />
                  </Col>

                  <Col md={6}>
                    <Label
                      style={{ color: "#eee4e4" }}
                      htmlFor="inputState"
                      className="form-label"
                    >
                      Fuel Type
                    </Label>
                    <select
                      // value={fuel}
                      style={{ backgroundColor: "#2a2f34", color: "#eee4e4" }}
                      onChange={(e) => {
                        setFuel(e.target.value);
                      }}
                      id="inputState"
                      className="form-select"
                      data-choices
                      data-choices-sorting="true"
                    >
                      <option >Select Any</option>
                      <option value="Petrol">Petrol</option>
                      <option value="Disel">Disel</option>
                      <option value="Electric">Electric</option>
                      <option value="Hybrid">Hybrid</option>
                    </select>
                  </Col>
                  <Col md={6}>
                    <Label
                      style={{ color: "#eee4e4" }}
                      htmlFor="inputState"
                      className="form-label"
                    >
                      Gear Type
                    </Label>
                    <select
                      // value={gear}
                      style={{ backgroundColor: "#2a2f34", color: "#eee4e4" }}
                      onChange={(e) => {
                        setGear(e.target.value);
                      }}
                      id="inputState"
                      className="form-select"
                      data-choices
                      data-choices-sorting="true"
                    >
                      <option>Select Any</option>
                      <option value="manual">Manual</option>
                      <option value="Automatic">Automatic</option>
                    </select>
                  </Col>
                  <Col md={6}>
                    <Label
                      style={{ color: "#eee4e4" }}
                      htmlFor="inputEmail4"
                      className="form-label"
                    >
                      Luggage
                    </Label>
                    <Input
                      // value={luggage}
                      style={{ backgroundColor: "#2a2f34", color: "#eee4e4" }}
                      onChange={(e) => {
                        setLuggage(e.target.value);
                      }}
                      type="text"
                      className="form-control"
                      id="inputEmail4"
                      placeholder="Enter no of Luggages (bags)"
                    />
                  </Col>
                  <Col md={6}>
                    <Label
                      style={{ color: "#eee4e4" }}
                      htmlFor="inputPassword4"
                      className="form-label"
                    >
                      Seats
                    </Label>
                    <Input
                      // value={seats}
                      style={{ backgroundColor: "#2a2f34", color: "#eee4e4" }}
                      onChange={(e) => {
                        setSeats(e.target.value);
                      }}
                      type="text"
                      className="form-control"
                      id="inputPassword4"
                      placeholder="Enter Seates"
                    />
                  </Col>
                  <Row className="mt-3">
                    <Col md={6}>
                      <Label
                        style={{ color: "#eee4e4" }}
                        htmlFor="inputPassword4"
                        className="form-label"
                      >
                        App Landing Images
                      </Label>
                      <Card
                        style={{
                          backgroundColor: "#2a2f34",
                          color: "#eee4e4",
                        }}
                      >
                        <CardBody>
                          <input
                            style={{
                              backgroundColor: "#2a2f34",
                              color: "#eee4e4",
                            }}
                            className="form-control"
                            type="file"
                            onChange={uploadImages}
                          ></input>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col md={6}>
                      <Label
                        style={{ color: "#eee4e4" }}
                        htmlFor="inputPassword4"
                        className="form-label"
                      >
                        Slider Images
                      </Label>
                      <Card
                        style={{
                          backgroundColor: "#2a2f34",
                          color: "#eee4e4",
                        }}
                      >
                        <CardBody>
                          <Form enctype="multipart/form-data">
                            <input
                              style={{
                                backgroundColor: "#2a2f34",
                                color: "#eee4e4",
                              }}
                              className="form-control"
                              type="file"
                              onChange={uploadSliderImages}
                              multiple
                            />
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>

                  <Label htmlFor="employeeName" className="form-label">
                    Status
                  </Label>
                  <Col xxl={4} >
                    <select
                      id="inputState"
                      className="form-select"
                      value={status}
                      data-choices
                      data-choices-sorting="true"
                      onChange={(e) => {
                        setStatus(e.target.value);
                      }}
                    >
                      <option hidden>Select Status</option>
                      <option

                        value="Active"
                        onChange={(e) => {
                          setStatus(e.target.value);
                        }}
                      >Active</option>
                      <option
                        value="Inactive"
                        onChange={(e) => {
                          setStatus(e.target.value);
                        }}
                      >Inactive</option>

                    </select>
                  </Col>
                  <div className="text-center">
                    {/* <Input type="checkbox" className="btn-check" id="btn-check-outlined" /> */}
                    {/* <Label className="btn btn-outline-primary text-centre"  onClick={onsubmit} for="btn-check-outlined">Add</Label> */}
                    <Label
                      onClick={handelClick}
                      className="btn btn-outline-primary text-centre"
                      for="btn-check-outlined"
                    >
                      Add
                    </Label>
                    &nbsp; &nbsp; &nbsp;
                    <Link to="/car_fleet_lists">
                      <Label
                        className="btn btn-outline-danger"
                        for="danger-outlinedremove"
                      >
                        Cancel
                      </Label>
                    </Link>
                    <br></br>
                  </div>
                </form>
              </div>
              <div className="d-none code-view">
                <pre className="language-markup" style={{ height: "375px" }}>
                  <code>
                    <Gutters />
                  </code>
                </pre>
              </div>
            </div>
          </Card>
        </Col>
        <ToastContainer></ToastContainer>
      </Container>
      </div>
    </React.Fragment>
  );
};  
export default AddCarFleet;
