import React,{useState,useEffect,useNavigate} from 'react'
import Swal from 'sweetalert2/dist/sweetalert2.js';
import BreadCrumb from "../Cars/SelfBreadCrumb";
import { Link } from "react-router-dom";
import DataTable,{createTheme} from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import axios from '../../helpers/Axios'
import { api_url,aws_bucket_url } from "../../helpers/base_url";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
    Card,
    CardHeader,
    CardBody,
    Col,
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Container,
    Label,
    Row,
    Table,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    UncontrolledDropdown,
  } from "reactstrap";
import Modalviewyacht from './Modalviewyacht';
import Updatmodalyacht from '../Yatch/Updatmodalyacht'

const YachtList = () => {

  const [id, setId] = useState("");
  const [updateyatchname, setUpdateyatchname] = useState("");
  const [updatecapacity, setUpdatecapacity] = useState("");
  const [updateprice, setUpdateprice] = useState("");
  const [updatesailing, setUpdatesailing] = useState("");
  // const [updatedaltemai2, setUpdatedaltemail2] = useState("");
  const [updateanchorage, setUpdateanchorage] = useState("");
  const [updatetime, setUpdatetime] = useState("");
  const [updateyachtimage, setUpdateyachtImage] = useState("");
  const[updatedescription,setUpdatedescription]=useState("")

  const [showModal, setShowModal] = useState(false)
  const[show,setShow]=useState(false)
  // const [modalShow, setModalShow] = React.useState(false);

  const[receivedata,setReceivedata]=useState([])

  createTheme('fulldark',{
    text:{
        primary: 'white',
        secondary:'white'
    },
    background:{
        default:'#212529'
    },
    context:{
        text:'white'
    },
    button:{
        default:'white',
        focus:'white',
        hover:'white',
        disabled:'white'
    }
})

function gettingYachtDetails(){
  axios
      .get(`/getyachtdeatils`)
      .then((res) => {
        // console.log(" Yacht deatils Received ");
        // console.log(revdata)
        setReceivedata(res.data.users);
        // console.log(res.data.users);
       

        // console.log(res.users[0].agent_name)
      })
      .catch(() => {
        // console.log("error receiving data");
      });
}

  useEffect(() => {
    gettingYachtDetails()
  }, [showModal]);

  const[tableData,SetTableData]=useState([])

  const [location, setLocation] = useState([]);

  let statuslist = ["Active", "Inactive"];
  // for status button
  const [search, setSearch] = useState("");

  const [newstatus, setNewstatus] = useState("");

  function tog_list() {
    setmodal_list(!modal_list);
  }

  const [modal_list, setmodal_list] = useState(false);
  function tog_list() {
    setmodal_list(!modal_list);
  }

  
  const showToastMessageSuccessUpdate = () => {
    toast.success("Updated Successfully !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  // error update
  const showToastMessageErrorUpdate = () => {
    toast.error("Error Updating !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  // sucessfull deleting
  const showToastMessageSuccessfulDelete = () => {
    toast.success("Deleted Sucessfully !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  // Reterive deleting
  const showToastMessageerrorDelete = () => {
    toast.success("Reterived Delete", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  




  // let jumpto=useNavigate()


  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Id</span>,
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: <p>Yacht Image </p>,
      cell: (row) => {
        return (
          <div>
            {/* <img
              width="140px"
              height="120px"
              // src={require(`../../CarImages/YachtImages/${row.yacht_image}`)}
              src={aws_bucket_url + '/' + `${row.yacht_image}`}
              alt = 'car'
            ></img> */}
            {<Carousel autoPlay infiniteLoop showThumbs={false} showArrows={false} swipeable={true} stopOnHover={true} showStatus={false}>
                      {
                        row.yacht_image.split(',').map((image) => {
                          return (
                            <div key={image}>
                              <img src={`${aws_bucket_url}/${image}`} alt="Car" />
                            </div>
                          )
                        })
                      }
            </Carousel>}
          </div>

        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Name</span>,
      selector: (row) => row.yacht_name,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Description</span>,
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">capacity</span>,
      selector: (row) => row.capacity,
      sortable: true,
    },
    
    {
      name: <span className="font-weight-bold fs-13">No of Hours</span>,
      selector: (row) => row.sailing + " " + "Hours Sailing " + row.anchorage + " " + " +  Hours Anchoring ",
      sortable: true,
    },

    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      sortable: true,

      cell: (row) => {
        return (
          <UncontrolledDropdown className="dropdown d-inline-block" direction="right">
            <DropdownToggle className="btn btn-soft-danger btn-sm" tag="button">
              <i className="ri-more-fill align-middle"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem
                href="#!"
                
                onClick={(e) => {
                 setId(row.id)
                 setUpdateyatchname(row.yacht_name)
                 setUpdatecapacity(row.capacity)
                 setUpdatedescription(row.description)
                 setUpdateprice(row.price)
                 setUpdatesailing(row.sailing)
                 setUpdateanchorage(row.anchorage)
                 setUpdatetime(row.time)
                 setUpdateyachtImage(row.yacht_image)
                  // setIsOpenView(true);
                  setShow(true);
              
                }}
              >
                <i className="ri-eye-fill align-bottom me-2 text-muted"></i>View
              </DropdownItem>
             
                <DropdownItem
                  className="edit-item-btn"
                  onClick={(e) => {
                    setId(row.id)
                    setUpdateyatchname(row.yacht_name)
                    setUpdatecapacity(row.capacity)
                    setUpdatedescription(row.description)
                    setUpdateprice(row.price)
                    setUpdatesailing(row.sailing)
                    setUpdateanchorage(row.anchorage)
                    setUpdatetime(row.time)
                    setUpdateyachtImage(row.yacht_image)
                    // setIsOpen(true);
                    setShowModal(true)
                  }}
                >
                  <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
                  Edit
                </DropdownItem>
              
              <DropdownItem
               
                onClick={() => {
                  Swal.fire({
                    title: "Are you sure?",
                    text: `Your sure .. u want to delete ${row.yacht_name}!`,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                    
                    background:"rgb(33, 37, 41)"
                    
                  }).then((result) => {
                    if (result.isConfirmed) {
                      Swal.fire({
                        title: "Deleted ",
                       
                        icon: "success",
                        background:"rgb(33, 37, 41)"

                      }
                        // "Deleted!",
                        // " data has been deleted.",
                        // "success"
                        
                      );
                      axios
                        .delete(
                          `/deleteyacht?id=${row.id}`
                        )
                        .then(() => {
                          // console.log("deleted");
                          gettingYachtDetails()
                        })
                        .catch(() => {
                          // console.log("error deleting ");
                          showToastMessageerrorDelete();
                        });
                    }
                  });
                }}
                className="remove-item-btn"
              >
                {" "}
                <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                Delete{" "}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      },
    },
  ];

  const data = receivedata;

  return (
    
      <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <BreadCrumb title="Master" pageTitle="Agents Lists" />

          <Card   >
            <CardHeader>
              <h4 className="card-title mb-0">Yacht</h4>
            </CardHeader>
            <CardBody>
              <Row className="g-4 mb-3">
                <Col className="col-sm-auto">
                  <div className="d-flex gap-1">
                    <Link to="/addyacht">
                      {" "}
                      <Label
                        className="btn btn-outline-primary text-centre"
                        for="btn-check-outlined"
                      >
                        Add Yacht
                      </Label>
                    </Link>
                  </div>
                </Col>
              
              </Row>

              <DataTable   columns={columns} data={data} pagination theme='fulldark' />
            </CardBody>
          </Card>
        </Container>
       
       
        <ToastContainer />
        {show && (
          <Modalviewyacht
          setShow={setShow}
          receivedata={receivedata}
          show={show}
          id={id}
          yachtname={updateyatchname}
          capacity={updatecapacity}
          price={updateprice}
          sailing={updatesailing}
          anchorage={updateanchorage}
          time={updatetime}
          yachtimage={updateyachtimage}
          description={updatedescription}
            // setIsOpen={setIsOpen}
            
          />
          
        )}

          {showModal && (
          <Updatmodalyacht
          setShow={setShowModal}
          receivedata={receivedata}
          show={showModal}
          id={id}
          yachtname={updateyatchname}
          capacity={updatecapacity}
          price={updateprice}
          sailing={updatesailing}
          anchorage={updateanchorage}
          time={updatetime}
          yachtimage={updateyachtimage}
          description={updatedescription}
            // setIsOpen={setIsOpen}
            
          />
          
        )}
       
       
      </div>
    </React.Fragment>
  
  )
}

export default YachtList
