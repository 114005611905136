import React from "react";
import { Navigate } from "react-router-dom";
import AddCarFleet from "../pages/AddCarFleet/AddCarFleet";
import DisplayCarFeatures from "../pages/CarFeatures/DisplayCarFeatures";
import AddCarDetails from "../pages/CarFeatures/AddCarDetails";
import AgentsLists from "../pages/Agents/AgentsLists";
// Airport
import Airport from "../pages/Airport_Pickup/Airport";
// Enquires
import Enquires from "../pages/OthersFiles/Enquires/Enquires";
//Subscription
import Subscription from "../pages/OthersFiles/Subscriptions/Subscription";

// Bookings
import Car from "../pages/Bookings/Car";
import Yatch from "../pages/Bookings/YachtBooking";
import BookingDispaly from "../pages/Bookings";
import Bookings from "../pages/Bookings/Bookings";

// Locations
import Location from "../pages/Location_Blck/Location";
import LocationList from "../pages/Location_Blck/LocationList";

//Crm Pages
// Car Brand
import AddCarBrand from "../pages/CarBrand/AddCarBrand";
import DisplayBrandIcon from "../pages/CarBrand/DisplayBrandIcon";

//Icon pages
import RemixIcons from "../pages/Icons/RemixIcons/RemixIcons";

// Coupon
import Coupon from "../pages/Coupon/Coupon";

//login
import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

//Agents
import Agents from "../pages/Agents/Agents";
import UpdateAgents from "../pages/Agents/UpdateAgents";

//Location
// import UpdateLocation from "../pages/Location_Blck/UpdateLocation";

//Surge
import Surge from "../pages/Surge/Surge";
import SurgeData from "../pages/Surge/SurgeData";

// Coupons
import CouponList from "../pages/Coupon/CouponList";
import Feedback from "../pages/Feedbacks/Feedback";

// Driver
import DriverList from "../pages/DriverPage/DriverList";
import Driver from "../pages/DriverPage/Driver";
import Dashboard from "../pages/Dashboard_Blck/Dashboard";
import Customers_List from "../pages/Customers/Customers_List";
import Customers from "../pages/Customers/Customers";
import Cars from "../pages/Cars/Cars";
import DisplayCarfleet from "../pages/AddCarFleet/DisplayCarfleet";

//Yatch 

import YatchList from "../pages/Yatch/YachtList";
// import YatchBooking from '../pages/Bookings/YachtBooking'
import Addyacht from "../pages/Yatch/Addyacht";
import CarsDisplay from "../pages/Cars/CarsDisplay";
import GeneralEnquires from "../pages/Enquiry/General_Enquiry/GeneralEnquires";
import YachtEnquires from "../pages/Enquiry/Yacht_Enquiry/YachtEnquires";
import JetsEnquires from "../pages/Enquiry/Jets_Enquiry/JetsEnquires";
import Viewers from "../pages/Viewers/Viewers";
  

const authProtectedRoutes = [
  { path: "/Car_Features", component: <DisplayCarFeatures /> },
  { path: "/backtodisplaypage", component: <DisplayCarFeatures /> },
  { path: "/Addcardetails", component: <AddCarDetails /> },
  { path: "/Agents_Lists", component: <AgentsLists /> },
  { path: "/AllAgents", component: <Agents /> },
  { path: "/dashboard", component: <Dashboard /> },
  // edit
  { path: "/Car_Features", component: <UpdateAgents /> },
  { path: "/Car_Fleet", component: <AddCarFleet /> },
  { path: "/car_fleet_lists", component: <DisplayCarfleet /> },
  { path: "/Cars", component: <Cars /> },
  { path: "/cars_list", component: <CarsDisplay /> },
  //Chats
  { path: "/airport", component: <Airport /> },
  { path: "/Enquires", component: <Enquires /> },
  { path: "/subscriptions", component: <Subscription /> },
  { path: "/feedback", component: <Feedback /> },
  //For location
  { path: "/Location_list", component: <LocationList /> },
  { path: "/location", component: <Location /> },
  // CarBrand Route
  { path: "/addCarbrand", component: <AddCarBrand /> },
  { path: "/Carbrand_list", component: <DisplayBrandIcon /> },
  // Driver
  { path: "/driver_list", component: <DriverList /> },
  { path: "/driver", component: <Driver /> },
  // Coupon path

  { path: "/coupon", component: <Coupon /> },
  { path: "/coupon_list", component: <CouponList /> },

  // Customers
  { path: "/customers_list", component: <Customers_List /> },
  {path: "/customers", component: <Customers/>},
  // Surge
  { path: "/surge", component: <Surge /> },
  { path: "/surge_data", component: <SurgeData /> },
  { path: "/viewers", component: <Viewers /> },
  //Icons
  { path: "/icons-remix", component: <RemixIcons /> },

  // Bookings (Yacht and Cars)
  { path: "/bookcars", component: <Car /> },
  { path: "/bookings", component: <Bookings /> },
 

  // {path:"/yachtbooking", component:<YatchBooking/>},

  //Yacht
  { path: "/Yachtlist", component: <YatchList /> },
  { path: "/addyacht", component: <Addyacht /> },

  // Enquires
  { path: "/general_enquires", component: <GeneralEnquires /> },
  { path: "/yacht_enquires", component: <YachtEnquires /> },
  { path: "/jet_enquires", component: <JetsEnquires /> },


  {
    path: "/",
    exact: true,
    component: <Navigate to="/login" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },

  //AuthenticationInner pages
];

export { authProtectedRoutes, publicRoutes };
