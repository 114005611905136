import React, { useEffect, useState } from "react";
// import styles from "./Carfeaturesmodal.module.css";
import axios from "../../helpers/Axios";
// import { ListGroup, ListGroupItem, ModalBody, ModalFooter,  ModalHeader } from 'reactstrap';
import { Link } from "react-router-dom";
import {
  FormGrid,
  Gutters,
  VerticalForm,
  HorizontalForm,
  HorizontalFormLabelSizing,
  ColumnSizing,
  AutoSizing,
  InlineForms,
  FloatingLabels,
} from "../AddCarFleet/FormsLayoutDummy";
import PreviewCardHeader from "../AddCarFleet/PreviewCardHeaderDummy";
import Flatpickr from "react-flatpickr";
import { ToastContainer, toast } from "react-toastify";
import { color } from "echarts";

// import React, { useState } from 'react';
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import {
  Card,
  CardBody,
  CardHeader,
  Input,
  Label,
  Col,
  Collapse,
  Container,
  Row,
  DropdownItem,
  ModalBody,
  ModalFooter,
  ModalHeader,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  CardFooter,
} from "reactstrap";
// import ImageForView from "./ImageForView";
function Updatmodalyacht({
  setShow,
  show,
  id,
  yachtname,
  capacity,
  price,
  anchorage,
  sailing,
  time,
  yachtimage,
  description,
}) {
  //   const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [updateId, setUpdateId] = useState(id);
  const [updatedyachtname, setUpdatedyachtname] = useState(yachtname);
  const [updatedcapacity, setUpdatedcapacity] = useState(capacity);
  const [updatedprice, setUpdatedprice] = useState(price);
  const [updatedsailing, setUpdatedsailing] = useState(sailing);
  const [updatedanchorage, setUpdatedanchorage] = useState(anchorage);
  const [updatedtime, setUpdatedtime] = useState(time);
  const [updatedyachtimage, setUpdatedyachtimage] = useState(yachtimage);
  const [updateddescription, setUpdateddescription] = useState(description);
  //   const [updatedstatus, setUpdatedStatus] = useState(status);

  // let jump=useNavigate()

  const [revdata, setRevdata] = useState("");

  const a = () => {
    toast.success("Updated Successfully !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const errormessage = () => {
    toast.error("No Changes Detected !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  // for refereshing

  function togetallfeatureIcons() {}
  useEffect(() => {
    togetallfeatureIcons();
  }, []);

  // for uploaing image
  const yachtimagesUpload = (e) => {
    e.preventDefault();
    const Image = e.target.files;
    // console.log(e.target.files.name);
    let imageNameArray = []
      let AllImageNames = ''
      for (let i = 0; i < Image.length; i++) {
        imageNameArray.push("yacht/"+Image[i].name)
      }
      AllImageNames = imageNameArray.join(',')
      setUpdatedyachtimage(AllImageNames);

    // setFiles(e.target.files[0].name)
    // console.log(Image);
    if (Image != null) {
      for (let i = 0; i < Image.length; i++) {
      const data = new FormData();
      data.append("Image", Image[i]);
      // console.log(data);
      axios
        .post("/uploadyatchimage", data)
        .then(() => {
          // console.log("Uploaded");
        })
        .catch(() => {
          // console.log("Error in Uploading");
        });
      }
    }
  };
  //   console.log(updatedfeatureicon);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{yachtname}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div>
              <CardHeader>
                <div className="text-center mt-10">
                  <h5 style={{ color: "#eee4e4" }}>Edit Information</h5>
                </div>
              </CardHeader>
              <div className="p-2 mt-0 justify-content-center">
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                  action="#"
                >
                  <Row>
                    <Col lg={6}>
                      <div className="mb-2">
                        <Label
                          style={{ color: "#eee4e4" }}
                          htmlFor="catname"
                          className="form-label"
                        >
                          Yacht Name <span className="text-danger">*</span>
                        </Label>
                        <Input
                          style={{
                            backgroundColor: "#2a2f34",
                            color: "#eee4e4",
                          }}
                          id="catname"
                          name="catname"
                          className="form-control"
                          placeholder="Edit name"
                          type="text"
                          defaultValue={yachtname}
                          onChange={(e) => {
                            setUpdatedyachtname(e.target.value);
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-2">
                        <Label
                          style={{ color: "#eee4e4" }}
                          htmlFor="catprice"
                          className="form-label"
                        >
                          Yacht Image <span className="text-danger">*</span>
                        </Label>

                        {/* <Input
                                                            name="price"
                                                            type="text"
                                                            placeholder="Enter new price"
                                                            defaultValue={files}
                                                            onChange={(e) => { setUpdatedfeatureicon(e.target.value) }}
                                                        /> */}
                        <Input
                          style={{
                            backgroundColor: "#2a2f34",
                            color: "#eee4e4",
                          }}
                          className="form-control"
                          id="formFile"
                          placeholder="additonal input field "
                          type="file"
                          name="file"
                          //   defaultValue={files[0].name}
                          onChange={yachtimagesUpload}
                          multiple
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div className="mb-2">
                        <Label
                          style={{ color: "#eee4e4" }}
                          htmlFor="firstname"
                          className="form-label"
                        >
                          Description <span className="text-danger">*</span>
                        </Label>
                        <Input
                          style={{
                            backgroundColor: "#2a2f34",
                            color: "#eee4e4",
                          }}
                          name="firstname"
                          type="text"
                          placeholder="Enter username"
                          defaultValue={description}
                          onChange={(e) => {
                            setUpdateddescription(e.target.value);
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-2">
                        <Label
                          style={{ color: "#eee4e4" }}
                          htmlFor="firstname"
                          className="form-label"
                        >
                          Capacity <span className="text-danger">*</span>
                        </Label>
                        <Input
                          style={{
                            backgroundColor: "#2a2f34",
                            color: "#eee4e4",
                          }}
                          name="firstname"
                          type="text"
                          placeholder="Enter username"
                          defaultValue={capacity}
                          onChange={(e) => {
                            setUpdatedcapacity(e.target.value);
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div className="mb-2">
                        <Label
                          style={{ color: "#eee4e4" }}
                          htmlFor="firstname"
                          className="form-label"
                        >
                          Price <span className="text-danger">*</span>
                        </Label>
                        <Input
                          style={{
                            backgroundColor: "#2a2f34",
                            color: "#eee4e4",
                          }}
                          name="firstname"
                          type="text"
                          placeholder="Enter username"
                          defaultValue={price}
                          onChange={(e) => {
                            setUpdatedprice(e.target.value);
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-2">
                        <Label
                          style={{ color: "#eee4e4" }}
                          htmlFor="firstname"
                          className="form-label"
                        >
                          Time <span className="text-danger">*</span>
                        </Label>
                        <Input
                          style={{
                            backgroundColor: "#2a2f34",
                            color: "#eee4e4",
                          }}
                          name="firstname"
                          type="text"
                          placeholder="Enter username"
                          defaultValue={time}
                          onChange={(e) => {
                            setUpdatedtime(e.target.value);
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div className="mb-2">
                        <Label
                          style={{ color: "#eee4e4" }}
                          htmlFor="firstname"
                          className="form-label"
                        >
                          No of Hours <span className="text-danger">*</span>
                        </Label>
                        <Input
                        className="mb-3"
                          style={{
                            backgroundColor: "#2a2f34",
                            color: "#eee4e4",
                          }}
                          name="firstname"
                          type="text"
                          placeholder="For Sailing"
                          defaultValue={sailing}
                          onChange={(e) => {
                            setUpdatedsailing(e.target.value);
                          }}
                        />
                        <Input
                          style={{
                            backgroundColor: "#2a2f34",
                            color: "#eee4e4",
                          }}
                          name="firstname"
                          type="text"
                          placeholder="For Anchoring"
                          defaultValue={anchorage}
                          onChange={(e) => {
                            setUpdatedanchorage(e.target.value);
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"

            onClick={() => {
              let payload = {
                // id:updateId,
                id:id,
                yachtname:updatedyachtname,
                description:updateddescription,
                yachtimage:updatedyachtimage,
                price:updatedprice,
                capacity:updatedcapacity,
                anchorage:updatedanchorage,
                sailing:updatedsailing,
                time:updatedtime,
              };
              axios
                .put(
                  "/updateyatch",
                  payload
                )
                .then(() => {
                  // console.log("reached");

                  setTimeout(() => {
                    a();
                  }, 200);
                  // setIsOpen(false)
                })

                .catch(() => {
                  setTimeout(() => {
                    errormessage();
                    // console.log("Error")
                  }, 10);
                  // setIsOpen(false)
                });
            }}
          >
            Update
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

// render(<ModalView />);
export default Updatmodalyacht;
