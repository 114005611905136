const DashboardData=[
    {
        data:[
            '33','78','13','56','89','13','98','23','45','23','45'
        ],
        name:"bookings",
        type:'area'
    },
    {
        data:[
            '90','20','94','62','52','60','70','50','45','75','15'
        ],
        name:"subscription",
        type:'bar'
    },
    {
        data:[
            '12','20','30','43','45','67','79','80','98','100','120'
        ],
        name:"customers",
        type:'line'
    }
]

export default DashboardData;