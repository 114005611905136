import React, { useEffect,useState } from 'react'
import { Card, CardBody, CardHeader, Input, Label, Col,Form, Collapse, Container, Row, Button, DropdownItem,Modal, ModalBody, ModalFooter,  ModalHeader, DropdownToggle,DropdownMenu,UncontrolledDropdown, CardFooter } from 'reactstrap';
import axios from 'axios'
import UiContent from './UiContent'
import BreadCrumb from './SelfBreadCrumb'
import DataTable,{createTheme} from 'react-data-table-component';

const Enquires = () => {

  const [revdata,setRevdata]=useState("")

  function getData(e){
    // e.preventDefault()
    axios.get("/getContactUsData")
    .then((res)=>{
    //   console.log(res.users)
      setRevdata(res.users)
    //   console.log("data Fetched")
    })
    .catch(()=>{
    //   console.log("error fetching data")
    })
  }
  useEffect(()=>{
    getData()
  },[])
  createTheme('fulldark',{
    text:{
        primary: 'white',
        secondary:'white'
    },
    background:{
        default:'#212529'
    },
    context:{
        text:'white'
    },
    button:{
        default:'white',
        focus:'white',
        hover:'white',
        disabled:'white'
    }
})

  // tabel
  const columns = [

    {
        name:
            <p>Id </p>,
        selector: (row) => row.id,
    },
   
    {
        name:
            <p>First Name </p>,
        selector: (row) => row.firstname,
    },
    
    
    {
        name:
            <p>Email </p>,
        selector: (row) => row.email,
    },
    {
        name:
            <p>Phone Number</p>,
        selector: (row) => row.phnno,
    },
    {
      name:
          <p>City</p>,
      selector: (row) => row.city,
  },
  {
    name:
        <p>Message</p>,
    selector: (row) => row.message,
      },
    // {
    //     name: <span className="font-weight-bold fs-13">Action</span>,
    //     sortable: true,
  
    //     cell: (row) => {
    //       return (
    //         <UncontrolledDropdown className="dropdown d-inline-block">
    //           <DropdownToggle className="btn btn-soft-danger btn-sm" tag="button">
    //             <i className="ri-more-fill align-middle"></i>
    //           </DropdownToggle>
    //           <DropdownMenu className="dropdown-menu-end">
    //             <DropdownItem
    //               href="#!"
    //               onClick={(e) => {
    //                 setId(row.id);
    //                     setUpdatefeaturename(row.featurename);
    //                     setUpdatedescription(row.description);
    //                     setUpdatefeatureicons(row.files);
    //                     setUpdatestatus(row.status);
    //                     setIsopenview(true);
                       
                    
                    
                       
    //               }}
    //             //   onClick={() =>
                    
    //             // } 
                
    //             >
    //               <i className="ri-eye-fill align-bottom me-2 text-muted"></i>View
    //             </DropdownItem>
               
    //               <DropdownItem
    //                 className="edit-item-btn"
    //                 onClick={(e) => {
    //                     setId(row.id);
    //                     setUpdatefeaturename(row.featurename);
    //                     setUpdatedescription(row.description);
    //                     setUpdatefeatureicons(row.files);
    //                     setUpdatestatus(row.status);
    //                     setIsOpen(true);
                        
                       
                        
    //                   }}
    //               >
    //                 <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
    //                 Edit
    //               </DropdownItem>
                
    //             <DropdownItem
    //               onClick={() => {
    //                 Swal.fire({
    //                   title: "Are you sure?",
    //                   text: `Your sure .. u want to delete ${row.featurename}!`,
    //                   icon: "warning",
    //                   showCancelButton: true,
    //                   confirmButtonColor: "#3085d6",
    //                   cancelButtonColor: "#d33",
    //                   confirmButtonText: "Yes, delete it!",
    //                 }).then((result) => {
    //                   if (result.isConfirmed) {
    //                     Swal.fire(
    //                       "Deleted!",
    //                       " data has been deleted.",
    //                       "success"
    //                     );
    //                     axios
    //                       .delete(
    //                         `http://13.126.174.149/deleteCarFeatures?id=${row.id}`
    //                       )
    //                       .then(() => {
    //                         console.log("deleted");
    //                         togetallfeatureIcons()
    //                       })
    //                       .catch(() => {
    //                         console.log("error deleting ");
    //                       });
    //                   }
    //                 });
    //               }}
    //               className="remove-item-btn"
    //             >
    //               {" "}
    //               <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
    //               Delete{" "}
    //             </DropdownItem>
    //           </DropdownMenu>
    //       </UncontrolledDropdown>
    //       );
    //     },
    //   },  
]
  return (
    <React.Fragment>
    <UiContent />
    <div className="page-content" style={{backgroundColor:"#212529"}} >
        <Container fluid>
            <BreadCrumb title="MASTER" pageTitle="Car Features" />
            <Row>
                <Col    lg={12}>
                    <Card style={{backgroundColor:"#2a2f34"}}  >
                        <CardHeader>
                           
                        </CardHeader>
                        <CardBody>
                            <Row className="g-4 mb-3">
                                
                                {/* // serach button  */}
                                <Col className="col-sm">
                                    {/* <div className="d-flex justify-content-sm-end">
                                        <div className="search-box ms-6">
                                            <input type="text" onChange={(e) => { setSearch(e.target.value) }} className="form-control search" placeholder="Search..." />
                                            <i className="ri-search-line search-icon"></i>
                                        </div>
                                    </div> */}
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-12">
                                    <Row>
                                    </Row>
                                </Col>
                            </Row>
                            <DataTable columns={columns} data={revdata} pagination  theme='fulldark' />
                        </CardBody>
                    </Card>
                </Col>
            
            </Row>
            </Container>
            </div>
            </React.Fragment>
  )
}

export default Enquires
