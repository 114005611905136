// new code
import React, { useState, useEffect } from "react";
import axios from "../../helpers/Axios";
import BreadCrumb from "../Cars/SelfBreadCrumb";
import Swal from "sweetalert2";
import DataTable,{createTheme} from "react-data-table-component";
// import { DropdownItem, DropdownMenu, DropdownToggle, Input,UncontrolledDropdown } from 'reactstrap';
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ViewDriver from "./ViewDriver";
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Container,
  Label,
  Row,
  Table,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  UncontrolledDropdown,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import UpdateDriver from "./UpdateDriver";
import { api_url,aws_bucket_url } from "../../helpers/base_url";


const DriverList = () => {
  // // for edit
  const [updateId, setUpdateId] = useState("");
  const [updatedriverName, setUpdatedriverName] = useState("");
  const [updatedriverImage, setUpdatedriverImage] = useState("");
  const [updatelocation, setUpdatelocation] = useState("");
  const [updateEmail, setUpdateEmail] = useState("");
  const [updatecontactno, setUpdatecontactNo] = useState("");
  const [updateAltercontactno, setUpdateAltercontactno] = useState("");
  const [updatedrivinglicno, setUpdatedrivinglicno] = useState("");
  const [updateAadharno, setUpdateAadharno] = useState("");
  const [updatePancardno, setUpdatePancardno] = useState("");
  const [updateDob, setUpdateDob] =useState("");
  const [updatePin, setUpdatePin] = useState("");
  const [updateStatus, setUpdateStatus] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const [show, setShow] =useState(false)
  const [showModal, setShowModal] = useState(false)

  //           // const[tableData,SetTableData]=useState([])
  const [reciveData, setReceiveData] = useState([]);

  const [location, setLocation] = useState([]);

  createTheme('fulldark',{
    text:{
        primary: 'white',
        secondary:'white'
    },
    background:{
        default:'#212529'
    },
    context:{
        text:'white'
    },
    button:{
        default:'white',
        focus:'white',
        hover:'white',
        disabled:'white'
    }
})


  // for status button
  const [search, setSearch] = useState("");

  const [newstatus, setNewstatus] = useState("");




  // sucessfull update
  const showToastMessageSuccessUpdate = () => {
    toast.success("Updated Successfully !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  // error update
  const showToastMessageErrorUpdate = () => {
    toast.error("Error Updating !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  // sucessfull deleting
  const showToastMessageSuccessfulDelete = () => {
    toast.success("Deleted Sucessfully !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  // Reterive deleting
  const showToastMessageerrorDelete = () => {
    toast.success("Reterived Delete", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  function getDriversInfo() {
    axios
      .get(`/driver_details`)
      .then((res) => {
        // console.log(" drivers info data received");
        setReceiveData(res.data.users);
        // console.log(res.data.users);
        // console.log(res.users[0].agent_name)
      })
      .catch(() => {
        // console.log("error receiving data");
      });
  }
  // function getDriverLocationInfo() {
  //   axios
  //     .get("http://13.126.174.149/getLocationList")
  //     .then((res) => {
  //       console.log("data received");
  //       setLocation(res.users);
  //       console.log(res.users);
  //       // console.log(res.users[0].agent_name)
  //     })
  //     .catch(() => {
  //       console.log("error receiving data");
  //     });
  // }

  useEffect(() => {
    getDriversInfo();
    // getDriverLocationInfo();
  }, [showModal]);

  // let jumpto=useNavigate()

  // AllAgents

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Id</span>,
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Drivers Name</span>,
      selector: (row) => row.driver_name,
      sortable: true,
    },
    {
      name: <p>Driver Images</p>,
      cell: (row) => {
        return (
          <div>
            <img
              width="100px"
              height="100px"
              // src={require(`../../CarImages/DriverImages/${row.driver_img}`)}
              src={aws_bucket_url + '/' + `${row.driver_img}`}
            ></img>
          </div>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Location</span>,
      selector: (row) => row.driver_loc,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Email</span>,
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Contact Number</span>,
      selector: (row) => row.contact,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Alternative Contact</span>,
      selector: (row) => row.alter_contact,
      sortable: true,
    },
    {
      name: (
        <span className="font-weight-bold fs-13">Driving Licines Number</span>
      ),
      selector: (row) => row.driving_licence_no,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Aadhar number</span>,
      selector: (row) => row.aadhar_no,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">pan card Number</span>,
      selector: (row) => row.pancard_no,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">DOB</span>,
      selector: (row) => row.date_of_birth,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Pin</span>,
      selector: (row) => row.driver_pin,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Status</span>,
      selector: (row) => row.status,
      sortable: true,
      
      // cell: (row) => {
      //   return (
      //     <Col lg={6}>
      //       <select
      //         defaultValue={data.status}
      //         className="form-select rounded-pill mb-u8"
      //         aria-label="Default select example"
      //         onChange={(e) => {
      //           setNewstatus(false);
      //           let newstatus = e.target.value;
      //           let id = row.id;
      //           let payload = { newstatus, id };
      //           axios
      //             .post("http://13.126.174.149/updateDriversStatus", payload)
      //             .then(() => {
      //               setNewstatus(true);
      //               console.log("status changed");
      //               showToastMessageSuccessUpdate();
      //             })
      //             .catch(() => {
      //               console.log("error updating");
      //               showToastMessageErrorUpdate();
      //             });
      //         }}
      //       >
      //         {statuslist.map((data) => {
      //           return (
      //             <option name="statuslist" key={data[0]}>
      //               {data}
      //             </option>
      //           );
      //         })}
      //       </select>
      //     </Col>
      //   );
      // },
    },

    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      sortable: true,

      cell: (row) => {
        return (
          <UncontrolledDropdown className="dropdown d-inline-block" direction="right">
            <DropdownToggle className="btn btn-soft-danger btn-sm" tag="button">
              <i className="ri-more-fill align-middle"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem
                onClick={(e)=>{
                  setUpdateId(row.id);
                  setUpdatedriverName(row.driver_name);
                  setUpdatedriverImage(row.driver_img)
                  setUpdatelocation(row.driver_loc);
                  setUpdateEmail(row.email);
                  setUpdatecontactNo(row.contact);
                  setUpdateAltercontactno(row.alter_contact)
                  setUpdatedrivinglicno(row.driving_licence_no)
                  setUpdateAadharno(row.aadhar_no);
                  setUpdatePancardno(row.pancard_no);
                  setUpdateDob(row.date_of_birth)
                  setUpdatePin(row.driver_pin)
                  setUpdateStatus(row.status)
                  // setIsOpen(true);
                  setShow(true)
                }}

                href="#!"
                // onClick={() => tog_list()}
                id="create-btn"

              >
                <i className="ri-eye-fill align-bottom me-2 text-muted"></i>View
              </DropdownItem>
                <DropdownItem
                  className="edit-item-btn"
                  onClick={(e) => {
                    setUpdateId(row.id);
                    setUpdatedriverName(row.driver_name);
                    setUpdatedriverImage(row.driver_img)
                    setUpdatelocation(row.driver_loc);
                    setUpdateEmail(row.email);
                    setUpdatecontactNo(row.contact);
                    setUpdateAltercontactno(row.alter_contact)
                    setUpdatedrivinglicno(row.driving_licence_no)
                    setUpdateAadharno(row.aadhar_no);
                    setUpdatePancardno(row.pancard_no);
                    setUpdateDob(row.date_of_birth)
                    setUpdatePin(row.driver_pin)
                    setUpdateStatus(row.status)
                    // setIsOpen(true);
                    setShowModal(true)
                  }}
                >
                  <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
                  Edit
                </DropdownItem>
              <DropdownItem
                onClick={() => {
                  Swal.fire({
                    title: "Are you sure?",
                    text: `Your sure .. u want to delete ${row.driver_name}!`,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                    background:"rgb(33, 37, 41)"
                  }).then((result) => {
                    if (result.isConfirmed) {
                      Swal.fire({
                        title: "Deleted ",
                       
                        icon: "success",
                        background:"rgb(33, 37, 41)"

                      }
                        // "Deleted!",
                        // " data has been deleted.",
                        // "success"
                        
                      );
                      axios
                        .delete(
                          `/driver_details?id=${row.id}`
                        )
                        .then(() => {
                          // console.log("deleted");
                          //   getAgentInfo();
                          getDriversInfo();
                        })
                        .catch(() => {
                          // console.log("error deleting ");
                          showToastMessageerrorDelete();
                        });
                    }
                  });
                }}
                className="remove-item-btn"
              >
                {" "}
                <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                Delete{" "}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      },
    },
  ];

  const data = reciveData;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Cars" pageTitle="Drivers List" />

          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">Drivers</h4>
            </CardHeader>
            <CardBody>
              <Row className="g-4 mb-3">
                <Col className="col-sm-auto">
                  <div className="d-flex gap-1">
                    <Link to="/driver">
                      {" "}
                      <Label
                        className="btn btn-outline-primary text-centre"
                        for="btn-check-outlined"
                      >
                        Add Driver
                      </Label>
                    </Link>
                  </div>
                </Col>
                {/* <Col className="col-sm">
                  <div className="d-flex justify-content-sm-end">
                    <div className="search-box ms-2">
                      <input
                        type="text"
                        className="form-control search"
                        placeholder="Search..."
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                  </div>
                </Col> */}
              </Row>

              <DataTable columns={columns} data={data} pagination  theme='fulldark' />
            </CardBody>
          </Card>
        </Container>

        {/* <Modal
          isOpen={modal_list}
          toggle={() => {
            tog_list();
          }}
          centered
        >
          <ModalHeader
            className="bg-light p-3"
            id="exampleModalLabel"
            toggle={() => {
              tog_list();
            }}
          >
            {" "}
            Drivers Information
          </ModalHeader>
          <form className="tablelist-form">
            <ModalBody>
              <Col key={data.id} sm={12}>
                <Card className="border card-border-primary">
                  <CardBody></CardBody>
                  <p4 className="flex-grow-1 ms-3">Drivers name : </p4>
                  <p className="flex-grow-1 ms-3">location : </p>
                  <p className="flex-grow-1 ms-3">email : </p>
                  <p className="flex-grow-1 ms-3">contact number : </p>
                  <p className="flex-grow-1 ms-3">Driving Licines number : </p>
                  <p className="flex-grow-1 ms-3">Aadhar number: </p>
                  <p className="flex-grow-1 ms-3">pan card email: </p>

                  <div className="card-footer">
                    <div className="text-center">
                      <Label
                        className="btn btn-danger"
                        onClick={() => setmodal_list(false)}
                      >
                        close
                      </Label>
                    </div>
                  </div>
                </Card>
              </Col>
            </ModalBody>
            <ModalFooter>
              <div className="hstack gap-2 justify-content-end">
                <button type="button" className="btn btn-light" >Close</button>
                            <button type="submit" className="btn btn-success" id="add-btn">Add Customer</button>
                <button type="button" className="btn btn-success" id="edit-btn">Update</button>
              </div>
            </ModalFooter>
          </form>
        </Modal> */}
        <ToastContainer />
        {showModal &&
        <UpdateDriver 
        id ={updateId}
        driver_name ={updatedriverName} 
        driver_img ={updatedriverImage}
        driver_loc = {updatelocation}
        email = {updateEmail}
        contact = {updatecontactno}
        alter_contact = {updateAltercontactno}
        driving_licence_no = {updatedrivinglicno}
        aadhar_no = {updateAadharno}
        pancard_no = {updatePancardno}
        date_of_birth = {updateDob}
        driver_pin = {updatePin}
        status = {updateStatus}
        setShow={setShowModal}
        show = {showModal}
        />}
        {
          show && <ViewDriver id ={updateId}
          driver_name ={updatedriverName} 
          driver_img ={updatedriverImage}
          driver_loc = {updatelocation}
          email = {updateEmail}
          contact = {updatecontactno}
          alter_contact = {updateAltercontactno}
          driving_licence_no = {updatedrivinglicno}
          aadhar_no = {updateAadharno}
          pancard_no = {updatePancardno}
          date_of_birth = {updateDob}
          driver_pin = {updatePin}
          status = {updateStatus}
          setShow={setShow}
          show ={show}/>
        }
      </div>
    </React.Fragment>
  );
};

export default DriverList;
