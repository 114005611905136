import React, { useState, useEffect } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Form,
} from "reactstrap";

import axios from "../../helpers/Axios";
import PreviewCardHeader from "../CarFeatures/PreviewCardReaderDemo";
import { Link } from "react-router-dom";
import BreadCrumb from "../AddCarFleet/BreadcrumDummy";
// import { VerticalForm, HorizontalForm } from "./FormlayoutsCode";
// Import React FilePond
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
const AddCarBrand = () => {
  const [carbrand, setCarbrand] = useState("");
  const [status, setStatus] = useState("");
  const [uploadinglogo, setUploadinglogo] = useState("");

  const uploadIcon = (e) => {
    e.preventDefault();
    const Image = e.target.files[0];
    // console.log(e.target.files[0].name);
    setUploadinglogo(e.target.files[0].name);
    // setFiles(e.target.files[0].name)
    if (Image != null) {
      const data = new FormData();
      data.append("Image", Image);
      axios
        .post("/uploadlogo", data)
        .then(() => {
          // setTimeout(()=>{
          //   setUploadinglogo("")
          // },1500)
          // console.log("Uploaded icon");
        })
        .catch(() => {
          // console.log("Error in Uploading logo");
        });
    }
  };

  const numberformat = /^[0-9.]+$/;
  const nameformat = /^[A-Za-z_." "]+$/;

  function handleSubmit(e) {
    e.preventDefault();
    if (
      carbrand === "" &&
      // cartype === "" &&
      uploadinglogo === "" &&
      status === ""
    ) {
      messageForEmpty();
    } else if (carbrand === "") {
      messageforEmptybrandName();
    } else if (!carbrand.match(nameformat)) {
      showToastBrandNameNotValid();
    }
    else if (uploadinglogo === "") {
      messageforEmptyBrandlogo();
    } else if (status === "") {
      messageforEmptystatus();
    } else {
      const payload = { carbrand, uploadinglogo, status };
      axios
        .post(`/carsbrand`, payload)
        .then(() => {
          showToastMessage();
          setTimeout(() => {
            setCarbrand("");
            setUploadinglogo("");
            // setCartype("");
            setStatus("");
          }, 1500);
          // console.log("Data saved successfully");
        })
        .catch(() => {
          // console.log("Error");
        });
    }
  }

  const messageForEmpty = () => {
    toast.error("Please fill all details ...!", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "0",
    });
  };
  const messageforEmptybrandName = () => {
    toast.error("Please fill brand name ...!", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "1",
      // limit:1
    });
  };
  const messageforEmptyCartype = () => {
    toast.error("Please Select Car type ...!", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "2",
    });
  };
  const messageforEmptyBrandlogo = () => {
    toast.error("Please upload brand logo ...!", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "3",
    });
  };
  const messageforEmptystatus = () => {
    toast.error("Please select status ...!", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "4",
    });
  };

  const showToastBrandNameNotValid = () => {
    toast.error("Brand Name not valid  !", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "5",
    });
  };

  const showToastMessage = () => {
    toast.success("Car Brand Added Sucessfully !", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "6",
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Master" pageTitle="Car Brand" />
          <Row>
            <Col xxl={12}>
              <Card>
                <PreviewCardHeader
                  className="text-center"
                  title="Add Car Brand"
                />
                <CardBody>
                  <div className="live-preview">
                    <Form action="/uploadicon" encType="multipart/form-data">
                      <div className="mb-3">
                        <Label htmlFor="employeeName" className="form-label">
                          Brand Name
                        </Label>
                        <Input
                          type="text"
                          value={carbrand}
                          className="form-control"
                          id="employeeName"
                          placeholder="Enter Brand Name"
                          onChange={(e) => {
                            setCarbrand(e.target.value);
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <p>Brand Logo</p>
                        <Input
                          type="file"
                          className="form-control"
                          id="carfile"
                          placeholder="upload icon"
                          onChange={uploadIcon}
                        />
                      </div>

                      <Col xl={4}>
                        <Label htmlFor="employeeName" className="form-label">
                          Status
                        </Label>

                        <select
                          id="inputState"
                          className="form-select"
                          value={status}
                          data-choices
                          data-choices-sorting="true"
                          onChange={(e) => {
                            setStatus(e.target.value);
                          }}
                        >
                          <option hidden>Select Status</option>
                          <option

                            value="Active"
                            onChange={(e) => {
                              setStatus(e.target.value);
                            }}
                          >Active</option>
                          <option
                            value="Inactive"
                            onChange={(e) => {
                              setStatus(e.target.value);
                            }}
                          >Inactive</option>

                        </select>
                      </Col>

                      <div className="text-center mt-3" >
                        <Label
                          className="btn btn-outline-primary text-centre"
                          for="btn-check-outlined"
                          onClick={handleSubmit}
                        >
                          Add
                        </Label>
                        &nbsp; &nbsp; &nbsp;
                        <Link to="/Carbrand_list">
                          <Label
                            className="btn btn-outline-danger"
                            for="danger-outlinedremove"
                          >
                            Cancel
                          </Label>
                        </Link>
                      </div>
                      {/* </Col> */}
                    </Form>
                  </div>
                  <div className="d-none code-view">
                    <pre
                      className="language-markup"
                      style={{ height: "375px" }}
                    >
                      <code>{/* <VerticalForm /> */}</code>
                    </pre>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <ToastContainer />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default AddCarBrand;
