
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../helpers/Axios";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import emailjs from 'emailjs-com';

import { TimePicker } from 'react-ios-time-picker';
// import 'react-ios-time-picker/dist/index.css';
// import { DesktopDatePicker } from '@mui/x-date-pickers-pro/DesktopDatePicker';
import s from './Bookings.module.css'
import {
  Card,
  CardBody,
  CardHeader,
  Input,
  Label,
  Col,
  Collapse,
  Container,
  Row,
  DropdownItem,
  ModalBody,
  ModalFooter,
  ModalHeader,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  CardFooter,
} from "reactstrap";
import { json } from "react-router-dom";
function BookingsAgent({
  setShow,
  show,
  carname,
  bookingid,
  phonee,
  cartype,
  selectedVendorName,
  receiveid,
  roww,
  id,
  agentname,
  location,
  email,
  status,
  alter_email,
  // alter_email2,
  phone,
  alt_phone,
}) {
  //   const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const useCurrentCallback = (callback) => {
    const reference = React.useRef();
    reference.current = callback;
    return (...args) => {
      return reference.current?.(...args);
    };
  };
  const now = new Date();
  // console.log('Current date: ' + now.toDateString());
  // console.log('Current time: ' + now.toTimeString()); 
  const time = now.toLocaleTimeString();
  const date = now.toLocaleDateString();

  // const now = new Date();

  const [reciveData, setReceiveData] = useState([]);
  // const [driverdetails, setDriverdetails]= useState([]);
  const [chauffeurname, setChauffeurname] = useState("")
  const [vehiclenumber, setVehiclenumber] = useState("")
  const [contact, setContact] = useState("")
  const [alternativeno, setAlternativeno] = useState("")
  const [reportingdate, setReportingdate] = useState(new Date());
  const [reportingtime, setReportingtime] = useState(now);
  const [cartypee, setCartype] = useState("")
  const [bookinid, setBookingid] = useState("")
  const [car, setCar] = useState("")

  const [updateBooking_id, setupdateBooking_id] = useState(false)
  const [updateBooking_summary, setupdateBooking_summary] = useState(false)
  const [updatepayment_status, setupdateupdatepayment_status] = useState(false)
  const [updatedrazor_orderId,setupdatedrazor_orderId] = useState(false)
  const [updaterazor_paymentId, setupdaterazor_paymentId] = useState(false)
  const [updaterazor_sign, setupdaterazor_sign] = useState(false)
  const [updateuser_details, setupdateuser_details] = useState(false)
  const [updateduser_id, setupdateduser_id] = useState(false)
  const [updateId, setUpdateId] = useState(false);
  const [agentdetails, setAgentDetails] = useState('')
  const [putdata, setputdata] = useState({
    Address:'',Agent:'',Email:'',First_Name:'',Last_Name:'',location:'',price:'',phone:''
  });
  const [email_n,setEmail_n]= useState('')
  // setReportingdate(date)
  // setReportingtime(time)

  const showToastMessageUploadedSuccess = () => {
    toast.success("Updated Successfully !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const showToastMessageErroruploading = () => {
    toast.error("Error Updating !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  
  useEffect(()=>{


    const parsee=JSON.parse(receiveid.user_details)
    // console.log(selectedVendorName[receiveid.booking_id],'SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS')
    const nameagent=selectedVendorName[receiveid.booking_id]
    setupdateBooking_id(receiveid.booking_id)
    setupdateBooking_summary(receiveid.booking_summary)
    setupdatedrazor_orderId(receiveid.razor_orderId)
    setupdateduser_id(receiveid.user_id)
    setupdaterazor_paymentId(receiveid.razor_paymentId)
    setupdaterazor_sign(receiveid.razor_sign)
    setupdateupdatepayment_status(receiveid.payment_status)
    setupdateuser_details({...updateuser_details,Address:parsee.Address,Phone:parsee.Phone,Agent:nameagent,Email:parsee.Email,First_Name:parsee.First_Name,Last_Name:parsee.Last_Name,price:parsee.price,location:parsee.location})
    setUpdateId(receiveid.id)
    axios
    .get("/getAgentInfo")
    .then((res) => {
      setAgentDetails(res.data.users);
      // console.log(res.data.users);
      console.log("received agent Info ");
      res.data.users.forEach((vendor) => {
        // console.log(vendor.agent_name,'MMMMMMMMMMMMMMMMMMMMMMMMMMMMM')
        // console.log(nameagent,'EEEEEEEEEEEEEEEEEEEEEEEE')
        const name = vendor.agent_name;
        if (name === nameagent) {
          // alert('HHHHHHHHHHHHiiiiiiiii')
          setEmail_n(vendor.email); // Call the sendSMS function passing the phone number and vendor name
        }
      });
    })
  },[])
  
  //   function a(){
  //   axios
  //   .get("/getAgentInfo")
  //   .then((res) => {
  //     setAgentDetails(res.data.users);
  //     console.log(res.data.users);
  //     console.log("received agent Info ");
  //     res.data.users.forEach((vendor) => {
  //       console.log(vendor.agent_name,'MMMMMMMMMMMMMMMMMMMMMMMMMMMMM')
  //       console.log(updateuser_details.Agent,'EEEEEEEEEEEEEEEEEEEEEEEE')
  //       const name = vendor.agent_name;
  //       if (name === updateuser_details.Agent) {
  //         // alert('HHHHHHHHHHHHiiiiiiiii')
  //         setEmail_n(vendor.email); // Call the sendSMS function passing the phone number and vendor name
  //       }
  //     });
  //   })
  // }
 
function updaateagent(){
  const jsonString = JSON.stringify(updateuser_details);
// console.log("jsonstring!!!!!!!!!!!!!111",jsonString)
    let payload = {
      id: updateId,
      booking_id:updateBooking_id,
      booking_summary: updateBooking_summary,
     
      payment_status: updatepayment_status,
      razor_orderId: updatedrazor_orderId,
      razor_paymentId:updaterazor_paymentId,
      razor_sign: updaterazor_sign,
      user_details: jsonString,
      user_id: updateduser_id,
    };
    // console.log(payload,'%%%%%%%%%%%%$$$$$$$$$$$$$$$$$$$$$$$^^^^^^^^^^^^^^^')
    axios
      .put("/payment/success", payload)
      .then((res) => {
        // console.log(res,"agent updated")
      })
      .catch((err) => {
        // console.log("Error")
      });
    
      // console.log(email,'^^^^^^&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&')
      // console.log(email_n,'RRRRRRRRRRRRRRRRRRRRRRRRRRRRRRR')
      const booking_details = JSON.parse(updateBooking_summary)
      const data = {
        user_email: email_n,
        Vendorname: updateuser_details.Agent,
        Customer_number: updateuser_details.Phone,
        Customer_Name: updateuser_details.First_Name +' '+ updateuser_details.Last_Name,
        Type: booking_details.Package,
        Location: updateuser_details.location,
        Car_Model: booking_details.name,
        Booking_Date: booking_details.date,
        Booking_Time: booking_details.time,
        booking_id:updateBooking_id,
      }
      // console.log(data,'###############################%%%%%%%%%%%%%%%%%%')
      const service_id = "service_s5tk4mq";
      const template_id = "template_dxm7et6";
      const user_id = "nPl0g9QOyS4S6H9Fy";
      emailjs.send(
        service_id,
        template_id,
        data,
        user_id
      )

      toast.success('Email send...')


      
}


  function driversend() {

alert(carname)
    // axios.get(`https://api.mylogin.co.in/api/v2/SendSMS?ApiKey=8NzlHH40L/AH3MR83XpYmT5d1EQVofsbB+Vx1jxqUcY=&ClientId=e649ffb9-407e-49ec-8155-8eba78e30620&SenderId=BLKLUX&Message=Greetings from BLCK! Please find your chauffeur and vehicle details below, Chauffeur name:${chauffeurname} Contact number:${contact} Alternate number:${contact} Vehicle number: ${vehiclenumber} Reporting Time:${reportingtime} cheers Team BLCK&MobileNumbers=${phonee}`)
    // toast.success('Sms send...')

  }
  // console.log(selectedVendorName[receiveid.booking_id], '@@@@@@@@@@@@@@!!!!!!!!!!!@@@@@@@@')
  // console.log(contact, '@@@@@@@@@@@@@@@@@@@@@@')
  // console.log(vehiclenumber, '@@@@@@@@@@@@@@@@@@@@@@')
  // console.log(alternativeno, '@@@@@@@@@@@@@@@@@@@@@@')
  // console.log(reportingdate, '@@@@@@@@@@@@@@@@@@@@@@')
  // console.log(reportingtime, '@@@@@@@@@@@@@@@@@@@@@@')
  // console.log(carname,'#########################')
  // console.log(bookingid,'#########################')
  // console.log(phonee,'######################')
  // console.log(cartype,"#######################")
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Greetings from BLCK!{selectedVendorName[receiveid.booking_id]}
          </Modal.Title>
        </Modal.Header>
       
        <Modal.Footer>
          <Button
            variant="success"
            onClick={updaateagent}
          //   let payload = {
          //     agentname: updatedagentname,
          //     id: id,
          //     location: updatedlocation,
          //     email: updatedemail,
          //     alter_email: updatedaltemail,
          //     // alter_email2: updatedaltemai2,
          //     phone: updatedphn,
          //     status: updatedstatus,
          //     alt_phone: updatedaltphn,
          //   };
          //   axios
          //     .put("/updateAgentslist", payload)
          //     .then((res) => {
          //       // setIsOpen(false)
          //       showToastMessageUploadedSuccess();
          //     })
          //     .catch((err) => {
          //       // setIsOpen(false)
          //       showToastMessageErroruploading();
          //     });
          // }}
          >
            Send
          </Button>

          {/*  <Button variant="secondary" onClick={handleClose}>
            Close
                              </Button>*/}
        </Modal.Footer>
      </Modal>
    </>
  );
}

// render(<ModalView />);
export default BookingsAgent;
