import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import {
  Card,
  CardBody,
  CardHeader,
  Input,
  Label,
  Col,
  Collapse,
  Container,
  Row,
  Button,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  CardFooter,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import DataTable, { createTheme } from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import s from './Bookings.module.css'
import axios from "../../helpers/Axios";
import { api_url, aws_bucket_url } from "../../helpers/base_url";
// import axiosvender from "axios";
import BookingsModal from "./BookingsModal";
import BookingsAgent from "./BookingsAgent";
import ViewAgentModal from '../Bookings/ViewBooking'

const Bookings = (gettingBrandicon) => {
  // for map methods

  const [recive, setRecive] = useState([]);

  const [show, setShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [updateId, setUpdateId] = useState("");
  const [updateicon, setUpdateicon] = useState("");
  const [updateBrandname, setUpdateBrandname] = useState("");
  const [updateCartype, setUpdateCartype] = useState("");
  const [updatedstatus, setUpdatedstatus] = useState("");
  const [modalShow, setModalShow] = React.useState(false);
  const [showModal, setShowModal] = useState(false)
  const [showAgent, setShowAgent] = useState(false)

  const [viewModal,setviewModal]=useState(false)
  const [update_booking_id, setupdate_booking_id] = useState("");
  const [Update_userDetails, setUpdate_userDetails] = useState("");
  const [Updated_userDetailsphone, setUpdated_userDetailsphone] = useState("");
  const [Updated_bookingSummaryname, setUpdated_bookingSummaryname] = useState("");
  const [Updated_userDetails_location, setUpdated_userDetails_location] = useState("");
  const [Updated_bookingSummarytime, setUpdated_bookingSummarytime] = useState("");
  const [Updated_bookingSummarydate, setUpdated_bookingSummarydate] = useState("");
  const [Updated_bookingSummaryprice, setUpdated_bookingSummaryprice] = useState("");
  const [updated_vendorname, setupdated_vendorname] = useState("");

  const [transactionsDetails, setTransactionsDetails] = useState([])
  const [agentDetails, setAgentDetails] = useState([])
  const [isOpenViewcar, setIsOpenViewcar] = useState(false);
  const [carname, setCarname] = useState({});
  const [bookingid, setBookingid] = useState({});
  const [search, setSearch] = useState("");
  const [phonee, setPhone] = useState('');
  const [cartype, setCartype] = useState('');
  const [selectedVendorPhone, setSelectedVendorPhone] = useState("");
  const [selectedVendorName, setSelectedVendorName] = useState("")
  const [selectedAgent, setSelectedAgent] = useState('');
  const [receiveid, setreceiveid] = useState('');
  const [rowdy, setrowdy] = useState('');
  const [selectedVendorForRow, setSelectedVendorForRow] = useState({});

  let jump = useNavigate("");

  function handelClick() {
    jump("/bookcars");
  }

  // const handleAgentChange = (event) => {
  //   setSelectedAgent(event.target.value);
  //     };

  function gettingtransactiondetails() {
    axios
      .get("/payment/success")
      .then((res) => {
        setRecive(res.data.users);
        // console.log(res.data.users);
        // console.log("received brand Icons ");
      })
      .catch(() => {
        // console.log("error receiveing brand icons deatils ");
      });
  }

  useEffect(() => {
    gettingtransactiondetails();
  }, [modalShow]);
  // console.log(recive, '%%%%%%%%%%%%%%%%%%%%%')
  // useEffect(()=>{
  //   const gettingBrandicon = async () =>{
  //     setLoading(true);
  //     const res = await axios.get("/carsbrand");
  //     setRecive(res.users);
  //     setLoading(false);
  //   }
  //   gettingBrandicon();
  // },[]);

  // console.log(recive)

  // sucessfull delete

  function GettingVenderDetails() {
    axios
      .get("/getAgentInfo")
      .then((res) => {
        setAgentDetails(res.data.users);
        // console.log(res.data.users);
        // console.log("received agent Info ");
        res.data.users.forEach((vendor) => {
          const phone = vendor.phone;
          const name = vendor.agent_name;
          if (phone === selectedVendorPhone) {
            sendSMS(phone, name); // Call the sendSMS function passing the phone number and vendor name
          }
        });
      })
      .catch(() => {
        // console.log("error receiveing agent Info ");
      });
  }
  const sendSMS = (phone, name) => {
    // Check if an agent is selecte
    if (phone && name === selectedVendorPhone) {
      axios
        .get(`https://api.mylogin.co.in/api/v2/SendSMS?ApiKey=8NzlHH40L/AH3MR83XpYmT5d1EQVofsbB+Vx1jxqUcY=&ClientId=e649ffb9-407e-49ec-8155-8eba78e30620&SenderId=BLKLUX&Message=Customer OTP verfication for Blck luxury!, Your otp is 1234 Happy driving with Blck Luxury!.&MobileNumbers=${phone}`)
        .then((response) => {
          // console.log("SMS sent successfully!");
        })
        .catch((error) => {
          // console.log("Failed to send SMS:", error);
        });
    }
  };

  // const handleVendorSelect = (event) => {
  //   const name = event.target.value;
  //   setSelectedVendorName(name);
  //   // Get the phone number of the selected vendor
  //   const vendor = agentDetails.find((vendor) => vendor.agent_name === name);
  //   if (vendor) {
  //     setSelectedVendorPhone(vendor.phone);
  //   } else {
  //     setSelectedVendorPhone("");
  //   }
  // };
  useEffect(() => {
    GettingVenderDetails();
    // gettingTransctionData();
  }, []);
  const showToastMessageDeleteSuccess = () => {
    toast.success("Deleted Successfully !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  //Cancelation message
  const showToastMessageRetervied = () => {
    toast.success("Delete Retervied !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const handelDriverClick = (bookingId, carName, Phonee, Cartype) => {
    setCarname(carName);
    setBookingid(bookingId);
    setPhone(Phonee);
    setCartype(Cartype)
    setShowModal(true)

  }
  const handelAgent = (bookingId, carName, Phonee, Cartype, agentname, payment_status) => {
    setreceiveid(payment_status);
    setSelectedVendorName(agentname);
    setCarname(carName);
    setBookingid(bookingId);
    setPhone(Phonee);
    setCartype(Cartype)
    setShowAgent(true)

  }
  // const gear=()=>{
  //   // setShow(true);
  //                 setviewModal(true);
  // }
  createTheme("fulldark", {
    text: {
      primary: "white",
      secondary: "white",
    },
    background: {
      default: "#212529",
    },
    context: {
      text: "white",
    },
    button: {
      default: "white",
      focus: "white",
      hover: "white",
      disabled: "white",
    },
  });

  // const data =
  // {
  //   "booking_id": "918500137795",
  //   "booking_summary": "{\"name\":\"BMW 520 D\",\"date\":\"5-18-2023\",\"time\":\"16:38:30\",\"allowance\":1155,\"additionalKms\":2,\"additionalHrs\":2,\"price\":15245,\"deposit\":50000,\"img\":\"car_fleet/car_fleet/car_fleet/Mark5_bmw_520d_1.webp\"}",
  //   "payment_status": "777",
  //   "razor_orderId": "order_Lr2WgjFWIDDvMj",
  //   "razor_paymentId": "pay_Lr2X0Pl8uP8iOp",
  //   "razor_sign": "b9fed1084d0193c70174abd62a3b002e9f064353cb58e837615a716db2720fb9",
  //   "user_details": "{\"First Name\":\"Lanked\",\"Last Name\":\"Satishd\",\"Phone\":\"+918500137795\",\"Email\":\"satishlandke31@gmail.com\",\"Address\":\"Dno 7-11-0d34,d 2nd Cross road  old Rajeev nagar\"}",
  //   "user_id": "918500137795"
  // }
  //  const bookingId = recive[0].booking_Id;
  //   const bookingSummary = JSON.parse(data.booking_summary);
  // const userDetailcs = JSON.parse(recive[0].user_details);
  //   const userId = JSON.parse(data.user_id)
  //   const bookingStatus = JSON.parse(data.payment_status)
  // console.log('ssssssssssssssssssss',recive[0].user_details)
  //   const extractedData = [
  //     {
  //       name: 'ggggg',
  //       firstName: userDetailcs['First Name'],
  //       lastName: userDetailcs['Last Name'],
  //       number: userDetailcs['Phone'],
  //       booking_id: 'kjhfksjdf',
  //       razor_orderId: 'sdfsdfsf',
  //       date: '3/03/2024',
  //       time: '09:50',
  //     },
  //   ];
  // const agentDetails=''
  // console.log(selectedAgent, 'kkkkkkkkkkkkkkkk')
  const columns = [
    {
      name: <p>Id </p>,
      selector: (row) => row.id,
    },
    {
      name: <p>Booking </p>,
      // selector: (row) => row.booking_id,
      cell: (row) => {
        return (
          <div className={s.division}>
            <div className={s.booking_division}>
              <div className={s.globe_icon}>
                <i className="ri-globe-line"></i>
              </div>
              <div className={s.bookingid_type}>
                <h4>{row.booking_id}</h4>
                <p>standard</p>
              </div>
            </div>
          </div>
        );
      },
      sortable: true,
    },
    {
      name: <p>Customer</p>,
      // selector: (row) => row.customer,
      selector: 'firstName',
      sortable: true,
      cell: (row) => {
        const userDetails = JSON.parse(row.user_details);
        setrowdy(row)
        // console.log(userDetails, 'yyyyyyyyyyyyyy')
        return (
          <div className={s.customers_division}>
            <h3>{userDetails["First_Name"]}&nbsp;{userDetails["Last_Name"]}</h3>
            <p>{userDetails["Phone"]}</p>
          </div>
        );
      },

    },

    {
      name: <p>Details </p>,
      // selector: (row) => row.car_name,
      cell: (row) => {
        const bookingSummary = JSON.parse(row.booking_summary);
        const userDetails = JSON.parse(row.user_details);
        const value = row

        return (
          <div className={s.details_division}>
            <h3>{bookingSummary["name"]}</h3>
            <p>{userDetails["location"]}</p>
          </div>
        );
      },
    },

    {
      name: <p>Date </p>,
      // selector: (row) => row.date,
      cell: (row) => {
        const bookingSummary = JSON.parse(row.booking_summary);
        return (
          <div className={s.date_time}>
            <p>{bookingSummary["date"]}</p>
            <p>{bookingSummary["time"]}</p>
          </div>

        );
      },
    },
    {
      name: <p>Amount </p>,
      // selector: (row) => row.amount,
      cell: (row) => {
        const userDetails = JSON.parse(row.user_details);
        const a = row.payment_status
        // console.log(a, '88888888888888888888888888')
        return (
          // <div className={s.amount}>
          //   <p>₹ {bookingSummary["price"]}</p>
          // </div>
          <div className={s.amount}>
            <p>₹  {userDetails["price"]}</p>
          </div>
        );
      },
    },
    {
      name: <p>Type </p>,
      // selector: (row) => row.type,
      cell: (row) => {
        return (
          <div className={s.type}>
            <p>User</p>
          </div>
        );
      },
    },
    {
      name: <p>GST and Company&emsp;&emsp;&nbsp;</p>,
      // selector: (row) => row.type,
      cell: (row) => {
        const userDetails = JSON.parse(row.user_details);
        return (
          <div className={s.type}>
            <p>{userDetails["GSTno"]}&emsp;{userDetails["GST_company_name"]}</p>
          </div>
        );
      },
    },


    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      sortable: true,

      cell: (row) => {
        const bookingSummary = JSON.parse(row.booking_summary);
        const userDetails = JSON.parse(row.user_details);
        return (
          <UncontrolledDropdown className="dropdown d-inline-block">
            <DropdownToggle className="btn btn-soft-danger btn-sm" tag="button">
              <i className="ri-more-fill align-middle"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem
                href="#!"
                onClick={() => {
                  setUpdateId(row.id);
                  setupdate_booking_id(row.booking_id);
                  setUpdate_userDetails(userDetails["First_Name"]);
                  setUpdated_userDetailsphone(userDetails["Phone"]);
                  setUpdated_bookingSummaryname(bookingSummary["name"]);
                  setUpdated_userDetails_location(userDetails["location"]);
                  if(bookingSummary["endDate"]){
                    setUpdated_bookingSummarytime(bookingSummary["startTime"]+" in "+bookingSummary["startDate"]);
                    setUpdated_bookingSummarydate(bookingSummary["endDate"]+" in "+bookingSummary["endTime"]);
                  }else{
                    setUpdated_bookingSummarytime(bookingSummary["startTime"]);
                    setUpdated_bookingSummarydate(bookingSummary["startDate"]);
                  }
                  setUpdated_bookingSummaryprice(userDetails["price"]);
                  setupdated_vendorname(userDetails["Agent"])
                  setShow(true);
                  setviewModal(true);
                }}
              >
                <i className="ri-eye-fill align-bottom me-2 text-muted"></i>View
              </DropdownItem>

             

         
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      },
    },
  ];
// console.log(selectedVendorForRow,'000000000000000000000000000000')
  const conditionalRowStyles = [
    {
      when: row => row.id,
      style: {
        backgroundColor: 'green',
        color: 'white',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
    // You can also pass a callback to style for additional customization
    {
      when: row => row.id,
      style: row => ({ backgroundColor: row.isSpecial ? 'pink' : 'inerit' }),
    },
  ];
  const customStyles = {
    rows: {
      style: {
        minHeight: '72px', // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
      },
    },
  };
  const Content = ({ booking_summary, booking_id, user_details, payment_status }) => {
    const [bookingSummary, setBookingSummary] = useState({});
    const [userDetails, setUserDetails] = useState({});
    useEffect(() => {

      if (booking_summary) {
        try {
          const parsedSummary = JSON.parse(booking_summary);
          setBookingSummary(parsedSummary);
        } catch (error) {
          // console.log('Error parsing booking_summary:', error);
        }
      }
    }, [booking_summary]);
    useEffect(() => {
      if (user_details) {
        try {
          const parsedUserDetails = JSON.parse(user_details);
          setUserDetails(parsedUserDetails);
        } catch (error) {
          // console.log('Error parsing user_details:', error);
        }
      }
    }, [user_details]);

    const handleSendDriverDetails = () => {
      const { name } = bookingSummary;
      const phone = userDetails?.Phone;
      const cartype = userDetails?.Car_Type;
      handelDriverClick(booking_id, name, phone, cartype);

    };
    const handleAgentDetails = (bookingId, selectedAgentName) => {
      setSelectedVendorForRow((prevState) => ({
        ...prevState,
        [bookingId]: selectedAgentName,
      }));
      const agentname = selectedAgent
      const { name } = bookingSummary;
      const phone = userDetails?.Phone;
      const cartype = userDetails?.Car_Type;

      handelAgent(booking_id, name, phone, cartype, agentname, payment_status);

    };
    
    // console.log(userDetails, '########################$$$$$$$$$$$$$$$$')
    // console.log(selectedVendorForRow, '########################$$$$$$$$$$$$$$$$')

    // createTheme("fulldark", {
    //   text: {
    //     primary: "white",
    //     secondary: "white",
    //   },
    //   background: {
    //     default: "#000000",
    //   },
    //   context: {
    //     text: "white",
    //   },
    //   button: {
    //     default: "white",
    //     focus: "white",
    //     hover: "white",
    //     disabled: "white",
    //   },
    //   // Adding border to the theme
    //   components: {
    //     MuiPaper: {
    //       styleOverrides: {
    //         root: {
    //           border: '1px solid gray',
    //         },
    //       },
    //     },
    //   },
    // });
    // const columns = [
    //   // {
    //   //   name: <p>Id </p>,
    //   //   selector: (row) => row.id,
    //   // },
    //   {
    //     name: <p>Booking </p>,
    //     // selector: (row) => row.booking_id,
    //     cell: (row) => {
    //       return (
    //         <div className={s.division}>
    //           <div className={s.booking_division}>
    //             <div className={s.globe_icon}>
    //               <i className="ri-globe-line"></i>
    //             </div>
    //             <div className={s.bookingid_type}>

    //               <p>Booking Created</p>
    //               <p>{selectedAgent}</p>
    //             </div>
    //           </div>
    //         </div>
    //       );
    //     },
    //     sortable: true,
    //   },]
    // return (
    //   <div>
    //   <div  >
    //     <DataTable
    //     columns={columns}
    //     data={selectedAgent}
    //     theme="fulldark"
    //   />
    //  </div>
    //   </div>
    // )




    return (
      <div className={s.booking_btn_details}>
        <div className={s.booking_created}>
          <p>Booking Created</p>
          {bookingSummary && (
            <span>
              {bookingSummary.date} {bookingSummary.time}
            </span>
          )}
        </div>
        {userDetails["Agent"] ?

          <select
            value={selectedVendorForRow[booking_id] || ""}
            onChange={(event) => handleAgentDetails(booking_id, event.target.value)}
            style={{ backgroundColor: 'green', color: 'white' }}
          >
            <option hidden> Vendor Assigned({userDetails["Agent"]})</option>
            {agentDetails.map((elem) => (
              <option key={elem} value={elem.agent_name}>
                {elem.agent_name}
              </option>
            ))}
          </select>

          : <select
            value={selectedVendorForRow[booking_id] || ""}
            onChange={(event) => handleAgentDetails(booking_id, event.target.value)}
          >
            <option hidden>Assign Vendor</option>
            {agentDetails.map((elem) => (
              <option key={elem} value={elem.agent_name}>
                {elem.agent_name}
              </option>
            ))}
          </select>}

        <button className={s.driver_details} onClick={handleSendDriverDetails}>
          Send Driver Details
        </button>
      </div>
    );


  };



  // console.log(rowdy, '))))))))))))))))))))))))))))))))))))))))))')

  // } 
  const sortedData = recive.slice().sort((a, b) => {
    const timestampA = new Date(a.id);
    const timestampB = new Date(b.id);
    console.log(timestampA)
    return timestampB - timestampA;
  });
console.log(sortedData,'ssssssssssssssssssssssssssssssssss')
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Bookings" pageTitle="Car Bookings" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0  text-centre">Car Bookings</h4>
                </CardHeader>
                <CardBody>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <Label
                        className="btn btn-outline-primary"
                        // onClick={handelClick}
                        
                        for="btn-check-outlined"
                      >
                        <li><a href='https://blckluxury.in/admin'>Booking</a></li>
                      </Label>
                    </Col>
                    <Col className="col-sm">
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-12">
                      <Row></Row>
                    </Col>
                  </Row>
                  <DataTable
                    columns={columns}
                    data={sortedData}
                    pagination
                    theme="fulldark"
                    expandableRows
                    // expandableRowsComponent={ExpandedComponent}
                    conditionalRowStyles={conditionalRowStyles}
                    customStyles={customStyles}
                    expandableRowsComponent={({ data }) => <Content payment_status={data} booking_summary={data.booking_summary} booking_id={data.booking_id} user_details={data.user_details}
                      phonee={phonee} cartype={cartype} setCarname={setCarname} setBookingid={setBookingid} handelDriverClick={handelDriverClick} selectedVendorName={selectedVendorForRow} receiveid={receiveid} />}
                    expandableRowDisabled={row => !row}
                  />
                  {
                    showModal && <BookingsModal
                      status={updatedstatus}
                      setShow={setShowModal}
                      carname={carname}
                      bookingid={bookingid}
                      phonee={phonee}
                      cartype={cartype}
                      show={showModal}
                    />
                  }
                  {
                    showAgent && <BookingsAgent
                      status={updatedstatus}
                      setShow={setShowAgent}
                      carname={carname}
                      bookingid={bookingid}
                      phonee={phonee}
                      cartype={cartype}
                      receiveid={receiveid}
                      // roww={roww}
                      selectedVendorName={selectedVendorForRow}
                      show={showAgent}
                    />
                  }
                 
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ToastContainer />
          
        </Container>
        {
          viewModal && <ViewAgentModal
            // status={updatedstatus}
            setShow={setviewModal}
            updateId={updateId}
            update_booking_id={update_booking_id}
            Update_userDetails={Update_userDetails}
            Updated_userDetailsphone={Updated_userDetailsphone}
            Updated_bookingSummaryname={Updated_bookingSummaryname}
            Updated_userDetails_location= {Updated_userDetails_location}
            Updated_bookingSummarytime={Updated_bookingSummarytime}
            Updated_bookingSummarydate={Updated_bookingSummarydate}
            Updated_bookingSummaryprice={Updated_bookingSummaryprice}
            updated_vendorname={updated_vendorname}
            
            show={viewModal}
          />
        }

      </div>
    </React.Fragment>
  );
};

export default Bookings;
