import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import BreadCrumb from "../../../Components/Common/BreadCrumb"
import {
  Card,
  CardBody,
  CardHeader,
  Input,
  Label,
  Col,
  Collapse,
  Container,
  Row,
  Button,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  CardFooter,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import DataTable, { createTheme } from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "../../../helpers/Axios"

const YachtEnquires = (gettingBrandicon) => {

  const [recive, setRecive] = useState([]);
  const [updateId, setUpdateId] = useState("");
  const [updateusername, setUpdateusername] = useState("");
  const [updatephonenumber, setUpdatephonenumber] = useState("");
  const [updateyachtname, setUpdateyachtname] = useState("");
  const [updateemail, setUpdateemail] = useState("");
  const [updatebookingdate, setUpdatebookingdate] = useState("");
  const [updateenquirytime, setUpdateenquirytime] = useState("");
  const [updateenquirydate, setUpdateenquirydate] = useState("");
  const [isOpenView, setIsOpenView] = useState(false);
  function gettingYachtData() {
    axios
      .get("/yacht_enquires")
      .then((res) => {
        setRecive(res.data.users);
        // console.log(res.data.users);
        // console.log("received yacht_enquires details ");
      })
      .catch(() => {
        // console.log("error receiveing yacht_enquires details ");
      });
  }
  useEffect(() => {
    gettingYachtData();
  }, []);
  const showToastMessageDeleteSuccess = () => {
    toast.success("Deleted Successfully !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const showToastMessageRetervied = () => {
    toast.success("Delete Retervied !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  createTheme("fulldark", {
    text: {
      primary: "white",
      secondary: "white",
    },
    background: {
      default: "#212529",
    },
    context: {
      text: "white",
    },
    button: {
      default: "white",
      focus: "white",
      hover: "white",
      disabled: "white",
    },
  });

  
  const columns = [
    {
      name: <p>Id </p>,
      selector: (row) => row.id,
    },
    {
      name: <p>User Name </p>,
      selector: (row) => row.user_name,
    },
    {
      name: <p>Email </p>,
      selector: (row) => row.email,
    },
    {
      name: <p>Phone Number </p>,
      selector: (row) => row.phone_number,
    },
    {
      name: <p>Yacht Name </p>,
      selector: (row) => row.yacht_name,
    },
    {
      name: <p>Booking Date </p>,
      selector: (row) => row.booking_date,
    },
    {
      name: <p>Enquires Date </p>,
      selector: (row) => row.enquiry_date,
    },
    {
      name: <p>Enquires Time </p>,
      selector: (row) => row.enquiry_time,
    },
    {
      name: <p className="font-weight-bold fs-13">Action</p>,
      cell: (row) => {
        return (
          <UncontrolledDropdown className="dropdown d-inline-block" direction="right">
            <DropdownToggle
              className="btn btn-soft-secondary btn-sm"
              tag="button"
            >
              <i className="ri-more-fill align-middle"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem
                onClick={(e) => {
                  setUpdateId(row.id);
                  setUpdateusername(row.user_name);
                  setUpdatephonenumber(row.phone_number);
                  setUpdateyachtname(row.yacht_name)
                  setUpdatebookingdate(row.booking_date);
                  setUpdateenquirytime(row.enquiry_time);
                  setUpdateenquirydate(row.enquiry_date)
                  setUpdateemail(row.email);     
                  setIsOpenView(true);
                }}
              >
                <i className="ri-eye-fill align-bottom me-2 text-muted"></i>
                View
              </DropdownItem>
              </DropdownMenu>
              </UncontrolledDropdown>
        )
      },
    }
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Enquires" pageTitle="Yacht" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0  text-centre">Yacht Enquires</h4>
                </CardHeader>
                <CardBody>
                  <DataTable
                    columns={columns}
                    data={recive}
                    pagination
                    theme="fulldark"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ToastContainer />
        </Container>
      </div>
      <Modal isOpen={isOpenView} toggle={() => setIsOpenView(false)}>
        <ModalHeader>View Yacht Enquires</ModalHeader>
        <ModalBody>
          {/* ... Render the view modal content ... */}
          {/* {selectedFeedbackData && ( */}
          <CardHeader>
          <div style={{ padding: "10px"}}></div>
          </CardHeader>
            
               <p><b>ID:</b>&nbsp;&nbsp;{updateId}</p>
               <p><b>User Name:</b>&nbsp;&nbsp;{updateusername}</p>
               <p><b>Email:</b>&nbsp;&nbsp;{updateemail}</p>
               <p><b>Phone Number:</b>&nbsp;&nbsp;{updatephonenumber}</p>
               <p><b>Yacht Name:</b>&nbsp;&nbsp;{updateyachtname}</p>
        <p><b>Booking Date:</b>&nbsp;&nbsp;{updatebookingdate}</p>
        <p><b>Enquires Date:</b>&nbsp;&nbsp;{updateenquirydate}</p>
        <p><b>Enquires Time:</b>&nbsp;&nbsp;{updateenquirytime}</p>
              {/* Display other feedback details here */}
            
          {/* )} */}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setIsOpenView(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default YachtEnquires;
