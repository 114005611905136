import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// all components
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Alert,
  Spinner,
} from "reactstrap";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import axios from "../../helpers/Axios";
import PreviewCardHeader from "../CarFeatures/PreviewCardReaderDemo";
import { Link } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import Dropzone from "react-dropzone";
import BreadCrumb from "../AddCarFleet/BreadcrumDummy";
// import { VerticalForm, HorizontalForm } from "./FormlayoutsCode";
// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
const Surge = () => {
  const [luxurycd, setLuxurycd] = useState("");
  const [luxurysd, setLuxurysd] = useState("");
  const [luxuryat, setLuxuryat] = useState("");

  const [standardcd, setStandardcd] = useState("");
  const [standardsd, setStandardsd] = useState("");
  const [standardat, setStandardat] = useState("");

  const [yatch, setYatch] = useState("");

  const [selectedFiles, setselectedFiles] = useState([]);
  // function handelSubmit(e){
  //     e.Preventdefault()
  //     const payload ={carname, cartype, logo, status}
  //     axios.post(``)
  // }
  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  }
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const numberformat = /^\d{2}$/;

  function handleClick(e) {
    e.preventDefault();
    // if (luxurycd === "") {
    //   messageforEmptyLuxuryCDsurge();
    // } else if (luxurysd === "") {
    //   messageforEmptyLuxurySDsurge();
    // } else if (luxuryat === "") {
    //   messageforEmptyLuxuryATsurge();
    // } else if (standardcd === "") {
    //   messageforEmptyStandardCDsurge();
    // } else if (standardsd === "") {
    //   messageforEmptyStandardSDsurge();
    // } else if (standardat === "") {
    //   messageforEmptyStandardATsurge();
    // } else if (yatch === "") {
    //   messageforEmptyYatch();
    // } else if (!luxurycd.match(numberformat)) {
    //   messageforinvalidluxuryCDSurge();
    // } else if (!luxurysd.match(numberformat)) {
    //   messageforinvalidluxurySDSurge();
    // } else if (!luxuryat.match(numberformat)) {
    //   messageforinvalidluxuryATSurge();
    // } else if (!standardcd.match(numberformat)) {
    //   messageforinvalidStandardCDSurge();
    // } else if (!standardsd.match(numberformat)) {
    //   messageforinvalidStandardSDSurge();
    // } else if (!standardat.match(numberformat)) {
    //   messageforinvalidStandardATSurge();
    // } else if (!yatch.match(numberformat)) {
    //   messageforinvalidYatch();
    // } else {
   
      const payload = {
        luxuryat,
        luxurycd,
        luxurysd,
        standardcd,
        standardsd,
        standardat,
        yatch,
      };
      // console.log(payload)
      axios
        .post(`/surge`, payload)
        .then(() => {
          // console.log("Surge added successfully");
        })
        .catch(() => {
          // console.log("Getting error on surge");
        });
    }
  

  const messageforEmptyLuxuryCDsurge = () => {
    toast.error("Please fill Luxury CD Surge ...!", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const messageforEmptyLuxurySDsurge = () => {
    toast.error("Please fill Luxury SD Surge ...!", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const messageforEmptyLuxuryATsurge = () => {
    toast.error("Please fill Luxury AT Surge ...!", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const messageforEmptyStandardCDsurge = () => {
    toast.error("Please fill brand name ...!", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const messageforEmptyStandardSDsurge = () => {
    toast.error("Please fill brand name ...!", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const messageforEmptyStandardATsurge = () => {
    toast.error("Please fill brand name ...!", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const messageforEmptyYatch = () => {
    toast.error("Please fill brand name ...!", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const messageforinvalidluxuryCDSurge = () => {
    toast.error("Please enter valid CD Surge in Luxury...!", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const messageforinvalidluxurySDSurge = () => {
    toast.error("Please enter valid SD Surge in Luxury...!", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const messageforinvalidluxuryATSurge = () => {
    toast.error("Please enter valid AT Surge in Luxury...!", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const messageforinvalidStandardCDSurge = () => {
    toast.error("Please enter valid CD Surge in Standard...!", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const messageforinvalidStandardSDSurge = () => {
    toast.error("Please enter valid CD Surge in Standard...!", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const messageforinvalidStandardATSurge = () => {
    toast.error("Please enter valid CD Surge in Standard...!", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const messageforinvalidYatch = () => {
    toast.error("Please enter valid Yatch...!", {
      position: toast.POSITION.TOP_CENTER,
    });
    handelCencel()
  };

  const canceljump = useNavigate()

  function handelCencel(){
    canceljump('/surge_data')
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Surge" pageTitle="Add" />
          <Row>
            <Col xxl={12}>
              <Card>
                <PreviewCardHeader className="text-center" title="Surge" />
                <CardBody>
                  <div className="live-preview">
                    <Form action="#">
                      <Row>
                        <Label htmlFor="fullnameInput" className="form-label">
                          Luxury
                        </Label>
                        <Col md={4}>
                          <Input
                            type="text"
                            className="form-control"
                            id="fullnameInput"
                            placeholder="CD Surge"
                            onChange={(e) => setLuxurycd(e.target.value)}
                          />
                        </Col>
                        <Col md={4}>
                          <Input
                            type="text"
                            className="form-control"
                            id="fullnameInput"
                            placeholder="SD Surge"
                            onChange={(e) => setLuxurysd(e.target.value)}
                          />
                        </Col>
                        <Col md={4}>
                          <Input
                            type="text"
                            className="form-control"
                            id="fullnameInput"
                            placeholder="AT Surge"
                            onChange={(e) => setLuxuryat(e.target.value)}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Label htmlFor="fullnameInput" className="form-label">
                          Standard
                        </Label>
                        <Col md={4}>
                          <Input
                            type="text"
                            className="form-control"
                            id="fullnameInput"
                            placeholder="CD Surge"
                            onChange={(e) => setStandardcd(e.target.value)}
                          />
                        </Col>
                        <Col md={4}>
                          <Input
                            type="text"
                            className="form-control"
                            id="fullnameInput"
                            placeholder="SD Surge"
                            onChange={(e) => setStandardsd(e.target.value)}
                          />
                        </Col>
                        <Col md={4}>
                          <Input
                            type="text"
                            className="form-control"
                            id="fullnameInput"
                            placeholder="AT Surge"
                            onChange={(e) => setStandardat(e.target.value)}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Label htmlFor="fullnameInput" className="form-label">
                         Yacht
                        </Label>
                        <Col md={4}>
                          <Input
                            type="text"
                            className="form-control"
                            id="fullnameInput"
                            placeholder="Yatch"
                            onChange={(e) => setYatch(e.target.value)}
                          />
                        </Col>
                      </Row>
                      <div className="text-center">
                        {/* <Input
                      type="checkbox"
                      className="btn-check"
                      id="btn-check-outlined"
                    />
                    <Label
                      className="btn btn-outline-primary text-centre"
                      for="btn-check-outlined"
                    >
                      Add
                    </Label>
                    &nbsp; &nbsp; &nbsp;
                    <Input
                      type="checkbox"
                      className="btn-check"
                      id="btn-check-outline"
                    />
                    <Label
                      className="btn btn-outline-primary text-centre"
                      for="btn-check-outline"
                    >
                      Cancel
                    </Label> */}
                        <Label
                          className="btn btn-outline-primary text-centre"
                          for="btn-check-outlined"
                          onClick={handleClick}
                        >
                          Add
                        </Label>
                        &nbsp; &nbsp; &nbsp;
                        <Label
                          className="btn btn-outline-danger"
                          for="danger-outlinedremove"
                          onClick={handelCencel}
                        >
                          Cancel
                        </Label>
                      </div>
                      {/* </Col> */}
                    </Form>
                  </div>
                  <div className="d-none code-view">
                    <pre
                      className="language-markup"
                      style={{ height: "375px" }}
                    >
                      <code>{/* <VerticalForm /> */}</code>
                    </pre>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Surge;
