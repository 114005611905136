import React, { useEffect, useState } from "react";
// import styles from "./Carfeaturesmodal.module.css";
import axios from "../../helpers/Axios";
// import { ListGroup, ListGroupItem, ModalBody, ModalFooter,  ModalHeader } from 'reactstrap';
import { Link } from "react-router-dom";
import {
  FormGrid,
  Gutters,
  VerticalForm,
  HorizontalForm,
  HorizontalFormLabelSizing,
  ColumnSizing,
  AutoSizing,
  InlineForms,
  FloatingLabels,
} from "../AddCarFleet/FormsLayoutDummy";
import PreviewCardHeader from "../AddCarFleet/PreviewCardHeaderDummy";
import Flatpickr from "react-flatpickr";
import { ToastContainer, toast } from "react-toastify";
import { color } from "echarts";

// import React, { useState } from 'react';
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import {
  Card,
  CardBody,
  CardHeader,
  Input,
  Label,
  Col,
  Collapse,
  Container,
  Row,
  DropdownItem,
  ModalBody,
  ModalFooter,
  ModalHeader,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  CardFooter,
} from "reactstrap";
import ImageForView from "./ImageForView";
function UpdateCarFeatures({
  setShow,
  show,
  id,
  featurename,
  description,
  files,
  status,
  img,
}) {
  //   const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [updateId, setUpdateId] = useState(id);
  const [updatedfeaturename, setUpdatedfeaturename] = useState(featurename);
  const [updateddescription, setUpdateddescription] = useState(description);
  const [updatedfeatureicon, setUpdatedfeatureicon] = useState(files);
  const [updatedicon, setUpdatedicon] = useState(img);
  const [updatedstatus, setUpdatedStatus] = useState(status);

  // let jump=useNavigate()

  const [revdata, setRevdata] = useState("");

  const a = () => {
    toast.success("Updated Successfully !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const errormessage = () => {
    toast.error("No Changes Detected !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  // for refereshing

  function togetallfeatureIcons() {}
  useEffect(() => {
    togetallfeatureIcons();
  }, []);

  // for uploaing image
  const imagesUpload = (e) => {
    e.preventDefault();
    const Image = e.target.files[0];
    // console.log(e.target.files[0].name);
    setUpdatedicon("car_features/"+e.target.files[0].name);

    // setFiles(e.target.files[0].name)
    // console.log(Image);
    if (Image != null) {
      const data = new FormData();
      data.append("Image", Image);
      // console.log(data);
      axios
        .post("/uploadfeatureimg", data)
        .then(() => {
          // setFeaturename("")
          // setDescription("")
          // setStatus("")
          // setUploadimg("")
          // setStatus("")
          // console.log("Uploaded");
        })
        .catch(() => {
          // console.log("Error in Uploading");
        });
    }
  };
  // console.log(updatedfeatureicon);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{featurename}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div>
              <CardHeader>
                <div className="text-center mt-10">
                  <h5 style={{ color: "#eee4e4" }}>Edit Information</h5>
                </div>
              </CardHeader>
              <div className="p-2 mt-0 justify-content-center">
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                  action="#"
                >
                  <Row>
                    <Col lg={6}>
                      <div className="mb-2">
                        <Label
                          style={{ color: "#eee4e4" }}
                          htmlFor="catname"
                          className="form-label"
                        >
                          Featurename <span className="text-danger">*</span>
                        </Label>
                        <Input
                          style={{
                            backgroundColor: "#2a2f34",
                            color: "#eee4e4",
                          }}
                          id="catname"
                          name="catname"
                          className="form-control"
                          placeholder="Edit name"
                          type="text"
                          defaultValue={featurename}
                          onChange={(e) => {
                            setUpdatedfeaturename(e.target.value);
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-2">
                        <Label
                          style={{ color: "#eee4e4" }}
                          htmlFor="catprice"
                          className="form-label"
                        >
                          FeatureIcons <span className="text-danger">*</span>
                        </Label>
                        <h6>{updatedicon}</h6>
                        <Input
                          style={{
                            backgroundColor: "#2a2f34",
                            color: "#eee4e4",
                          }}
                          className="form-control"
                          id="formFile"
                          placeholder="additonal input field "
                          type="file"
                          name="file"
                          defaultValue={files[0].name}
                          onChange={imagesUpload}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div className="mb-2">
                        <Label
                          style={{ color: "#eee4e4" }}
                          htmlFor="firstname"
                          className="form-label"
                        >
                          Description <span className="text-danger">*</span>
                        </Label>
                        <Input
                          style={{
                            backgroundColor: "#2a2f34",
                            color: "#eee4e4",
                          }}
                          name="firstname"
                          type="text"
                          placeholder="Enter username"
                          defaultValue={description}
                          onChange={(e) => {
                            setUpdateddescription(e.target.value);
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label
                          htmlFor="employeeNamestatus"
                          className="form-label"
                          style={{ color: "#eee4e4" }}
                        >
                          Status {updatedstatus}
                        </Label>
                        <select
                          style={{
                            backgroundColor: "#2a2f34",
                            color: "#eee4e4",
                          }}
                          id="employeeNamestatus"
                          className="form-select"
                          defaultValue={status}
                          data-choices
                          data-choices-sorting="true"
                          onChange={(e) => setUpdatedStatus(e.target.value)}
                        >
                          <option value={"Active"}>Active</option>
                          <option value={"Inactive"}>Inactive</option>
                        </select>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={() => {
              let payload = {
                featurename: updatedfeaturename,
                id: id,
                files: updatedfeatureicon,
                img : updatedicon,
                description: updateddescription,
                status: updatedstatus,
              };
              axios
                .put("/updateCarFeatureslist", payload)
                .then(() => {
                  // console.log("reached");

                  setTimeout(() => {
                    a();
                  }, 200);
                  // setIsOpen(false)
                })

                .catch(() => {
                  setTimeout(() => {
                    errormessage();
                  }, 10);
                  // setIsOpen(false)
                });
            }}
          >
            Update
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

// render(<ModalView />);
export default UpdateCarFeatures;
