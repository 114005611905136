import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap";
import PreviewCardHeader from "../AddCarFleet/PreviewCardHeaderDummy";
import { Link, useSearchParams } from "react-router-dom";
import {
  FormGrid,
  Gutters,
  VerticalForm,
  HorizontalForm,
  HorizontalFormLabelSizing,
  ColumnSizing,
  AutoSizing,
  InlineForms,
  FloatingLabels,
} from "../AddCarFleet/FormsLayoutDummy";
import BreadCrumb from "../Cars/SelfBreadCrumb";
import axios from "../../helpers/Axios"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { number, use } from "echarts";

const Cars = () => {
  // conditional states for rendering use
  const[packagechange,setPackagechange]=useState(false)
  const[despositChangeself,setDepositchangeself]=useState(false)
  const[Price,setrPrice]=useState(false)
  const[disablecheck,setDisableCheck]=useState(false)
  const [change, setChange] = useState(false);
  const [standardchange, setStandardchange] = useState(false)
  const [outstationchange, setOutstattionchange] = useState(false)
  const [airportchange, setAirportchange] = useState(false)
// console.log(standardchange , outstationchange, airportchange);
  //states for storing data from other tables
  const [brandlist, setBrandlist] = useState("");
  const [agentlist, setAgentlist] = useState("");
  const [modellist, setModellist] = useState("");
  const [getlocation, setGetlocation] = useState();
  const[despositChange,setDespositchange]=useState(true)

  //new 
  const [chauffer01, setChauffer01] = useState("")
  const [standard01, setStandard01] = useState("")
  const [outstation01,setOutstation01] = useState("")
  const [airport01, setAirport01] = useState("")

  const [standardclicked, setStandardclicked] = useState(false)

  const [finalstandard, setFinalstandard] = useState(false)
  const [finaloutstation, setFinaloutstation] = useState(false)
  const [finalairport, setFinalairport] = useState(false)
  const [finalselfdrive, setFinalselfdrive] = useState(false)

  const [condision, setCondision] = useState("") 

  //self drive

  const [selfDriveCars, setSelfDriveCars] = useState([])


  //data format checking regex
  const numberformat = /^[0-9]+$/;
  const nameformat = /^[A-Za-z]+$/;


  const [data, setData] = useState({
    carType: '', carStyle: '', carModel: '', location: '', agent: '', color: '', launch: '', deposit: '', driverAllowance: '', status: '', forPrime:''
  })
  const [selfDriveData, setSelfDriveData] = useState({
    slefDrivePrice: ''
  })
  const [standardData, setStandardData] = useState({
    standardPrice: '', standardPackageHrs: '8', standardKmsLimit: '80', standardGstPrice: ''
  })
  const [outStationData, setOutstaionData] = useState({
    outStationPrice: '', outStationPackageHrs: '', outStationKmsLimit: '', outStationGstPrice: ''
  })
  const [airportData, setAirportData] = useState({
    airprice:'', tollFee: '', parkingFee: '', gstPrice: ''
  })

  //payloads
  let payloadSelfDrive = {
    ...data, ...selfDriveData
  }
  // console.log(payloadSelfDrive);

  let payloadStandard = {
    ...data, ...standardData
  }
  //  console.log(payloadStandard,"standed");

  let payloadOutstation = {
    ...data, ...outStationData
  }
  // console.log(payloadOutstation);

  let payloadAirport = {
    ...data, ...airportData
  }
  // console.log(payloadAirport);
  useEffect(() => {
  
    setFinalstandard(false)
    setFinaloutstation(false)
    setFinalairport(false)
    setFinalselfdrive(false)
  
  },[data]);
  useEffect(() => {
    alreadydatafound()
    // console.log(finalselfdrive,"uplode")
  
  });
  // Functions
  async function alreadydatafound()
{
if(chauffer01 == true){

if(data.location){
  // console.log("started")
await axios.get(`/get-chauffeur-cars?location=${data.location}`)
.then((res) => {
    // setChauffeur(res.data)
    setStandard01(res.data.standard)
    setOutstation01(res.data.outstation)
    setAirport01(res.data.airport)
      // console.log(res.data,"ture")
})
}
}
else if (chauffer01 == false) {
  // console.log("selfdrive")
  if(data.location){
  await axios.get(`/get-selfdrive-cars?location=${data.location}`)
  .then((res) => {
      // setChauffeur(res.data)
      setSelfDriveCars(res.data)
        // console.log(res.data,"ture")
        
  })
  }
} 
}
function dataget01(){
let found =false
  if(chauffer01 == true){
if(standardchange == true){
  // const x ="";
  standard01.forEach(item => {
  
   
    if(item[12]==data.carModel) 
    {
      // if(item[14] == data.carStyle){
        setFinalstandard(true)
        
        found=true
       
      // }
    }
  
 });
}

else if(outstationchange == true){
 
  outstation01.forEach(item => {
    if(item[12]==data.carModel) 
    {
      // if(item[14] == data.carStyle){
    setFinaloutstation(true)
    // console.log("outstationchange = true",)
    alreaddatafound()
    found=true
      // }
   
    }
 
   });
  }
  else if(airportchange == true){
 
    airport01.forEach(item => {
      if(item[12]==data.carModel) 
      {
        // if(item[14] == data.carStyle){
      setFinalairport(true)
      // console.log("airportchange = true",)
      alreaddatafound()
      found=true
      // }
    }
   
     });
    
  
  }
else{
  showToastMessageSelectPackage()}
  }
  else if (chauffer01 == false) {
    if(data.carModel)
    {
      selfDriveCars.forEach(item => {
        if(item[12]==data.carModel) 
        {
          // if(item[14] == data.carStyle){
            setFinalselfdrive(true)
        // console.log("selfDriveCars = true",)
        alreaddatafound()
        found=true
        // }
      }
     
       });
  
  
    }


  }
  return found
}

  function checkEmptyInputs(e){
    // e.preventDefault()
    if(chauffer01 == true){
    if (data.carType == "") { showToastMessagecartype(); }
    else if (data.carStyle == ""){showToastMessageClassStyle ()}
    else if (data.carModel == "") { showToastMessageClass(); }
    else if (data.location == "") { showToastMessageLocation(); }
    else if (data.forPrime==""){showToastMessageForPrime()}
    else if (data.forPrime=="no" && standardchange==false && outstationchange==false && airportchange==false){showToastMessageSelectPackage()}
    }
    if(chauffer01 == false){
      if (data.carType == "") { showToastMessagecartype(); }
      else if (data.carStyle == ""){showToastMessageClassStyle ()}
      else if (data.carModel == "") { showToastMessageClass(); }
      else if (data.location == "") { showToastMessageLocation(); }
      else if (data.forPrime==""){showToastMessageForPrime()}
    }
    
    

   
    
    // else if (data.agent == "") { showToastMessageAgent(); }
    // else if (!data.color.match(nameformat)) { showToastMessageErrorInColor(); }
    // else if (!data.launch.match(numberformat)) { showToastMessageErrorInLaunch(); }
    // else if (data.forPrime==="no" && data.deposit=="") { showToastMessageInvalidDeposit(); }
    //  else if (data.standardPrice==""||data.standardPackageHrs==""||data.standardKmsLimit==""||data.standardGstPrice=="") { showToastMessageSelectPackage(); }

 
    
  
    // else if (!data.driverAllowance.match(numberformat)) { showToastMessageDriverBatta(); }
    else return true
  } 

  function uploadSelfDriveData(e) {
    // e.preventDefault()
    if (data.carType == "") { showToastMessagecartype(); }
    else if (data.carStyle == "") {showToastMessageClassStyle();}
    else if (data.carModel == "") { showToastMessageClass(); }
    else if (data.location == "") { showToastMessageLocation(); }
    else if (data.forPrime==""){showToastMessageForPrime();}
    // else if (data.agent == "") { showToastMessageAgent(); }
    // else if (!data.color.match(nameformat)) { showToastMessageErrorInColor(); }
    // else if (!data.launch.match(numberformat)) { showToastMessageErrorInLaunch(); }
    else if (!data.deposit.match(numberformat) && (data.forPrime==="no")) { showToastMessageInvalidDeposit(); }
    else if (!selfDriveData.slefDrivePrice.match(numberformat) && (data.forPrime==="no")) { showToastMessageInvalidprice(); }
    // else if (!data.driverAllowance.match(numberformat)) { showToastMessageDriverBatta(); }
    // else if (!selfDriveData.deposit.match(numberformat) && (data.forPrime==="no")) { showToastMessageInvalidDeposit(); }
    // else if (!selfDriveData.slefDrivePrice.match(numberformat) && (data.forPrime==="no")) { showToastMessagePrice(); }
    else if (data.status==""){showToastMessageSelectStatus()}
    else if (dataget01()){alreaddatafound()}
    
    else {
      
      axios.post("/self-drive-data", payloadSelfDrive)
        .then((res) => {
          // console.log(res);
          if(res.data == false){
            toast.success("Car with this location exists!", {
              position: toast.POSITION.TOP_CENTER,
              toastId: "1",
            });
          }
          else{showToastMessageSuccessAdding();}
          // console.log("saved")
        })
        .catch((err) => {
          // console.log("error saving data ");
          showToastMessageErrorAdding();
        });
    }
  }

  function uploadStandardData(e) {
    // e.preventDefault()
    if ((standardData.standardPrice ===!(numberformat)) && data.forPrime=="no"  && standardchange==true ) { showToastMessageInvalidprice(); }
    // else if (data.carStyle == "") {showToastMessageClassStyle();}
    // else if (data.carModel == "") { showToastMessageClass(); }
    // else if (data.location == "") { showToastMessageLocation(); }
    // else if (data.forPrime==""){showToastMessageForPrime();}

  
    // // else if (!data.color.match(nameformat)) { showToastMessageErrorInColor(); }
    // // else if (!data.launch.match(numberformat)) { showToastMessageErrorInLaunch(); }
    // // else if (!data.deposit.match(numberformat)) { showToastMessageInvalidDeposit(); }
    // // else if (!data.driverAllowance.match(numberformat)) { showToastMessageDriverBatta(); }
    // // else if (!standardData.standardPrice.match(numberformat)) { showToastMessagePrice(); }
    // // else if (!standardData.standardGstPrice.match(numberformat)) { showToastMessageGStPrice(); }
    // // else if (!standardData.standardPackageHrs.match(numberformat)) { showToastMessageExtraPckhrse(); }
    // // else if (!standardData.standardKmsLimit.match(numberformat)) { showToastMessageExtraKmsLimits(); }
    else if (data.status=="" ){showToastMessageSelectStatus()}
    else if (dataget01()){alreaddatafound()}
    else {
      // alert("upload done")
//       const karthik ={
//         // agent: 6,
//         // car_id: 16,
//         // car_style: "Luxury",
//         // car_type: "Chauffeur_Driven_Standard",
//         // color: "test",
//         // deposit: 1,
//         // extra_kms: 80,
//         // forPrime: "no",
//         // // "id": 129,
//         // launch: "",
//         // location: "2",
//         // package_hours: 8,
//         // price: 1,
//         // status: "Active"


// agent: "6",
// carModel:"16",
// carStyle:"Standard",

// Chauffeur_Driven_Standard:"Chauffeur_Driven_Standard",
// standardKmsLimit:80,
// standardPackageHrs:"8",

// color:"test",
// deposit:"1",
// driverAllowance:"",
// forPrime:"no",
// launch:"",
// location:"2",
// standardPrice:"1",
// status:"Inactive"
//       }
      axios.post("/standard-data", payloadStandard)
      // axios.post("/standard-data", karthik)
        .then(() => {
          console.log("data reached");
          showToastMessageSuccessAdding();
        })
        .catch(() => {
          console.log("error saving data ");
          showToastMessageErrorAdding();
        });
    }
  }

  function uploadOutstationData(e) {
    // e.preventDefault()
    if (outStationData.outStationPrice==!(numberformat) && data.forPrime=="no" && outstationchange==true ) { showToastMessageInvalidprice(); }
    // if (!outStationData.outStationPrice.match(numberformat)) { showToastMessagePrice(); }
    // else if (data.carStyle == "") {showToastMessageClassStyle();}
    // else if (data.carModel == "") { showToastMessageClass(); }
    // else if (data.location == "") { showToastMessageLocation(); }
    // else if (data.forPrime==""){showToastMessageForPrime();}
  
    // else if (data.agent == "") { showToastMessageAgent(); }
    // else if (!data.color.match(nameformat)) { showToastMessageErrorInColor(); }
    // else if (!data.launch.match(numberformat)) { showToastMessageErrorInLaunch(); }
    // else if (!data.deposit.match(numberformat)) { showToastMessageInvalidDeposit(); }
    // else if (!data.driverAllowance.match(numberformat)) { showToastMessageDriverBatta(); }
    // else if (!outStationData.outStationPrice.match(numberformat)) { showToastMessagePrice(); }
    // else if (!outStationData.outStationGstPrice.match(numberformat)) { showToastMessageGStPrice(); }
    // else if (!outStationData.outStationPackageHrs.match(numberformat)) { showToastMessageExtraPckhrse(); }
    // else if (!outStationData.outStationKmsLimit.match(numberformat)) { showToastMessageExtraKmsLimits(); }
    else if (data.status==""){showToastMessageSelectStatus()}
    else if (dataget01()){alreaddatafound()}
    else {
      axios.post("/outstation-data", payloadOutstation)
        .then(() => {
          // console.log("data reached");
          showToastMessageSuccessAdding();
        })
        .catch(() => {
          // console.log("error saving data ");
          showToastMessageErrorAdding();
        });
    }
  }

  function uploadAirportData(e) {
    // e.preventDefault()
    if ((airportData.airprice==!(numberformat)) && data.forPrime=="no" && airportchange==true ) { showToastMessageInvalidprice(); }
    else if (!(airportData.tollFee==numberformat) && data.forPrime=="no" && airportchange==true && airportData.tollFee=='') { showToastMessageTollFee(); }
    else if (!(airportData.parkingFee==numberformat) && data.forPrime=="no" && airportchange==true && airportData.parkingFee=='') { showToastMessageParkingFee(); }
    // else if (data.carModel == "") { showToastMessageClass(); }
    // else if (data.location == "") { showToastMessageLocation(); }
    // else if (data.forPrime==""){showToastMessageForPrime();}
   
    //  else if (data.forPrime == "" && (data.forPrime=="yes") ) { showToastMessageLocation(); }
    //  else if (data.deposit == "" && (data.forPrime=="no") ) { showToastMessageClass(); }
    // else if(data.airprice=="" || data.tollFee==""||data.parkingFee==""){showToastMessageClass()}
    // else if (data.agent == "") { showToastMessageAgent(); }
    // else if (!data.color.match(nameformat)) { showToastMessageErrorInColor(); }
    // else if (!data.launch.match(numberformat)) { showToastMessageErrorInLaunch(); }
    // else if (!data.deposit.match(numberformat)) { showToastMessageInvalidDeposit(); }
    // else if (!data.driverAllowance.match(numberformat)) { showToastMessageDriverBatta(); }
    
    // else if (!airportData.gstPrice.match(numberformat)) { showToastMessageGStPrice(); }
    // else if (!airportData.parkingFee.match(numberformat || nameformat)){ showToastMessageParkingCharges()}
    // else if (!airportData.tollFee.match(numberformat)) { showToastMessageTollfeeCharges(); }
    else  if (data.status==""){showToastMessageSelectStatus()}
    else if (dataget01()){alreaddatafound()}
    else {
      axios.post("/airport-data", payloadAirport)
        .then(() => {
          // console.log("data reached");
          showToastMessageSuccessAdding();
        })
        .catch(() => {
          // console.log("error saving data ");
          showToastMessageErrorAdding();
        });
    }
  }

  // for brand list
  function getbrandlist() {
    axios
      .get("/getbrandlist")
      // .get("/getCarClaslist")
      .then((res) => {
        setBrandlist(res.data.users);
        // console.log(res.data.users,"rtrtrtrtrtrtrtrt");
        console.log("received brand List");
      })
      .catch(() => {
        // console.log("error receiving brand list");
      });
  }

  // for location  list
  function getlocationList() {
    axios
      .get("/getlocationlist")
      .then((res) => {
        setGetlocation(res.data.users);
        // console.log(res.data.users);
        // console.log("getting locations");
      })
      .catch(() => {
        // console.log("error receiving data ");
      });
  }

  // for class list from car fleet
  function getcarclasslist() {
    axios
      .get("/getCarClaslist")
      .then((res) => {
        setModellist(res.data.users);
        // console.log(res.data.users);
        // console.log("received Class List");
      })
      .catch(() => {
        // console.log("error receiving class list");
      });
  }

  // for agent list from agent
  function getcaragentlist() {
    axios
      .get("/getagentlist")
      .then((res) => {
        setAgentlist(res.data.users);
        // console.log(res.data.users);
        // console.log("received agent List");
      })
      .catch(() => {
        // console.log("error receiving agent list");
      });
  }

  //useEfeect
  useEffect(() => {
    
  
    // setStandardData({ ...standardData, standardPackageHrs: 8})
    // setStandardData({ ...standardData, standardKmsLimit: 80})
    getbrandlist();
    getcarclasslist();
    getcaragentlist();
    getlocationList();
  }, []);

  // Toastify messages
  // Added Successfully
  const showToastMessageSuccessAdding = () => {
    toast.success("Added Successfully!", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "1",
    });
  };
  const showToastMessageSelectPackage = () => {
    toast.error("Select Package", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "100",
    });
  };
  const showToastMessageTollFee= () => {
    toast.error("Enter Toll Fees", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "101",
    });
  };
  const showToastMessageParkingFee = () => {
    toast.error(" Enter Parking Fee", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "102",
    });
  };
  // error adding
  const showToastMessageErrorAdding = () => {
    toast.error("Error Adding Details!", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "2",
    });
  };
  //fill all details
  const showToastMessageFillAllDetails = () => {
    toast.error("Fill All the Details !", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "3",
    });
  };
  // color error
  const showToastMessageErrorInColor = () => {
    toast.error("Invalid Color !", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "4",
    });
  };
  //launch
  const showToastMessageErrorInLaunch = () => {
    toast.error("Invalid Launch Year!", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "5",
    });
  };
  //extra km charges
  const showToastMessageExtraKmCharges = () => {
    toast.error("Invalid Extra km Charges!", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "6",
    });
  };
  // invalid deposit
  const showToastMessageInvalidDeposit = () => {
    toast.error("Invalid Deposit!", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "7",
    });
  };
  //invalid price
  const showToastMessageInvalidprice = () => {
    toast.error("Invalid Price!", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "8",
    });
  };
  //invalid package hr
  const showToastMessagePackagehrs = () => {
    toast.error("Invalid Package Hours!", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "9",
    });
  };
  // invalid km limits
  const showToastMessageKmsLimits = () => {
    toast.error("Invalid Kms Limits!", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "10",
    });
  };
  const showToastMessagecartype = () => {
    toast.error("Select Car Type ", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "11",
    });
  };
  const showToastMessagecarBrandtype = () => {
    toast.error("Select Brand ", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "12",
    });
  };
  const showToastMessageClass = () => {
    toast.error("Please Select Car", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "13",
    });
  };
  const showToastMessageClassStyle = () => {
    toast.error("Please Select Car Style", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "14",
    });
  };
  const showToastMessageLocation = () => {
    toast.error("Select Location", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "14",
    });
  };
  const showToastMessageForPrime = () => {
    toast.error("Select On Request", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "15",
    });
  };
  const showToastMessageSelectStatus = () => {
    toast.error("Select Status", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "16",
    });
  };
  const showToastMessageDriverBatta = () => {
    toast.error("Invalid Driver Batta", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "17",
    });
  };
  const showToastMessagePrice = () => {
    toast.error("Invalid Price", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "18",
    });
  };
  const showToastMessageGStPrice = () => {
    toast.error("Invalid GST Price for Standard Package", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "19",
    });
  };
  const showToastMessageTollfeeCharges = () => {
    toast.error("Enter Toll Fee Charges", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "20",
    });
  };
  const showToastMessageParkingCharges = () => {
    toast.error("Enter Parking Charges", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "21",
    });
  };
  const showToastMessageEnterAllData = () => {
    toast.error("Enter all the Data", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "22",
    });
  }; 
   const alreaddatafound = () => {
    toast.error("Same car Same pakage in database ", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "23",
    });
  };
  const uploaddone = () => {
    toast.error("upload done", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "23",
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Cars" pageTitle="Create" />
          <Col xxl={12}>
            <Card>
              <PreviewCardHeader title="Cars " />
              <div className="card-body">
                <div className="live-preview">
                  <form action="#" className="row g-3">
                  <Col md={6}>
                      <Label htmlFor="inputState" className="form-label">
                        Location
                      </Label>
                      <select
                        onChange={(e) => {
                          // setLocation(e.target.value);
                          setData({ ...data, location: e.target.value })
                        }}
                        id="inputState"
                        className="form-select"
                        data-choices
                        data-choices-sorting="true"
                      >
                        <option value="">Select Any</option>
                        {getlocation ? (
                          getlocation.map((data) => {
                            return (
                              <option key={data.id} value={data.id}>{data.location}</option>
                            );
                          })
                        ) : (
                          <h1>null</h1>
                        )}
                      </select>
                    </Col>
                   
                    <Col md={6}>
                      <Label htmlFor="employeeName" className="form-label">
                        Car Style
                      </Label>
                      <select
                        id="inputState"
                        className="form-select"
                        data-choices
                        data-choices-sorting="true"
                        onChange={(e) => {
                          setData({ ...data, carStyle: e.target.value })
                        }}
                      >
                        <option>Choose Car Type</option>
                        <option value={"Super Car"}>Super Car</option>
                        <option value={"Standard"}>Standard</option>
                        <option value={"Luxury"}>Luxury</option>
                        <option value={"Executive"}>Executive</option>
                        <option value={"Premium"}>Premium</option>
                      </select>
                      {/* </div> */}
                    </Col>
                    <Col md={6}>
                      <Label htmlFor="inputState" className="form-label">
                        Select Car
                      </Label>
                      <select
                        onChange={(e) => {
                          setData({ ...data, carModel: e.target.value })
                        
                        }}
                        id="inputState"
                        className="form-select"
                        data-choices
                        data-choices-sorting="true"
                      >
                        <option value="">Select Any</option>
                        {modellist ? (
                          modellist.map((data) => {
                            return (
                              <option key={data.id} value={data.id}>{data.car_brand}{' '}{data.car_model}</option>
                            );
                          })
                        ) : (
                          <h1>null</h1>
                        )}
                      </select>
                    </Col>
                    <Col md={6}>
                      <Label htmlFor="inputState" className="form-label">
                        CarType
                      </Label>
                      <select
                        onChange={(e) => {
                          setData({ ...data, carType: e.target.value })
                          if (e.target.value == "Chauffeur_Driven") {
                            setChange(!change);
                            setAirportchange(false)
                            setStandardchange(false)
                            setOutstattionchange(false)
                            setDespositchange(false)
                            setDisableCheck(true)
                            setChauffer01(true)
                          }
                          else {
                            setChange(false);
                            setDespositchange(true)
                            setChauffer01(false)
                          }
                        }}
                        id="inputState"
                        className="form-select"
                        data-choices
                        data-choices-sorting="true"
                      >
                        <option value="">Select Any</option>
                        <option value="Self_Drive">Self Drive</option>
                        <option value="Chauffeur_Driven">
                          Chauffeur Driven
                        </option>
                      </select>
                    </Col>

                    <Col md={6}>
                      <Label htmlFor="inputState" className="form-label">
                        Agent (Optional) {" "}
                      </Label>
                      <select
                        onChange={(e) => {
                          // setAgent(e.target.value);
                          setData({ ...data, agent: e.target.value })
                        }}
                        id="inputState"
                        className="form-select"
                        data-choices
                        data-choices-sorting="true"
                      >
                        <option value="">Select Any</option>
                        {agentlist ? (
                          agentlist.map((data) => {
                            return (
                              <option key={data.id} value={data.id}>{data.agent_name}</option>
                            );
                          })
                        ) : (
                          <h1>null</h1>
                        )}
                      </select>
                    </Col>

                    <Col md={6}>
                      <Label htmlFor="fullnameInput" className="form-label">
                        Color (Optional)
                      </Label>
                      <Input
                        onChange={(e) => {
                          // setColor(e.target.value);
                          setData({ ...data, color: e.target.value })
                        }}
                        type="text"
                        className="form-control"
                        id="fullnameInput"
                        placeholder="Enter Color"
                      />
                    </Col>

                    <Col md={6}>
                      <Label htmlFor="fullnameInput" className="form-label">
                        Launch Year (Optional)
                      </Label>
                      <Input
                        type="text"
                        maxLength={4}
                        onChange={(e) => {
                          // setLaunch(e.target.value);
                          setData({ ...data, launch: e.target.value })
                        }}
                        className="form-control"
                        id="fullnameInput"
                        placeholder="Enter Launch Year"
                      />
                    </Col>

                   {/* <Col md={6}>
                      <Label htmlFor="fullnameInput" className="form-label">
                        Availability count
                      </Label>
                      <Input
                        type="text"
                        maxLength={4}
                        onChange={(e) => {
                          // setLaunch(e.target.value);
                          setData({ ...data, availabilityCount: e.target.value })
                        }}
                        className="form-control"
                        id="fullnameInput"
                        placeholder="Enter availability count"
                      />
                    </Col>  */}

                    <Col md={6}>
                      <Label htmlFor="employeeName" className="form-label">
                        On Request
                      </Label>
                      <select
                        id="inputState"
                        className="form-select"
                        data-choices
                        data-choices-sorting="true"
                        onChange={(e) => {
                          setData({ ...data, forPrime:e.target.value })
                          if(e.target.value==="yes"){
                            if(data.carType=="Chauffeur_Driven")setDespositchange(false)
                            setrPrice(false)
                            setDisableCheck(true)
                            setStandardchange(false)
                            setAirportchange(false)
                            setOutstattionchange(false)
                            setStandardData("")
                            if(data.carType=="Self_Drive") setDepositchangeself(false)
                          }
                          else{
                            if(data.carType=="Chauffeur_Driven")setDespositchange(false)
                            setrPrice(true)
                            setDisableCheck(false)
                            setStandardchange(false)
                            setAirportchange(false)
                            setOutstattionchange(false)
                            setStandardData(false)
                            if(data.carType=="Self_Drive") setDepositchangeself(true)
                          }
                        }}
                      >
                        <option hidden>Select</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                      {/* </div> */}
                    </Col>

                  {
                
                  despositChange? 
                   ( <Col md={6}>
                    <Label htmlFor="inputEmail4" className="form-label">
                      Deposit
                    </Label>
                    <Input
                      onChange={(e) => {
                        // setDeposit(e.target.value);
                        setData({ ...data, deposit: e.target.value })
                      }}
                      type="text"
                      className="form-control"
                      id="inputEmail42"
                      placeholder="Enter Deposit Amount"
                    />
                  </Col>) : ( <Col md={6}>
                      <Label htmlFor="inputEmail4" className="form-label">
                        Deposit (Optional)
                      </Label>
                      <Input
                        onChange={(e) => {
                          // setDeposit(e.target.value);
                          setData({ ...data, deposit: e.target.value })
                        }}
                        type="text"
                        className="form-control"
                        id="inputEmail43"
                        placeholder="Enter Deposit Amount"
                      />
                    </Col>)
                   }

                    {/* <Col md={6}>
                      <Label htmlFor="fullnameInput" className="form-label">
                        Driver Allowance{" "}
                      </Label>
                      <Input
                        onChange={(e) => {
                          // setDriverbatta(e.target.value);
                          setData({ ...data, driverAllowance: e.target.value })
                        }}
                        type="text"
                        className="form-control"
                        id="fullnameInput"
                        placeholder="Enter Driver Batta Price"
                      />
                    </Col> */}

                    {
                   
                    change ? (
                      <Row>
                        <CardHeader>Add Packages</CardHeader>
                        <CardBody>
                          <Row>
                            <div className="form-check mt-3">
                              <div className="form-check form-check-outline form-check-primary mb-3 ">

                               {
                                disablecheck?(
                                  <Input disabled={true} className="form-check-input" type="checkbox" checked={false} id="formCheck17" onChange={(e) => { setStandardchange(!standardchange) 
                                
                                  }} />
                                ):(
                                  <Input disabled={false} className="form-check-input" type="checkbox" id="formCheck17" onChange={(e) => { setStandardchange(!standardchange) 
                                    setStandardData({ ...standardData, 
                                      standardPackageHrs: "8",
                                      standardKmsLimit: 80
                                    })
                                 
                                   
                                  }} />
                                )
                               }

                                <Label className="form-check-label" for="formCheck17">
                                  Standard
                                </Label>

                              </div>
                            </div>
                            {standardchange ? <Col md={4} >
                            <label>Enter Price</label> 
                              <Input
                                onChange={(e) => {
                                  // setStandprice(e.target.value);
                                  setStandardData({ ...standardData, standardPrice: e.target.value })
                                }}
                                type="text"
                                className="form-control"
                                id="inputEmail4"
                                placeholder="Enter Price"
                                
                              />
                              <br></br>
                              <label>Package Hr</label>  
                             <Input
                                onChange={(e) => {
                                  // setStandpackagehr(e.target.value);
                                  setStandardData({ ...standardData, standardPackageHrs: e.target.value })
                                }}
                                type="text"
                                className="form-control"
                                id="inputEmail1"
                                placeholder="Enter Package Hours (*) "
                                defaultValue="8"
                                
                              />
                            
                              <br></br>
                              <label>Enter Km Limits</label> 
                              <Input
                                onChange={(e) => {
                                  // setStandExtrakms(e.target.value);
                                  setStandardData({ ...standardData, standardKmsLimit: e.target.value })
                                }}
                                type="text"
                                className="form-control"
                                id="inputEmail4"
                                placeholder="Enter Kms Limits"
                                defaultValue="80"

                              />
                              <br></br>
                              {/* <Input
                                onChange={(e) => {
                                  // setStandGstprice(e.target.value);
                                  setStandardData({ ...standardData, standardGstPrice: e.target.value })
                                }}
                                type="text"
                                className="form-control"
                                id="inputEmail4"
                                placeholder="GST Price "
                              /> */}
                            </Col> : ""}

                            <div className="form-check mt-3">
                              <div className="form-check form-check-outline form-check-success mb-3">
                               {
                                disablecheck?(
                                  <Input disabled={true} className="form-check-input" type="checkbox" checked={false} id="formCheck18" onChange={(e) => { setOutstattionchange(!outstationchange) }} />
                                ):(
                                  <Input disabled={false} className="form-check-input" type="checkbox" id="formCheck18" onChange={(e) => { setOutstattionchange(!outstationchange) }} />
                                )
                               }
                                <Label className="form-check-label" for="formCheck18">
                                  OutStation
                                </Label>
                              </div>
                            </div>

                            {outstationchange ?
                              <Col md={4} >
                                <label>Enter Price</label> 
                                <Input
                                  onChange={(e) => {
                                    // setOutprice(e.target.value);
                                    setOutstaionData({ ...outStationData, outStationPrice: e.target.value })
                                  }}
                                  type="text"
                                  className="form-control"
                                  id="inputEmail4"
                                  placeholder="Enter  Price"
                                />
                                <br></br>

                                <label>Package Hr</label> 
                                <Input
                                  onChange={(e) => {
                                    // setOutpackagehr(e.target.value);
                                    setOutstaionData({ ...outStationData, outStationPackageHrs: e.target.value })
                                  }}
                                  type="text"
                                  className="form-control"
                                  id="inputEmail4"
                                  placeholder="Enter Package Hours "
                                  defaultValue="24"
                                />
                                <br></br>
                                <label>Enter Km Limits</label> 
                                <Input
                                  onChange={(e) => {
                                    // setOutExtrakms(e.target.value);
                                    setOutstaionData({ ...outStationData, outStationKmsLimit: e.target.value })
                                  }}
                                  type="text"
                                  className="form-control"
                                  id="inputEmail4"
                                  placeholder="Enter Kms Limits"
                                  defaultValue="300"
                                />
                                <br></br>
                                {/* <Input
                                  onChange={(e) => {
                                    // setOutGstprice(e.target.value);
                                    setOutstaionData({ ...outStationData, outStationGstPrice: e.target.value })
                                  }}
                                  type="text"
                                  className="form-control"
                                  id="inputEmail4"
                                  placeholder="GST Price "
                                  
                                /> */}
                              </Col> : ""}
                            <div className="form-check  mt-3 ">
                              <div className="form-check form-check-outline form-check-danger mb-3">
                            {
                              disablecheck?
                              (    <Input disabled={true}   className="form-check-input" type="checkbox"   checked={false} id="formCheck19" onChange={(e) => { setAirportchange(!airportchange) }} />)
                              :
                                  <Input disabled={false} className="form-check-input" type="checkbox" id="formCheck19" onChange={(e) => { setAirportchange(!airportchange) }} />
                            }
                                <Label className="form-check-label" for="formCheck19">
                                  Airport
                                </Label>
                              </div>
                            </div>

                            {airportchange ? <Col md={4} >
                              <label>Enter Price</label>
                              <Input
                                onChange={(e) => {
                                  // setAirprice(e.target.value);
                                  setAirportData({ ...airportData, airprice: e.target.value })
                                }}
                                type="text"
                                className="form-control"
                                id="inputEmail4"
                                placeholder="Enter  Price"
                              />
                              <br></br>
                              <label>Enter Toll Fee</label>
                              <Input
                                onChange={(e) => {
                                  // setAirTollfee(e.target.value);
                                  setAirportData({ ...airportData, tollFee: e.target.value })
                                }}
                                type="text"
                                className="form-control"
                                id="inputEmail4"
                                placeholder="Enter Toll Fee "
                              />
                              <br></br>
                              <label>Enter Parking Fee</label>
                              <Input
                                onChange={(e) => {
                                  // setAirParkingfee(e.target.value);
                                  setAirportData({ ...airportData, parkingFee: e.target.value })
                                }}
                                type="text"
                                className="form-control"
                                id="inputEmail4"
                                placeholder="Enter Parking Fee"
                              />
                              <br></br>
                              {/* <Input
                                onChange={(e) => {
                                  // setAirGstprice(e.target.value);
                                  setAirportData({ ...airportData, gstPrice: e.target.value })
                                }}
                                type="text"
                                className="form-control"
                                id="inputEmail4"
                                placeholder="GST Price "
                              /> */}
                            </Col> : ""}
                          </Row>
                        </CardBody>
                      </Row>
                    ) : (
                      Price? <Col md={6} className="mt-3">
                     <Label htmlFor="fullnameInput" className="form-label">
                       Price{" "}
                     </Label>
                     <Input
                       onChange={(e) => {
                         // setCarprice(e.target.value);
                         setSelfDriveData({ ...selfDriveData, slefDrivePrice: e.target.value })
                       }}
                       type="text"
                       className="form-control"
                       id="fullnameInput"
                       placeholder="Enter CarPrice"
                     />
                   </Col>:<></>
                    )}

                        



                    <Col xxl={5} className="mt-3">
                      <Label htmlFor="employeeName" className="form-label">
                        Status
                      </Label>

                      <select
                        id="inputState"
                        className="form-select"
                        // value={status}
                        data-choices
                        data-choices-sorting="true"
                        onChange={(e) => {
                          // setStatus(e.target.value);
                          setData({ ...data, status: e.target.value })
                        }}
                      >
                        <option>Select Status</option>
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                      </select>
                    </Col>

                    <div className="text-center">
                      <Input
                        type="checkbox"
                        className="btn-check"
                        id="btn-check-outlined"
                      />
                      <Label
                        onClick={(e) => {
                          //  dataget01()
                         
                          // e.preventDefault();
                           checkEmptyInputs();
                          if(data.forPrime=="yes" && data.carType==="Chauffeur_Driven")
                          {
                            
                            uploadStandardData()
                            uploadAirportData()
                            uploadOutstationData()
                            
                          }
                          else if(data.forPrime=="yes" && data.carType==="Self_Drive"){
                            uploadSelfDriveData()
                          }
                          else{
                            if (data.carType === 'Self_Drive') uploadSelfDriveData();
                          else {
                            if (data.carType === "Chauffeur_Driven") {
                              checkEmptyInputs();
                              if (standardchange === true) uploadStandardData();
                              if (outstationchange === true) uploadOutstationData();
                              if (airportchange === true) uploadAirportData();
                            }
                            else {
                              showToastMessageEnterAllData()
                                // checkEmptyInputs()
                            }
                          }
                          }
                        }}
                        className="btn btn-outline-primary text-centre"
                        for="btn-check-outlined"
                      >
                        Add
                      </Label>
                      &nbsp; &nbsp; &nbsp;
                      <Link to="/cars_list">
                        <Label
                          className="btn btn-outline-danger"
                          for="danger-outlinedremove"
                        >
                          Cancel
                        </Label>
                      </Link>
                      <br></br>
                    </div>
                  </form>
                </div>
                <div className="d-none code-view">
                  <pre className="language-markup" style={{ height: "375px" }}>
                    <code>
                      <Gutters />
                    </code>
                  </pre>
                </div>
              </div>
            </Card>
          </Col>
        </Container>
      </div>
      <ToastContainer></ToastContainer>
    </React.Fragment>
  );
};

export default Cars;
