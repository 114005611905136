import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Card, CardBody, CardHeader, Input, Label, Col, Collapse, Container, Row, DropdownItem, ModalBody, ModalFooter, ModalHeader, DropdownToggle, DropdownMenu, UncontrolledDropdown, CardFooter } from 'reactstrap';
import ImageForView from './ImageForView';
import { api_url,aws_bucket_url } from "../../helpers/base_url";


function ModalView({ setShow, show, id, featurename, files, img, description, status }) {
  //   const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{featurename}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group classNameNameName="mb-3" controlId="exampleForm.ControlInput1">
              <CardBody>
                <div className="d-flex align-items-center">
                  <div >
                    <img 
                    // src={require(`../../CarImages/CarFeatureIcons/${files}`)} 
                    src={aws_bucket_url + '/' + `${img}`}

                    height="300px" width="460px"></img>
    
                  </div>
                  <div className="flex-grow-1 ms-3">

                  </div>
                </div>
              </CardBody>

            </Form.Group>
            <Form.Group
              classNameNameName="mb-3"
              controlId="exampleForm.ControlInput1"
            >
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

// render(<ModalView />);
export default ModalView;
