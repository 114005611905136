import axios from "../../helpers/Axios";
import React, { useEffect, useState } from "react";
import BreadCrumb from "../../Components/Common/BreadCrumb";

import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Table,
  CardHeader,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import SurgeUpdate from "./SurgeUpdate";

const SurgeData = () => {
  const [surgedata, setSurgedata] = useState([]);

  const [updateId, setUpdateId] = useState("");
  const [luxury_cdupdated,setLuxury_cdupdated] = useState("");
  const [luxury_sdupdated,setLuxury_sdupdated] = useState("");
  const [luxury_atupdated,setLuxury_atupdated] = useState("");
  const [standard_cdupdated,setStandard_cdupdated] = useState("");
  const [standard_sdupdated,setStandard_sdupdated] = useState("");
  const [standard_atupdated,setStandard_atupdated] = useState("");
  const [yatchupdated,setYatchupdated] = useState("");
  const [isOpensurge, setIsOpensurge] = useState(false)

 

  function handelClick() {
    axios
      .get(`/surge`)
      .then((res) => {
        setSurgedata(res.data.users);
        // console.log(res.data.users,"dataaaaaaaaaaaaaaaaaaaa");
      })
      .catch(() => {
        // console.log("error getting surge data");
      });
  }

  useEffect(() => {
    handelClick();
  }, [isOpensurge]);

  const jump = useNavigate()

  function handelClicked() {
      jump("/surge");
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Surge" pageTitle="Surge Data" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">Surge Data</h4>
                </CardHeader>

                <CardBody>
                <Row className="g-4 mb-3">
                      <Col className="col-sm-auto">
                        <Label
                          className="btn btn-outline-primary"
                          onClick={handelClicked}
                          for="btn-check-outlined"
                        >
                          Add Surge
                        </Label>
                      </Col>

                      {/* // serach button  */}
                      {/* <Col className="col-sm">
                        <div className="d-flex justify-content-sm-end">
                          <div className="search-box ms-6">
                            <input
                              type="text"
                              onChange={(e) => {
                                setSearch(e.target.value);
                              }}
                              className="form-control search"
                              placeholder="Search..."
                            />
                            <i className="ri-search-line search-icon"></i>
                          </div>
                        </div>
                      </Col> */}
                    </Row>
                    <div className="table-responsive table-card">
                      <Row>
                        <table className="table align-middle table-nowrap table-striped-columns mb-0">
                          <thead className="table-light">
                            <tr>
                              <th style={{ textAlign: "center" }} colSpan={3}>
                                Luxury
                              </th>
                              <th style={{ textAlign: "center" }} colSpan={3}>
                                Standard
                              </th>
                              <th style={{ textAlign: "center" }}>
                                Yacht
                              </th>
                              <th style={{ textAlign: "center" }}>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr style={{ textAlign: "center" }}>
                              <td>CD</td>
                              <td>SD</td>
                              <td>AT</td>
                              <td>CD</td>
                              <td>SD</td>
                              <td>AT</td>
                              <td>Yacht</td>
                              <td>Action-Edit</td>
                            </tr>
                            {surgedata.map((res) => {
                              return (
                                <tr style={{ textAlign: "center" }} key={res.id}>
                                  <td>{res.luxury_cd}</td>
                                  <td>{res.luxury_sd}</td>
                                  <td>{res.luxury_at}</td>
                                  <td>{res.standard_cd}</td>
                                  <td>{res.standard_sd}</td>
                                  <td>{res.standard_at}</td>
                                  <td>{res.yatch}</td>
                                  <td onClick={()=>{
                                    setUpdateId(res.id)
                                    setLuxury_cdupdated(res.luxury_cd)
                                    setLuxury_sdupdated(res.luxury_sd)
                                    setLuxury_atupdated(res.luxury_at)
                                    setStandard_cdupdated(res.standard_cd)
                                    setStandard_sdupdated(res.standard_sd)
                                    setStandard_atupdated(res.standard_at)
                                    setYatchupdated(res.yatch)
                                    setIsOpensurge(true)
                                  }}
                                  ><button className="btn btn-outline-primary ">Edit</button></td>
                                </tr>
                                // <td key={res.id}>{res.luxury_sd}</td>
                              );
                            })}
                          </tbody>
                        </table>
                      </Row>
                    </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {
          isOpensurge && 
          (<SurgeUpdate setIsOpensurge={setIsOpensurge}
            id={updateId}
            luxury_cd ={luxury_cdupdated}
            luxury_sd ={luxury_sdupdated}
            luxury_at ={luxury_atupdated}
            standard_cd ={standard_cdupdated}
            standard_sd ={standard_sdupdated}
            standard_at ={standard_atupdated}
            yatch ={yatchupdated}
          />)
        }
      </div>
    </React.Fragment>
  );
};

export default SurgeData;
