import React, { useState, useEffect } from "react";
import axios from "axios";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { Charts } from "./Charts";
import CountUp from "react-countup";
import { useSelector, useDispatch } from "react-redux";
import { getDashboardData } from "../../store/dashboardEcommerce/action";
import DashboardData from './DashobardData'

const DashboardCharts = () => {

  const dispatch = useDispatch();

  const [chartData, setchartData] = useState([]);

  const { revenueData } = useSelector(state => ({
    revenueData: state.DashboardEcommerce.revenueData
  }));

  console.log(chartData)

  useEffect(() => {
    setchartData(DashboardData);
  }, [DashboardData]);

  const onChangeChartPeriod = pType => {
    dispatch(getDashboardData(pType));
  };

  useEffect(() => {
    dispatch(getDashboardData("all"));
  }, [dispatch]);

  //Dashboard 

  const[subscriptioncount,setSubscriptionCount]=useState()
  const[customerDetails,setCustomersDetails]=useState()
  const [enquireCount, setEnquireCount] = useState(0)
  

  function subscription(){
    let length = 0;
    axios
      .get(`/creact-account-deatils`)
      .then((res) => {
        setCustomersDetails(res.users)
        console.log(res.users);
        // setTime(res.users)
        length = Object.keys(res.users).length;
        setSubscriptionCount(length)
        console.log(length)
        
      })
      .catch(() => {
        console.log("Error");
      });
  }

  useEffect(()=>{
    subscription()
    gettingEnquires()
  },[])

  function gettingEnquires(){
    axios.get('/general_enquires')
    .then((res)=>{
      setEnquireCount(res.data.users.length)
      console.log(res.data.users.length)
    })
    .catch((re)=>{
      console.log("Error")
    })
  }

  var Days = ['Sunday', 'Monday', 'Tuesday',
  'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  var currentDay = new Date();
    console.log(currentDay)

  var day = Days[currentDay.getDay()] 
  console.log(day)

  var Months = ['January', 'February', 'March', 'April',
  'May', 'June', 'July', 'August', 'September',
  'October', 'November', 'December'];

  
  // months , half year and yearly calculation 

  // function* getRevenueChartsData({ payload: data }) {
  //   try {
  //     var response;
  //     if (data === "all") {
  //       response = yield call(getAllRevenueData, data);
  //     }
  //     if (data === "month") {
  //       response = yield call(getMonthRevenueData, data);
  //     }
  //     if (data === "halfyear") {
  //       response = yield call(getHalfYearRevenueData, data);
  //     }
  //     if (data === "year") {
  //       response = yield call(getYearRevenueData, data);
  //     }
  //     yield put(dashboardEcommerceApiSuccess(GET_REVENUE_CHARTS_DATA, response));
  //   } catch (error) {
  //     yield put(dashboardEcommerceApiError(GET_REVENUE_CHARTS_DATA, error));
  //   }
  // }



  return (
    <React.Fragment>
      <Card>
        <CardHeader className="border-0 align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">Statistics</h4>
          <div className="d-flex gap-1">
            <button type="button" className="btn btn-soft-secondary btn-sm" onClick={() => { onChangeChartPeriod("all"); }}>
              ALL
            </button>
            <button type="button" className="btn btn-soft-secondary btn-sm" onClick={() => { onChangeChartPeriod("month"); }}>
              1M
            </button>
            <button type="button" className="btn btn-soft-secondary btn-sm" onClick={() => { onChangeChartPeriod("halfyear"); }}>
              6M
            </button>
            <button type="button" className="btn btn-soft-primary btn-sm" onClick={() => { onChangeChartPeriod("year"); }}>
              1Y
            </button>
          </div>
        </CardHeader>

        <CardHeader className="p-0 border-0 bg-soft-light">
          <Row className="g-0 text-center">
            <Col xs={6} sm={3}>
              <div className="p-3 border border-dashed border-start-0">
                <h5 className="mb-1">
                  <CountUp start={0} end={100} duration={3} separator="," />
                </h5>
                <p className="text-muted mb-0"> Bookings </p>
              </div>
            </Col>
            <Col xs={6} sm={3}>
              <div className="p-3 border border-dashed border-start-0">
                <h5 className="mb-1">
                  <CountUp
                    // suffix="k"
                    // prefix="$"
                    start={0}
                    // decimals={2}
                    end={100}
                    duration={3}
                  />
                </h5>
                <p className="text-muted mb-0">Customers</p>
              </div>
            </Col>
            <Col xs={6} sm={3}>
              <div className="p-3 border border-dashed border-start-0">
                <h5 className="mb-1">
                  <CountUp start={0} end={enquireCount} duration={3} />
                </h5>
                <p className="text-muted mb-0">Enquires</p>
              </div>
            </Col>
            <Col xs={6} sm={3}>
              <div className="p-3 border border-dashed border-start-0 border-end-0">
                <h5 className="mb-1 ">
                  <CountUp
                    start={0}
                    end={subscriptioncount}
                    // decimals={2}
                    duration={3}
                    // suffix="%"
                  />
                </h5>
                <p className="text-muted mb-0">Subscription</p>
              </div>
            </Col>
          </Row>
        </CardHeader>

        <CardBody className="p-0 pb-2">
          <div className="w-100">
            <div dir="ltr">
              <Charts series={chartData}  dataColors='["--vz-warning", "--vz-primary", "--vz-danger"]' />
            </div>
          </div>
        </CardBody>
      </Card>
   
    </React.Fragment>
  );
};

export default DashboardCharts;
