
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../helpers/Axios";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { TimePicker } from 'react-ios-time-picker';
// import 'react-ios-time-picker/dist/index.css';
// import { DesktopDatePicker } from '@mui/x-date-pickers-pro/DesktopDatePicker';
import s from './Bookings.module.css'
import {
  Card,
  CardBody,
  CardHeader,
  Input,
  Label,
  Col,
  Collapse,
  Container,
  Row,
  DropdownItem,
  ModalBody,
  ModalFooter,
  ModalHeader,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  CardFooter,
} from "reactstrap";
function BookingsModal({
  setShow,
  show,
  carname,
  bookingid,
  phonee,
  cartype,
  id,
  agentname,
  location,
  email,
  status,
  alter_email,
  // alter_email2,
  phone,
  alt_phone,
}) {
  //   const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const useCurrentCallback = (callback) => {
    const reference = React.useRef();
    reference.current = callback;
    return (...args) => {
      return reference.current?.(...args);
    };
  };
  const now = new Date();
  // console.log('Current date: ' + now.toDateString());
  // console.log('Current time: ' + now.toTimeString()); 
  const time = now.toLocaleTimeString();
  const date = now.toLocaleDateString();

  // const now = new Date();

  const [reciveData, setReceiveData] = useState([]);
  // const [driverdetails, setDriverdetails]= useState([]);
  const [chauffeurname, setChauffeurname] = useState("")
  const [vehiclenumber, setVehiclenumber] = useState("")
  const [contact, setContact] = useState("")
  const [alternativeno, setAlternativeno] = useState("")
  const [reportingdate, setReportingdate] = useState(new Date());
  const [reportingtime, setReportingtime] = useState(now);
  const [cartypee, setCartype] = useState("")
  const [bookinid, setBookingid] = useState("")
  const [car, setCar] = useState("")
  // setReportingdate(date)
  // setReportingtime(time)

  const showToastMessageUploadedSuccess = () => {
    toast.success("Updated Successfully !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const showToastMessageErroruploading = () => {
    toast.error("Error Updating !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  function driversend() {
    let ph=''
    if(phonee.includes("+")){
     ph=phonee.match(/(\+91)(.{10})/)
    //  axios.get(`https://api.mylogin.co.in/api/v2/SendSMS?ApiKey=8NzlHH40L/AH3MR83XpYmT5d1EQVofsbB+Vx1jxqUcY=&ClientId=e649ffb9-407e-49ec-8155-8eba78e30620&SenderId=BLKLUX&Message=Greetings from BLCK! Please find your chauffeur and vehicle details below, Chauffeur name:${chauffeurname} Contact number:${contact} Alternate number:${contact} Vehicle number: ${vehiclenumber} Reporting Time:${reportingtime} cheers Team BLCK&MobileNumbers=${ph[2]}`)
     axios.get(`https://api.mylogin.co.in/api/v2/SendSMS?ApiKey=8NzlHH40L/AH3MR83XpYmT5d1EQVofsbB+Vx1jxqUcY=&ClientId=e649ffb9-407e-49ec-8155-8eba78e30620&SenderId=BLKLUX&Message=Greetings from BLCK!%0A%0A Please find the your chauffeur and vehicle details below,%0A%0A Chauffeur name : ${chauffeurname}%0A Contact number :${contact}%0A Alternative number :null%0A Vehicle number :${vehiclenumber}%0A Reporting Time : ${reportingtime}%0A%0A Cheers Team BLCK&MobileNumbers=91${ph[2]}&Is_Unicode=Is_Unicode=false&Is_Flash=false`)

     toast.success('Sms send...')
    }else{
      axios.get(`https://api.mylogin.co.in/api/v2/SendSMS?ApiKey=8NzlHH40L/AH3MR83XpYmT5d1EQVofsbB+Vx1jxqUcY=&ClientId=e649ffb9-407e-49ec-8155-8eba78e30620&SenderId=BLKLUX&Message=Greetings from BLCK!%0A%0A Please find the your chauffeur and vehicle details below,%0A%0A Chauffeur name : ${chauffeurname}%0A Contact number :${contact}%0A Alternative number :null%0A Vehicle number :${vehiclenumber}%0A Reporting Time : ${reportingtime}%0A%0A Cheers Team BLCK&MobileNumbers=91${phonee}&Is_Unicode=Is_Unicode=false&Is_Flash=false`)
      toast.success('Sms send...')
    }
  

  }
  // console.log(chauffeurname, '@@@@@@@@@@@@@@@@@@@@@@')
  // console.log(contact, '@@@@@@@@@@@@@@@@@@@@@@')
  // console.log(vehiclenumber, '@@@@@@@@@@@@@@@@@@@@@@')
  // console.log(alternativeno, '@@@@@@@@@@@@@@@@@@@@@@')
  // console.log(reportingdate, '@@@@@@@@@@@@@@@@@@@@@@')
  // console.log(reportingtime, '@@@@@@@@@@@@@@@@@@@@@@')
  // console.log(carname,'#########################')
  // console.log(bookingid,'#########################')
  // console.log(phonee,'######################')
  // console.log(cartype,"#######################")
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Greetings from BLCK!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group
              classNameNameName="mb-3"
              controlId="exampleForm.ControlInput1"
            >
              <div className="auth-page-content">
                <Container fluid>
                  <Row>
                    <Col xxl={12}>
                      <Card>
                        <CardBody className="p-7">
                          <div>
                            <CardHeader>
                              <div className="text-center mt-5">
                                <h5>
                                  Please find the your chauffeur and vehicle details below
                                </h5>
                              </div>
                            </CardHeader>
                            <div className="p-2 mt-0 justify-content-center">
                              <Form
                                onSubmit={(e) => {
                                  e.preventDefault();
                                }}
                                action="#"
                              >
                                <Row>
                                <Col lg={6}>
                                    <div className="mb-2">
                                      <Label
                                        htmlFor="bokking_id"
                                        className="form-label"
                                      >
                                        Booking Id :
                                        {" "}
                                      </Label>
                                      <Input
                                        id="booking_id"
                                        name="booking_id"
                                        className="form-control"
                                        value={bookingid}
                                        type="text"
                                        onChange={e => setBookingid(e.target.value)}
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="mb-2">
                                      <Label
                                        htmlFor="chauffeur_name"
                                        className="form-label"
                                      >
                                        Chauffeur name :
                                        {" "}
                                      </Label>
                                      <Input
                                        id="chauffeur_name"
                                        name="chauffeur_name"
                                        className="form-control"
                                        placeholder="Enter Chauffeur Name:"
                                        type="text"
                                        onChange={e => setChauffeurname(e.target.value)}
                                      />
                                    </div>
                                  </Col>
                                  </Row>
                                  <Row>
                                  <Col lg={6}>
                                    <div className="mb-2">
                                      <Label
                                        htmlFor="car_type"
                                        className="form-label"
                                      >
                                        Package :
                                        {" "}
                                      </Label>
                                      <Input
                                        id="car_type"
                                        name="car_type"
                                        type="text"
                                        placeholder="Please Enter Car Type"
                                        value={cartype}
                                        onChange={e => setCartype(e.target.value)}
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="mb-2">
                                      <Label
                                        htmlFor="contact_number"
                                        className="form-label"
                                      >
                                        Contact number :
                                        {" "}
                                      </Label>
                                      <Input
                                        id="contact_number"
                                        name="contact_number"
                                        type="text"
                                        placeholder="Please Enter Contact Number"
                                        onChange={e => setContact(e.target.value)}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={6}>
                                    <div className="mb-2">
                                      <Label
                                        htmlFor="alternative_number"
                                        className="form-label"
                                      >
                                        Car :
                                        {" "}
                                      </Label>
                                      <Input
                                        id="alternative_number"
                                        name="alternative_number"
                                        type="text"
                                        // placeholder="Enter Car Number"
                                        value={carname}
                                        onChange={e => setCar(e.target.value)}
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="mb-2">
                                      <Label
                                        htmlFor="vehicle_number"
                                        className="form-label"
                                      >
                                        Vehicle number :
                                        {" "}
                                      </Label>
                                      <Input
                                        id="vehicle_number"
                                        name="vehicle_number"
                                        type="text"
                                        placeholder="Enter Vehicle Number"
                                        onChange={e => setVehiclenumber(e.target.value)}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={6}>
                                    <div className="mb-2">
                                      <Label htmlFor="reporting_date" className="form-label">
                                        Reporting Date:
                                      </Label>
                                      <DatePicker
                                        id={s.reporting_date}
                                        name="reporting_date"
                                        // popperClassName=
                                        calendarClassName={s.datecontrol}
                                        placeholderText="Select Date"
                                        selected={reportingdate}
                                        onChange={date => setReportingdate(date)}
                                        className="form-control"
                                        dateFormat="MM/dd/yyyy"
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className={s.mb-2}>
                                      <Label htmlFor="reporting_time" className="form-label">
                                        Reporting Time:
                                      </Label>
                                      <TimePicker
                                        id={s.reporting_time}
                                        name="reporting_time"
                                        defaultValue={new Date(reportingtime)}
                                        onChange={time => setReportingtime(time)}
                                      />
                                    </div>
                                  </Col>

                                </Row>
                              </Form>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Container>
                <ToastContainer />
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={driversend}
          //   let payload = {
          //     agentname: updatedagentname,
          //     id: id,
          //     location: updatedlocation,
          //     email: updatedemail,
          //     alter_email: updatedaltemail,
          //     // alter_email2: updatedaltemai2,
          //     phone: updatedphn,
          //     status: updatedstatus,
          //     alt_phone: updatedaltphn,
          //   };
          //   axios
          //     .put("/updateAgentslist", payload)
          //     .then((res) => {
          //       // setIsOpen(false)
          //       showToastMessageUploadedSuccess();
          //     })
          //     .catch((err) => {
          //       // setIsOpen(false)
          //       showToastMessageErroruploading();
          //     });
          // }}
          >
            Send
          </Button>

          {/*  <Button variant="secondary" onClick={handleClose}>
            Close
                              </Button>*/}
        </Modal.Footer>
      </Modal>
    </>
  );
}

// render(<ModalView />);
export default BookingsModal;
