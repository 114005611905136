import React, { useEffect, useState } from "react";
import {SubscriptionCount} from '../Customers/Customers_List'
import { Link } from "react-router-dom";
import axios from 'axios'
import CountUp from "react-countup";
import DashboardCharts from "./DashboardCharts";
import {
  Card,
  Container,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { bestSellingProducts } from "../../common/data";
import { Row } from "reactstrap";
import Flatpickr from "react-flatpickr";
import { recentOrders } from "../../common/data";



const Dashboard = () => {
  
  const sidebar = () => {
    const element = document.getElementById("layout-rightside-coll");
    element.classList.toggle("d-none");
  };

  const[subscriptioncount,setSubscriptionCount]=useState()
  const[customerDetails,setCustomersDetails]=useState()
  const [enquireCount, setEnquireCount] = useState(0)
  

  function subscription(){
    let length = 0;
    axios
      .get(`/creact-account-deatils`)
      .then((res) => {
        setCustomersDetails(res.users)
        console.log(res.users);
        // setTime(res.users)
        length = Object.keys(res.users).length;
        setSubscriptionCount(length)
        console.log(length)
        
      })
      .catch(() => {
        console.log("Error");
      });
  }

  useEffect(()=>{
    subscription()
    gettingEnquires()
  },[])

  function gettingEnquires(){
    axios.get('/general_enquires')
    .then((res)=>{
      setEnquireCount(res.data.users.length)
      console.log(res.data.users.length)
    })
    .catch((re)=>{
      console.log("Error")
    })
  }



  return (
  
    <>
      <div className="page-content">
        <Container fluid>
          <h1>Dashboard</h1>
          <br></br>
          <Row>
            <Col xxl={3}>
              <Card className="card-danger">
                <CardBody>
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      {/* <img src={avatar2} alt="" className="avatar-sm rounded-circle" /> */}
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <p className="card-text">
                        {" "}
                        <h2>No of Booking</h2>
                      </p>
                    </div>
                  </div>
                </CardBody>
                <div className="card-footer">
                  <div className="text-center">
                  <h1>
                   
                   <CountUp
                     start={0}
                     end={enquireCount}
                     duration={2}
                   />
                      &nbsp;
                   <i className="fa-solid fa-book-open">
                   </i>
                 </h1>
                  </div>
                </div>
              </Card>
            </Col>

            <Col xxl={3}>
              <Card className="card-success">
                <CardBody>
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      {/* <img src={avatar2} alt="" className="avatar-sm rounded-circle" /> */}
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <p className="card-text">
                        {" "}
                        <h2>No of customers</h2>
                      </p>
                    </div>
                  </div>
                </CardBody>
                <div className="card-footer">
                  <div className="text-center">
                  
                    <h1>
                   
                   <CountUp
                     start={0}
                     end={enquireCount}
                     duration={2}
                   />
                      &nbsp;
                   <i className="fa-solid fa-users">
                   </i>
                 </h1>
                  </div>
                </div>
              </Card>
            </Col>
            <Col xxl={3}>
              <Card className="card-primary">
                <CardBody>
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      {/* <img src={avatar2} alt="" className="avatar-sm rounded-circle" /> */}
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <p className="card-text">
                        {" "}
                        <h2>Enquires</h2>{" "}
                      </p>
                    </div>
                  </div>
                </CardBody>
                <div className="card-footer">
                  <div className="text-center">
                    
                  <h1>
                   
                      <CountUp
                        start={0}
                        end={enquireCount}
                        duration={2}
                      />
                         &nbsp;
                      <i className="fa-solid fa-message">
                      </i>
                    </h1>
                  </div>
                </div>
              </Card>
            </Col>
            <Col xxl={3}>
              <Card className="card-info">
                <CardBody>
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      {/* <img src={avatar2} alt="" className="avatar-sm rounded-circle" /> */}
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <p className="card-text">
                        {" "}
                        <h2>Subscription</h2>{" "}
                      </p>
                    </div>
                  </div>
                </CardBody>
                <div className="card-footer">
                  <div className="text-center">
                   <Link to="./customers_list">
                   
                   <h1>
                    
                      <CountUp
                        start={0}
                        end={subscriptioncount}
                        duration={2}
                      />
                        &nbsp;
                      <i className="fa-solid fa-hand">
                      </i>
                    </h1>
                   
                   </Link>
                    
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
          <Row>
          <DashboardCharts></DashboardCharts>
          </Row>
        </Container>
       
      </div>
    </>
  );
};

export default Dashboard;
