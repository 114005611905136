import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { api_url,aws_bucket_url } from "../../helpers/base_url";
import {
  Card,
  CardBody,
  CardHeader,
  Input,
  Label,
  Col,
  Collapse,
  Container,
  Row,
  DropdownItem,
  ModalBody,
  ModalFooter,
  ModalHeader,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  CardFooter,
} from "reactstrap";

// import ImageForView from "./ImageForView";

function CarfleetView({
  setShow,
  show,
  car_brand,
  car_features,
  car_image,
  car_model,
  fuel,
  gear,
  id,
  luggage,
  seats,
  slider_images,
  status

}) {
  //   const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}

      <Modal show={show} onHide={handleClose}  size="lg" aria-labelledby="example-modal-sizes-title-sm" > 
        <Modal.Header closeButton>
          <Modal.Title>{car_brand}&nbsp;{car_model}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form style={{display:'flex', justifyContent:"center", alignItems:"center"}}>
            <Form.Group
              classNameNameName="mb-3"
              controlId="exampleForm.ControlInput1"
            >
              <CardBody>
                <div className="d-flex align-items-center">
                  <div>
                  <img
              width="200px"
              height="100px"
              // src={require(`../../CarImages/CarBrandIcon/${car_image}`)}
              src={aws_bucket_url + '/' + `${car_image}`}
            ></img>
                  </div>
                </div>
                {/* <div>
                    <label>Features: {car_features}</label>
                    <label>Fuel: {fuel}</label>
                    <label>Gear: {gear}</label>
                    <label>Luggage: {luggage}</label>
                    <label>Seats: {seats}</label>
                    <label>Status: {status}</label>
                </div> */}
              </CardBody>
            </Form.Group>
            <Form.Group>
              {/* {cartype} */}
            </Form.Group>
          </Form>
        </Modal.Body>
         
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

// render(<ModalView />);
export default CarfleetView;
 