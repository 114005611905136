import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Card, CardBody, CardHeader, Input, Label, Col, Collapse, Container, Row, DropdownItem, ModalBody, ModalFooter, ModalHeader, DropdownToggle, DropdownMenu, UncontrolledDropdown, CardFooter } from 'reactstrap';
// import ImageForView from './ImageForView';
function ModalViewCoupons ({ setShow, show, id, car_type, start_date, end_date, description, code, deduct_deposit, discounts_in_amount, discounts_type, maxdiscount_amount, status  }) {
  //   const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Modal show={show} onHide={handleClose}  >
        <Modal.Header closeButton>
          <Modal.Title>{car_type}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                <Form style={{display:'flex', justifyContent:"center", alignItems:"center"}} >
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <CardBody>
                      <div className="d-flex align-items-center">
                      <htmlForm>
        <div className="mb-3">
          <label htmlFor="exampleInputEmail1" className="form-label">Start Date &nbsp; :&nbsp; {start_date}</label>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">End Date &nbsp; : &nbsp; {end_date}</label>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">Coupon Code &nbsp;:&nbsp; {code}</label>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">Deduct Deposit &nbsp;: &nbsp; {deduct_deposit}</label>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">Description &nbsp;:&nbsp; {description}</label>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">Deduct Deposit &nbsp;:&nbsp;  {deduct_deposit}</label>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">Discount Amount &nbsp; :&nbsp; {discounts_in_amount}</label>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">Maximum Discount &nbsp;:&nbsp; {maxdiscount_amount}</label>
        </div>
       
        
        
      </htmlForm>
                  <div className="flex-grow-1 ms-3">

                  </div>
                </div>
              </CardBody>

            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>

        </Modal.Footer>
      </Modal>
    </>
  );
}

// render(<ModalView />);
export default ModalViewCoupons