import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Card, CardBody, CardHeader, Input, Label, Col, Collapse, Container, Row, DropdownItem, ModalBody, ModalFooter, ModalHeader, DropdownToggle, DropdownMenu, UncontrolledDropdown, CardFooter } from 'reactstrap';
// import ImageForView from './ImageForView';
function ModalViewLocation({ setShow, show,  id, location, location_status,status }) {
  //   const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Modal show={show} onHide={handleClose} size="sm" aria-labelledby="example-modal-sizes-title-sm" >
        <Modal.Header closeButton>
          <Modal.Title>{location}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form style={{display:'flex', justifyContent:"center", alignItems:"center"}} >
            <Form.Group classNameNameName="mb-3" controlId="exampleForm.ControlInput1">
              <CardBody>
                <div className="d-flex align-items-center">
                  <div >
                    <h5>{location_status}</h5>
                  </div>
                  <div className="flex-grow-1 ms-3">

                  </div>
                </div>
              </CardBody>

            </Form.Group>
            <Form.Group
              classNameNameName="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>

        </Modal.Footer>
      </Modal>
    </>
  );
}

// render(<ModalView />);
export default ModalViewLocation