import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Input,
  Label,
  Col,
  Form,
  Collapse,
  Container,
  Row,
  Button,
  DropdownItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  CardFooter,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import UiContent from "./UiContent";
import BreadCrumb from "../Cars/SelfBreadCrumb";
import DataTable, { createTheme } from "react-data-table-component";
import "react-toastify/dist/ReactToastify.css";
import "./custom.css";
import axios from "../../helpers/Axios";
import UpdateCarFeatures from "./UpdateCarFeatures";
import ModalView from "./ModalView";
// import '@sweetalert2/themes/dark/dark.scss';
import { api_url, aws_bucket_url } from "../../helpers/base_url";

//

const DisplayCarFeatures = () => {
  // for update

  const [isOpen, setIsOpen] = useState(false);
  // for view operation
  const [isopenview, setIsopenview] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [updatefeaturename, setUpdatefeaturename] = useState("");
  const [updatefeatureicon, setUpdatefeatureicons] = useState("");
  const [updatedescription, setUpdatedescription] = useState("");
  const [updatestatus, setUpdatestatus] = useState("");
  const [id, setId] = useState();
  const [updatedicon, setUpdatedicon] = useState("");

  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);

  //for view purpose

  // console.log(updatefeaturename)
  // console.log(updatefeatureicon)

  // for map methods

  const [revdata, setRevdata] = useState([]);

  let jumpto = useNavigate("");

  function handelClick() {
    jumpto("/Addcardetails");
  }
  //sweet alert function
  function alert() {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      allowEscapeKey: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          "Deleted!",
          "Your file has been deleted.",
          "black",
          "success"
        );
      }
    });
  }

  async function togetallfeatureIcons() {
    await axios
      .get("/featureIcons")
      .then((res) => {
        setRevdata(res.data.users);
        // console.log(res.data.users);
        // console.log("received Feature Icons ");
      })
      .then(() => {
        setLoaded(true);
      })
      .catch(() => {
        // console.log("error receiveing icons deatils ");
      });
  }
  useEffect(() => {
    togetallfeatureIcons();
  }, [modalShow]);

  //

  // sucessfull delete
  const showToastMessageDeleteSuccess = () => {
    toast.success("Deleted Successfully !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  //

  createTheme("fulldark", {
    text: {
      primary: "white",
      secondary: "white",
    },
    background: {
      default: "#212529",
    },
    context: {
      text: "white",
    },
    button: {
      default: "white",
      focus: "white",
      hover: "white",
      disabled: "white",
    },
  });
  const columns = [
    {
      name: <p>Id </p>,
      selector: (row) => row.id,
    },
    {
      name: <p>Feature Icon </p>,
      cell: (row) => {
        return (
          <div>
            <img
              width="130px"
              height="150px"
              src={aws_bucket_url + "/" + `${row.img}`}
            ></img>
          </div>
        );
      },
    },

    {
      name: <p>Feature Name </p>,
      selector: (row) => row.feature_name,
    },

    {
      name: <p>Description </p>,
      selector: (row) => row.description,
    },
    {
      name: <p>Status</p>,
      selector: (row) => row.status,
    },
    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      sortable: true,

      cell: (row) => {
        return (
          <UncontrolledDropdown className="dropdown d-inline-block" direction="right">
            <DropdownToggle className="btn btn-soft-danger btn-sm" tag="button">
              <i className="ri-more-fill align-middle"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem
                href="#!"
                onClick={(e) => {
                  setId(row.id);
                  setUpdatefeaturename(row.feature_name);
                  setUpdatedescription(row.description);
                  setUpdatefeatureicons(row.files);
                  setUpdatestatus(row.status);
                  setUpdatedicon(row.img);
                  setShow(true);
                }}
                //   onClick={() =>

                // }
              >
                <i className="ri-eye-fill align-bottom me-2 text-muted"></i>View
              </DropdownItem>

              <DropdownItem
                className="edit-item-btn"
                onClick={(e) => {
                  setId(row.id);
                  setUpdatefeaturename(row.feature_name);
                  setUpdatedescription(row.description);
                  setUpdatefeatureicons(row.files);
                  setUpdatedicon(row.img);
                  setUpdatestatus(row.status);
                  setModalShow(true);
                }}
              >
                <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
                Edit
              </DropdownItem>

              <DropdownItem
                onClick={() => {
                  Swal.fire({
                    title: "Are you sure?",
                    text: `Your sure .. u want to delete ${row.feature_name}!`,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                    background:"rgb(33, 37, 41)"
                  }).then((result) => {
                    if (result.isConfirmed) {
                      Swal.fire({
                        title: "Deleted ",
                       
                        icon: "success",
                        background:"rgb(33, 37, 41)"

                      }
                        // "Deleted!",
                        // " data has been deleted.",
                        // "success"
                        
                      );
                      axios
                        .delete(`/deleteCarFeatures?id=${row.id}`)
                        .then(() => {
                          // console.log("deleted");
                          togetallfeatureIcons();
                        })
                        .catch(() => {
                          // console.log("error deleting ");
                        });
                    }
                  });
                }}
                className="remove-item-btn"
              >
                {" "}
                <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                Delete{" "}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      },
    },
  ];
  //image uploads
  const imagesUpload = (e) => {
    e.preventDefault();
    const Image = e.target.files[0];
    // console.log(e.target.files[0].name);
    setUpdatefeatureicons(e.target.files[0].name);
    // setFiles(e.target.files[0].name)
    // console.log(Image);
    if (Image != null) {
      const data = new FormData();
      data.append("Image", Image);
      // console.log(data);
      axios
        .post("/uploadimg", data)
        .then(() => {
          // setFeaturename("")
          // setDescription("")
          // setStatus("")
          // setUploadimg("")
          // setStatus("")
          // console.log("Uploaded");
        })

        .catch(() => {
          // console.log("Error in Uploading");
        });
    }
  };

  return (
    <React.Fragment>
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="MASTER" pageTitle="Car Features" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0  text-centre">
                    Add Car Features{" "}
                  </h4>
                </CardHeader>
                <CardBody>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <Label
                        className="btn btn-outline-primary"
                        onClick={handelClick}
                        for="btn-check-outlined"
                      >
                        Add Car Features
                      </Label>
                    </Col>
                    <Col className="col-sm"></Col>
                  </Row>
                  <Row>
                    <Col className="col-12">
                      <Row></Row>
                    </Col>
                  </Row>
                  <CardBody className="p-2">
                    <div className="live-preview">
                      <div className="table-responsive">
                        {loaded ? (
                          <DataTable
                            columns={columns}
                            data={revdata}
                            pagination
                            theme="fulldark"
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </CardBody>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {show && (
            <ModalView
              setShow={setShow}
              show={show}
              id={id}
              featurename={updatefeaturename}
              files={updatefeatureicon}
              img={updatedicon}
              description={updatedescription}
              status={updatestatus}
            />
          )}
          <ToastContainer />
          {modalShow && (
            <UpdateCarFeatures
              setShow={setModalShow}
              show={modalShow}
              id={id}
              featurename={updatefeaturename}
              img={updatedicon}
              files={updatefeatureicon}
              description={updatedescription}
              status={updatestatus}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DisplayCarFeatures;
