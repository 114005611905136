import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const Footer = () => {
    return (
        <React.Fragment>
            <footer className="footer" style={{backgroundColor:"#212529"}}>
                <Container fluid>
              
                        <div style={{display:'flex' , justifyContent:"space-between"}}>
                            <div>
                            {new Date().getFullYear()} © Blck Luxury.
                            </div>
                       
                            <div className="text-sm d-none d-sm-block">
                                 Design & Developed by Religh Technologies
                            </div>
                  
                        </div>
                   
                </Container>
            </footer>
        </React.Fragment>
    );
};

export default Footer;