import React, { useState, useEffect } from "react";
import UiContent from "../AddCarFleet/UIContentDummy";
//import Components
import BreadCrumb from "../AddCarFleet/BreadcrumDummy";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import PreviewCardHeader from "../AddCarFleet/PreviewCardHeaderDummy";
import { Link } from "react-router-dom";
import {
  FormGrid,
  Gutters,
  VerticalForm,
  HorizontalForm,
  HorizontalFormLabelSizing,
  ColumnSizing,
  AutoSizing,
  InlineForms,
  FloatingLabels,
} from "../AddCarFleet/FormsLayoutDummy";
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { useNavigate } from "react-router-dom";
import BreadCrumbDummy from "../AddCarFleet/BreadcrumDummy";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "../../helpers/Axios";
// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);
//Import Flatepicker
// import "flatpickr/dist/themes/material_blue.css"
const Driver = () => {
  const [selectedFiles, setselectedFiles] = useState([]);
  const [files, setFiles] = useState([]);
  document.title = "Blck_luxury";

  const [driversname, setDriversname] = useState("");
  const [email, setEmail] = useState("");
  const [contact_no, setcontact_no] = useState("");
  const [altcontact_no, setaltcontact_no] = useState("");
  const [driving_lic_num, setdriving_lic_num] = useState("");
  const [aadar_num, setaadar_num] = useState("");
  const [pan_card_no, Setpan_card_no] = useState("");
  const [status, setstatus] = useState("");
  const [dob, setDob] = useState("");
  const [loc, setLoc] = useState("");
  const [pin, setPin] = useState("");
  const [img,setImg] =useState("");
  const [locationdetails, setLocationdetails] = useState([]);

  const [editdata, setEditdata] = useState("");

  const uploadimg = (e) => {
    e.preventDefault();
    const Image = e.target.files[0];
    // console.log(e.target.files[0].name);
    setImg(e.target.files[0].name);
    // setFiles(e.target.files[0].name)
    // console.log(Image);
    if (Image != null) {
      const data = new FormData();
      data.append("Image", Image);
      // console.log(data);
      axios
        .post("/driverimg", data)
        .then(() => {
          // setTimeout(()=>{
          //   setUploadinglogo("")
          // },1500)
          // console.log("Uploaded Image");
        })
        .catch(() => {
          // console.log("Error in Uploading image");
        });
    }
  };

  const emailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  const numberformat = /^[0-9.]+$/;
  const nameformat = /^[A-Za-z_.]+$/;
  const phoneNumPattern = /^\d{10}$/
  const aadhaarPattern = /^\d{12}$/;
  const panPattern = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
  // const drivingLicensePattern = /^[A-Z]{2}-\d{2}-\d{7}-\d{2}$/;
  const drivingLicensePattern = /^[A-Z]{2}\d{13}$/;
  const pinCodePattern = /^[1-9][0-9]{5}$/;

  function onsubmit(e) {
    e.preventDefault();
    if(driversname === ""){showToastforDriversName()}
    else if (!nameformat.test(driversname)){showToastforInvalidDriversName()}
    else if (email === ""){showToastforDriverEmail()}
    else if (!email.match(emailformat)){showToastforInvalidDriverEmail()}
    else if (img ===""){showToastforDriverImage()}
    else if (contact_no === ""){showToastforDriverContact()}
    else if (!phoneNumPattern.test(contact_no)){showToastforinvalidDriverContact()}
    else if (altcontact_no === ""){showToastforDriverAltContact()}
    else if (!phoneNumPattern.test(altcontact_no)){showToastforinvalidAlterDriverContact()}
    else if (contact_no === altcontact_no){showToastforinvalidSameDriverContact()}
    else if (driving_lic_num === ""){showToastforDrvingLicence()}
    else if (!drivingLicensePattern.test(driving_lic_num)){showToastforInvalidDrvingLicence()}
    else if (aadar_num === ""){showToastforAAdhar()}
    else if (!aadhaarPattern.test(aadar_num)){showToastforAAdharInvalid()}
    else if (pan_card_no === ""){showToastforPancardNumber()}
    else if (!panPattern.test(pan_card_no)){showToastforInvalidPancardNumber()}
    else if (dob === ""){showToastforDateOfBirth()}
    else if (loc === ""){showToastforDriverlocation()}
    else if (pin ===""){showToastforPin()}
    else if (!pinCodePattern.test(pin)){showToastforInvalidPin()}
    else if (status ===""){showToastforStatus()}
    else {
      let payload = {
        driversname,
        email,
        contact_no,
        altcontact_no,
        driving_lic_num,
        aadar_num,
        pan_card_no,
        dob,
        loc,
        img,
        pin,
        status,
      };
      axios
        .post("/driver_details", payload)
        .then(() => {
          showToastMessage();
          setTimeout(() => {
            setDriversname("");
            setEmail("");
            setcontact_no("");
            setaltcontact_no("")
            setdriving_lic_num("");
            setaadar_num("");
            Setpan_card_no("");
            setDob("");
            setLoc("");
            setPin("");
            setImg("");
            setstatus("");
          }, 1500);
          // console.log("data reached");
        })
        .catch(() => {
          // console.log("error saving data");
        });
    }
  }
  let jump = useNavigate();
  function handelclick() {
    jump("/Add_Drivers");
  }

  const showToastforDriversName = () => {
    toast.error("Please Fill Driver's Name", {
      position: toast.POSITION.TOP_CENTER,
      toastId: '1',
    });
  };
  const showToastforInvalidDriversName = () => {
    toast.error("Invalid Driver Name", {
      position: toast.POSITION.TOP_CENTER,
      toastId: '2',
    });
  };
  const showToastforDriverEmail = () => {
    toast.error("Enter Email", {
      position: toast.POSITION.TOP_CENTER,
      toastId: '3',
    });
  };
  const showToastforInvalidDriverEmail = () => {
    toast.error("Invalid Email", {
      position: toast.POSITION.TOP_CENTER,
      toastId: '4',
    });
  };
  const showToastforDriverImage = () => {
    toast.error("Please Upload Driver's Image", {
      position: toast.POSITION.TOP_CENTER,
      toastId: '5',
    });
  };
  const showToastforDriverContact = () => {
    toast.error(" Enter Contact", {
      position: toast.POSITION.TOP_CENTER,
       toastId: '6',
    });
  };
  const showToastforinvalidDriverContact = () => {
    toast.error("Invalid Contact Number", {
      position: toast.POSITION.TOP_CENTER,
      toastId: '7',
    });
  };
  const showToastforDriverAltContact = () => {
    toast.error("Enter Alternative Contact", {
      position: toast.POSITION.TOP_CENTER,
      toastId: '8',
    });
  };
  const showToastforinvalidAlterDriverContact = () => {
    toast.error("Invalid Alternative Contact Number", {
      position: toast.POSITION.TOP_CENTER,
      toastId: '9',
    });
  };
  const showToastforinvalidSameDriverContact = () => {
    toast.error("Contact and Alternative contact Can't be same", {
      position: toast.POSITION.TOP_CENTER,
      toastId: '10',
    });
  };
  //agent name not valid
  const showToastforDrvingLicence = () => {
    toast.error("Enter Driving Licence Number !", {
      position: toast.POSITION.TOP_CENTER,
      toastId: '11',
    });
  };
  const showToastforInvalidDrvingLicence = () => {
    toast.error(" Invalid Driving Licence Number !", {
      position: toast.POSITION.TOP_CENTER,
      toastId: '12',
    });
  };
  const showToastforAAdhar = () => {
    toast.error("Enter Aadhar Number !", {
      position: toast.POSITION.TOP_CENTER,
      toastId: '13',
    });
  };
  const showToastforAAdharInvalid = () => {
    toast.error("Invalid Aadhar number !", {
      position: toast.POSITION.TOP_CENTER,
      toastId: '14',
    });
  };
  const showToastforPancardNumber = () => {
    toast.error("Enter PanCard Number!", {
      position: toast.POSITION.TOP_CENTER,
      toastId: '15',
    });
  };
  const showToastforInvalidPancardNumber = () => {
    toast.error("Invalid PanCard Number!", {
      position: toast.POSITION.TOP_CENTER,
      toastId: '16',
    });
  };
  const showToastforDateOfBirth= () => {
    toast.error("Please Fill Driver's Date Of Birth!", {
      position: toast.POSITION.TOP_CENTER,
      toastId: '17',
    });
  };
  const showToastforDriverlocation= () => {
    toast.error(" Select Driver's Location", {
      position: toast.POSITION.TOP_CENTER,
      toastId: '18',
    });
  };
  const showToastforPin= () => {
    toast.error("Enter Driver's Pin!", {
      position: toast.POSITION.TOP_CENTER,
      toastId: '19',
    });
  };
  const showToastforInvalidPin= () => {
    toast.error("Ivalid Driver Pin!", {
      position: toast.POSITION.TOP_CENTER,
      toastId: '20',
    });
  };
  const showToastforStatus= () => {
    toast.error("Add Driver's Status!", {
      position: toast.POSITION.TOP_CENTER,
      toastId: '21',
    });
  };

  const showToastMessage = () => {
    toast.success("Driver Added Sucessfully !", {
      position: toast.POSITION.TOP_CENTER,
      toastId: '22',
    });
  };

  function Driverlocation() {
    axios
      .get("/location")
      .then((res) => {
        // console.log(res.data.location);
        setLocationdetails(res.data.location);
        // console.log("loc getting")
      })
      .catch(() => {
        // console.log("error getting locations");
      });
  }
  useEffect(()=>{
      Driverlocation()
  },[])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Master" pageTitle="Driver" />
          <Col xxl={12}>
            <Card>
              <PreviewCardHeader title="Add Driver" />
              <div className="card-body">
                <div className="live-preview">
                  <form action="#" className="row g-3">
                    <div className="mb-3">
                      <Row>
                        <Col md={6}>
                          <Label htmlFor="fullnameInput" className="form-label">
                            Driver Name
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="fullnameInput"
                            value={driversname}
                            onChange={(e) => {
                              setDriversname(e.target.value);
                              
                            }}
                            placeholder="Enter drivers name"
                          />
                        </Col>
                        <Col md={6}>
                          <Label htmlFor="fullnameInput" className="form-label">
                            Email
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="fullnameInput"
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                            placeholder="Enter Driver's Email"
                          />
                        </Col>
                      </Row>
                    </div>
                      <Row lg={12}>
                        <Col lg={12}>
                          <Card>
                            <CardHeader className="card-header">
                              <h5 className="text-center">Driver Image</h5>
                            </CardHeader>
                            <CardBody>
                              <p className="text-muted"></p>
                              <Input
                                type="file"
                                className="form-control"
                                id="carfile"
                                placeholder="upload icon"
                                  onChange={uploadimg}
                              />
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    <div className="mb-3">
                      <Row>
                        <Col md={6}>
                          <Label htmlFor="fullnameInput" className="form-label">
                            Contact Number{" "}
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="fullnameInput"
                            onChange={(e) => {
                              setcontact_no(e.target.value);
                            }}
                            placeholder="Enter contact number"
                          />
                        </Col>
                        <Col md={6}>
                          <Label htmlFor="fullnameInput" className="form-label">
                            Alternative Contact Number{" "}
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="fullnameInput"
                            onChange={(e) => {
                              setaltcontact_no(e.target.value);
                            }}
                            placeholder="Enter another contact number"
                          />
                        </Col>
                      </Row>
                    </div>
                    <div className="mb-3">
                      <Row>
                        <Col md={6}>
                          <Label htmlFor="licencenum" className="form-label">
                            Driving Licence Number
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="licencenum"
                            onChange={(e) => {
                              setdriving_lic_num(e.target.value);
                            }}
                            placeholder="Enter Driving licence number"
                          />
                        </Col>
                        <Col md={6}>
                          <Label htmlFor="aadharnum" className="form-label">
                            Aadhar Number
                          </Label>
                          <Input
                            type="number"
                            className="form-control"
                            id="aadharnum"
                            onChange={(e) => {
                              setaadar_num(e.target.value);
                            }}
                            placeholder="xxxx-xxxx-xxxx"
                          />
                        </Col>
                      </Row>
                    </div>
                    <div className="mb-3">
                      <Row>
                        <Col md={6}>
                          <Label
                            htmlFor="pancardlicence"
                            className="form-label"
                          >
                            Pancard Number
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="pancardlicence"
                            onChange={(e) => {
                              Setpan_card_no(e.target.value);
                            }}
                            placeholder="xxxx-xxx-xxx"
                          />
                        </Col>
                        <Col md={6}>
                          <Label
                            htmlFor="pancardlicence"
                            className="form-label"
                          >
                            Date of Birth
                          </Label>
                          <Input
                            type="date"
                            className="form-control"
                            id="pancardlicence"
                            onChange={(e) => {
                              setDob(e.target.value);
                            }}
                            placeholder="DD/MM/YYYY"
                          />
                        </Col>
                      </Row>
                      </div>
                      <div className="mb-3">
                          <Row>
                            <Col md={6}>
                              <Label
                                htmlFor="employeeName"
                                className="form-label"
                              >
                                Driver Location
                              </Label>
                              <select
                                id="inputState"
                                className="form-select"
                                value={loc}
                                data-choices
                                data-choices-sorting="true"
                                onChange={(e) => {
                                  setLoc(e.target.value);
                                }}
                              >
                                <option>Select Driver's Location</option>
                                {
                                    locationdetails.map((data)=>{
                                        return (
                                            <option key={data.id}>{data.location}</option>
                                        )
                                    })
                                }
                              </select>
                            </Col>
                            <Col md={6}>
                              <Label
                                htmlFor="pancardlicence"
                                className="form-label"
                              >
                                Pin Number
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="pancardlicence"
                                onChange={(e) => {
                                  setPin(e.target.value);
                                }}
                                placeholder="Enter your pin"
                              />
                            </Col>
                          </Row>
                      </div>
                    <Row className="mt-2">
                      <Col lg={12} md={6}>
                        <div className="justify-content-between d-flex align-items-center mb-3"></div>
                      </Col>
                    </Row>
                    {/* <Label htmlFor="statusdrive" className="form-label">
                      Status
                    </Label>
                    <div className="live-preview">
                      <div className="hstack gap-2 flex-wrap">
                        <Input
                          type="radio"
                          className="btn-check"
                          value="Active"
                          onClick={(e) => {
                            setstatus(e.target.value);
                          }}
                          name="options-outlined"
                          id="success-outlined"
                        />
                        <Label
                          className="btn btn-outline-success"
                          for="success-outlined"
                        >
                          Active
                        </Label>
                        <Input
                          type="radio"
                          className="btn-check"
                          value="Inactive"
                          onClick={(e) => {
                            setstatus(e.target.value);
                          }}
                          name="options-outlined"
                          id="danger-outlined"
                        />
                        <Label
                          className="btn btn-outline-danger"
                          for="danger-outlined"
                        >
                          Inactive
                        </Label>
                      </div>
                    </div> */}
                    <Col xl={4}>
                        <Label htmlFor="employeeName" className="form-label">
                          Status
                        </Label>

                        <select
                          id="inputState"
                          className="form-select"
                          value={status}
                          data-choices
                          data-choices-sorting="true"
                          onChange={(e) => {
                            setstatus(e.target.value);
                          }}
                        >
                          <option hidden>Select Status</option>
                          <option

                            value="Active"
                            onChange={(e) => {
                              setstatus(e.target.value);
                            }}
                          >Active</option>
                          <option
                            value="Inactive"
                            onChange={(e) => {
                              setstatus(e.target.value);
                            }}
                          >Inactive</option>

                        </select>
                      </Col>
                    <div className="text-center">
                      {/* <Input type="checkbox" className="btn-check" id="btn-check-outlined" /> */}
                      {/* <Label className="btn btn-outline-primary text-centre"  onClick={onsubmit} for="btn-check-outlined">Add</Label> */}
                      <Label
                        className="btn btn-outline-primary text-centre"
                        for="btn-check-outlined"
                        onClick={onsubmit}
                      >
                        Add
                      </Label>
                      &nbsp; &nbsp; &nbsp;
                      <Link to="/driver_list">
                        <Label
                          className="btn btn-outline-danger"
                          for="danger-outlinedremove"
                        >
                          Cancel
                        </Label>
                      </Link>
                      <br></br>
                    </div>
                  </form>
                </div>
                <div className="d-none code-view">
                  <pre className="language-markup" style={{ height: "375px" }}>
                    <code>
                      <Gutters />
                    </code>
                  </pre>
                </div>
              </div>
            </Card>
          </Col>
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  );
};
export default Driver;
